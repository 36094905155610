import React from 'react'
import { ScheduledPayment } from '../../types/ScheduledPayment'
import ScheduledPaymentNotice from './scheduled-payment-notice/ScheduledPaymentNotice'

interface Props {
    nextScheduledPayments: Array<ScheduledPayment>
}

const ScheduledPaymentSection = ({ nextScheduledPayments }: Props) => {
    const nextScheduledPaymentsContent =
        nextScheduledPayments && nextScheduledPayments.length > 0 ? (
            <div>
                {nextScheduledPayments.map((scheduledPayment, index) => {
                    return <ScheduledPaymentNotice key={index} scheduledPayment={scheduledPayment} />
                })}
            </div>
        ) : null

    return (
        <section className="scheduled-payment-section">
            <h3>Scheduled</h3>
            {nextScheduledPaymentsContent}
        </section>
    )
}

export default ScheduledPaymentSection
