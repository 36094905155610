import React, { useEffect } from 'react'
import './InvoiceHistory.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../reducers/app/RootState'
import { SFAlert, SFLoading } from '@simplifeye/component-library'
import { loadInvoiceHistory, loadInvoiceHistoryError } from '../reducers/invoice-history/invoiceHistory.actions'
import { useHistory } from 'react-router'
import InvoiceDetailsData from '../invoice-details/invoice-details-data/InvoiceDetailsData'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import RedoIcon from '@material-ui/icons/Redo'

const InvoiceHistory = () => {
    const { loading, error, invoiceDetailsResponse } = useSelector((state: RootState) => state.invoiceHistory)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        try {
            const chargeEventId = history.location.pathname.split('/').pop()
            dispatch(loadInvoiceHistory({ chargeEventId }))
        } catch (err) {
            dispatch(
                loadInvoiceHistoryError({ title: 'Invoice History Error', message: 'Invoice id unavailable in url' })
            )
        }
    }, [])

    if (loading) {
        return (
            <div className="invoice-history">
                <SFLoading fullScreen={true} />
            </div>
        )
    } else if (error.showError) {
        return (
            <div className="invoice-history">
                <div className="invoice-history-inner">
                    <div className="alert-wrapper">
                        <SFAlert severity="error" title={error.title} message={error.message} showAlert={true} />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="invoice-history">
                <div className="invoice-history-inner">
                    <InvoiceDetailsData invoiceDetails={invoiceDetailsResponse} isPaid={true} />
                    <div className="status">
                        <div className="item">
                            <label htmlFor="paymentStatus">Status</label>
                            <div id="paymentStatus" className="value">
                                {invoiceDetailsResponse.status === 'paid' ? (
                                    <div className="paid-wrapper">
                                        <AttachMoneyIcon /> <div>{invoiceDetailsResponse.status}</div>
                                    </div>
                                ) : (
                                    <div className="paid-wrapper">
                                        <RedoIcon /> <div>{invoiceDetailsResponse.status}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InvoiceHistory
