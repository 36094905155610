import React, { ChangeEvent, useState } from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import { Button } from '@material-ui/core'
import './EditPaymentPlan.scss'
import { useDispatch } from 'react-redux'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { SFSelect, SFLoading } from '@simplifeye/component-library'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import {
    handleEditActive,
    handleEditActiveCancel,
    handleEditSave,
} from '../../../reducers/payment-plan/paymentPlan.action'
import SFSelectOptions from '@simplifeye/component-library/lib/component/SFSelect/types/SFSelectOptions.types'
import { InvoicePaymentMethodsType, InvoicePaymentMethods } from '../../../types/Constants'
import { startCase, toLower } from 'lodash'

interface Props {
    paymentPlanId: string
    paymentPlanMethod: {
        // paymentType: InvoicePaymentMethodsType
        // bankLast4: string
        // bankName: string
        // cardType: string
        // last4: string
        tokenId: string
        cardToken: string
        nameOnCard: string
        last4: string
        cardExpirationDate: string
        payerId: string
        cardType: string
        cardFundingType: string
        activeFlag: boolean
        fingerprint: string
        bankName: string
        accountLast4: string
        achId: string
    }
    editPaymentPlan: {
        active: boolean
        loading: boolean
        paymentMethods: Array<SFSelectOptions>
        error: boolean
        selectedMethod: string // TODO remove
        success: boolean
        updatedPaymentPlanMethod: string
    }
    isCancelled: boolean
}

const EditPaymentPlan = ({
    paymentPlanId,
    paymentPlanMethod,
    editPaymentPlan: { active, loading, paymentMethods, error, success, updatedPaymentPlanMethod },
    isCancelled,
}: Props) => {
    const [selectedMethod, setSelectedMethod] = useState<string>('')
    const dispatch = useDispatch()

    const getPaymentMethodForDisplay = (paymentPlanMethod: {
        tokenId: string
        cardToken: string
        nameOnCard: string
        last4: string
        cardExpirationDate: string
        payerId: string
        cardType: string
        cardFundingType: string
        activeFlag: boolean
        fingerprint: string
        bankName: string
        accountLast4: string
        achId: string
    }) => {
        if (paymentPlanMethod.achId) {
            return `${startCase(toLower(paymentPlanMethod.bankName))} ****${paymentPlanMethod.accountLast4}`
        } else {
            return `${startCase(toLower(paymentPlanMethod.cardType))} ****${paymentPlanMethod.last4}`
        }
    }

    if (loading) {
        return (
            <div className="edit-payment-plan">
                {updatedPaymentPlanMethod ? updatedPaymentPlanMethod : getPaymentMethodForDisplay(paymentPlanMethod)}
                <div className="edit-card edit-loading">
                    <SFLoading width={24} />
                </div>
            </div>
        )
    } else if (error) {
        return (
            <div className="edit-payment-plan">
                {updatedPaymentPlanMethod ? updatedPaymentPlanMethod : getPaymentMethodForDisplay(paymentPlanMethod)}
                <div className="edit-card">
                    <div className="error">
                        <ErrorOutlineIcon />
                        <div>Unable to update at this time</div>
                    </div>
                </div>
            </div>
        )
    } else if (success) {
        return (
            <div className="edit-payment-plan">
                <div className="card-info">
                    {updatedPaymentPlanMethod
                        ? updatedPaymentPlanMethod
                        : getPaymentMethodForDisplay(paymentPlanMethod)}
                </div>
                <div className="edit-card">
                    <div className="success">
                        <CheckCircleOutlineIcon />
                        <div>Successfully updated payment method</div>
                    </div>
                </div>
            </div>
        )
    } else {
        if (!active) {
            return (
                <div className="edit-payment-plan">
                    <div className="card-info">
                        {updatedPaymentPlanMethod
                            ? updatedPaymentPlanMethod
                            : getPaymentMethodForDisplay(paymentPlanMethod)}
                    </div>
                    <div className="edit-card">
                        {!isCancelled ? (
                            <div className="edit-payment-plan">
                                <div
                                    className="edit-link"
                                    onClick={() => {
                                        dispatch(handleEditActive({ paymentPlanId }))
                                    }}
                                >
                                    <SettingsIcon />
                                    <span>Edit Payment Method</span>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="edit-payment-plan">
                    <div className="card-info">
                        {updatedPaymentPlanMethod
                            ? updatedPaymentPlanMethod
                            : getPaymentMethodForDisplay(paymentPlanMethod)}
                    </div>
                    <div className="edit-card">
                        <div className="active">
                            <SFSelect
                                options={paymentMethods}
                                defaultText="Select A Payment Method"
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setSelectedMethod(e.target.value)
                                }}
                            />
                            <div className="button-wrapper">
                                <Button
                                    className="save-button"
                                    onClick={() => {
                                        dispatch(handleEditSave({ paymentPlanId, selectedMethod }))
                                    }}
                                    disabled={!selectedMethod}
                                    id="editCardSaveButton"
                                >
                                    {selectedMethod === 'NEW' ? 'ADD' : 'SAVE'}
                                </Button>
                                <Button
                                    className="cancel-button"
                                    onClick={() => {
                                        dispatch(handleEditActiveCancel({ paymentPlanId }))
                                    }}
                                    disabled={false}
                                    id="editCardSaveButton"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default EditPaymentPlan
