import React from 'react'
import { Grid } from '@material-ui/core'
import '../PaymentMethod/PaymentMethod.scss'
import { capitalize } from 'lodash'
import DeleteIcon from '@material-ui/icons/Delete'
import { useDispatch } from 'react-redux'
import { startDeleteAchPaymentMethod } from '../../reducers/payment-methods/paymentMethods.action'
import { AchPaymentMethod } from '../../types/AchPaymentMethod'

interface Props {
    achPaymentMethod: AchPaymentMethod
}

const AchMethod = ({ achPaymentMethod }: Props) => {
    const dispatch = useDispatch()

    return (
        <div className={'payment-method'}>
            <Grid container={true} className={'basic-info'}>
                <Grid item={true} xs={11}>
                    <span className={'payment-description'}>
                        {`${capitalize(achPaymentMethod.bankName)} ****${achPaymentMethod.accountLast4}`}
                    </span>
                </Grid>
                <Grid item={true} xs={1} className="delete-container"
                      onClick={() => dispatch(startDeleteAchPaymentMethod({ achMethod: achPaymentMethod }))}>
                    <div className="delete">
                        <DeleteIcon />
                        <span>Delete</span>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default AchMethod
