import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import {
    submitInvoiceUseExistingMethod,
    onPayInvoiceUseExistingCancel,
    onInvoiceDialogErrorCancel,
    onInvoiceDialogSuccessBackToHome,
    onStripeManualCheckoutCancel,
} from '../../reducers/invoice-details/invoiceDetails.action'
import { getResetCode } from '../../reducers/login/login.action'

import SessionManager from '../../../SessionManager'
import { RootState } from '../../reducers/app/RootState'
import StripeManualCheckoutDialog from '../../shared/components/stripe-manual-checkout-dialog/StripeManualCheckoutDialog'
import { formateDateObject } from '../../shared/utils'
import { InvoicePaymentMethods, InvoicePaymentMethodsType } from '../../types/Constants'
import {
    resetStripeManualDialog,
    setupStripeDialog,
} from '../../reducers/stripe-manual-checkout-dialog/stripeManualCheckoutDialog.actions'

import './InvoiceDetailsDialog.scss'

interface Props {
    onPaymentMethodSelect: (paymentMethodType: InvoicePaymentMethodsType) => void
}

const InvoiceDetailsDialogReplacement = (props: Props) => {
    const {
        showInvoiceDialog,
        billingTotals,
        dialogLoading,
        stripePaymentSuccess,
        stripePaymentError,
        isAddingNewPaymentMethod,
        invoiceDetailsResponse: { transactionId: chargeEventId },
        selectedSavedPaymentMethod: paymentMethod,
        selectedPaymentMethod,
        isUsingSavedAchMethod,
        stripeData: { clientSecret, paymentIntent },
        applicationFees: allFees,
        total: allTotals,
    } = useSelector((state: RootState) => state.invoiceDetails)
    const { practiceId } = useSelector((state: RootState) => state.session)
    const { achPaymentMethod } = useSelector((state: RootState) => state.paymentMethods)
    const { code, accountStatus } = useSelector((state: RootState) => state.login)
    const locationId = SessionManager.sessionLocationId

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (accountStatus === 'pending') {
            dispatch(getResetCode({ emailAddress: SessionManager.sessionEmail }))
        }
    }, [accountStatus])

    let successButtonText

    if (SessionManager.isLoggedIn) {
        successButtonText = 'Back to Home'
    } else if (accountStatus === 'active') {
        successButtonText = 'Log In'
    } else {
        successButtonText = 'Make an Account'
    }

    useEffect(() => {
        if (showInvoiceDialog && (!isAddingNewPaymentMethod || (!!clientSecret && !!paymentIntent))) {
            dispatch(
                setupStripeDialog({
                    clientSecret: clientSecret,
                    chargeEventId: chargeEventId,
                    paymentIntentId: paymentIntent,
                    paymentMethod: selectedPaymentMethod,
                    billingTotals: {
                        subtotal: billingTotals.paymentDue - billingTotals.taxSubtotal,
                        tax: billingTotals.taxSubtotal,
                        fee: billingTotals.convenienceFeeSubtotal,
                        total: billingTotals.paymentDue,
                        allFees: allFees,
                        allTotals: allTotals,
                    },
                })
            )
        }
    }, [showInvoiceDialog, clientSecret, paymentIntent, isAddingNewPaymentMethod])

    if (!showInvoiceDialog) {
        return null
    }

    return (
        <StripeManualCheckoutDialog
            updatePaymentMethod={props.onPaymentMethodSelect}
            onClose={(isError?: boolean) => {
                if (!isError && isAddingNewPaymentMethod) {
                    dispatch(onStripeManualCheckoutCancel())
                } else {
                    dispatch(onInvoiceDialogErrorCancel())
                }
            }}
            onSuccessClick={() => {
                if (SessionManager.isLoggedIn) {
                    dispatch(onInvoiceDialogSuccessBackToHome())
                    dispatch(resetStripeManualDialog())
                    history.push('/overview')
                } else {
                    const encodedEmail = SessionManager.sessionEmail
                        ? Buffer.from(SessionManager.sessionEmail).toString('base64')
                        : ''

                    if (accountStatus === 'active') {
                        history.push('/login')
                    } else {
                        history.push(
                            `/create/${code}?s=${encodedEmail}&practiceId=${practiceId}&locationId=${locationId}`
                        )
                    }
                }
            }}
            successButtonText={successButtonText}
            isUseExistingPaymentMethod={!isAddingNewPaymentMethod}
            useExistingDetails={
                !isAddingNewPaymentMethod
                    ? (() => {
                          return {
                              rows: [
                                  {
                                      label: 'Invoice #',
                                      description: chargeEventId,
                                  },
                                  {
                                      label: 'Date',
                                      description: formateDateObject(new Date(), 'MM/DD/YYYY'),
                                  },
                                  {
                                      label: 'Payment Method',
                                      description: isUsingSavedAchMethod
                                          ? `${achPaymentMethod.bankName} ****${achPaymentMethod.accountLast4}`
                                          : `${paymentMethod.cardType} ****${paymentMethod.last4}`,
                                  },
                              ],
                              subtotal: billingTotals.paymentDue - billingTotals.taxSubtotal,
                              tax: billingTotals.taxSubtotal,
                              total: billingTotals.paymentDue,
                              onSubmit: async () => {
                                  await dispatch(submitInvoiceUseExistingMethod())
                              },
                              onCancel: () => {
                                  dispatch(onPayInvoiceUseExistingCancel())
                              },
                          }
                      })()
                    : undefined
            }
        />
    )
}

export default InvoiceDetailsDialogReplacement
