import React from 'react'
import './PaymentPlanCard.scss'
import { Collapse } from '@material-ui/core'
import { formatCurrencyCents, formatDate, getDayOfMonth } from '../../shared/utils'
import { TransactionTable, SFLoading, SFAlert } from '@simplifeye/component-library'
import { PaymentPlanHistory } from '../../types/PaymentPlanHistory'
import TransactionRows from '@simplifeye/component-library/lib/component/TransactionTable/types/TransactionRow.types'
import EditPaymentPlan from './EditPaymentPlan/EditPaymentPlan'
import { PaymentPlanCardType } from '../../types/PaymentPlanCardType'
import { useDispatch } from 'react-redux'
import { collapsePaymentPlan, expandPaymentPlan } from '../../reducers/payment-plan/paymentPlan.action'
import { startCase } from 'lodash'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { SFError } from '../../types/SFError'
import { PaymentPlan } from '../../types/PaymentPlan'
import PaymentPlanExpander from './payment-plan-expander/PaymentPlanExpander'

interface Props {
    plan: PaymentPlanCardType
    index: number
    paymentPlanDetailsLoading: boolean
    paymentPlanDetails: PaymentPlan
    error: SFError
    isCompleted: boolean
}

const PaymentPlanCard = ({ plan, index, paymentPlanDetailsLoading, error, paymentPlanDetails, isCompleted }: Props) => {
    const dispatch = useDispatch()

    const handleExpand = () => {
        if (plan.isExpanded) {
            dispatch(collapsePaymentPlan())
        } else {
            dispatch(expandPaymentPlan(plan.paymentPlanBasicInfo.paymentPlanId))
        }
    }

    const headers = ['Transaction #', 'Date Paid', 'Amount', 'Status']
    const mapPlanHistoryToRows = (planHistorys: Array<PaymentPlanHistory>): Array<TransactionRows> => {
        return planHistorys.reverse().map((planHistory: PaymentPlanHistory) => {
            return {
                classes: [],
                data: {
                    transactionNum: planHistory.chargeEventId,
                    datePaid: formatDate(planHistory.paidTs),
                    amount: formatCurrencyCents(planHistory.amount),
                    status: startCase(planHistory.paymentStatus),
                },
            }
        })
    }

    return (
        <div className="payment-plan-card" id={`paymentPlanCard${index}`}>
            <div className="card-header" onClick={handleExpand}>
                <div className="left">{`${plan.paymentPlanBasicInfo.paymentPlanId} - ${plan.paymentPlanBasicInfo.description}`}</div>
                {plan.paymentPlanBasicInfo.paymentPlanStatus === 'cancelled' ? (
                    <div className="middle cancelled">
                        <label htmlFor="status">Status</label>
                        <div className="value">Cancelled</div>
                    </div>
                ) : null}
                {isCompleted ? (
                    <div className="middle paid-off">
                        <label htmlFor="status">Status</label>
                        <div className="value">Completed</div>
                    </div>
                ) : null}
                <div className="right">{plan.isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>
            </div>
            <Collapse in={plan.isExpanded} unmountOnExit={true}>
                {paymentPlanDetailsLoading ? (
                    <div className="details-loading">
                        <SFLoading />
                    </div>
                ) : error.showError ? (
                    <div className="error-wrapper">
                        <SFAlert showAlert={true} severity={'error'} title={error.title} message={error.message} />
                    </div>
                ) : (
                    <div className="card-expanded">
                        {!isCompleted ? (
                            <PaymentPlanExpander
                                headerText={'Balance Remaining'}
                                total={paymentPlanDetails.balanceRemaining.total}
                                costBreakdown={[
                                    {
                                        label: 'Subtotal',
                                        value: paymentPlanDetails.balanceRemaining.subtotalBalance,
                                        ariaLabelId: 'balanceSubtotal',
                                    },
                                    {
                                        label: 'Tax',
                                        value: paymentPlanDetails.balanceRemaining.taxBalance,
                                        ariaLabelId: 'balanceTax',
                                    },
                                ]}
                                ariaLabelId={'balanceRemaining'}
                                isExpanded={false}
                            />
                        ) : null}
                        <PaymentPlanExpander
                            headerText={'Total Cost'}
                            total={paymentPlanDetails.planTotals.total}
                            costBreakdown={[
                                {
                                    label: 'Treatment Cost',
                                    value: paymentPlanDetails.planTotals.subtotal,
                                    ariaLabelId: 'totalSubtotal',
                                },
                                {
                                    label: 'Tax',
                                    value: paymentPlanDetails.planTotals.taxTotal,
                                    ariaLabelId: 'totalTax',
                                },
                            ]}
                            ariaLabelId={'totalCost'}
                            isExpanded={false}
                        />
                        {!isCompleted ? (
                            <PaymentPlanExpander
                                headerText={'Monthly Payment'}
                                total={paymentPlanDetails.monthlyPayment}
                                costBreakdown={[
                                    {
                                        label: 'Treatment Cost',
                                        value: paymentPlanDetails.monthlySubtotal,
                                        ariaLabelId: 'totalSubtotal',
                                    },
                                    {
                                        label: 'Tax',
                                        value: paymentPlanDetails.monthlyTaxAmount,
                                        ariaLabelId: 'totalTax',
                                    },
                                ]}
                                ariaLabelId={'totalCost'}
                                isExpanded={false}
                                infoMessage={'Last monthly payment may be less than shown above'}
                            />
                        ) : null}
                        <section className="plan-info">
                            <div className="row">
                                <div className="item">
                                    <label htmlFor="billingDate">Billing Date</label>
                                    <div id="billingDate" className="value">
                                        Billed monthly on the {getDayOfMonth(paymentPlanDetails.startDate)}
                                    </div>
                                </div>
                                <div className="item">
                                    <label htmlFor="startDate">Start Date</label>
                                    <div id="startDate" className="value">
                                        {formatDate(paymentPlanDetails.startDate, 'MM/DD/YYYY')}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="item">
                                    <label htmlFor="downPayment">Down Payment</label>
                                    <div id="downPayment" className="value">
                                        {formatCurrencyCents(paymentPlanDetails.downPayment)}
                                    </div>
                                </div>
                                <div className="item">
                                    <label htmlFor="endDate">End Date</label>
                                    <div id="endDate" className="value">
                                        {formatDate(paymentPlanDetails.endDate, 'MM/DD/YYYY')}
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <section>
                            <div className="currency-wrapper">
                                <h3 className="section-header">Amount Paid - {'$4.05'}</h3>
                                <div className="slider-wrapper">
                                    <Slider
                                        key={`slider-${50}`}
                                        defaultValue={50}
                                        marks={[
                                            {
                                                value: 0,
                                                label: '0°C',
                                            },
                                            {
                                                value: 100,
                                                label: '100°C',
                                            },
                                        ]}
                                        disabled={true}
                                        valueLabelDisplay="on"
                                        valueLabelFormat={''}
                                    />
                                </div>
                            </div>
                        </section> */}
                        {!isCompleted ? (
                            <section className="card-info-section">
                                <h3>Payment Method</h3>
                                <div className="card-info-wrapper">
                                    <EditPaymentPlan
                                        paymentPlanId={paymentPlanDetails.paymentPlanId}
                                        paymentPlanMethod={paymentPlanDetails.paymentPlanPaymentMethod}
                                        editPaymentPlan={plan.editPaymentPlan}
                                        isCancelled={paymentPlanDetails.paymentPlanStatus === 'cancelled'}
                                    />
                                </div>
                            </section>
                        ) : null}
                        <section className="payment-history">
                            <h3>Payment History</h3>
                            <TransactionTable
                                headers={headers}
                                rows={mapPlanHistoryToRows(paymentPlanDetails.paymentPlanHistory)}
                            />
                        </section>
                    </div>
                )}
            </Collapse>
        </div>
    )
}

export default PaymentPlanCard
