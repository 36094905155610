import React from 'react'
import { useDispatch } from 'react-redux'
import {
    onAddNewPaymentMethodSelection,
    onUseExistingAchSelection,
    onUseExistingCreditDebitMethodSelection,
} from '../../../reducers/pay-down-plan-dialog/payDownPlanDialog.actions'
import SelectPaymentMethod from '../../../shared/components/select-payment-method/SelectPaymentMethod'
import { AchPaymentMethod } from '../../../types/AchPaymentMethod'
import { PayerPaymentMethods } from '../../../types/Response/PayerPaymentMethods'

interface Props {
    isLoadingPaymentMethods: boolean
    paymentPlanSelected: string
    paymentMethods: PayerPaymentMethods[]
    achPaymentMethod: AchPaymentMethod
}

const PayDownPlanSelectMethod = ({
    paymentPlanSelected,
    paymentMethods,
    achPaymentMethod,
    isLoadingPaymentMethods,
}: Props) => {
    const dispatch = useDispatch()
    const onPaymentMethodSelectChange = (
        paymentMethod: string,
        isExistingPayment: boolean,
        savedPaymentMethod?: PayerPaymentMethods,
        achPaymentMethod?: AchPaymentMethod
    ) => {
        if (!isExistingPayment) {
            dispatch(onAddNewPaymentMethodSelection())
        } else if (achPaymentMethod) {
            dispatch(onUseExistingAchSelection({ achPaymentMethod }))
        } else {
            dispatch(onUseExistingCreditDebitMethodSelection({ savedCreditDebit: savedPaymentMethod }))
        }
    }

    return (
        <div className="pay-down-plan-dialog-inner">
            <div className="selected-wrapper">
                <label htmlFor="description">Payment Plan</label>
                <div id="description">{paymentPlanSelected}</div>
            </div>
            <div className="select-wrapper">
                <SelectPaymentMethod
                    isLoading={isLoadingPaymentMethods}
                    selectedPaymentMethod={null}
                    savedPaymentMethods={paymentMethods}
                    isAddingNewPaymentMethod={false}
                    achPaymentMethod={achPaymentMethod}
                    onNewPaymentMethodSelect={() => {}}
                    onPaymentMethodSelectChange={onPaymentMethodSelectChange}
                    isExternalInvoice={false}
                />
            </div>
        </div>
    )
}

export default PayDownPlanSelectMethod
