import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import './PaymentMethod.scss'
import { capitalize } from 'lodash'
import { PayerPaymentMethods } from '../../types/Response/PayerPaymentMethods'
import DeleteIcon from '@material-ui/icons/Delete'
import { useDispatch } from 'react-redux'
import { startDeletePaymentMethod } from '../../reducers/payment-methods/paymentMethods.action'
import WarningIcon from '@material-ui/icons/Warning'

interface Props {
    method: PayerPaymentMethods
    index: number
}

const PaymentMethod = ({ method, index }: Props) => {
    const [isCloseToExpired, setIsCloseToExpired] = useState<boolean>(false)
    const [isExpired, setIsExpired] = useState<boolean>(false)
    const dispatch = useDispatch()

    useEffect(() => {
        try {
            const [cardMonth, cardYear] = method.cardExpirationDate.split('/').map((i) => parseInt(i))
            const cardDate = new Date(cardYear, cardMonth - 1, 1).getTime()
            const cardDateNextMonth = new Date(cardYear, cardMonth - 2, 1).getTime()
            const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime()

            if (cardDate < currentDate) {
                setIsExpired(true)
            } else if (cardDate === currentDate || currentDate === cardDateNextMonth) {
                setIsCloseToExpired(true)
            } else {
                setIsExpired(false)
                setIsCloseToExpired(false)
            }
        } catch (err) {
            setIsExpired(false)
            setIsCloseToExpired(false)
        }
    }, [method])

    return (
        <div className={`payment-method ${isCloseToExpired ? 'close-expiration' : ''}`} id={`paymentMethod${index}`}>
        <Grid container={true} className={'basic-info'}>
          <Grid item={true} xs={12} className='card-info'>
            <Grid item={true} xs={6} sm={4} className='card-header-info'>
              <div className={'payment-description'}>{`${capitalize(method.cardType)} ****${method.last4}`}</div>
              {isCloseToExpired || isExpired ? (
                  <div className="info-text">
                      <WarningIcon />
                      {isCloseToExpired ? <div>Expiring Soon!</div> : null}
                      {isExpired ? <div>Expired!</div> : null}
                  </div>
              ) : null}
            </Grid> 
            <Grid item={true} xs={3} sm={4}>
              <div className={'expiration-date info-field'}>
                  <p className={'label'}>Exp Date</p>
                  <span>{method.cardExpirationDate}</span>
              </div>
            </Grid>
            <Grid item={true} xs={3} sm={4} className="delete-container">
              <div className="delete" onClick={() => dispatch(startDeletePaymentMethod({ method }))}>
                  <DeleteIcon />
                  <span>Delete</span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        </div>
    )
}

export default PaymentMethod
