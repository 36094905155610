import React from 'react'
import { History } from 'history'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router'
import { Banner } from '@simplifeye/component-library'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../reducers/app/RootState'
import SectionSelect from './section-select/SectionSelect'
import { logUserOutFromSession } from '../reducers/session/session.actions'

import './Header.scss'

interface Props {
    hideLogout?: boolean
    overrideBannerText?: string
}

const HeaderUI = ({ hideLogout, overrideBannerText }: Props) => {
    const {
        currentPracticeName,
        payer,
        portalUserId,
        activeSection,
        logoUrl,
        logoBackgroundColor,
        practiceId,
        locationId,
    } = useSelector((state: RootState) => ({
        currentPracticeName: state.session.practiceInfo.practiceName,
        payer: state.header.payer,
        portalUserId: state.session.portalUserId,
        activeSection: state.header.activeSection,
        logoUrl: state.session.logoUrl,
        logoBackgroundColor: state.session.logoBackgroundColor,
        practiceId: state.session.practiceId,
        locationId: state.session.locationId,
    }))

    const history = useHistory()
    const dispatch = useDispatch()

    return (
        <div className="header">
            <div className="header-wrapper">
                <div
                    className="logo"
                    onClick={() => {
                        history.push('/overview')
                    }}
                >
                    {logoUrl ? (
                        <img
                            src={logoUrl}
                            style={{ backgroundColor: logoBackgroundColor }}
                            alt="current practice logo"
                        />
                    ) : (
                        currentPracticeName
                    )}
                </div>
                <div className="title">Payment Center</div>
            </div>
            <div className="banner-wrapper">
                <Banner text={!!overrideBannerText ? overrideBannerText : `${payer.firstName} ${payer.lastName}`} />
                {hideLogout !== true && (
                    <div className="button-wrapper">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                dispatch(logUserOutFromSession())
                                history.push('/login')
                            }}
                            id="logOffButton"
                        >
                            Log Off
                        </Button>
                    </div>
                )}
            </div>

            {history.location.pathname.includes('external-invoice') ? null : (
                <div className="section-select-wrapper">
                    <SectionSelect activeSection={activeSection} />
                </div>
            )}
        </div>
    )
}

export default HeaderUI
