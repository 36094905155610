import React, { useEffect, useState } from 'react'
import '../LoginShared.scss'
import './Create.scss'
import SimplifeyeVyneLogo from '../../icons/SimplifeyeVyneLogo'
import { SFAlert } from '@simplifeye/component-library'
import { RegisterAccountIcon } from '../../icons/LoginIcons'
import { Spinner } from '../../login/Spinner'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import PasswordContainer from '../password-container/PasswordContainer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../reducers/app/RootState'
import {
    registerNewUserAccount,
    registerNewUserAccountError,
    getLocationLogo,
    registerNewUserAccountExternalInvoice,
} from '../../reducers/login/login.action'
import { useHistory, useLocation } from 'react-router'

const Create = () => {
    const {
        error,
        isLoading,
        goToLoginFromAccountCreation,
        logoUrl,
        externalInvoice: { inExternalInvoice },
        logoBackgroundColor,
    } = useSelector((state: RootState) => state.login)
    const [password, setPassword] = useState<string>('')
    const [metAllRequirements, setMetAllRequirements] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [code, setCode] = useState<string>('')
    const [externalInvoice, setExternalInvoice] = useState<{
        practiceId: string
        locationId: string
        isExternalInvoice: boolean
    }>({
        practiceId: '',
        locationId: '',
        isExternalInvoice: false,
    })

    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()

    const onCreateNewAccount = () => {
        if (!externalInvoice.isExternalInvoice) {
            dispatch(registerNewUserAccount({ email, password, code }))
        } else {
            dispatch(
                registerNewUserAccountExternalInvoice({
                    email,
                    password,
                    code,
                    practiceId: externalInvoice.practiceId,
                    locationId: externalInvoice.locationId,
                })
            )
        }
    }

    const getEmailCodeAndPracticeIdFromUrl = () => {
        try {
            const queryObj = new URLSearchParams(location.search)
            const emailFromUrl = atob(queryObj.get('s'))
            setEmail(emailFromUrl)
            const codeFromUrl = location.pathname.split('/').pop()
            setCode(codeFromUrl)
            if (!emailFromUrl || !codeFromUrl) {
                throw 'Email or code is missing'
            }

            const practiceId = queryObj.get('practiceId')
            const locationId = queryObj.get('locationId')
            dispatch(getLocationLogo({ practiceId, locationId }))

            // for external invoice
            const isExternalInvoice = queryObj.get('externalReset')
            if (isExternalInvoice) {
                setExternalInvoice({
                    practiceId,
                    locationId: queryObj.get('locationId'),
                    isExternalInvoice: true,
                })
            }
        } catch (err) {
            dispatch(registerNewUserAccountError({ title: 'Error', message: 'Unable to get email or code from url' }))
        }
    }

    useEffect(() => {
        getEmailCodeAndPracticeIdFromUrl()
    }, [])

    useEffect(() => {
        if (goToLoginFromAccountCreation) {
            history.push('/login')
        }
    }, [goToLoginFromAccountCreation, inExternalInvoice])

    return (
        <div className="login create">
            <div className="login-box">
                <div className="login-box-inner">
                    <div className="logo-or-lock-icon">
                        {logoUrl ? (
                            <div className="logo" style={{ backgroundColor: logoBackgroundColor }}>
                                <img src={logoUrl} alt="the logo of the practice" />
                            </div>
                        ) : (
                            <div className="lock-icon">
                                <RegisterAccountIcon />
                            </div>
                        )}
                    </div>
                    <div className="error-wrapper">
                        <SFAlert
                            showAlert={error.showError}
                            severity={'error'}
                            title={error.title}
                            message={error.message}
                        />
                    </div>
                    <div>
                        <PasswordContainer
                            password={password}
                            isError={error.showError}
                            setPassword={setPassword}
                            setMetAllRequirements={setMetAllRequirements}
                        />
                    </div>
                    <div className="login-button-wrapper">
                        {isLoading ? (
                            <div className="create-spinner">
                                <Spinner />
                            </div>
                        ) : (
                            <button
                                disabled={!metAllRequirements}
                                onClick={() => {
                                    onCreateNewAccount()
                                }}
                            >
                                <LockOpenIcon />
                                <div>Create Account</div>
                            </button>
                        )}
                    </div>
                    <div className="already-have-account">
                        Already have an account?{' '}
                        <a
                            onClick={() => {
                                history.push('/login')
                            }}
                        >
                            Click here to log in
                        </a>
                    </div>
                    <div className="terms-and-conditions">
                        By clicking CREATE ACCOUNT, you agree to our
                        <a href="https://simplifeye.co/terms/" target="_blank">
                            Terms
                        </a>
                        and have read and acknowledged our
                        <a href="https://simplifeye.co/privacy-policy/" target="_blank">
                            Privacy Statement
                        </a>
                    </div>
                </div>
            </div>
            <div className="powered-by">
                powered by <SimplifeyeVyneLogo />
            </div>
        </div>
    )
}

export default Create
