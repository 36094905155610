import { OverviewState } from './overview.state'
import initialState from './overview.initial'
import { TransactionTableDefaults } from '../../types/Constants/TransactionTableDefaults'
import {
    Action,
    LOAD_OVERVIEW_DATA_SUCCESS,
    LOAD_OVERVIEW_DATA_ERROR,
    REQUEST_PATIENT_TRANSACTIONS_SUCCESS,
    NO_PATIENT_TRANSACTIONS,
    REQUEST_UNPAID_INVOICES_SUCCESS,
    NO_UNPAID_INVOICES,
    REQUEST_LATE_INVOICES_SUCCESS,
    NO_LATE_INVOICES,
    REQUEST_NEXT_SCHEDULE_PAYMENT_SUCCESS,
    NO_SCHEDULED_PAYMENTS,
    REQUEST_PENDING_INVOICES_SUCCESS,
    NO_PENDING_INVOICES,
    NO_FAILED_PAYMENTS,
    FAILED_PAYMENTS_SUCCESS,
    NO_FAILED_INVOICES,
    REQUEST_FAILED_INVOICES_SUCCESS,
    REQUEST_LOAD_OVERVIEW_DATA,
} from './overview.action'

const overviewReducer = (state: OverviewState = initialState, action: Action): OverviewState => {
    switch (action.type) {
        case REQUEST_LOAD_OVERVIEW_DATA: {
            return {
                ...state,
                loading: true,
            }
        }
        case LOAD_OVERVIEW_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: {
                    ...initialState.error,
                },
            }
        }
        case LOAD_OVERVIEW_DATA_ERROR: {
            return {
                ...state,
                loading: false,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case REQUEST_PATIENT_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                payerTransactions: {
                    ...action.payload.payerTransactions,
                    pagination: {
                        ...action.payload.payerTransactions.pagination,
                        pageNumber: action.payload.payerTransactions.pagination.pageNumber - 1, // TODO pagination is zero based index
                    },
                },
            }
        }
        case NO_PATIENT_TRANSACTIONS: {
            return {
                ...state,
                payerTransactions: {
                    transactions: [],
                    pagination: {
                        totalRecords: 0,
                        totalPages: 0,
                        pageNumber: 1,
                        pageSize: TransactionTableDefaults.NUMBER_OF_RECORDS,
                    },
                },
            }
        }
        case REQUEST_UNPAID_INVOICES_SUCCESS: {
            return {
                ...state,
                unpaidInvoices: action.payload.unpaidInvoices,
            }
        }
        case NO_UNPAID_INVOICES: {
            return {
                ...state,
                unpaidInvoices: [],
            }
        }
        case REQUEST_LATE_INVOICES_SUCCESS: {
            return {
                ...state,
                lateInvoices: action.payload.lateInvoices,
            }
        }
        case NO_LATE_INVOICES: {
            return {
                ...state,
                lateInvoices: [],
            }
        }
        case REQUEST_NEXT_SCHEDULE_PAYMENT_SUCCESS: {
            return {
                ...state,
                nextScheduledPayments: action.payload.nextScheduledPayments,
            }
        }
        case NO_SCHEDULED_PAYMENTS: {
            return {
                ...state,
                nextScheduledPayments: [],
            }
        }
        case REQUEST_PENDING_INVOICES_SUCCESS: {
            return {
                ...state,
                pendingInvoices: action.payload.pendingInvoices,
            }
        }
        case NO_PENDING_INVOICES: {
            return {
                ...state,
                pendingInvoices: [],
            }
        }
        case NO_FAILED_PAYMENTS: {
            return {
                ...state,
                failedPayments: [],
            }
        }
        case FAILED_PAYMENTS_SUCCESS: {
            return {
                ...state,
                failedPayments: action.payload.failedPayments,
            }
        }
        case NO_FAILED_INVOICES: {
            return {
                ...state,
                failedInvoices: [],
            }
        }
        case REQUEST_FAILED_INVOICES_SUCCESS: {
            return {
                ...state,
                failedInvoices: action.payload.failedInvoices,
            }
        }
        default:
            return { ...state }
    }
}

export default overviewReducer
