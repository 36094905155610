import { PayerInfo } from '../../types/PayerInfo'
import Api from '../../api/Api'
import { ActiveSection } from './header.state'

const GET_PAYER_INFO_SUCCESS = '[HEADER] GET PAYER INFO SUCCESS'
const GET_PAYER_INFO_ERROR = '[HEADER] GET PAYER INFO ERROR'
const CHANGE_ACTIVE_SECTION = '[HEADER] CHANGE ACTIVE SECTION'

export type HeaderActions =
    | { type: typeof GET_PAYER_INFO_SUCCESS; payload: { payers: Array<PayerInfo> } }
    | { type: typeof GET_PAYER_INFO_ERROR; payload: { title: string; message: string } }
    | { type: typeof CHANGE_ACTIVE_SECTION; payload: { activeSection: ActiveSection } }

const getPayerInfo = (portalUserId: string) => {
    return async (dispatch: any) => {
        try {
            const {
                data: { payers },
            } = await Api.getPayerInfo(portalUserId)
            return dispatch(getPayerInfoSuccess({ payers }))
        } catch (err) {
            const error = {
                title: 'Payer Info',
                message: `Error getting first/last name for portalUserId: ${portalUserId}`,
            }
            dispatch(getPayerInfoError(error))
            throw error
        }
    }
}

const getPayerInfoSuccess = (payload: { payers: Array<PayerInfo> }) => {
    return {
        type: GET_PAYER_INFO_SUCCESS,
        payload,
    }
}

const getPayerInfoError = (payload: { title: string; message: string }) => {
    return {
        type: GET_PAYER_INFO_ERROR,
        payload,
    }
}

const changeActiveSection = (payload: { activeSection: ActiveSection }) => {
    return {
        type: CHANGE_ACTIVE_SECTION,
        payload,
    }
}

export { GET_PAYER_INFO_SUCCESS, GET_PAYER_INFO_ERROR, CHANGE_ACTIVE_SECTION, getPayerInfo, changeActiveSection }
