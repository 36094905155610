import React, { useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import InputBox from '../../../../ui/Input'
import { StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox } from '@material-ui/core'
import { formatCurrencyCents } from '../../../../shared/utils'
import { RootState } from '../../../../reducers/app/RootState'
import { SFLoading } from '@simplifeye/component-library'
import {
    onStripeManualCheckoutError,
    onStripeManualCheckoutSuccess,
    changeChargeEventStatusToPending,
    updateMismatchCardPaymentAmount,
    updateExternalInvoiceMismatchCardPaymentAmount,
    updateEnableSavePaymentMethod,
} from '../../../../reducers/stripe-manual-checkout-dialog/stripeManualCheckoutDialog.actions'
import { InvoicePaymentMethods, InvoicePaymentMethodsType } from '../../../../types/Constants'

import './StripeCheckoutForm.scss'

interface Props {
    isExternalInvoice?: boolean
    onClose: () => void
    isZeroOrMixedAccount?: boolean
    shouldPreventStripePending?: boolean
    showNewUIForZeroAndMixed?: boolean
    updatePaymentMethod?: (paymentMethod: InvoicePaymentMethodsType) => void
}

const StripeCheckoutForm = ({
    isExternalInvoice,
    isZeroOrMixedAccount,
    onClose,
    shouldPreventStripePending,
    showNewUIForZeroAndMixed,
    updatePaymentMethod,
}: Props) => {
    const stripe = useStripe()
    const elements = useElements()
    const [name, setName] = useState('')
    const [showNameError, setShowNameError] = useState(false)
    const [stripeInfoComplete, setStripeInfoComplete] = useState(false)
    const [stripeErrorMessage, setStripeErrorMessage] = useState('')
    const [canSubmitStripe, setCanSubmitStripe] = useState(false)
    const [isDebitFees, setIsDebitFees] = useState(false)
    const [isPrepaidFees, setIsPrepaidFees] = useState(false)
    const [requestSent, setRequestSent] = useState(false)
    const [shouldSaveCard, setShouldSaveCard] = useState(true)

    const dispatch = useDispatch()

    const {
        clientSecret,
        chargeEventId,
        paymentIntentId,
        paymentMethod: selectedPaymentMethod,
        billingTotals,
        practiceId,
    } = useSelector((state: RootState) => ({
        ...state.stripeManualCheckoutDialog,
        practiceId: state.session.practiceId,
    }))

    const cardStyleOptions = {
        style: {
            base: {
                backgroundColor: '#ffffff',
                fontSize: '16px',
                color: '#ABABAB',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#ABABAB',
                },
            },
            invalid: {
                color: '#c82323',
            },
        },
    }

    const onUpdatePaymentMethod = (paymentMethod: InvoicePaymentMethodsType) => {
        if (updatePaymentMethod) {
            updatePaymentMethod(paymentMethod)
        }
    }

    const handleSubmitPayment = async () => {
        if (!elements || !stripe || requestSent) {
            return
        }
        const cardElement = elements.getElement(CardElement)

        if (cardElement) {
            setCanSubmitStripe(false)
            setRequestSent(true)
            try {
                await handleUpdateFees(cardElement)
            } catch (err) {
                dispatch(
                    onStripeManualCheckoutError({
                        title: 'Payment Error',
                        message:
                            'There was an issue with your payment. If the problem persists, please contact your dental office',
                    })
                )
                setRequestSent(false)
                return
            }
            if (shouldPreventStripePending !== true) {
                dispatch(changeChargeEventStatusToPending({ chargeEventId }))
            }
            const { paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: name,
                    },
                },
            })

            if (paymentIntent) {
                dispatch(onStripeManualCheckoutSuccess())
            } else {
                dispatch(
                    onStripeManualCheckoutError({
                        title: 'Payment Error',
                        message:
                            'There was an issue with your payment. If the problem persists, please contact your dental office',
                    })
                )
                setRequestSent(false)
            }
        }
    }

    const handleUpdateFees = async (cardElement: StripeCardElement) => {
        let { paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name,
            },
        })

        const _cardFundingType = paymentMethod.card.funding.toLowerCase()
        const _selectedCardType: InvoicePaymentMethodsType = selectedPaymentMethod

        let isMismatch = false
        let mismatchPayMethod: InvoicePaymentMethodsType

        if (
            (_selectedCardType === 'card' || _selectedCardType === 'credit') &&
            ['debit', 'unknown', null].indexOf(_cardFundingType) > -1 &&
            !isDebitFees
        ) {
            // if not credit, update to debit fees (we use credit total and fees by default)
            isMismatch = true
            mismatchPayMethod = InvoicePaymentMethods.DEBIT
            onUpdatePaymentMethod(InvoicePaymentMethods.DEBIT)
            setIsDebitFees(true)
            setIsPrepaidFees(false)
        } else if (isDebitFees || (isPrepaidFees && _cardFundingType === 'credit')) {
            // if previously switched to debit or prepaid fees, and now using credit, switch to credit fees
            isMismatch = true
            mismatchPayMethod = InvoicePaymentMethods.CREDIT
            onUpdatePaymentMethod(InvoicePaymentMethods.CREDIT)
            setIsDebitFees(false)
            setIsPrepaidFees(false)
        } else if (!isPrepaidFees && _cardFundingType === 'prepaid') {
            isMismatch = true

            mismatchPayMethod = InvoicePaymentMethods.PREPAID
            onUpdatePaymentMethod(InvoicePaymentMethods.PREPAID)
            setIsDebitFees(false)
            setIsPrepaidFees(true)
        } else if (_cardFundingType === 'credit' && (_selectedCardType === 'card' || _selectedCardType === 'credit')) {
            onUpdatePaymentMethod(InvoicePaymentMethods.CREDIT)
        }

        if (isMismatch) {
            if (isExternalInvoice) {
                await dispatch(updateExternalInvoiceMismatchCardPaymentAmount(chargeEventId, mismatchPayMethod))
            } else {
                await dispatch(
                    updateMismatchCardPaymentAmount(
                        practiceId,
                        paymentIntentId,
                        billingTotals.allTotals[mismatchPayMethod],
                        shouldSaveCard,
                        billingTotals.allFees[mismatchPayMethod]
                    )
                )
            }
        } else if (!isExternalInvoice) {
            await dispatch(updateEnableSavePaymentMethod(practiceId, paymentIntentId, shouldSaveCard))
        }
    }

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        setShowNameError(!e.target.value)
        checkCanSubmitStripe(e.target.value, stripeInfoComplete)
    }

    const handleStripeInputChange = (event: StripeCardElementChangeEvent) => {
        if (event.complete) {
            setStripeInfoComplete(true)
            setStripeErrorMessage('')
            checkCanSubmitStripe(name, true)
        } else {
            setStripeInfoComplete(false)
            checkCanSubmitStripe(name, false)
            if (event.error) {
                setStripeErrorMessage(event.error.message)
            }
        }
    }

    const checkCanSubmitStripe = (name: string, stripeInfoComplete: boolean) => {
        if (stripeInfoComplete && !!name) {
            setCanSubmitStripe(true)
        } else {
            setCanSubmitStripe(false)
        }
    }

    if (!stripe && !elements) {
        return (
            <div className="stripe-error">
                Error setting up payment. Please try again, or contact practice if problem persists.
            </div>
        )
    } else {
        return (
            <div className="stripe-checkout-form">
                {requestSent ? (
                    <div className="loading">
                        <SFLoading />
                    </div>
                ) : null}
                <form>
                    <div className="center-wrapper">
                        <div className="checkout-header">
                            <span className="big">Credit Card Information</span>
                            <span className="small">(all fields required)</span>
                        </div>
                        <div className="input-container">
                            <label>Credit Card</label>
                            <div className="card-element-wrapper">
                                <CardElement onChange={handleStripeInputChange} options={cardStyleOptions} />
                            </div>
                            <div className="error" role="alert">
                                {stripeErrorMessage}
                            </div>
                        </div>
                        <div className="input-container">
                            <label>Name on Card</label>
                            <InputBox
                                name="cardHolderName"
                                value={name}
                                onChange={handleNameChange}
                                showError={showNameError}
                                errorText="Name is required"
                            />
                        </div>
                        {!isExternalInvoice && (
                            <div className="input-container compliance-container">
                                <Checkbox
                                    id="compliance-checkbox"
                                    color="primary"
                                    onChange={e => setShouldSaveCard(e.target.checked)}
                                    checked={shouldSaveCard}
                                />
                                <label htmlFor="compliance-checkbox" className="compliance-text">
                                    Save Card for future payments
                                </label>
                            </div>
                        )}
                    </div>

                    {showNewUIForZeroAndMixed !== true && (
                        <div className="cost-summary-wrapper">
                            <div className="cost-summary treatment-cost">
                                <h4>Subtotal</h4>
                                <span>{formatCurrencyCents(billingTotals.subtotal)}</span>
                            </div>
                            {billingTotals.tax > 0 ? (
                                <div className="cost-summary treatment-cost">
                                    <h4>Sales Tax</h4>
                                    <span>{formatCurrencyCents(billingTotals.tax)}</span>
                                </div>
                            ) : null}
                            {isZeroOrMixedAccount && billingTotals.fee > 0 && (
                                <div className="cost-summary convenience-fee">
                                    <h4>Convenience Fee</h4>
                                    <span>{formatCurrencyCents(billingTotals.fee)}</span>
                                </div>
                            )}
                            <div className="cost-summary payment-due">
                                <h4>Payment Due</h4>
                                <span>{formatCurrencyCents(billingTotals.total)}</span>
                            </div>
                        </div>
                    )}
                    {showNewUIForZeroAndMixed === true && (
                        <div className="cost-summary-wrapper cost-summary-side-by-side">
                            <div>
                                <h4>Regular Price (Credit)</h4>
                                <div className="cost-summary-sbs-price">
                                    {formatCurrencyCents(billingTotals.allTotals['credit'] || 0)}
                                </div>
                            </div>
                            <div>
                                <h4>Discount Price (Debit)</h4>
                                <div className="cost-summary-sbs-price">
                                    {formatCurrencyCents(billingTotals.allTotals['debit'] || 0)}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="button-wrapper">
                        <div className="center-wrapper">
                            <Button
                                className="review-payment-btn cancel"
                                onClick={() => {
                                    if (!requestSent) {
                                        onClose()
                                    }
                                }}
                            >
                                <span className="review-payment">Cancel</span>
                            </Button>

                            <Button
                                className="review-payment-btn pay"
                                onClick={handleSubmitPayment}
                                disabled={!canSubmitStripe}
                            >
                                <span className="review-payment">Submit Payment</span>
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default StripeCheckoutForm
