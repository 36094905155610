import { Dispatch } from 'react'
import Api from '../../api/Api'
import { RootState } from '../app/RootState'
import { apiResponseContainErrorCode } from '../../shared/utils'
import { PaymentPlan } from '../../types/PaymentPlan'
import { PaymentPlanCardType } from '../../types/PaymentPlanCardType'
import { BalanceRemainingResponse } from '../../types/Response/BalanceRemainingResponse'
import {
    cancelAddNewMethod,
    addNewPaymentMethodSuccess,
    addNewPaymentMethodSetupDefaultPlan,
    addNewPaymentMethodSetupError,
    showAddNewMethodDialog,
    addNewMethodLoading,
} from '../add-new-method/addNewMethod.actions'
import { PayerPaymentMethods } from '../../types/Response/PayerPaymentMethods'
import SFSelectOptions from '@simplifeye/component-library/lib/component/SFSelect/types/SFSelectOptions.types'
import { AchPaymentMethod } from '../../types/AchPaymentMethod'
import { PaymentPlanBasicInfo } from '../../types/PaymentPlanBasicInfo'
import {
    onStripeAddCardSuccessPayDownFlow,
    onPlaidSuccessPayDownFlow,
} from '../pay-down-plan-dialog/payDownPlanDialog.actions'

const REQUEST_PAYMENT_PLANS = '[PAYMENT PLANS] REQUEST PAYMENT PLANS'
const NO_PAYMENT_PLANS_FOUND = '[PAYMENT PLANS] NO PAYMENT PLANS FOUND'
const RECIEVE_PAYMENT_PLANS_SUCCESS = '[PAYMENT PLANS] REVIEVE PAYMENT PLANS SUCCESS'
const RECIEVE_PAYMENT_PLANS_ERROR = '[PAYMENT PLANS] REVIEVE PAYMENT PLANS ERROR'
const EXPAND_PAYMENT_PLAN_REQUEST = '[PAYMENT PLANS] EXPAND PAYMENT PLAN REQUEST'
const EXPAND_PAYMENT_PLAN_SUCCESS = '[PAYMENT PLANS] EXPAND PAYMENT PLAN SUCCESS'
const EXPAND_PAYMENT_PLAN_ERROR = '[PAYMENT PLANS] EXPAND PAYMENT PLAN ERROR'
const COLLAPSE_PAYMENT_PLAN = '[PAYMENT PLANS] COLLAPSE PAYMENT PLAN'
const HANDLE_EDIT_REQUEST_PAYMENT_METHODS = '[PAYMENT PLANS] HANDLE EDIT REQUEST PAYMENT METHODS'
const HANDLE_EDIT_ACTIVE_SUCCESS = '[PAYMENT PLANS] HANDLE EDIT ACTIVE SUCCESS'
const HANDLE_EDIT_ACTIVE_ERROR = '[PAYMENT PLANS] HANDLE EDIT ACTIVE ERROR'
const HANDLE_EDIT_ACTIVE_CANCEL = '[PAYMENT PLANS] HANDLE EDIT ACTIVE CANCEL'
const HANDLE_EDIT_SAVE_EXISTING_METHOD_SUCCESS = '[PAYMENT PLANS] HANDLE EDIT SAVE EXISTING METHOD SUCCESS'
const HANDLE_EDIT_SAVE_EXISTING_METHOD_COMPLETE = '[PAYMENT PLANS] HANDLE EDIT SAVE EXISTING COMPLETE'
const HANDLE_EDIT_SAVE_EXISTING_METHOD_ERROR = '[PAYMENT PLANS] HANDLE EDIT SAVE EXISTING METHOD ERROR'
const RESET_EDIT_PAYMENT_PLAN = '[PAYMENT PLANS] RESET EDIT PAYMENT PLAN'
const SHOW_SELECT_PAYMENT_PLAN_DIALOG = '[PAYMENT PLANS] SHOW SELECT PAYMENT PLAN DIALOG'
const UPDATE_UPDATED_PAYMENT_PLAN_METHOD = '[PAYMENT PLANS] UPDATE UPDATED PAYMENT PLAN METHOD'
const CLOSE_SELECT_PAYMENT_PLAN_DIALOG = '[PAYMENT PLANS] CLOSE SELECT PAYMENT PLAN DIALOG'

export type Action =
    | { type: typeof REQUEST_PAYMENT_PLANS }
    | { type: typeof NO_PAYMENT_PLANS_FOUND }
    | { type: typeof RECIEVE_PAYMENT_PLANS_SUCCESS; payload: { paymentPlans: Array<PaymentPlanBasicInfo> } }
    | { type: typeof RECIEVE_PAYMENT_PLANS_ERROR; payload: { title: string; message: string } }
    | { type: typeof EXPAND_PAYMENT_PLAN_REQUEST; payload: { paymentPlanId: string } }
    | { type: typeof EXPAND_PAYMENT_PLAN_SUCCESS; payload: { paymentPlanId: string; paymentPlan: PaymentPlan } }
    | { type: typeof EXPAND_PAYMENT_PLAN_ERROR; payload: { title: string; message: string } }
    | { type: typeof COLLAPSE_PAYMENT_PLAN }
    | { type: typeof HANDLE_EDIT_REQUEST_PAYMENT_METHODS }
    | {
          type: typeof HANDLE_EDIT_ACTIVE_SUCCESS
          payload: {
              paymentPlanId: string
              paymentMethods: Array<PayerPaymentMethods>
              achPaymentMethod: AchPaymentMethod
          }
      }
    | { type: typeof HANDLE_EDIT_ACTIVE_ERROR; payload: { paymentPlanId: string } }
    | { type: typeof HANDLE_EDIT_ACTIVE_CANCEL; payload: { paymentPlanId: string } }
    | { type: typeof HANDLE_EDIT_SAVE_EXISTING_METHOD_SUCCESS; payload: { paymentPlanId: string; updatedCard: string } }
    | { type: typeof HANDLE_EDIT_SAVE_EXISTING_METHOD_COMPLETE; payload: { paymentPlanId: string } }
    | { type: typeof HANDLE_EDIT_SAVE_EXISTING_METHOD_ERROR }
    | { type: typeof RESET_EDIT_PAYMENT_PLAN }
    | {
          type: typeof SHOW_SELECT_PAYMENT_PLAN_DIALOG
          payload: { updatedPaymentPlanMethod: string; tokenId: string; achId: string }
      }
    | {
          type: typeof UPDATE_UPDATED_PAYMENT_PLAN_METHOD
          payload: { selectedPlans: string[]; updatedPaymentPlanMethod: string }
      }
    | { type: typeof CLOSE_SELECT_PAYMENT_PLAN_DIALOG }

const getPaymentPlans = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId },
        } = getState()

        dispatch(requestPaymentPlans())

        try {
            const {
                data: { paymentPlans },
            } = await Api.getPaymentPlans(payerId)
            return dispatch(recievePaymentPlansSuccess({ paymentPlans }))
        } catch (err) {
            if (apiResponseContainErrorCode(err as any, 409)) {
                return dispatch(noPaymentPlansFound())
            } else {
                const error = {
                    title: 'Payment Plans',
                    message: `Error getting payment plans for payer: ${payerId}`,
                }
                return dispatch(recievePaymentPlansError(error))
            }
        }
    }
}

const requestPaymentPlans = () => {
    return {
        type: REQUEST_PAYMENT_PLANS,
    }
}

const noPaymentPlansFound = () => {
    return {
        type: NO_PAYMENT_PLANS_FOUND,
    }
}

const recievePaymentPlansSuccess = (payload: { paymentPlans: Array<PaymentPlanBasicInfo> }) => {
    return {
        type: RECIEVE_PAYMENT_PLANS_SUCCESS,
        payload,
    }
}

const recievePaymentPlansError = (payload: { title: string; message: string }) => {
    return {
        type: RECIEVE_PAYMENT_PLANS_ERROR,
        payload,
    }
}

const expandPaymentPlan = (paymentPlanId: string) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(expandPaymentPlanRequest({ paymentPlanId }))
        try {
            const { data } = await Api.getBalanceRemainingForPaymentPlan(paymentPlanId)
            return dispatch(expandPaymentPlanSuccess({ paymentPlanId, paymentPlan: data }))
        } catch (err) {
            return dispatch(
                expandPaymentPlanError({
                    title: 'Payment Plan Details',
                    message: `Unable to get details for ${paymentPlanId}`,
                })
            )
        }
    }
}

const expandPaymentPlanRequest = (payload: { paymentPlanId: string }) => {
    return {
        type: EXPAND_PAYMENT_PLAN_REQUEST,
        payload,
    }
}

const expandPaymentPlanSuccess = (payload: { paymentPlanId: string; paymentPlan: PaymentPlan }) => {
    return {
        type: EXPAND_PAYMENT_PLAN_SUCCESS,
        payload,
    }
}

const expandPaymentPlanError = (payload: { title: string; message: string }) => {
    return {
        type: EXPAND_PAYMENT_PLAN_ERROR,
        payload,
    }
}

const collapsePaymentPlan = () => {
    return {
        type: COLLAPSE_PAYMENT_PLAN,
    }
}

const handleEditActive = (payload: { paymentPlanId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(requestPaymentMethods())
        try {
            const {
                session: { payerId },
            } = getState()
            const {
                data: { paymentMethods, achPaymentMethod },
            } = await Api.getPatientPaymentMethods(payerId)
            return dispatch(
                handleEditActiveSuccess({ paymentPlanId: payload.paymentPlanId, paymentMethods, achPaymentMethod })
            )
        } catch (err) {
            return dispatch(handleEditActiveError({ paymentPlanId: payload.paymentPlanId }))
        }
    }
}

const requestPaymentMethods = () => {
    return {
        type: HANDLE_EDIT_REQUEST_PAYMENT_METHODS,
    }
}

const handleEditActiveSuccess = (payload: {
    paymentPlanId: string
    paymentMethods: Array<PayerPaymentMethods>
    achPaymentMethod: AchPaymentMethod
}) => {
    return {
        type: HANDLE_EDIT_ACTIVE_SUCCESS,
        payload,
    }
}

const handleEditActiveError = (payload: { paymentPlanId: string }) => {
    return {
        type: HANDLE_EDIT_ACTIVE_ERROR,
        payload,
    }
}

const handleEditActiveCancel = (payload: { paymentPlanId: string }) => {
    return {
        type: HANDLE_EDIT_ACTIVE_CANCEL,
        payload,
    }
}

const handleEditSave = (payload: { paymentPlanId: string; selectedMethod: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            paymentPlan: {
                editPaymentPlan: { paymentMethods, achPaymentMethod },
            },
        } = getState()

        if (payload.selectedMethod === 'NEW') {
            dispatch(addNewPaymentMethodSetupDefaultPlan({ defaultSelectedPaymentPlan: payload.paymentPlanId }))
        } else {
            try {
                if (payload.selectedMethod === 'ACH') {
                    // eslint-disable-next-line
                    let { data } = await Api.updatePaymentPlanPaymentMethodUseAch(
                        payload.paymentPlanId,
                        achPaymentMethod.achId
                    )
                } else {
                    // eslint-disable-next-line
                    let { data } = await Api.updatePaymentPlanPaymentMethodUseCard(
                        payload.paymentPlanId,
                        payload.selectedMethod
                    )
                }

                const updatedCard = paymentMethods.find((pm: SFSelectOptions) => pm.value === payload.selectedMethod)

                dispatch(
                    handleEditSaveExistingMethodSuccess({
                        paymentPlanId: payload.paymentPlanId,
                        updatedCard: updatedCard.text,
                    })
                )
                setTimeout(() => {
                    dispatch(handleEditSaveExistingMethodComplete({ paymentPlanId: payload.paymentPlanId }))
                }, 6000)
            } catch (err) {
                dispatch(handleEditSaveExistingMethodError())
                setTimeout(() => {
                    dispatch(resetEditPaymentPlan())
                }, 6000)
            }
        }
    }
}

const handleEditSaveExistingMethodSuccess = (payload: { paymentPlanId: string; updatedCard: string }) => {
    return {
        type: HANDLE_EDIT_SAVE_EXISTING_METHOD_SUCCESS,
        payload,
    }
}

const handleEditSaveExistingMethodComplete = (payload: { paymentPlanId: string }) => {
    return {
        type: HANDLE_EDIT_SAVE_EXISTING_METHOD_COMPLETE,
        payload,
    }
}

const handleEditSaveExistingMethodError = () => {
    return {
        type: HANDLE_EDIT_SAVE_EXISTING_METHOD_ERROR,
    }
}

const resetEditPaymentPlan = () => {
    return {
        type: RESET_EDIT_PAYMENT_PLAN,
    }
}

const onPlaidLinkSuccess = (payload: {
    publicToken: string
    accountId: string
    plaidBankName: string
    accountLast4: string
}) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            payDownPlanDialog: { isAddingNewPaymentMethod },
        } = getState()

        if (isAddingNewPaymentMethod) {
            return dispatch(onPlaidSuccessPayDownFlow(payload))
            // add ach from pay down plan flow
        } else {
            return dispatch(continueWithAddPlaidEditFlow(payload))
        }
    }
}

const continueWithAddPlaidEditFlow = (payload: {
    publicToken: string
    accountId: string
    plaidBankName: string
    accountLast4: string
}) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId, practiceId },
            paymentPlan: { paymentPlans, activePlanId },
        } = getState()

        dispatch(showAddNewMethodDialog())
        dispatch(addNewMethodLoading())

        try {
            // eslint-disable-next-line
            const { data: createCustomerData } = await Api.createAchCustomerForPortal(
                payerId,
                practiceId,
                payload.publicToken,
                payload.accountId
            )

            if (paymentPlans && paymentPlans.length > 1) {
                dispatch(cancelAddNewMethod())
                return dispatch(
                    showSelectPaymentPlanDialog({
                        updatedPaymentPlanMethod: `Bank account updating shortly`,
                        tokenId: '',
                        achId: createCustomerData.achId,
                    })
                )
            } else {
                return dispatch(
                    updatePaymentPlanMethodsUseAch({
                        selectedPlans: [activePlanId],
                        achId: createCustomerData.achId,
                        updatedPaymentPlanMethod: `Bank account updating shortly`,
                    })
                )
            }
        } catch (err) {
            dispatch(
                addNewPaymentMethodSetupError({
                    title: 'API Error',
                    message: `Unable to add bank account to customer ${payerId}`,
                    messageJson: err as any,
                })
            )
        }
    }
}

const showSelectPaymentPlanDialog = (payload: { updatedPaymentPlanMethod: string; tokenId: string; achId: string }) => {
    return {
        type: SHOW_SELECT_PAYMENT_PLAN_DIALOG,
        payload,
    }
}

const closeSelectPaymentPlanDialog = () => {
    return {
        type: CLOSE_SELECT_PAYMENT_PLAN_DIALOG,
    }
}

const updatePaymentPlanMethodsUseAch = (payload: {
    selectedPlans: string[]
    achId: string
    updatedPaymentPlanMethod: string
}) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            for (const planId of payload.selectedPlans) {
                // eslint-disable-next-line
                const { data } = await Api.updatePaymentPlanPaymentMethodUseAch(planId, payload.achId)
            }
            dispatch(
                updateUpdatedPaymentPlanMethod({
                    selectedPlans: payload.selectedPlans,
                    updatedPaymentPlanMethod: payload.updatedPaymentPlanMethod,
                })
            )
            return dispatch(addNewPaymentMethodSuccess())
        } catch (err) {
            throw err
        }
    }
}

const updateUpdatedPaymentPlanMethod = (payload: { selectedPlans: string[]; updatedPaymentPlanMethod: string }) => {
    return {
        type: UPDATE_UPDATED_PAYMENT_PLAN_METHOD,
        payload,
    }
}

const editPaymentMethodAddCardFailure = (payload: { title: string; message: string; messageJson: string }) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(addNewPaymentMethodSetupError(payload))
    }
}

const editPaymentMethodAddCardCancel = () => {
    return async (dispatch: Dispatch<any>) => {
        return dispatch(cancelAddNewMethod())
    }
}

const updatePaymentPlanMethodsUseCard = (payload: {
    selectedPlans: string[]
    tokenId: string
    updatedPaymentPlanMethod: string
}) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            for (const planId of payload.selectedPlans) {
                // eslint-disable-next-line
                const { data } = await Api.updatePaymentPlanPaymentMethodUseCard(planId, payload.tokenId)
            }
            dispatch(
                updateUpdatedPaymentPlanMethod({
                    selectedPlans: payload.selectedPlans,
                    updatedPaymentPlanMethod: payload.updatedPaymentPlanMethod,
                })
            )
            return dispatch(addNewPaymentMethodSuccess())
        } catch (err) {
            throw err
        }
    }
}

const onStripeAddCardSuccess = (payload: { paymentMethodCardId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            payDownPlanDialog: { isAddingNewPaymentMethod },
        } = getState()
        if (isAddingNewPaymentMethod) {
            // user is in the pay down plan flow, card is added now use it to pay down
            return dispatch(onStripeAddCardSuccessPayDownFlow(payload))
        } else {
            return dispatch(continueWithAddCardEditFlow(payload))
        }
    }
}

const continueWithAddCardEditFlow = (payload: { paymentMethodCardId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            paymentPlan: { paymentPlans, activePlanId },
            session: { payerId },
        } = getState()

        try {
            const {
                data: { tokenId, cardType, last4 },
            } = await Api.getPaymentMethod(payload.paymentMethodCardId, payerId)

            if (paymentPlans && paymentPlans.length > 1) {
                dispatch(cancelAddNewMethod())
                return dispatch(
                    showSelectPaymentPlanDialog({
                        updatedPaymentPlanMethod: `${cardType} ****${last4}`,
                        tokenId,
                        achId: '',
                    })
                )
            } else {
                return dispatch(
                    updatePaymentPlanMethodsUseCard({
                        selectedPlans: [activePlanId],
                        tokenId,
                        updatedPaymentPlanMethod: `${cardType} ****${last4}`,
                    })
                )
            }
        } catch (err) {
            return dispatch(
                editPaymentMethodAddCardFailure({
                    title: 'API Error',
                    message: `Unable to update selected plan with the new payment method`,
                    messageJson: err as any,
                })
            )
        }
    }
}

const onPaymentPlanSelectionComplete = (payload: { selectedPaymentPlanIds: string[] }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            paymentPlan: {
                editPaymentPlan: { tokenId, achId, updatedPaymentPlanMethod },
            },
        } = getState()

        dispatch(closeSelectPaymentPlanDialog())
        dispatch(showAddNewMethodDialog())

        try {
            if (tokenId) {
                return dispatch(
                    updatePaymentPlanMethodsUseCard({
                        selectedPlans: payload.selectedPaymentPlanIds,
                        tokenId,
                        updatedPaymentPlanMethod,
                    })
                )
            } else {
                return dispatch(
                    updatePaymentPlanMethodsUseAch({
                        selectedPlans: payload.selectedPaymentPlanIds,
                        achId,
                        updatedPaymentPlanMethod: `Bank account updating shortly`,
                    })
                )
            }
        } catch (err) {
            editPaymentMethodAddCardFailure({
                title: 'API Error',
                message: `Unable to update selected plan with the new payment method`,
                messageJson: err as any,
            })
        }
    }
}

export {
    REQUEST_PAYMENT_PLANS,
    NO_PAYMENT_PLANS_FOUND,
    RECIEVE_PAYMENT_PLANS_SUCCESS,
    RECIEVE_PAYMENT_PLANS_ERROR,
    EXPAND_PAYMENT_PLAN_REQUEST,
    EXPAND_PAYMENT_PLAN_SUCCESS,
    EXPAND_PAYMENT_PLAN_ERROR,
    COLLAPSE_PAYMENT_PLAN,
    HANDLE_EDIT_REQUEST_PAYMENT_METHODS,
    HANDLE_EDIT_ACTIVE_SUCCESS,
    HANDLE_EDIT_ACTIVE_ERROR,
    HANDLE_EDIT_ACTIVE_CANCEL,
    HANDLE_EDIT_SAVE_EXISTING_METHOD_SUCCESS,
    HANDLE_EDIT_SAVE_EXISTING_METHOD_COMPLETE,
    HANDLE_EDIT_SAVE_EXISTING_METHOD_ERROR,
    RESET_EDIT_PAYMENT_PLAN,
    SHOW_SELECT_PAYMENT_PLAN_DIALOG,
    UPDATE_UPDATED_PAYMENT_PLAN_METHOD,
    CLOSE_SELECT_PAYMENT_PLAN_DIALOG,
    getPaymentPlans,
    expandPaymentPlan,
    collapsePaymentPlan,
    handleEditActive,
    handleEditActiveCancel,
    handleEditSave,
    onPlaidLinkSuccess,
    editPaymentMethodAddCardCancel,
    onStripeAddCardSuccess,
    onPaymentPlanSelectionComplete,
    editPaymentMethodAddCardFailure,
    closeSelectPaymentPlanDialog,
}
