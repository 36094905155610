import React from 'react'
import classNames from 'classnames'
import { Icon } from '@material-ui/core'

import './Tooltip.scss'

interface Props {
    className?: string
    text: string
    hideIcon?: boolean
    forceShow?: boolean
}

export default function Tooltip(props: Props) {
    return (
        <div
            className={classNames('simplifeye-tooltip-wrapper', props.className, {
                'simplifeye-tooltip-wrapper--show': !!props.forceShow,
            })}
        >
            {!props.hideIcon && <Icon className="simplifeye-tooltip-icon">info</Icon>}
            <span className="simplifeye-tooltip">{props.text}</span>
        </div>
    )
}
