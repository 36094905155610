import { Action, LOAD_INVOICE_HISTORY_SUCCESS, LOAD_INVOICE_HISTORY_ERROR } from './invoiceHistory.actions'
import initialState from './invoiceHistory.initial'
import { InvoiceHistoryState } from './invoiceHistory.state'

const invoiceHistoryReducer = (state: InvoiceHistoryState = initialState, action: Action): InvoiceHistoryState => {
    switch (action.type) {
        case LOAD_INVOICE_HISTORY_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: {
                    showError: false,
                    title: '',
                    message: '',
                },
                invoiceDetailsResponse: action.payload.invoiceDetailsResponse,
            }
        }
        case LOAD_INVOICE_HISTORY_ERROR: {
            return {
                ...state,
                loading: false,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        default:
            return { ...state }
    }
}

export default invoiceHistoryReducer
