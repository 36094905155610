import { SFAlert, SFLoading } from '@simplifeye/component-library'
import React, { useCallback, useEffect, useState } from 'react'
import './InvoiceDetailsGuest.scss'
import BillingTotalsSection, {
    BaseBillingTotalsProps,
    OneTapPaymentBillingTotalsProps,
    ClassicPaymentBillingTotalsProps,
} from '../invoice-details/billing-totals/BillingTotalsSection'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../reducers/app/RootState'
import { useHistory, useParams } from 'react-router'
import {
    dataForInvoiceDetailsGuest,
    onPayInvoice,
    onUseExistingPaymentAchSelect,
    onUseExistingPaymentSelect,
    paymentMethodSelect,
    cancelPlaidFlow,
    onPlaidLinkSuccess,
    plaidFlowError,
    onOneTapPayInvoice,
    getAddressForLocation,
    getPracticeLogo,
    fetchPracticeLogoBgColor,
} from '../reducers/invoice-details/invoiceDetails.action'
import AchDialog from '../invoice-details/ach-dialog/AchDialog'
import SelectPaymentMethod from '../shared/components/select-payment-method/SelectPaymentMethod'
import { InvoicePaymentMethodsType, InvoicePaymentMethods } from '../types/Constants'
import { PayerPaymentMethods } from '../types/Response/PayerPaymentMethods'
import { AchPaymentMethod } from '../types/AchPaymentMethod'
import PlaidLink from '../shared/components/plaid-link/PlaidLink'
import InvoiceDetailsInfoAlert from '../invoice-details/invoice-details-info-alert/InvoiceDetailsInfoAlert'
import InvoiceDetailsDataGuest from './invoice-details-data-guest/InvoiceDetailsDataGuest'
// import InvoiceDetailsDialog from '../invoice-details/invoice-details-dialog/InvoiceDetailsDialog'
import Footer from '../footer/Footer'
import { InvoiceNotAvailableIcon } from '../icons/PaymentIcons'
import { Link } from 'react-router-dom'
import { addPracticeAndPayerToSession } from '../reducers/session/session.actions'
import { getGuestUserDetails } from '../reducers/login/login.action'
import SessionManager from '../../SessionManager'
import InvoiceDetailsDialogReplacement from '../invoice-details/invoice-details-dialog/InvoiceDetailsDialogReplacement'
import { ChargeEventStatusForAPI } from '../types/Constants/ChargeEventStatus'

const InvoiceDetailsGuest = () => {
    const {
        invoiceDetailsResponse,
        selectedPaymentMethod,
        billingTotals,
        error,
        loading,
        chargeEventId,
        canSubmitPayInvoice,
        isAddingNewPaymentMethod,
        plaid,
        total,
    } = useSelector((state: RootState) => state.invoiceDetails)

    const [loadingFromRefresh, setLoadingFromRefresh] = useState(true)

    let { invoiceId } = useParams<{ invoiceId: string }>()

    const [logoUrl, setLogoUrl] = useState(null)
    const [logoBgColor, setLogoBgColor] = useState(null)
    const { practiceId } = useSelector((state: RootState) => state.session)

    const { paymentMethods, achPaymentMethod, enableGuestLink } = useSelector((state: RootState) => {
        return {
            ...state.paymentMethods,
            enableGuestLink: state.login.enableGuestLink,
        }
    })

    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        const loadData = async () => {
            // this is only true on refresh of a guest invoice
            if (enableGuestLink === undefined && !!practiceId) {
                await dispatch(
                    getGuestUserDetails({ email: SessionManager.sessionEmail, chargeEventId: invoiceId, practiceId })
                )
                setLoadingFromRefresh(false)
            } else if (enableGuestLink !== undefined) {
                setLoadingFromRefresh(false)
            }
        }
        loadData()
    }, [dispatch, enableGuestLink, practiceId])

    // consts
    const isZeroOrMixedAccount =
        invoiceDetailsResponse?.accountType === 'simplyzero' || invoiceDetailsResponse?.accountType === 'mixed'
    const isPaid = invoiceDetailsResponse?.status === ChargeEventStatusForAPI.PAID

    useEffect(() => {
        try {
            dispatch(dataForInvoiceDetailsGuest(invoiceId))
        } catch (err) {
            const invoiceId = ''
            dispatch(dataForInvoiceDetailsGuest(invoiceId))
        }
    }, [dispatch, history])

    useEffect(() => {
        if (invoiceDetailsResponse) {
            dispatch(
                addPracticeAndPayerToSession({
                    payerId: invoiceDetailsResponse.payer.payerId,
                    practiceId: invoiceDetailsResponse.practiceId,
                })
            )
            dispatch(getAddressForLocation(invoiceDetailsResponse.practiceId, invoiceDetailsResponse.locationId))
        }
    }, [invoiceDetailsResponse])

    const onNewPaymentMethodSelect = (paymentMethodType: InvoicePaymentMethodsType) => {
        dispatch(paymentMethodSelect(paymentMethodType))
    }

    const onPaymentMethodSelectChange = (
        paymentMethod: string,
        isExistingPayment: boolean,
        savedPaymentMethod?: PayerPaymentMethods,
        achPaymentMethod?: AchPaymentMethod
    ) => {
        if (!isExistingPayment) {
            dispatch(onUseExistingPaymentSelect('', false))
        } else if (achPaymentMethod) {
            dispatch(onUseExistingPaymentAchSelect())
        } else {
            dispatch(onUseExistingPaymentSelect(paymentMethod, true, savedPaymentMethod))
        }
    }

    const onOneTapPay = useCallback(
        (event, stripe) => {
            dispatch(
                onOneTapPayInvoice(
                    event.paymentMethod.id,
                    event.paymentMethod.card.funding.toString(),
                    event.complete,
                    stripe
                )
            )
        },
        [dispatch]
    )

    const generateStripeDisplayItems = useCallback(() => {
        // if not one tap pay exit
        if (selectedPaymentMethod !== InvoicePaymentMethods.OTP) {
            return
        }
        // grab invoiced procedures
        let displayItems: Array<{ label: string; amount: number }> =
            invoiceDetailsResponse.procedureDescriptions.map(procedure => ({
                label: procedure.treatmentDescription,
                amount: procedure.amount,
            })) || []

        // grab taxes
        if (billingTotals) {
            const { taxSubtotal } = billingTotals
            displayItems = [...displayItems, { label: 'Sales Tax', amount: taxSubtotal }]
        }

        return displayItems
    }, [billingTotals, selectedPaymentMethod, invoiceDetailsResponse])

    const generateBillingPageProps = useCallback(() => {
        // base props required by either mode
        const baseProps: BaseBillingTotalsProps = {
            subtotal: billingTotals.paymentDue - billingTotals.taxSubtotal,
            taxSubtotal: billingTotals.taxSubtotal,
            paymentDue: billingTotals.paymentDue,
            canSubmitReview: canSubmitPayInvoice,
            isNewMethod: isAddingNewPaymentMethod,
            isZeroOrMixedAccount: isZeroOrMixedAccount,
            selectedMethod: selectedPaymentMethod,
        }

        // one tap pay specific props
        if (selectedPaymentMethod === InvoicePaymentMethods.OTP) {
            return {
                ...baseProps,
                useOneTapPay: true,
                displayItems: generateStripeDisplayItems(),
                onOneTapPaymentSubmit: onOneTapPay,
            } as OneTapPaymentBillingTotalsProps
        }
        // classic payment specific props
        return {
            ...baseProps,
            useOneTapPay: false,
            onReviewPaymentSubmit: onPayInvoice,
        } as ClassicPaymentBillingTotalsProps
    }, [
        billingTotals,
        canSubmitPayInvoice,
        generateStripeDisplayItems,
        onOneTapPay,
        selectedPaymentMethod,
        isAddingNewPaymentMethod,
        isZeroOrMixedAccount,
    ])

    const loadLogo = async (practiceId: string) => {
        try {
            const loadedLogoUrl = await dispatch(getPracticeLogo(practiceId))
            if (loadedLogoUrl) {
                setLogoUrl(loadedLogoUrl)
            }
            const loadedBgColor = await dispatch(fetchPracticeLogoBgColor(practiceId))
            if (loadedBgColor) {
                setLogoBgColor((loadedBgColor as unknown) as string)
            }
        } catch (err) {}
    }

    useEffect(() => {
        if (practiceId) {
            loadLogo(practiceId)
        }
    }, [practiceId])

    if (enableGuestLink === false) {
        return (
            <div className={'invoice-details'}>
                <div className={'section payment-center-header invoice-details-inner'}>
                    <div className="logo-wrapper">
                        {logoUrl ? (
                            <img src={logoUrl} style={{ backgroundColor: logoBgColor }} alt="practice-logo" />
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="payment-center-title">
                        <h3>Payment Center</h3>
                    </div>
                </div>
                <div className={'section pay-invoice-title'}>
                    <div className={'invoice-details-inner'}>
                        <h3>Pay my Invoice</h3>
                    </div>
                </div>
                <div className={'section invoice-title'}>
                    <div className="invoice-details-inner">
                        <div className="invoice-number-header">
                            <h3>Invoice #{chargeEventId}</h3>
                        </div>
                    </div>
                </div>
                <div className="details-section">
                    <div className="invoice-details-inner invoice-details-inner--not-available">
                        <div>{InvoiceNotAvailableIcon()}</div>
                        <h2>Invoice no longer available</h2>
                        <p>
                            Please <Link to="/login">log into your account</Link> for details or contact our practice
                            for more information.
                        </p>
                    </div>
                </div>
                <footer>
                    <Footer />
                </footer>
            </div>
        )
    }

    if (loading || loadingFromRefresh) {
        return (
            <div className="invoice-details">
                <SFLoading fullScreen={true} />
            </div>
        )
    } else if (error.showError) {
        return (
            <div className={'invoice-details'}>
                <div className="invoice-details-inner">
                    <div className="alert-wrapper">
                        <SFAlert severity="error" title={error.title} message={error.message} showAlert={true} />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={'invoice-details'}>
                {error.showError ? (
                    <div className="invoice-details-inner">
                        <div className="alert-wrapper">
                            <SFAlert severity="error" title={error.title} message={error.message} showAlert={true} />
                        </div>
                    </div>
                ) : null}
                <div className={'section payment-center-header invoice-details-inner'}>
                    <div className="logo-wrapper">
                        <img src={logoUrl} style={{ backgroundColor: logoBgColor }} alt="practice-logo" />
                    </div>
                    <div className="payment-center-title">
                        <h3>Payment Center</h3>
                    </div>
                </div>
                <div className={'section pay-invoice-title'}>
                    <div className={'invoice-details-inner'}>
                        <h3>Pay my Invoice</h3>
                    </div>
                </div>
                <div className={'section invoice-title'}>
                    <div className="invoice-details-inner">
                        <div className="invoice-number-header">
                            <h3>Invoice #{chargeEventId}</h3>
                        </div>
                    </div>
                </div>
                <div className="details-section">
                    <div className="invoice-details-inner">
                        {isPaid && (
                            <div className="invoice-details-paid-wrapper">
                                <SFAlert
                                    severity="success"
                                    title="Payment successful"
                                    message="This invoice has been paid successfully."
                                    showAlert={true}
                                />
                            </div>
                        )}
                        <InvoiceDetailsDataGuest invoiceDetails={invoiceDetailsResponse} isPaid={false} />
                        {!isPaid && (
                            <div>
                                <SelectPaymentMethod
                                    selectedPaymentMethod={selectedPaymentMethod}
                                    savedPaymentMethods={paymentMethods}
                                    isAddingNewPaymentMethod={isAddingNewPaymentMethod}
                                    achPaymentMethod={achPaymentMethod}
                                    onNewPaymentMethodSelect={onNewPaymentMethodSelect}
                                    onPaymentMethodSelectChange={onPaymentMethodSelectChange}
                                    isExternalInvoice={false}
                                    showDropdown={false}
                                />
                                <InvoiceDetailsInfoAlert
                                    showAlert={
                                        total.debit &&
                                        isZeroOrMixedAccount &&
                                        isAddingNewPaymentMethod &&
                                        (selectedPaymentMethod === InvoicePaymentMethods.CARD ||
                                            selectedPaymentMethod === InvoicePaymentMethods.OTP)
                                    }
                                    amount={total.debit || 0}
                                />
                                <BillingTotalsSection {...generateBillingPageProps()} />
                                <InvoiceDetailsDialogReplacement onPaymentMethodSelect={onNewPaymentMethodSelect} />
                                {plaid.showPlaid ? (
                                    <PlaidLink
                                        plaidLinkToken={plaid.plaidLinkToken}
                                        practiceId={practiceId}
                                        onPlaidLinkSuccess={onPlaidLinkSuccess}
                                        onPlaidLinkError={plaidFlowError}
                                        cancelPlaidFlow={cancelPlaidFlow}
                                    />
                                ) : null}
                                <AchDialog />
                            </div>
                        )}
                    </div>
                </div>
                <footer>
                    <Footer />
                </footer>
            </div>
        )
    }
}

export default InvoiceDetailsGuest
