import React from 'react'
import Icon from '@material-ui/core/Icon'

import './Input.sass'

class InputBox extends React.Component {
    setFocus = () => {
        if (this.inputRef) {
            this.inputRef.focus()
            return true
        }
        return false
    }

    render() {
        const { wrapperClass, errorText, labelText, showError, ...inputProps } = this.props

        const id = `input-${Math.random().toString(36).substring(2)}`

        return (
            <div className={`input-wrapper ${wrapperClass} ${showError ? 'input-error' : ''}`}>
                <label className={'input-error-icon'} htmlFor={id}>
                    <Icon>error</Icon>
                </label>
                {showError && errorText && <div className={'input-row-error'}>{errorText}</div>}
                <input
                    id={id}
                    ref={(input) => {
                        this.inputRef = input
                    }}
                    {...inputProps}
                />
                {labelText && (
                    <label className={'input-label'} htmlFor={id}>
                        {labelText}
                    </label>
                )}
            </div>
        )
    }
}

export default InputBox
