import { InvoicePaymentMethodsType } from '../../types/Constants'
import { CalculateFeesForSubtotalResponse } from '../../types/Response/CalculateFeesForSubtotalResponse'
import {
    Action,
    LOAD_EXTERNAL_INVOICE_DATA_SUCCESS,
    LOAD_EXTERNAL_INVOICE_DATA_ERROR,
    ON_PAY_EXTERNAL_INVOICE_SUCCESS,
    ON_PAY_EXTERNAL_INVOICE_ERROR,
    UPDATE_COST_INFO,
    EDIT_COST_INFO,
    CALCULATE_FEES_ERROR,
    SHOW_EXTERNAL_INVOICE_DIALOG,
    ON_USE_EXISTING_PAYMENT_METHOD_SUCCESS,
    ON_PAY_EXTERNAL_INVOICE_CANCEL,
    REQUEST_CREATE_VOLUNTARY_INVOICE,
    ON_ADD_NEW_PAYMENT_METHOD,
    UPDATE_NEW_PAYMENT_METHOD_SELECT,
    ON_NEW_PAYMENT_METHOD_SELECTION_ACTION_SUCCESS,
    CREATE_INVOICE_FOR_ADDING_NEW_METHOD_ERROR,
} from './externalInvoice.action'
import { initialState } from './externalInvoice.initial'
import { ExternalInvoiceBillingTotals, ExternalInvoiceState } from './externalInvoice.state'

const externalInvoiceReducer = (state: ExternalInvoiceState = initialState, action: Action): ExternalInvoiceState => {
    switch (action.type) {
        case LOAD_EXTERNAL_INVOICE_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                payerInfo: action.payload.payerInfoResponse.payers[0],
                accountType: action.payload.accountType,
                error: {
                    ...initialState.error,
                },
            }
        }
        case LOAD_EXTERNAL_INVOICE_DATA_ERROR: {
            return {
                ...state,
                loading: false,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case ON_USE_EXISTING_PAYMENT_METHOD_SUCCESS: {
            return {
                ...state,
                billingTotals: mapFeeResponseToFee(action.payload.calculateFeeResponse, action.cardType),
                savedPaymentMethod: action.payload.savedPaymentMethod,
                isAddingNewPaymentMethod: false,
            }
        }
        case CALCULATE_FEES_ERROR: {
            return {
                ...state,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case UPDATE_COST_INFO: {
            return {
                ...state,
                costInfo: {
                    invoiceNumber: action.payload.invoiceNumber,
                    amount: action.payload.amount,
                    description: action.payload.description,
                    complete: true,
                },
                billingTotals: {
                    ...state.billingTotals,
                    subtotal: action.payload.amount,
                    fees: 0,
                    total: action.payload.amount,
                },
            }
        }
        case EDIT_COST_INFO: {
            return {
                ...state,
                costInfo: {
                    ...state.costInfo,
                    complete: false,
                },
                selectedPaymentMethod: null,
                savedPaymentMethod: null,
            }
        }
        case SHOW_EXTERNAL_INVOICE_DIALOG: {
            return {
                ...state,
                dialog: {
                    open: true,
                    loading: false,
                    error: {
                        title: '',
                        message: '',
                        showError: false,
                    },
                    success: false,
                },
            }
        }
        case ON_PAY_EXTERNAL_INVOICE_CANCEL: {
            return {
                ...state,
                dialog: {
                    ...initialState.dialog,
                },
            }
        }
        case ON_PAY_EXTERNAL_INVOICE_SUCCESS: {
            return {
                ...state,
                dialog: {
                    open: true,
                    loading: false,
                    error: {
                        title: '',
                        message: '',
                        showError: false,
                    },
                    success: true,
                },
            }
        }
        case ON_PAY_EXTERNAL_INVOICE_ERROR: {
            return {
                ...state,
                dialog: {
                    open: true,
                    loading: false,
                    error: {
                        title: 'Payment Error',
                        message: 'Unable to process payment at this time',
                        showError: true,
                    },
                    success: false,
                },
            }
        }
        case REQUEST_CREATE_VOLUNTARY_INVOICE: {
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    loading: true,
                },
            }
        }
        case ON_ADD_NEW_PAYMENT_METHOD: {
            return {
                ...state,
                isAddingNewPaymentMethod: true,
            }
        }
        case UPDATE_NEW_PAYMENT_METHOD_SELECT: {
            return {
                ...state,
                selectedPaymentMethod: action.payload.paymentMethodType,
            }
        }
        case ON_NEW_PAYMENT_METHOD_SELECTION_ACTION_SUCCESS: {
            return {
                ...state,
                billingTotals: mapFeeResponseToFee(action.payload.calculateFeeResponse, action.cardType),
            }
        }
        case CREATE_INVOICE_FOR_ADDING_NEW_METHOD_ERROR: {
            return {
                ...state,
                loading: false,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        default: {
            return { ...state }
        }
    }
}

const mapFeeResponseToFee = (
    calculateFeeResponse: CalculateFeesForSubtotalResponse,
    cardType: InvoicePaymentMethodsType
): ExternalInvoiceBillingTotals => {
    let fees = calculateFeeResponse.total[cardType] - calculateFeeResponse.subtotal
    let total = calculateFeeResponse.total[cardType]

    return {
        subtotal: calculateFeeResponse.subtotal,
        fees,
        total,
        allFees: calculateFeeResponse.applicationFees,
        allTotals: calculateFeeResponse.total,
    }
}

export default externalInvoiceReducer
