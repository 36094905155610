import { SFDialog } from '@simplifeye/component-library'
import DialogFooterProps from '@simplifeye/component-library/lib/component/SFDialog/types/DialogFooter.types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PaymentPlan } from '../../../types/PaymentPlan'
import PaymentPlanSelection from './payment-plan-selection/PaymentPlanSelection'

interface Props {
    show: boolean
    cancel: () => void
    paymentPlans: PaymentPlan[]
    activePlanId: string
    onPaymentPlanSelectionComplete: (payload: { selectedPaymentPlanIds: string[] }) => void
}

const SelectPaymentPlanDialog = ({
    show,
    paymentPlans,
    activePlanId,
    cancel,
    onPaymentPlanSelectionComplete,
}: Props) => {
    const [dialog, setDialog] = useState<{ content: React.ReactNode; footer: DialogFooterProps }>({
        content: null,
        footer: null,
    })
    const [selectedPaymentPlanIds, setSelectedPaymentPlanIds] = useState<string[]>([])

    const dispatch = useDispatch()

    const onPaymentPlanSelected = (planId: string) => {
        const index = selectedPaymentPlanIds.indexOf(planId)
        if (index === -1) {
            setSelectedPaymentPlanIds([...selectedPaymentPlanIds, planId])
        } else {
            const removed = selectedPaymentPlanIds.filter((id) => id !== planId)
            setSelectedPaymentPlanIds(removed)
        }
    }

    useEffect(() => {
        setDialog({
            content: (
                <div className="select-new-ach">
                    <PaymentPlanSelection
                        paymentPlans={paymentPlans}
                        onPaymentPlanSelected={onPaymentPlanSelected}
                        selectedPaymentPlanIds={selectedPaymentPlanIds}
                    />
                </div>
            ),
            footer: {
                footerButtons: {
                    twoButtons: {
                        leftButton: {
                            text: 'Cancel',
                            action: () => {
                                dispatch(cancel())
                            },
                        },
                        rightButton: {
                            text: selectedPaymentPlanIds.length > 0 ? 'Next' : 'Skip',
                            action: () => {
                                dispatch(onPaymentPlanSelectionComplete({ selectedPaymentPlanIds }))
                            },
                        },
                    },
                },
            },
        })
    }, [show, paymentPlans, cancel, selectedPaymentPlanIds])

    useEffect(() => {
        if (activePlanId) {
            setSelectedPaymentPlanIds([activePlanId])
        }
    }, [activePlanId])

    return (
        <SFDialog
            open={show}
            title="Select Plans to Update"
            onClose={() => {
                dispatch(cancel())
            }}
            content={dialog.content}
            footer={dialog.footer}
            styleClass="select-payment-plan-dialog"
        />
    )
}

export default SelectPaymentPlanDialog
