import React, { useState, useEffect } from 'react'
import { SFDialog, SFLoading, SFErrorDialogContent, SFSuccessDialogContent } from '@simplifeye/component-library'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../reducers/app/RootState'
import './AchDialog.scss'
import AchUseExistingAccount from './AchUseExistingAccount/AchUseExistingAccount'
import DialogFooterProps from '@simplifeye/component-library/lib/component/SFDialog/types/DialogFooter.types'
import { cancelPlaidFlow, onAchSuccessBackToHome } from '../../reducers/invoice-details/invoiceDetails.action'
import { useHistory } from 'react-router'
import SessionManager from '../../../SessionManager'
import { getResetCode } from '../../reducers/login/login.action'

const AchDialog = () => {
    const {
        achDialog: { show, loading, error, success, showUseExisting },
    } = useSelector((state: RootState) => state.invoiceDetails)
    const { practiceId } = useSelector((state: RootState) => state.session)
    const { achPaymentMethod } = useSelector((state: RootState) => state.paymentMethods)
    const { code, accountStatus } = useSelector((state: RootState) => state.login)
    const locationId = SessionManager.sessionLocationId
    const encodedEmail = SessionManager.sessionEmail ? Buffer.from(SessionManager.sessionEmail).toString('base64') : ''
    const dispatch = useDispatch()
    const history = useHistory()

    const [dialogContent, setDialogContent] = useState<{
        content: any
        footer: DialogFooterProps
        onClose: () => void
    }>({
        content: null,
        footer: null,
        onClose: () => {},
    })

    useEffect(() => {
        if (accountStatus === 'pending') {
            dispatch(getResetCode({ emailAddress: SessionManager.sessionEmail }))
        }
    }, [accountStatus])

    useEffect(() => {
        if (loading) {
            setDialogContent({
                content: (
                    <div className="ach-loading">
                        <SFLoading />
                    </div>
                ),
                footer: {
                    footerButtons: {
                        centerButton: {
                            text: 'Cancel',
                            action: () => {
                                dispatch(cancelPlaidFlow())
                            },
                            classes: [],
                            disabled: true,
                        },
                    },
                },
                onClose: () => {
                    dispatch(cancelPlaidFlow())
                },
            })
        } else if (error.showError) {
            setDialogContent({
                content: (
                    <SFErrorDialogContent title={error.title} message={error.message} messageJson={error.messageJson} />
                ),
                footer: {
                    footerButtons: {
                        centerButton: {
                            text: 'Cancel',
                            action: () => {
                                dispatch(cancelPlaidFlow())
                            },
                            classes: [],
                            disabled: false,
                        },
                    },
                },
                onClose: () => {
                    dispatch(cancelPlaidFlow())
                },
            })
        } else if (success) {
            if (SessionManager.isLoggedIn) {
                setDialogContent({
                    content: (
                        <SFSuccessDialogContent
                            title={'Payment Succeeded'}
                            message={
                                'A receipt has been emailed to you! This payment may take a few minutes to reflect on your profile'
                            }
                        />
                    ),
                    footer: {
                        footerButtons: {
                            centerButton: {
                                text: 'Back to Home',
                                action: () => {
                                    dispatch(onAchSuccessBackToHome())
                                    history.push('/overview')
                                },
                                classes: [],
                            },
                        },
                    },
                    onClose: () => {
                        dispatch(onAchSuccessBackToHome())
                        history.push('/overview')
                    },
                })
            } else {
                setDialogContent({
                    content: (
                        <SFSuccessDialogContent
                            title={'Payment Succeeded'}
                            message={
                                'A receipt has been emailed to you! This payment may take a few minutes to process'
                            }
                        />
                    ),
                    footer: {
                        footerButtons: {
                            centerButton: {
                                text: accountStatus === 'active' ? 'Log In' : 'Make an Account',
                                action: () => {
                                    if (accountStatus === 'active') {
                                        history.push('/login')
                                    } else {
                                        history.push(
                                            `/create/${code}?s=${encodedEmail}&practiceId=${practiceId}&locationId=${locationId}`
                                        )
                                    }
                                },
                                classes: [],
                            },
                        },
                    },
                    onClose: () => {
                        dispatch(onAchSuccessBackToHome())
                        history.push('/overview')
                    },
                })
            }
        } else if (showUseExisting) {
            setDialogContent({
                content: <AchUseExistingAccount achPaymentMethod={achPaymentMethod} />,
                footer: null,
                onClose: () => {
                    dispatch(cancelPlaidFlow())
                },
            })
        }
    }, [
        show,
        loading,
        error,
        dispatch,
        history,
        showUseExisting,
        success,
        achPaymentMethod,
        accountStatus,
        code,
        encodedEmail,
        practiceId,
        locationId,
    ])

    return (
        <SFDialog
            open={show}
            title={'Bank Accounts'}
            onClose={dialogContent.onClose}
            content={dialogContent.content}
            footer={dialogContent.footer}
            styleClass={`ach-dialog`}
        />
    )
}

export default AchDialog
