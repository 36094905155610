import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SimplifeyeVyneLogo from '../../../icons/SimplifeyeVyneLogo'
import { LockIcon as CustomLockIcon } from '../../../icons/LoginIcons'
import { RootState } from '../../../reducers/app/RootState'

import { getGuestUserDetails, getResetCode } from '../../../reducers/login/login.action'

const useQuery = search => {
    return new URLSearchParams(search)
}

const TextToPayLogin = () => {
    const { accountStatus, logoUrl, code } = useSelector((state: RootState) => state.login)

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const searchParams = useQuery(location.search)

    // get search params
    // format: portal.simplifeye.co/p/LocationName?s=”BASE64Encoded(ChargeEventId)”&c=”BASE64Encoded(Email)
    // testing url: http://localhost:3000/p/vRwJZW28pOaz2IH1?s=NzRFS1I0ZGxocDBHZGhCRA==&c=bXZlbnR1cmlAc2ltcGxpZmV5ZS5jbw==
    const chargeEventId = atob(searchParams.get('s'))
    const email = atob(searchParams.get('c'))
    const practiceId = searchParams.get('p')
    const encodedEmail = Buffer.from(email).toString('base64')
    const locationId = location.pathname.split('/').pop()

    useEffect(() => {
        dispatch(getGuestUserDetails({ email, chargeEventId, practiceId }))
    }, [dispatch, email])

    useEffect(() => {
        if (accountStatus === 'pending') {
            dispatch(getResetCode({ emailAddress: email }))
        }
    }, [accountStatus])

    // redirect to login
    const goToLogin = () => {
        let path = '/login'
        history.push(path)
    }
    // redirect to account creation
    const goToAccountCreation = () => {
        let path = `/create/${code}?s=${encodedEmail}&practiceId=${practiceId}&locationId=${locationId}`
        history.push(path)
    }
    // redirect to invoice
    const goToInvoice = () => {
        let path = `/invoice-external/${chargeEventId}`
        history.push(path)
    }

    return (
        <div className="login">
            <div className="logo-or-lock-icon">
                <div className="logo">
                    <div className="logo-or-lock-icon">
                        {logoUrl ? (
                            <div className="logo">
                                <img src={logoUrl} alt="the logo of the practice" />
                            </div>
                        ) : (
                            <div className="lock-icon">
                                <CustomLockIcon />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="login-box border-radius-0">
                <div className="login-box-inner">
                    <div className="login-title">Payment Center</div>
                    <div className="login-button-wrapper">
                        <button className="text-to-pay-button" type="submit" onClick={goToInvoice}>
                            Continue as guest
                        </button>
                    </div>
                    <p className="login-text">Pay your bill securely without signing in</p>
                    <p className="login-text">-or-</p>
                    <div className="login-button-wrapper">
                        {accountStatus !== 'pending' ? (
                            <button className="text-to-pay-button" type="submit" onClick={goToLogin}>
                                Account login
                            </button>
                        ) : (
                            <button className="text-to-pay-button" type="submit" onClick={goToAccountCreation}>
                                Create account
                            </button>
                        )}
                    </div>
                    <p className="login-text">Log into your account with your user ID and password</p>
                </div>
            </div>
            <div className="powered-by-dark">
                powered by <SimplifeyeVyneLogo />
            </div>
            <div className="version-number">{process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : null}</div>
        </div>
    )
}

export default TextToPayLogin
