import { PaymentPlanBasicInfo } from '../../types/PaymentPlanBasicInfo'
import {
    Action,
    PAY_DOWN_PLAN_START_SUCCESS,
    REQUEST_PAYMENT_PLANS,
    PAY_DOWN_PLAN_ERROR,
    ON_PAYMENT_PLAN_SELECT,
    PAY_DOWN_PLAN_CANCEL,
    PAY_DOWN_PLAN_LOADING,
    ON_GO_TO_SELECT_PAYMENT_METHOD_SUCCESS,
    ON_USE_EXISTING_CREDIT_DEBIT,
    ON_BACK_TO_SELECT_PAYMENT_PLAN,
    GET_BALANCE_REMAINING_SUCCESS,
    ON_BACK_TO_SELECT_PAYMENT_METHOD,
    AMOUNT_TO_PAY_LESS_THAN_MAX,
    AMOUNT_TO_PAY_MORE_THAN_MAX,
    MAKE_OFF_CYCLE_PAYMENT_SUCCESS,
    ON_USE_EXISTING_ACH,
    ON_ADD_NEW_PAYMENT_METHOD_SELECTION,
    HIDE_PAY_DOWN_PLAN_DIALOG,
    SHOW_PAY_DOWN_PLAN_DIALOG,
    ADD_CARD_TOKEN_TO_USER_SUCCESS,
    CREATE_ACH_CUSTOMER_FOR_PORTAL_SUCCESS,
    ON_GO_TO_CONFIRMATION_SUCCESS,
    ON_BACK_TO_CHOOSE_HOW_MUCH,
} from './payDownPlanDialog.actions'
import { initialState } from './payDownPlanDialog.initial'
import { PayDownPlanDialogState } from './payDownPlanDialog.state'

const payDownPlanReducer = (state: PayDownPlanDialogState = initialState, action: Action): PayDownPlanDialogState => {
    switch (action.type) {
        case REQUEST_PAYMENT_PLANS: {
            return {
                ...state,
                show: true,
                loading: true,
            }
        }
        case PAY_DOWN_PLAN_START_SUCCESS: {
            return {
                ...state,
                loading: false,
                onSelectPaymentPlan: true,
                paymentPlans: action.payload.paymentPlans,
                paymentPlanSelect: mapPaymentPlanBasicInfoToPaymentPlanSelect(action.payload.paymentPlans),
                isOnPayDownPlanFlow: true,
            }
        }
        case PAY_DOWN_PLAN_ERROR: {
            return {
                ...state,
                loading: false,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                    messageJson: action.payload.messageJson,
                },
            }
        }
        case GET_BALANCE_REMAINING_SUCCESS: {
            return {
                ...state,
                // balanceRemainingMarks: mapBalanceRemainingToSliderMarks(action.payload.paymentPlan.planTotals.subtotal),
                // amountPaidPercentage: calculateAmountPaidPercentage(
                //     action.payload.paymentPlan.planTotals.subtotal,
                //     action.payload.paymentPlan.balanceRemaining.subtotalBalance
                // ),
                // amountPaid: formatCurrencyCents(
                //     action.payload.paymentPlan.planTotals.subtotal -
                //         action.payload.paymentPlan.balanceRemaining.subtotalBalance
                // ),
                loading: false,
                onSelectPaymentMethod: false,
                onChooseHowMuch: true,
                paymentPlanDetails: action.payload.paymentPlan,
                maxAllowedPayment: action.payload.paymentPlan.balanceRemaining.subtotalBalance,
            }
        }
        case ON_PAYMENT_PLAN_SELECT: {
            return {
                ...state,
                paymentPlanSelected: action.payload.paymentPlanId,
                paymentPlanSelectedInfo: getSelectedPaymentPlanInfoFromId(
                    action.payload.paymentPlanId,
                    state.paymentPlans
                ),
            }
        }
        case PAY_DOWN_PLAN_CANCEL: {
            return {
                ...initialState,
            }
        }
        case PAY_DOWN_PLAN_LOADING: {
            return {
                ...state,
                loading: true,
            }
        }
        case ON_GO_TO_SELECT_PAYMENT_METHOD_SUCCESS: {
            return {
                ...state,
                loading: false,
                onSelectPaymentPlan: false,
                onSelectPaymentMethod: true,
            }
        }
        case ON_USE_EXISTING_CREDIT_DEBIT: {
            return {
                ...state,
                savedCreditDebit: action.payload.savedCreditDebit,
                savedAchPaymentMethod: null,
                isAddingNewPaymentMethod: false,
            }
        }
        case ON_USE_EXISTING_ACH: {
            return {
                ...state,
                savedCreditDebit: null,
                savedAchPaymentMethod: action.payload.achPaymentMethod,
                isAddingNewPaymentMethod: false,
            }
        }
        case ON_ADD_NEW_PAYMENT_METHOD_SELECTION: {
            return {
                ...state,
                savedCreditDebit: null,
                savedAchPaymentMethod: null,
                isAddingNewPaymentMethod: true,
            }
        }

        case ON_BACK_TO_SELECT_PAYMENT_PLAN: {
            return {
                ...state,
                onSelectPaymentPlan: true,
                onSelectPaymentMethod: false,
                savedCreditDebit: null,
                savedAchPaymentMethod: null,
                isAddingNewPaymentMethod: false,
                amountToPayDown: 0,
            }
        }
        case ON_BACK_TO_SELECT_PAYMENT_METHOD: {
            return {
                ...state,
                onSelectPaymentMethod: true,
                onChooseHowMuch: true,
                savedCreditDebit: null,
                savedAchPaymentMethod: null,
                isAddingNewPaymentMethod: false,
            }
        }
        case AMOUNT_TO_PAY_LESS_THAN_MAX: {
            return {
                ...state,
                amountToPayDown: action.payload.amountToPayDown,
                amountToPayDownLessThanMax: true,
            }
        }
        case AMOUNT_TO_PAY_MORE_THAN_MAX: {
            return {
                ...state,
                amountToPayDown: action.payload.amountToPayDown,
                amountToPayDownLessThanMax: false,
            }
        }
        case MAKE_OFF_CYCLE_PAYMENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                isOnConfirmation: false,
                success: true,
                offCyclePaymentResponse: action.payload.offCyclePaymentResponse,
            }
        }
        case HIDE_PAY_DOWN_PLAN_DIALOG: {
            return {
                ...state,
                show: false,
            }
        }
        case SHOW_PAY_DOWN_PLAN_DIALOG: {
            return {
                ...state,
                show: true,
            }
        }
        case ADD_CARD_TOKEN_TO_USER_SUCCESS: {
            return {
                ...state,
                savedCreditDebit: {
                    tokenId: action.payload.tokenId,
                    cardToken: '',
                    nameOnCard: '',
                    last4: action.payload.last4,
                    cardExpirationDate: '',
                    cardType: action.payload.cardType,
                    cardFundingType: action.payload.cardFundingType,
                },
            }
        }
        case CREATE_ACH_CUSTOMER_FOR_PORTAL_SUCCESS: {
            return {
                ...state,
                show: true,
                savedAchPaymentMethod: {
                    achId: action.payload.achId,
                    payerId: '',
                    bankAccountPaymentMethodId: '',
                    bankName: action.payload.plaidBankName,
                    accountLast4: action.payload.accountLast4,
                    defaultFlag: false,
                    customerId: action.payload.customerId,
                },
            }
        }
        case ON_GO_TO_CONFIRMATION_SUCCESS: {
            return {
                ...state,
                onChooseHowMuch: false,
                isOnConfirmation: true,
                fees: action.payload.fees,
            }
        }
        case ON_BACK_TO_CHOOSE_HOW_MUCH: {
            return {
                ...state,
                onChooseHowMuch: true,
                isOnConfirmation: false,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const mapPaymentPlanBasicInfoToPaymentPlanSelect = (
    paymentPlans: PaymentPlanBasicInfo[]
): Array<{ value: string; text: string }> => {
    return paymentPlans
        .filter(
            (paymentPlan) =>
                paymentPlan.paymentPlanStatus !== 'paid_off' && paymentPlan.paymentPlanStatus !== 'cancelled'
        )
        .map((paymentPlan) => ({
            value: paymentPlan.paymentPlanId,
            text: `${paymentPlan.paymentPlanId} - ${paymentPlan.description}`,
        }))
}

const getSelectedPaymentPlanInfoFromId = (
    paymentPlanId: string,
    paymentPlans: Array<PaymentPlanBasicInfo>
): PaymentPlanBasicInfo => {
    return paymentPlans.find((paymentPlan) => paymentPlan.paymentPlanId === paymentPlanId)
}

// const mapBalanceRemainingToSliderMarks = (subtotal: number): Array<{ value: number; label: string }> => {
//     return [
//         {
//             value: 0,
//             label: '$0.00',
//         },
//         {
//             value: 100,
//             label: formatCurrencyCents(subtotal),
//         },
//     ]
// }

// // converts amountPaid/subtotal to x/100 (for the slider)
// const calculateAmountPaidPercentage = (subtotal: number, subtotalBalance: number): number => {
//     const amountPaid = subtotal - subtotalBalance
//     return Math.round((100 * amountPaid) / subtotal)
// }

export default payDownPlanReducer
