import { initialState } from './paymentPlan.initial'
import { PaymentPlanState } from './paymentPlan.state'
import { PaymentPlan } from '../../types/PaymentPlan'
import { PaymentPlanCardType } from '../../types/PaymentPlanCardType'
import { BalanceRemainingResponse } from '../../types/Response/BalanceRemainingResponse'
import SFSelectOptions from '@simplifeye/component-library/lib/component/SFSelect/types/SFSelectOptions.types'
import { PayerPaymentMethods } from '../../types/Response/PayerPaymentMethods'
import { AchPaymentMethod } from '../../types/AchPaymentMethod'
import { PaymentPlanBasicInfo } from '../../types/PaymentPlanBasicInfo'
import {
    Action,
    REQUEST_PAYMENT_PLANS,
    NO_PAYMENT_PLANS_FOUND,
    RECIEVE_PAYMENT_PLANS_ERROR,
    RECIEVE_PAYMENT_PLANS_SUCCESS,
    EXPAND_PAYMENT_PLAN_REQUEST,
    EXPAND_PAYMENT_PLAN_SUCCESS,
    EXPAND_PAYMENT_PLAN_ERROR,
    COLLAPSE_PAYMENT_PLAN,
    HANDLE_EDIT_REQUEST_PAYMENT_METHODS,
    HANDLE_EDIT_ACTIVE_SUCCESS,
    HANDLE_EDIT_ACTIVE_ERROR,
    HANDLE_EDIT_ACTIVE_CANCEL,
    HANDLE_EDIT_SAVE_EXISTING_METHOD_SUCCESS,
    HANDLE_EDIT_SAVE_EXISTING_METHOD_COMPLETE,
    HANDLE_EDIT_SAVE_EXISTING_METHOD_ERROR,
    RESET_EDIT_PAYMENT_PLAN,
    SHOW_SELECT_PAYMENT_PLAN_DIALOG,
    UPDATE_UPDATED_PAYMENT_PLAN_METHOD,
    CLOSE_SELECT_PAYMENT_PLAN_DIALOG,
} from './paymentPlan.action'
import { IS_ACH_DISABLED } from '../../shared/defaults'

const paymentPlanReducer = (state: PaymentPlanState = initialState, action: Action): PaymentPlanState => {
    switch (action.type) {
        case REQUEST_PAYMENT_PLANS: {
            return {
                ...state,
                loading: true,
                editPaymentPlan: {
                    ...initialState.editPaymentPlan,
                },
            }
        }
        case NO_PAYMENT_PLANS_FOUND: {
            return {
                ...state,
                showInfo: true,
                loading: false,
            }
        }
        case RECIEVE_PAYMENT_PLANS_ERROR: {
            return {
                ...state,
                error: {
                    title: action.payload.title,
                    message: action.payload.message,
                    showError: true,
                },
                loading: false,
            }
        }
        case RECIEVE_PAYMENT_PLANS_SUCCESS: {
            return {
                ...state,
                loading: false,
                paymentPlanCards: mapPaymentPlanToCard(action.payload.paymentPlans),
                error: {
                    ...initialState.error,
                },
            }
        }
        case EXPAND_PAYMENT_PLAN_REQUEST: {
            return {
                ...state,
                paymentPlanCards: expandSelectedPlan(action.payload.paymentPlanId, state.paymentPlanCards),
                editPaymentPlan: {
                    ...initialState.editPaymentPlan,
                },
                activePlanId: action.payload.paymentPlanId,
                paymentPlanDetailsLoading: true,
                paymentPlanDetailsError: {
                    showError: false,
                    title: '',
                    message: '',
                },
            }
        }
        case EXPAND_PAYMENT_PLAN_SUCCESS: {
            return {
                ...state,
                editPaymentPlan: {
                    ...initialState.editPaymentPlan,
                },
                paymentPlanDetailsLoading: false,
                paymentPlanDetails: action.payload.paymentPlan,
            }
        }
        case EXPAND_PAYMENT_PLAN_ERROR: {
            return {
                ...state,
                paymentPlanDetailsLoading: false,
                paymentPlanDetailsError: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case COLLAPSE_PAYMENT_PLAN: {
            return {
                ...state,
                paymentPlanCards: collapseAllPlans(state.paymentPlanCards),
                editPaymentPlan: {
                    ...initialState.editPaymentPlan,
                },
            }
        }
        case HANDLE_EDIT_REQUEST_PAYMENT_METHODS: {
            return {
                ...state,
                editPaymentPlan: {
                    ...state.editPaymentPlan,
                    loading: true,
                },
            }
        }
        case HANDLE_EDIT_ACTIVE_SUCCESS: {
            return {
                ...state,
                paymentPlanCards: makePaymentPlanEditActive(
                    action.payload.paymentPlanId,
                    state.paymentPlanCards,
                    action.payload.paymentMethods,
                    action.payload.achPaymentMethod
                ),
                editPaymentPlan: {
                    ...state.editPaymentPlan,
                    loading: false,
                    active: true,
                    paymentMethods: buildPaymentMethods(action.payload.paymentMethods, action.payload.achPaymentMethod),
                    achPaymentMethod: action.payload.achPaymentMethod,
                },
            }
        }
        case HANDLE_EDIT_ACTIVE_ERROR: {
            return {
                ...state,
                paymentPlanCards: makePaymentPlanEditError(action.payload.paymentPlanId, state.paymentPlanCards),
                editPaymentPlan: {
                    ...state.editPaymentPlan,
                    loading: false,
                    error: true,
                },
            }
        }
        case HANDLE_EDIT_ACTIVE_CANCEL: {
            return {
                ...state,
                paymentPlanCards: cancelPaymentPlanEdit(action.payload.paymentPlanId, state.paymentPlanCards),
                editPaymentPlan: {
                    ...initialState.editPaymentPlan,
                },
            }
        }
        case HANDLE_EDIT_SAVE_EXISTING_METHOD_SUCCESS: {
            return {
                ...state,
                paymentPlanCards: handleEditSaveExistingSuccess(
                    action.payload.paymentPlanId,
                    state.paymentPlanCards,
                    action.payload.updatedCard
                ),

                editPaymentPlan: {
                    ...state.editPaymentPlan,
                    success: true,
                    updatedPaymentPlanMethod: action.payload.updatedCard,
                },
            }
        }

        case HANDLE_EDIT_SAVE_EXISTING_METHOD_COMPLETE: {
            return {
                ...state,
                paymentPlanCards: handleEditSaveExistingMethodComplete(
                    action.payload.paymentPlanId,
                    state.paymentPlanCards
                ),
                editPaymentPlan: {
                    ...state.editPaymentPlan,
                    active: false,
                    success: false,
                    selectedMethod: '',
                },
            }
        }
        case HANDLE_EDIT_SAVE_EXISTING_METHOD_ERROR: {
            return {
                ...state,
                editPaymentPlan: {
                    ...state.editPaymentPlan,
                    error: true,
                },
            }
        }
        case RESET_EDIT_PAYMENT_PLAN: {
            return {
                ...state,
                editPaymentPlan: {
                    ...initialState.editPaymentPlan,
                },
            }
        }
        case SHOW_SELECT_PAYMENT_PLAN_DIALOG: {
            return {
                ...state,
                showSelectPaymentPlanDialog: true,
                editPaymentPlan: {
                    ...state.editPaymentPlan,
                    updatedPaymentPlanMethod: action.payload.updatedPaymentPlanMethod,
                    tokenId: action.payload.tokenId,
                    achId: action.payload.achId,
                },
            }
        }
        case CLOSE_SELECT_PAYMENT_PLAN_DIALOG: {
            return {
                ...state,
                showSelectPaymentPlanDialog: false,
            }
        }
        case UPDATE_UPDATED_PAYMENT_PLAN_METHOD: {
            return {
                ...state,
                paymentPlanCards: updatePaymentPlansWithNewMethod(
                    action.payload.selectedPlans,
                    state.paymentPlanCards,
                    action.payload.updatedPaymentPlanMethod
                ),
                editPaymentPlan: {
                    ...state.editPaymentPlan,
                    updatedPaymentPlanMethod: action.payload.updatedPaymentPlanMethod,
                },
            }
        }
        default: {
            return { ...state }
        }
    }
}

const mapPaymentPlanToCard = (plans: PaymentPlanBasicInfo[]): PaymentPlanCardType[] => {
    return plans
        .sort((plan1, plan2) => plan1.paymentPlanId.localeCompare(plan2.paymentPlanId))
        .map(plan => ({
            isExpanded: false,
            balance: {
                loading: true,
                balanceRemaining: null,
                error: false,
            },
            paymentPlanBasicInfo: plan,
            editPaymentPlan: {
                active: false,
                loading: false,
                paymentMethods: [],
                error: false,
                selectedMethod: '',
                success: false,
                updatedPaymentPlanMethod: '',
            },
            isPlanCompleted: plan.paymentPlanStatus === 'paid_off',
        }))
}

const expandSelectedPlan = (
    paymentPlanId: string,
    planCards: Array<PaymentPlanCardType>
): Array<PaymentPlanCardType> => {
    return planCards.map((pc: PaymentPlanCardType) =>
        pc.paymentPlanBasicInfo.paymentPlanId === paymentPlanId
            ? { ...pc, isExpanded: true }
            : { ...pc, isExpanded: false }
    )
}

const collapseAllPlans = (planCards: Array<PaymentPlanCardType>): Array<PaymentPlanCardType> => {
    return planCards.map((pc: PaymentPlanCardType) => ({ ...pc, isExpanded: false }))
}

const buildPaymentMethods = (
    paymentMethods: Array<PayerPaymentMethods>,
    achPaymentMethod: AchPaymentMethod
): Array<SFSelectOptions> => {
    const paymentMethodOptions = paymentMethods.map(pm => ({
        value: pm.tokenId,
        text: `${pm.cardType} **** ${pm.last4}`,
    }))

    if (!IS_ACH_DISABLED && achPaymentMethod) {
        paymentMethodOptions.push({
            value: 'ACH',
            text: `${achPaymentMethod.bankName} **** ${achPaymentMethod.accountLast4}`,
        })
    }

    paymentMethodOptions.push({ value: 'NEW', text: 'ADD NEW PAYMENT METHOD' })

    return paymentMethodOptions
}

const makePaymentPlanEditActive = (
    paymentPlanId: string,
    planCards: Array<PaymentPlanCardType>,
    paymentMethods: Array<PayerPaymentMethods>,
    achPaymentMethod: AchPaymentMethod
): Array<PaymentPlanCardType> => {
    return planCards.map(pc =>
        pc.paymentPlanBasicInfo.paymentPlanId === paymentPlanId
            ? {
                  ...pc,
                  editPaymentPlan: {
                      ...pc.editPaymentPlan,
                      active: true,
                      loading: false,
                      paymentMethods: buildPaymentMethods(paymentMethods, achPaymentMethod),
                  },
              }
            : { ...pc }
    )
}

const makePaymentPlanEditError = (
    paymentPlanId: string,
    planCards: Array<PaymentPlanCardType>
): Array<PaymentPlanCardType> => {
    return planCards.map(pc =>
        pc.paymentPlanBasicInfo.paymentPlanId === paymentPlanId
            ? {
                  ...pc,
                  editPaymentPlan: {
                      ...pc.editPaymentPlan,
                      error: true,
                      loading: false,
                  },
              }
            : { ...pc }
    )
}

const cancelPaymentPlanEdit = (
    paymentPlanId: string,
    planCards: Array<PaymentPlanCardType>
): Array<PaymentPlanCardType> => {
    return planCards.map(pc =>
        pc.paymentPlanBasicInfo.paymentPlanId === paymentPlanId
            ? {
                  ...pc,
                  editPaymentPlan: {
                      ...pc.editPaymentPlan,
                      active: false,
                      loading: false,
                      paymentMethods: [],
                      error: false,
                      selectedMethod: '',
                      success: false,
                  },
              }
            : { ...pc }
    )
}

const handleEditSaveExistingSuccess = (
    paymentPlanId: string,
    planCards: Array<PaymentPlanCardType>,
    updatedCard: string
): Array<PaymentPlanCardType> => {
    return planCards.map(pc =>
        pc.paymentPlanBasicInfo.paymentPlanId === paymentPlanId
            ? {
                  ...pc,
                  editPaymentPlan: {
                      ...pc.editPaymentPlan,
                      success: true,
                      updatedPaymentPlanMethod: updatedCard,
                  },
              }
            : { ...pc }
    )
}

const handleEditSaveExistingMethodComplete = (
    paymentPlanId: string,
    planCards: Array<PaymentPlanCardType>
): Array<PaymentPlanCardType> => {
    return planCards.map(pc =>
        pc.paymentPlanBasicInfo.paymentPlanId === paymentPlanId
            ? {
                  ...pc,
                  editPaymentPlan: {
                      ...pc.editPaymentPlan,
                      active: false,
                      success: false,
                      selectedMethod: '',
                  },
              }
            : { ...pc }
    )
}

const updatePaymentPlansWithNewMethod = (
    selectedPlans: string[],
    planCards: Array<PaymentPlanCardType>,
    updatedPaymentPlanMethod: string
): Array<PaymentPlanCardType> => {
    return planCards.map(pc =>
        selectedPlans.find(sp => sp === pc.paymentPlanBasicInfo.paymentPlanId)
            ? {
                  ...pc,
                  editPaymentPlan: {
                      ...pc.editPaymentPlan,
                      active: false,
                      success: false,
                      updatedPaymentPlanMethod,
                  },
              }
            : { ...pc }
    )
}

export default paymentPlanReducer
