import React from 'react'
import './PayDownPlanConfirmation.scss'
import { formatCurrencyCents, formateDateObject } from '../../../shared/utils'
import { AchPaymentMethod } from '../../../types/AchPaymentMethod'
import { CalculateFeesForSubtotalResponse } from '../../../types/Response/CalculateFeesForSubtotalResponse'
import { PayerPaymentMethods } from '../../../types/Response/PayerPaymentMethods'

interface Props {
    paymentPlanSelected: string
    savedCreditDebit: PayerPaymentMethods
    savedAchPaymentMethod: AchPaymentMethod
    fees: CalculateFeesForSubtotalResponse
}

const PayDownPlanConfirmation = ({ paymentPlanSelected, savedCreditDebit, savedAchPaymentMethod, fees }: Props) => {
    const displayPaymentMethod = (
        savedCreditDebit: PayerPaymentMethods,
        savedAchPaymentMethod: AchPaymentMethod
    ): string => {
        if (savedCreditDebit) {
            return `${savedCreditDebit.cardType} ****${savedCreditDebit.last4}`
        } else {
            return `${savedAchPaymentMethod.bankName} ****${savedAchPaymentMethod.accountLast4}`
        }
    }

    const calculateTotal = (): number => {
        if (savedCreditDebit) {
            // check type of card
            if (savedCreditDebit.cardFundingType === 'credit') {
                return fees.total.credit || 0 // return credit
            } else if (savedCreditDebit.cardFundingType === 'prepaid') {
                return fees.total.prepaid || 0 //return debit
            }
            return fees.total.debit || 0 //return debit
        }
        return fees.total.ach || 0 // return ach
    }

    return (
        <div className="pay-down-plan-dialog-inner pay-down-plan-confirmation">
            <div className="existing-payment-details">
                <h3>You're about to submit a payment. Please confirm the details below:</h3>
                <div className="details-wrapper">
                    <div className="detail">
                        <label htmlFor="description">Payment Plan</label>
                        <div id="description">{paymentPlanSelected}</div>
                    </div>
                    <div className="detail">
                        <label htmlFor="date">Date</label>
                        <div id="date">{formateDateObject(new Date(), 'MM/DD/YYYY')}</div>
                    </div>
                    <div className="detail">
                        <label htmlFor="method">Payment Method</label>
                        <div id="method">{displayPaymentMethod(savedCreditDebit, savedAchPaymentMethod)}</div>
                    </div>
                </div>
            </div>
            <div className="cost-summary-wrapper">
                <div className="cost-summary treatment-cost">
                    <h4>Subtotal</h4>
                    <span>{formatCurrencyCents(calculateTotal() - fees.taxAmount)}</span>
                </div>
                {fees.taxAmount > 0 && (
                    <div className="cost-summary convenience-fee">
                        <h4>Tax</h4>
                        <span>{formatCurrencyCents(fees.taxAmount)}</span>
                    </div>
                )}
                <div className="cost-summary payment-due">
                    <h4>Payment Due</h4>
                    <span>{formatCurrencyCents(calculateTotal())}</span>
                </div>
            </div>
        </div>
    )
}

export default PayDownPlanConfirmation
