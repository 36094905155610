import React from 'react'

const MaintenanceIcon = () => (
    <svg
        width="357px"
        height="344px"
        viewBox="0 0 357 344"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <title>3D7D3A1A-6688-4AA6-B461-8A5B6496EFAC</title>
        <defs>
            <polygon id="path-1" points="0 0 8.0597 0 8.0597 5.447 0 5.447"></polygon>
            <polygon id="path-3" points="0 2.84217094e-14 183.3313 2.84217094e-14 183.3313 183.036 0 183.036"></polygon>
            <polygon id="path-5" points="0 343.646 356.368 343.646 356.368 0 0 0"></polygon>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="portal-login" transform="translate(-341.000000, -110.000000)">
                <g id="site-maintenance" transform="translate(341.000000, 110.000000)">
                    <path
                        d="M270.633294,181.8352 L270.9163,184.7142 C270.8953,184.5462 278.2993,184.3282 278.2993,184.3282 L278.3033,181.4332 C278.3033,181.4332 270.6253,181.6592 270.633294,181.8352"
                        id="Fill-1"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M259.3131,184.0788 L260.2401,186.8268 C260.2401,186.8268 260.6791,186.6908 261.3271,186.4988 C261.9791,186.3098 262.8401,185.9938 263.7471,185.8548 C265.5351,185.4938 267.3351,185.1288 267.3351,185.1288 L266.8801,182.2698 C266.8801,182.2698 264.9771,182.6508 263.0701,183.0368 C262.1141,183.1928 261.1871,183.5168 260.4821,183.7298 C259.7851,183.9348 259.3131,184.0788 259.3131,184.0788"
                        id="Fill-3"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M248.8828,189.1604 L250.5558,191.5354 C250.4168,191.3014 256.8238,187.8644 256.9098,188.0574 L255.7278,185.4074 C255.6298,185.2064 248.7268,188.9144 248.8828,189.1604"
                        id="Fill-5"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M240.5727,197.2609 L242.8577,199.0489 C242.8577,199.0489 243.8867,197.5439 245.1707,196.2609 C246.3437,194.8619 247.7627,193.7219 247.7627,193.7219 L245.8677,191.5189 C245.8677,191.5189 244.3427,192.7489 243.0747,194.2509 C241.6887,195.6499 240.5727,197.2609 240.5727,197.2609"
                        id="Fill-7"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M234.7813,207.2565 L237.4143,208.4585 C237.4143,208.4585 238.1733,206.7725 239.0383,205.1445 C239.4773,204.3485 239.9203,203.5405 240.2443,202.9455 C240.6093,202.3715 240.8523,201.9825 240.8523,201.9825 L238.4153,200.4235 C238.4153,200.4235 238.1573,200.8335 237.7633,201.4575 C237.4143,202.0885 236.9433,202.9455 236.4793,203.7995 C235.5653,205.5095 234.7813,207.2565 234.7813,207.2565"
                        id="Fill-9"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M230.7661,217.3546 L233.1451,219.2046 C233.1451,219.2046 233.8211,217.3836 234.5021,215.5496 C235.2161,213.7286 235.9261,211.9116 235.9261,211.9116 L233.3461,210.5786 C233.3461,210.5786 232.6901,212.2646 232.0211,213.9546 C231.3981,215.6526 230.7661,217.3546 230.7661,217.3546"
                        id="Fill-11"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M226.8286,227.3993 L228.8256,229.9343 C228.8256,229.9343 229.6546,228.1913 230.3716,226.3903 C231.1356,224.6063 231.8036,222.7813 231.8036,222.7813 L229.5396,220.6763 C229.5396,220.6763 228.8916,222.3663 228.2396,224.0603 C227.5916,225.7543 226.8286,227.3993 226.8286,227.3993"
                        id="Fill-13"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M221.3652,236.6691 L222.8542,239.7041 C222.8542,239.7041 223.2022,239.3681 223.6132,238.7771 C224.0392,238.1911 224.6052,237.4111 225.1672,236.6321 C225.6802,235.8161 226.1802,234.9911 226.5662,234.3761 C226.9352,233.7611 227.1362,233.3261 227.1362,233.3261 L225.3022,230.5981 C225.3022,230.5981 224.4452,232.1941 223.5022,233.7451 C222.9772,234.4871 222.4522,235.2251 222.0662,235.7831 C221.6892,236.3531 221.3652,236.6691 221.3652,236.6691"
                        id="Fill-15"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M212.9119,243.2152 L213.7649,246.5782 C213.7649,246.5782 214.1999,246.3612 214.8479,246.0412 C215.4959,245.7172 216.3739,245.3192 217.1529,244.7332 C218.8179,243.7272 220.2169,242.3992 220.2209,242.3992 L218.9249,239.2322 C218.9249,239.2322 217.6249,240.5032 216.0699,241.4592 C215.3529,242.0172 214.5279,242.4032 213.9209,242.7022 C213.3139,243.0142 212.9119,243.2152 212.9119,243.2152"
                        id="Fill-17"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M202.4531,245.6105 L202.5971,249.1005 C202.5971,249.1005 204.5161,248.9075 206.4401,248.7025 C207.3871,248.5015 208.3301,248.3055 209.0441,248.1495 C209.7621,248.0175 210.2211,247.8745 210.2211,247.8745 L209.5941,244.4415 C209.5901,244.4415 209.1591,244.5805 208.4901,244.7125 C207.8301,244.8515 206.9401,245.0445 206.0541,245.2325 C204.2491,245.4175 202.4531,245.6105 202.4531,245.6105"
                        id="Fill-19"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M191.6985,245.0116 L191.1245,248.4656 C191.1245,248.4656 191.5915,248.5676 192.3055,248.7106 C193.0275,248.7726 193.9915,248.8546 194.9555,248.9366 C195.9195,249.0146 196.8835,249.0966 197.6055,249.1536 C198.3275,249.1496 198.8105,249.1536 198.8105,249.1536 L198.9055,245.6556 C198.9055,245.6556 198.4535,245.6556 197.7685,245.6596 C197.1005,245.6016 196.1935,245.5326 195.2875,245.4586 C194.3895,245.3806 193.4825,245.3026 192.8145,245.2446 C192.1415,245.1056 191.6985,245.0116 191.6985,245.0116"
                        id="Fill-21"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M182.0024,240.5776 L180.7514,243.7976 C180.6944,243.8716 182.3184,244.9176 183.9594,245.9346 C184.7304,246.5246 185.7104,246.7336 186.3544,247.0626 C187.0224,247.3376 187.4704,247.5176 187.4704,247.5176 L188.2784,244.1256 C188.2784,244.1256 187.8594,243.9496 187.2324,243.6866 C186.6334,243.3786 185.7184,243.1786 184.9924,242.6166 C183.4624,241.6356 181.9454,240.6516 182.0024,240.5776"
                        id="Fill-23"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M175.0258,232.4074 L173.2128,235.2044 C173.2128,235.2044 174.3118,236.7884 175.4068,238.3714 C176.6708,239.8274 177.9378,241.2834 177.9378,241.2834 L179.3898,238.1784 C179.3898,238.1784 178.2208,236.7924 177.0518,235.4134 C176.0348,233.9084 175.0258,232.4074 175.0258,232.4074"
                        id="Fill-25"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M170.2433,222.7482 L167.9793,224.9462 C167.9793,224.9462 168.7343,226.7352 169.5543,228.4902 C170.3093,230.2822 171.3013,231.9402 171.3013,231.9402 L173.2783,229.3272 C173.2783,229.3272 172.3683,227.7562 171.6833,226.0702 C170.9363,224.4212 170.2433,222.7482 170.2433,222.7482"
                        id="Fill-27"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M166.8022,212.5229 L164.2102,213.9909 C164.2102,213.9909 164.7512,215.8659 165.3712,217.7119 C165.9492,219.5729 166.6182,221.3989 166.6182,221.3989 L169.0002,219.4259 C169.0002,219.4259 168.3932,217.7159 167.8602,215.9849 C167.2982,214.2659 166.8022,212.5229 166.8022,212.5229"
                        id="Fill-29"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M164.1566,202.0435 L161.3426,202.7365 C161.3426,202.7365 161.7896,204.6275 162.2416,206.5225 C162.6806,208.4295 163.1886,210.3125 163.1886,210.3125 L165.8666,209.0985 C165.8666,209.0985 165.3956,207.3505 164.9896,205.5835 C164.5746,203.8155 164.1566,202.0435 164.1566,202.0435"
                        id="Fill-31"
                        fill="#FBE366"
                    ></path>
                    <polygon
                        id="Fill-33"
                        fill="#FBE366"
                        points="161.9499 191.1251 159.0999 191.6501 160.5639 199.0861 163.3979 198.4791"
                    ></polygon>
                    <polygon
                        id="Fill-35"
                        fill="#FBE366"
                        points="160.0347 180.0922 157.1797 180.5592 158.4467 188.0082 161.2977 187.5032"
                    ></polygon>
                    <polygon
                        id="Fill-37"
                        fill="#FBE366"
                        points="158.267 169.0138 155.412 169.4648 156.585 176.9218 159.444 176.4538"
                    ></polygon>
                    <polygon
                        id="Fill-39"
                        fill="#FBE366"
                        points="156.5277 157.9149 153.6687 158.3739 154.8417 165.8139 157.7007 165.3669"
                    ></polygon>
                    <polygon
                        id="Fill-41"
                        fill="#FBE366"
                        points="154.6739 146.8244 151.8279 147.3284 153.0779 154.7354 155.9329 154.2724"
                    ></polygon>
                    <path
                        d="M152.5533,135.754 L149.7313,136.332 C149.7313,136.332 150.1043,138.17 150.4783,140.012 C150.8263,141.861 151.1633,143.703 151.1633,143.703 L154.0173,143.174 C154.0173,143.174 153.6773,141.316 153.3283,139.458 C152.9433,137.604 152.5533,135.754 152.5533,135.754"
                        id="Fill-43"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M149.9816,124.7332 L147.1806,125.4882 C147.1806,125.4882 147.6476,127.2962 148.1116,129.1052 C148.5386,130.9262 148.9606,132.7472 148.9606,132.7472 L151.7826,132.1202 C151.7826,132.1202 151.3556,130.2662 150.9246,128.4202 C150.4536,126.5832 149.9816,124.7332 149.9816,124.7332"
                        id="Fill-45"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M146.5813,113.8968 L143.8743,114.9018 C143.8743,114.9018 144.5343,116.6288 145.1133,118.4008 C145.6583,120.1888 146.2083,121.9648 146.2083,121.9648 L148.9853,121.1488 C148.9853,121.1488 148.4273,119.3228 147.8613,117.5018 C147.2663,115.6848 146.5813,113.8968 146.5813,113.8968"
                        id="Fill-47"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M141.7538,103.4338 L139.3008,104.9838 C139.3008,104.9838 140.1628,106.5838 141.0158,108.1918 C141.8398,109.8158 142.5328,111.5348 142.5328,111.5428 L145.1948,110.3938 C145.1948,110.3978 144.4778,108.6228 143.5998,106.8828 C142.6808,105.1568 141.7538,103.4338 141.7538,103.4338"
                        id="Fill-49"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M134.248,94.6033 L132.345,96.7893 C132.345,96.7893 132.694,97.0803 133.206,97.5273 C133.69,98.0033 134.478,98.4993 135.023,99.2293 C135.61,99.9183 136.204,100.6163 136.643,101.1323 C137.115,101.6243 137.345,102.0263 137.345,102.0263 L139.63,100.2423 C139.63,100.2423 139.387,99.8163 138.883,99.2783 C138.403,98.7213 137.771,97.9783 137.131,97.2323 C136.541,96.4483 135.692,95.9113 135.171,95.3983 C134.617,94.9193 134.248,94.6033 134.248,94.6033"
                        id="Fill-51"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M124.5111,88.3688 L123.1741,90.9318 C123.1741,90.9318 123.5961,91.1048 124.1911,91.4698 C124.7821,91.8228 125.5811,92.2898 126.3731,92.7528 C128.0051,93.6518 129.4531,94.7298 129.4531,94.7298 L131.1391,92.3638 C131.1391,92.3638 129.5441,91.1988 127.8581,90.2718 C127.0371,89.7838 126.2051,89.2918 125.5811,88.9308 C124.9621,88.5498 124.5111,88.3688 124.5111,88.3688"
                        id="Fill-53"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M113.7323,84.1852 L113.0593,86.9992 C113.0593,86.9992 114.8023,87.5072 116.5453,88.0162 C118.2443,88.6892 119.9453,89.3532 119.9453,89.3532 L121.1063,86.7072 C121.1063,86.7072 119.3023,85.9982 117.4853,85.2842 C115.6143,84.7382 113.7323,84.1852 113.7323,84.1852"
                        id="Fill-55"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M102.0222,83.6068 L102.4572,86.4698 C102.4572,86.4698 102.8962,86.3918 103.5602,86.2928 C104.2162,86.2238 105.1192,86.2238 106.0042,86.1828 C107.7892,86.1618 109.5812,86.3718 109.5812,86.3718 L109.9422,83.4958 C109.9422,83.4958 107.9692,83.2658 105.9802,83.2828 C104.9912,83.3318 103.9952,83.3398 103.2522,83.4178 C102.5142,83.5288 102.0222,83.6068 102.0222,83.6068"
                        id="Fill-57"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M91.0586,87.885 L92.6666,90.297 C92.6666,90.297 93.0396,90.046 93.5896,89.673 C94.1796,89.353 94.9716,88.931 95.7506,88.508 C96.5586,88.131 97.3956,87.835 98.0106,87.569 C98.6176,87.302 99.0726,87.249 99.0606,87.224 L98.2156,84.46 C98.2156,84.46 97.7156,84.542 97.0266,84.854 C96.3376,85.141 95.3976,85.473 94.4996,85.9 C93.6226,86.371 92.7446,86.839 92.0926,87.196 C91.4686,87.614 91.0586,87.885 91.0586,87.885"
                        id="Fill-59"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M82.63,95.9443 L84.923,97.7033 C84.923,97.7033 85.197,97.3353 85.612,96.7973 C86.067,96.2853 86.658,95.6033 87.256,94.9103 C88.512,93.6103 89.865,92.4133 89.865,92.4133 L87.978,90.2223 C87.978,90.2223 86.494,91.5273 85.14,92.9383 C84.496,93.6803 83.852,94.4143 83.372,94.9723 C82.929,95.5463 82.63,95.9443 82.63,95.9443"
                        id="Fill-61"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M76.7235,105.8374 L79.3405,107.0514 C79.2015,106.9814 82.7365,100.5344 82.8635,100.6244 L80.4355,99.0534 C80.4355,99.0534 79.3975,100.6974 78.4465,102.3834 C77.5395,104.0944 76.7235,105.8374 76.7235,105.8374"
                        id="Fill-63"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M72.8108,116.6201 L75.6038,117.3751 C75.6038,117.3751 76.0958,115.5951 76.6578,113.8391 C77.2528,112.0881 77.9298,110.3731 77.9298,110.3731 L75.2308,109.3111 C75.2308,109.3111 74.5298,111.1041 73.9098,112.9291 C73.3198,114.7621 72.8148,116.6201 72.8108,116.6201"
                        id="Fill-65"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M70.7642,127.9366 L73.6512,128.1376 C73.6512,128.1376 73.7912,126.3326 74.0822,124.5116 C74.3612,122.6866 74.7512,120.8906 74.7512,120.8906 L71.9162,120.2786 C71.9162,120.2786 71.5102,122.1656 71.2232,124.0726 C70.9112,125.9756 70.7642,127.9366 70.7642,127.9366"
                        id="Fill-67"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M71.3874,139.3757 L74.2674,139.0847 L73.7374,134.1337 C73.6434,133.3257 73.5894,132.5017 73.5534,131.6857 L70.6574,131.7677 C70.6984,132.6457 70.7524,133.5307 70.8584,134.4047 L71.3874,139.3757 Z"
                        id="Fill-69"
                        fill="#FBE366"
                    ></path>
                    <polygon
                        id="Fill-71"
                        fill="#FBE366"
                        points="72.3308 150.5158 75.2188 150.3188 74.6198 142.7718 71.7358 143.0348"
                    ></polygon>
                    <path
                        d="M72.8885,161.6718 L75.7805,161.5898 C75.7805,161.5898 75.7185,159.6948 75.6495,157.7958 C75.5505,155.9178 75.4565,154.0148 75.4565,154.0148 L72.5645,154.1778 C72.5645,154.1778 72.6595,156.0528 72.7575,157.9268 C72.8225,159.7978 72.8885,161.6718 72.8885,161.6718"
                        id="Fill-73"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M72.9708,172.8283 L75.8628,172.8773 C75.8628,172.8773 75.8748,170.9823 75.8908,169.0833 C75.8748,167.1843 75.8588,165.2933 75.8588,165.2933 L72.9668,165.3343 C72.9668,165.3343 72.9828,167.2093 72.9948,169.0793 C72.9868,170.9543 72.9708,172.8283 72.9708,172.8283"
                        id="Fill-75"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M72.4702,183.9557 L75.3542,184.1767 C75.3542,184.1767 75.4642,182.2817 75.5832,180.3837 C75.7232,178.4887 75.7562,176.5927 75.7562,176.5927 L72.8602,176.4867 C72.8602,176.4867 72.8232,178.3607 72.6952,180.2237 C72.5812,182.0937 72.4702,183.9557 72.4702,183.9557"
                        id="Fill-77"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M71.2194,194.9971 L74.0824,195.4361 C74.0824,195.4361 74.3404,193.5491 74.5864,191.6661 C74.8534,189.7761 75.0134,187.8801 75.0134,187.8801 L72.1304,187.5941 C72.1304,187.5941 71.9784,189.4601 71.7114,191.3011 C71.4774,193.1511 71.2194,194.9971 71.2194,194.9971"
                        id="Fill-79"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M69.0086,205.8457 L71.8146,206.5717 C71.8146,206.5717 72.3066,204.7217 72.6916,202.8557 C73.1306,200.9977 73.4506,199.1147 73.4506,199.1147 L70.6086,198.5897 C70.6036,198.5937 70.2886,200.4277 69.8616,202.2317 C69.4886,204.0617 69.0086,205.8457 69.0086,205.8457"
                        id="Fill-81"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M65.5305,216.276 L68.1965,217.4 C68.1965,217.4 69.0165,215.653 69.5915,213.819 C70.2185,212.006 70.8135,210.185 70.8135,210.185 L68.0485,209.344 C68.0485,209.344 67.4625,211.1 66.8675,212.843 C66.3135,214.615 65.5305,216.276 65.5305,216.276"
                        id="Fill-83"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M60.375,225.8163 L62.701,227.5593 C62.701,227.5553 63.804,225.9353 64.825,224.2823 C65.74,222.5633 66.65,220.8493 66.65,220.8493 L64.058,219.5573 C64.058,219.5573 63.205,221.1733 62.348,222.7763 C61.359,224.2983 60.375,225.8163 60.375,225.8163"
                        id="Fill-85"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M53.0578,233.6913 L54.8048,236.0213 C54.9768,236.2303 60.5268,230.7213 60.3668,230.5703 L58.2138,228.6253 C58.3608,228.7573 53.2218,233.8883 53.0578,233.6913"
                        id="Fill-87"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M43.5994,238.7729 L44.6124,241.5009 C44.6124,241.5009 45.0764,241.3409 45.7694,241.0909 C46.4454,240.8279 47.4064,240.5819 48.2464,240.0689 C49.9694,239.1629 51.7004,238.2559 51.7004,238.2559 L50.1824,235.7589 C50.1824,235.7589 48.5704,236.6079 46.9754,237.4439 C46.1914,237.9319 45.3054,238.1379 44.6704,238.3919 C44.0344,238.6259 43.5994,238.7729 43.5994,238.7729"
                        id="Fill-89"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M33.0255,240.6721 L33.2185,243.5591 C33.2185,243.5591 35.1625,243.4201 37.1155,243.2681 C39.0425,243.0051 40.9455,242.5461 40.9495,242.5421 L40.1995,239.7371 C40.1995,239.7371 38.4355,240.1631 36.6395,240.4011 C34.8345,240.5371 33.0255,240.6721 33.0255,240.6721"
                        id="Fill-91"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M22.1975,239.8762 L21.7255,242.7312 C21.7255,242.7312 23.6335,243.0182 25.5325,243.3132 C27.4595,243.4812 29.4085,243.5302 29.4085,243.5302 L29.4775,240.6352 C29.4775,240.6352 27.6565,240.5902 25.8395,240.4382 C24.0185,240.1552 22.1975,239.8762 22.1975,239.8762"
                        id="Fill-93"
                        fill="#FBE366"
                    ></path>
                    <path
                        d="M11.5005,237.2802 L10.6395,240.0442 C10.5775,240.2172 18.0305,242.0382 18.0305,242.0382 L18.6535,239.2072 C18.6535,239.2072 11.4595,237.4562 11.5005,237.2802"
                        id="Fill-95"
                        fill="#FBE366"
                    ></path>
                    <g id="Group-99" transform="translate(0.000000, 233.396000)">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1"></use>
                        </mask>
                        <g id="Clip-98"></g>
                        <path
                            d="M1.1277,0 L-0.0003,2.674 C-0.0003,2.674 1.7597,3.404 3.5147,4.138 C4.3807,4.532 5.2827,4.848 5.9677,5.061 C6.6447,5.295 7.0957,5.447 7.0957,5.447 L8.0597,2.719 C8.0597,2.719 7.6167,2.572 6.9607,2.346 C6.2917,2.141 5.4177,1.821 4.5687,1.444 C2.8507,0.726 1.1277,0 1.1277,0"
                            id="Fill-97"
                            fill="#FBE366"
                            mask="url(#mask-2)"
                        ></path>
                    </g>
                    <path
                        d="M285.0708,243.5966 C284.6028,254.8516 275.0798,263.5626 263.8328,263.0746 C252.5698,262.5866 243.8458,253.0836 244.3138,241.8206 C244.8178,230.5746 254.3338,221.8626 265.5718,222.3376 C276.8348,222.8226 285.5588,232.3336 285.0708,243.5966"
                        id="Fill-100"
                        fill="#CBE9F4"
                    ></path>
                    <g id="Group-104" transform="translate(173.036700, 151.910400)">
                        <mask id="mask-4" fill="white">
                            <use xlinkHref="#path-3"></use>
                        </mask>
                        <g id="Clip-103"></g>
                        <path
                            d="M89.41,143.657 C60.223,142.398 37.574,117.711 38.837,88.516 C40.105,59.329 64.784,36.688 93.979,37.939 C123.149,39.207 145.831,63.89 144.547,93.081 C143.292,122.271 118.621,144.916 89.41,143.657 M162.594,79.472 C156.175,73.754 152.775,64.033 154.051,55.793 C154.846,50.642 169.337,41.672 169.337,41.672 L147.722,18.182 C147.722,18.182 137.579,31.927 132.394,32.283 C124.458,32.861 115.487,28.789 110.66,22.164 C107.596,17.948 111.566,1.374 111.566,1.374 L79.636,2.84217094e-14 C79.636,2.84217094e-14 82.138,16.874 78.742,20.786 C74.033,26.201 65.637,28.953 58.779,27.579 C53.669,26.566 45.408,11.661 45.408,11.661 L20.951,32.205 C20.951,32.205 34.191,42.935 34.326,48.123 C34.539,56.843 29.909,66.466 22.596,71.674 C18.346,74.701 1.788,70.518 1.788,70.518 L0,102.424 C0,102.424 16.874,100.107 20.738,103.56 C27.161,109.306 30.569,119.027 29.298,127.255 C28.506,132.398 13.99,141.364 13.99,141.364 L35.606,164.854 C35.606,164.854 45.741,151.122 50.941,150.761 C58.853,150.195 67.856,154.276 72.688,160.871 C75.747,165.096 71.806,181.658 71.806,181.658 L103.712,183.036 C103.712,183.036 101.186,166.17 104.582,162.249 C109.31,156.847 117.723,154.083 124.581,155.461 C129.671,156.491 137.944,171.384 137.944,171.384 L162.397,150.835 C162.397,150.835 149.166,140.101 149.01,134.908 C148.809,126.197 153.435,116.582 160.748,111.361 C164.997,108.355 181.568,112.538 181.568,112.538 L183.332,80.641 C183.332,80.641 166.482,82.929 162.594,79.472"
                            id="Fill-102"
                            fill="#CBE9F4"
                            mask="url(#mask-4)"
                        ></path>
                    </g>
                    <path
                        d="M305.1191,245.1754 C304.1641,267.4874 285.3011,284.7964 262.9801,283.8204 C240.6791,282.8564 223.3751,264.0014 224.3391,241.7014 C225.2951,219.3684 244.1621,202.0764 266.4741,203.0274 C288.7871,203.9994 306.0911,222.8674 305.1191,245.1754"
                        id="Fill-105"
                        fill="#CBE9F4"
                    ></path>
                    <path
                        d="M204.8689,99.734 C205.2089,97.232 203.6749,89.854 196.8459,87.725 C187.7159,84.887 168.5659,88.599 159.6329,78.886 C150.6749,69.128 153.3899,60.642 154.4439,54.711 C155.5229,48.768 158.7429,46.91 158.9689,43.391 C159.2149,39.851 155.0179,39.757 151.0109,48.866 C149.0919,53.218 137.0699,73.595 145.5809,87.147 C156.7249,104.914 202.7479,114.504 204.8689,99.734"
                        id="Fill-107"
                        fill="#FBC6B4"
                    ></path>
                    <path
                        d="M206.6652,95.0871 C206.6652,95.0871 207.2562,105.5581 191.7152,106.8711 C176.1662,108.1831 169.2142,106.6001 169.2142,106.6001 C169.2142,106.6001 165.4032,97.1381 170.0222,81.8061 C170.0222,81.8061 207.9492,80.2481 206.6652,95.0871"
                        id="Fill-109"
                        fill="#EDA503"
                    ></path>
                    <path
                        d="M232.103,87.7619 L232.079,87.7089 C226.837,84.8739 220.123,84.2339 214.971,83.6969 C203.626,82.5079 185.099,84.0909 181.563,103.9629 C180.411,110.5129 180.075,120.0939 180.666,129.8769 C180.764,132.0049 180.891,134.1789 181.166,136.3119 C182.072,145.8609 179.189,152.2789 182.597,157.1519 C192.494,171.3019 249.801,183.2749 240.585,165.6749 C240.405,165.2729 238.346,159.4489 242.468,157.6889 C239.572,149.5519 239.875,139.8189 238.543,133.0589 C238.157,131.0449 237.886,129.0149 237.743,126.9069 C237.361,122.0799 237.738,116.9569 239.104,110.9719 C242.209,97.6869 238.21,91.1869 232.103,87.7619"
                        id="Fill-111"
                        fill="#FFB502"
                    ></path>
                    <path
                        d="M159.653,223.269 C170.691,234.958 188.684,248.006 194.795,256.968 C195.772,258.395 195.903,259.047 191.883,263.165 C187.831,267.254 194.04,267.5 202.289,262.792 C210.545,258.063 183.803,220.64 169.32,204.168 C156.667,189.759 146.827,209.705 159.653,223.269"
                        id="Fill-113"
                        fill="#FBC6B4"
                    ></path>
                    <path
                        d="M203.6547,256.8199 C203.6547,256.8199 198.0767,263.9319 191.8837,263.1649 C191.8837,263.1649 184.3117,268.8089 190.6487,268.2229 C197.0057,267.6239 207.9697,264.1089 203.6547,256.8199"
                        id="Fill-115"
                        fill="#FFB502"
                    ></path>
                    <path
                        d="M224.1461,101.666 C222.1651,111.543 217.2431,126.727 239.9331,138.76 C239.9331,138.76 200.7301,186.142 177.2821,161.885 C177.2821,161.885 180.3871,119.04 186.1291,101.859 C187.7771,96.929 191.6911,83.398 202.4041,83.947 C202.4041,83.947 186.4041,108.2 193.5731,114.311 C200.7431,120.422 207.1331,114.865 210.0331,109.984 C214.6961,102.138 225.0121,85.165 225.0121,85.165 L231.2871,87.319 C231.2871,87.319 225.8361,93.258 224.1461,101.666"
                        id="Fill-117"
                        fill="#1C2745"
                    ></path>
                    <path
                        d="M223.818,69.5629 C223.818,69.5629 217.44,76.5149 221.821,82.6719 C226.328,88.9919 199.598,94.0089 201.666,89.4109 C204.315,83.4719 208.605,80.8049 207.806,72.7829 L223.818,69.5629 Z"
                        id="Fill-119"
                        fill="#F7B2A0"
                    ></path>
                    <path
                        d="M215.652,49.9984 C208.847,53.3044 196.44,73.2744 200.439,79.7844 C202.215,82.6104 212.818,83.3444 222.042,75.7314 C225.836,72.6354 231.312,66.2614 229.654,57.6354 C228.896,53.7264 223.539,46.1554 215.652,49.9984"
                        id="Fill-121"
                        fill="#FBC6B4"
                    ></path>
                    <path
                        d="M209.483,50.9544 C207.478,53.3214 207.613,54.0464 215.434,57.8574 C223.24,61.6844 224.827,61.5654 223.379,63.2544 C219.13,68.2464 225.331,68.9394 220.188,74.8544 C215.057,80.7804 223.24,78.7714 228.022,74.0994 C228.961,73.1514 234.137,68.7184 232.809,58.9564 C231.271,47.5494 216.903,42.2834 209.483,50.9544"
                        id="Fill-123"
                        fill="#1D1B3A"
                    ></path>
                    <path
                        d="M218.2971,65.6174 C218.2971,65.6174 199.4101,56.6434 201.9981,48.8954 C203.5971,44.1294 218.0511,48.6694 218.0511,48.6694 L218.2971,65.6174 Z"
                        id="Fill-125"
                        fill="#EDA503"
                    ></path>
                    <path
                        d="M208.4658,51.9754 C209.1548,60.0144 214.0888,64.5514 230.1758,72.0074 C230.1758,72.0074 241.3078,58.3414 231.7668,48.3904 C223.3418,39.6174 208.0308,46.8444 208.4658,51.9754"
                        id="Fill-127"
                        fill="#FFB502"
                    ></path>
                    <path
                        d="M182.868,152.394 C182.868,152.394 136.43,168.041 128.645,181.499 C120.861,194.96 189.11,256.619 189.11,256.619 C189.11,256.619 200.025,249.425 197.515,240.319 C188.102,206.186 184.369,195.112 184.369,195.112 L229.384,170.888 L182.868,152.394 Z"
                        id="Fill-129"
                        fill="#1C2745"
                    ></path>
                    <path
                        d="M203.745,248.8915 C205.435,264.8795 211.825,286.1665 211.251,296.9945 C211.16,298.7175 210.874,299.3285 205.172,300.1605 C199.475,300.9565 204.258,304.9145 213.679,306.1785 C223.108,307.4205 224.544,261.4505 223.018,239.5645 C221.693,220.4395 201.784,230.3275 203.745,248.8915"
                        id="Fill-131"
                        fill="#FBC6B4"
                    ></path>
                    <path
                        d="M218.3874,302.2571 C218.3874,302.2571 209.6304,304.5291 205.1724,300.1611 C205.1724,300.1611 195.7344,300.0581 201.1284,303.4261 C206.5464,306.8141 217.3954,310.6611 218.3874,302.2571"
                        id="Fill-133"
                        fill="#FFB502"
                    ></path>
                    <path
                        d="M239.933,138.7604 C239.933,138.7604 244.953,157.9924 245.097,184.1694 C245.261,215.7144 224.38,288.8454 224.38,288.8454 C224.38,288.8454 225.208,291.4904 207.162,285.4034 C202.297,283.7554 199.873,177.8734 199.873,177.8734 L211.792,150.7984 L239.933,138.7604 Z"
                        id="Fill-135"
                        fill="#1C2745"
                    ></path>
                    <path
                        d="M218.1661,288.7382 L217.1821,288.3772 C235.5031,238.2072 231.4281,153.4972 231.3851,152.6482 L232.4351,152.5912 C232.4781,153.4442 236.5641,238.3712 218.1661,288.7382"
                        id="Fill-137"
                        fill="#FFFFFF"
                    ></path>
                    <polygon
                        id="Fill-139"
                        fill="#464F72"
                        points="245.6732 340.4797 176.5882 170.1867 136.6642 170.1867 205.1042 338.9047 201.2122 340.4797 130.4292 165.9867 179.4182 165.9867 249.5652 338.9047"
                    ></polygon>
                    <mask id="mask-6" fill="white">
                        <use xlinkHref="#path-5"></use>
                    </mask>
                    <g id="Clip-142"></g>
                    <polygon
                        id="Fill-141"
                        fill="#464F72"
                        mask="url(#mask-6)"
                        points="164.333 246.082 208.79 246.082 208.79 241.882 164.333 241.882"
                    ></polygon>
                    <polygon
                        id="Fill-143"
                        fill="#464F72"
                        mask="url(#mask-6)"
                        points="173.233 266.922 217.69 266.922 217.69 262.722 173.233 262.722"
                    ></polygon>
                    <polygon
                        id="Fill-144"
                        fill="#464F72"
                        mask="url(#mask-6)"
                        points="180.929 287.963 225.385 287.963 225.385 283.763 180.929 283.763"
                    ></polygon>
                    <polygon
                        id="Fill-145"
                        fill="#464F72"
                        mask="url(#mask-6)"
                        points="190.579 309.418 235.04 309.418 235.04 305.218 190.579 305.218"
                    ></polygon>
                    <polygon
                        id="Fill-146"
                        fill="#464F72"
                        mask="url(#mask-6)"
                        points="109.1446 340.4301 105.2106 338.9541 151.3456 216.0871 155.2786 217.5641"
                    ></polygon>
                    <polygon
                        id="Fill-147"
                        fill="#464F72"
                        mask="url(#mask-6)"
                        points="155.2787 343.646 151.3457 342.169 197.4797 219.299 201.4127 220.776"
                    ></polygon>
                    <path
                        d="M168.8325,69.0954 C168.8325,77.0404 162.3885,83.4834 154.4445,83.4834 C146.4955,83.4834 140.0605,77.0404 140.0605,69.0954 C140.0605,61.1554 146.4955,54.7114 154.4445,54.7114 C162.3885,54.7114 168.8325,61.1554 168.8325,69.0954"
                        id="Fill-148"
                        fill="#FFCF03"
                        mask="url(#mask-6)"
                    ></path>
                    <polygon
                        id="Fill-149"
                        fill="#464F72"
                        mask="url(#mask-6)"
                        points="151.721 38.531 157.155 38.531 157.155 0.001 151.721 0.001"
                    ></polygon>
                    <polygon
                        id="Fill-150"
                        fill="#6D76A1"
                        mask="url(#mask-6)"
                        points="147.771 47.423 161.109 47.423 161.109 29.638 147.771 29.638"
                    ></polygon>
                    <path
                        d="M154.4399,38.0343 C136.0239,38.0343 121.0949,52.9683 121.0949,71.3803 L187.7819,71.3803 C187.7819,52.9683 172.8519,38.0343 154.4399,38.0343"
                        id="Fill-151"
                        fill="#C1E4FA"
                        mask="url(#mask-6)"
                    ></path>
                    <path
                        d="M238.9078,96.6991 C238.4238,94.3321 235.1918,89.6931 228.3828,90.0261 C219.2578,90.4401 203.2938,99.9961 192.1248,94.1811 C180.9238,88.3441 180.5998,79.8501 179.6158,74.1821 C178.6558,68.5131 180.9238,65.8021 179.9968,62.5701 C179.0618,59.3131 175.2558,60.5971 174.6328,70.0591 C174.3368,74.5871 170.1658,96.7731 182.2038,106.1651 C197.9738,118.4781 241.8118,110.6191 238.9078,96.6991"
                        id="Fill-152"
                        fill="#FBC6B4"
                        mask="url(#mask-6)"
                    ></path>
                    <path
                        d="M238.9364,94.3777 C238.9364,94.3777 242.8654,103.5857 229.3344,109.8117 C215.8324,116.0257 209.0814,116.8867 209.0814,116.8867 C209.0814,116.8867 202.5924,109.6187 201.7474,94.3817 C201.7474,94.3817 235.2574,80.6537 238.9364,94.3777"
                        id="Fill-153"
                        fill="#EDA503"
                        mask="url(#mask-6)"
                    ></path>
                    <path
                        d="M109.9464,124.2124 C107.2394,124.2124 105.0324,126.4144 105.0324,129.1214 C105.0324,131.8284 107.2394,134.0274 109.9464,134.0274 C112.6534,134.0274 114.8554,131.8284 114.8554,129.1214 C114.8554,126.4144 112.6534,124.2124 109.9464,124.2124 M109.9464,137.9854 C105.0534,137.9854 101.0794,134.0064 101.0794,129.1214 C101.0794,124.2364 105.0534,120.2624 109.9464,120.2624 C114.8354,120.2624 118.8094,124.2364 118.8094,129.1214 C118.8094,134.0064 114.8354,137.9854 109.9464,137.9854"
                        id="Fill-154"
                        fill="#64B4D7"
                        mask="url(#mask-6)"
                    ></path>
                    <polygon
                        id="Fill-155"
                        fill="#27355C"
                        mask="url(#mask-6)"
                        points="55.273 281.028 91.633 281.028 91.633 278.218 55.273 278.218"
                    ></polygon>
                    <polygon
                        id="Fill-156"
                        fill="#27355C"
                        mask="url(#mask-6)"
                        points="69.468 271.845 89.512 271.845 89.512 269.039 69.468 269.039"
                    ></polygon>
                </g>
            </g>
        </g>
    </svg>
)

export default MaintenanceIcon
