import React, { useEffect, useState } from 'react'
import * as Constants from '../../../../types/Constants'
import {
    CreditCardActiveIcon,
    CreditCardInActiveIcon,
    CheckActiveIcon,
    CheckInActiveIcon,
    AppleGooglePayInactiveIcon,
    AppleGooglePayActiveIcon,
} from '../../../../icons/PaymentIcons'
import './PaymentMethodButton.scss'

interface Props {
    paymentMethodType: Constants.InvoicePaymentMethodsType
    isActive: boolean
    onNewPaymentMethodSelect: (paymentMethodType: Constants.InvoicePaymentMethodsType) => void
}

const PaymentMethodButton = ({ paymentMethodType, isActive, onNewPaymentMethodSelect }: Props) => {
    const [paymentMethod, setPaymentMethod] = useState<{
        title: string
        activeIcon: React.ReactNode
        inactiveIcon: React.ReactNode
    }>({
        title: '',
        activeIcon: null,
        inactiveIcon: null,
    })

    useEffect(() => {
        switch (paymentMethodType) {
            case Constants.InvoicePaymentMethods.CARD:
                setPaymentMethod({
                    title: 'Credit/Debit',
                    activeIcon: <CreditCardActiveIcon className={'section-icon'} />,
                    inactiveIcon: <CreditCardInActiveIcon className={'section-icon'} />,
                })
                break
            case Constants.InvoicePaymentMethods.OTP:
                setPaymentMethod({
                    title: '',
                    activeIcon: <AppleGooglePayActiveIcon className={'section-icon apple-google-icon'} />,
                    inactiveIcon: <AppleGooglePayInactiveIcon className={'section-icon apple-google-icon'} />,
                })
                break
            case Constants.InvoicePaymentMethods.ACH:
            default:
                setPaymentMethod({
                    title: 'Bank Transfer',
                    activeIcon: <CheckActiveIcon className={'section-icon'} />,
                    inactiveIcon: <CheckInActiveIcon className={'section-icon'} />,
                })
                break
        }
    }, [paymentMethodType, isActive, onNewPaymentMethodSelect])

    return (
        <div
            className={`payment-method-button ${isActive ? 'active' : ''}`}
            onClick={() => {
                onNewPaymentMethodSelect(paymentMethodType)
            }}
        >
            {isActive ? paymentMethod.activeIcon : paymentMethod.inactiveIcon}
            <p className="section-title active">{paymentMethod.title}</p>
        </div>
    )
}

export default PaymentMethodButton
