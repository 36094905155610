import React, { useEffect } from 'react'
import { RootState } from '../reducers/app/RootState'
import { useSelector, useDispatch } from 'react-redux'
import { SFAlert } from '@simplifeye/component-library'

import { loadOverviewData } from '../reducers/overview/overview.action'
import PayerTransactionTable from './payer-transactions-table/PayerTransactionsTable'
import UnpaidInvoiceSection from './unpaid-invoice-section/UnpaidInvoiceSection'
import ScheduledPaymentSection from './scheduled-payment-section/ScheduledPaymentSection'
import FailedPaymentSection from './failed-payment-section/FailedPaymentSection'
import PlatformMaintenanceAlert from '../platform-maintenance/platform-maintenance-alert/PlatformMaintenanceAlert'
import LoadingPage from '../shared/components/loading-page/LoadingPage'

import './OverviewSection.scss'

const OverviewSection = () => {
    const { loading, error, payerTransactions, nextScheduledPayments } = useSelector(
        (state: RootState) => state.overview
    )
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadOverviewData())
    }, [dispatch])

    if (loading) {
        return (
            <div className="overview-section">
                <LoadingPage />
            </div>
        )
    } else if (error.showError) {
        return (
            <div className="overview-section">
                <div className="alert-wrapper">
                    <SFAlert severity="error" title={error.title} message={error.message} showAlert={true} />
                </div>
            </div>
        )
    } else {
        return (
            <div className="overview-section">
                <div className="overview-section-inner">
                    <PlatformMaintenanceAlert className="maintenance-alert" />
                    <FailedPaymentSection />
                    <UnpaidInvoiceSection />
                    {nextScheduledPayments && nextScheduledPayments.length > 0 ? (
                        <ScheduledPaymentSection nextScheduledPayments={nextScheduledPayments} />
                    ) : null}
                    <PayerTransactionTable payerTransactions={payerTransactions} loading={loading} />
                </div>
            </div>
        )
    }
}

export default OverviewSection
