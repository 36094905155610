import { useEffect, useRef } from 'react';

export const useCallbackInterval = (callback: () => unknown, delay = 60000) => {
    const storedCallback = useRef<() => unknown>(() => null);

    // store callback in ref
    useEffect(() => {
        storedCallback.current = callback;
    }, [callback]);

    // interval effect
    useEffect(() => {  
        const interval = setInterval(storedCallback.current, delay);

        return () =>{
            if(interval) clearInterval(interval);
        };
    }, [delay]);
}