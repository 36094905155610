import React from 'react'
import { TransactionTable } from '@simplifeye/component-library'
import { TransactionsPage } from '../../types/TransactionsPage'
import PaymentTransaction from '../../types/PaymentTransaction'
import { startCase } from 'lodash'
import { useHistory } from 'react-router'
import classNames from 'classnames'

import { formateDateObject, formatCurrencyCents } from '../../shared/utils'
import { useDispatch } from 'react-redux'
import { getPatientTransactions } from '../../reducers/overview/overview.action'
import { TransactionTableDefaults } from '../../types/Constants/TransactionTableDefaults'

import './PayerTransactionTable.scss'

interface Props {
    payerTransactions: TransactionsPage
    loading: boolean
}

const PayerTransactionTable = ({ payerTransactions, loading }: Props) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const getWarningClass = (status: string) =>
        status === 'Card Expiring' || status === 'Lack of Funds' ? ['warning'] : []

    const rows = payerTransactions
        ? payerTransactions.transactions.map((t: PaymentTransaction) => {
              return {
                  classes: getWarningClass(t.status),
                  data: {
                      id: (
                          <div
                              className="transaction-id"
                              onClick={() => history.push(`/invoice-history/${t.transactionId}`)}
                          >
                              {t.transactionId}
                          </div>
                      ),
                      status: startCase(t.status),
                      type: startCase(t.type),
                      transactionDate: formateDateObject(new Date(t.transactionDate)),
                      amount: formatCurrencyCents(t.amount),
                  },
              }
          })
        : []

    const onChangeTransactionPage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        if (event) {
            dispatch(
                getPatientTransactions(
                    newPage,
                    TransactionTableDefaults.NUMBER_OF_RECORDS,
                    TransactionTableDefaults.STATUS
                )
            )
        }
    }

    return (
        <section
            className={classNames('payer-transaction-table', {
                'payer-transaction-table-hide-pagination': payerTransactions.pagination.totalPages <= 1,
            })}
        >
            <h3>Your Recent Activity</h3>
            <TransactionTable
                headers={['Transaction #', 'Status', 'Type', 'Date Paid', 'Amount']}
                rows={rows}
                loading={loading}
                paginator={{
                    totalRows: payerTransactions.pagination.totalRecords,
                    currentPage: payerTransactions.pagination.pageNumber,
                    rowsPerPage: payerTransactions.pagination.pageSize,
                    totalPages: payerTransactions.pagination.totalPages,
                    onChangePage: onChangeTransactionPage,
                }}
            />
        </section>
    )
}

export default PayerTransactionTable
