import { createMuiTheme } from '@material-ui/core/styles'

const portalBlue = '#347cff'

export const calendarTheme = createMuiTheme({
    overrides: {
        MuiInputAdornment: {
            positionEnd: {
                marginLeft: '0',
            },
        },
        MuiIconButton: {
            root: {
                color: '#303030',
                marginRight: '1px',
                padding: '7px',
            },
        },
        MuiInputBase: {
            root: {
                backgroundColor: '#fff',
                border: '1px #cfdae9 solid',
                borderRadius: '6px',
                color: '#303030',
                marginRight: '20px',
                width: '155px',
                '&$focused': {
                    borderColor: portalBlue,
                },
            },
            input: {
                padding: '10.5px 11px',
            },
        },
        MuiInputLabel: {
            outlined: {
                color: '#737373 !important',
                fontSize: '12px !important',
                marginBottom: '6px',
                // position: 'static !important',
                transform: 'none !important',
            },
        },
        MuiOutlinedInput: {
            adornedEnd: {
                paddingRight: '0',
            },
            input: {
                padding: '10.5px 11px',
            },
            notchedOutline: {
                border: '0',
                display: 'none',
            },
        },
        // MuiPickersToolbar: {
        //     toolbar: {
        //         backgroundColor: portalBlue,
        //     },
        // },
        // MuiPickersDay: {
        //     isSelected: {
        //         backgroundColor: portalBlue,
        //     },
        //     current: {
        //         color: portalBlue,
        //     },
        // },
        // MuiPickersModal: {
        //     dialogAction: {
        //         color: portalBlue,
        //     },
        // },
    },
})
