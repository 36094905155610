import React, { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react'
import {
    convertStringDollarsToCents,
    handleCurrencyInputChange,
    handleFormatCurrencyInputBlur,
} from '../../../shared/utils'
import './CurrencyInput.scss'

interface Props {
    className?: string
    max?: number
    onValueChange: (value: number) => void
    value: number
    disabled?: boolean
}

const VALID_FIRST = /^[1-9]{1}$/
const VALID_NEXT = /^[0-9]{1}$/
const DELETE_KEY_CODE = 8

const CurrencyInput = ({ className = '', max = Number.MAX_SAFE_INTEGER, onValueChange, value, disabled }: Props) => {
    const [inputValue, setInputValue] = useState('$')
    const [hasChanged, setHasChanged] = useState(false)
    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>): void => {
            const { key, keyCode } = e
            if (
                (value === 0 && !VALID_FIRST.test(key)) ||
                (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
            ) {
                return
            }
            const valueString = value.toString()
            let nextValue: number
            if (keyCode !== DELETE_KEY_CODE) {
                const nextValueString: string = value === 0 ? key : `${valueString}${key}`
                nextValue = Number.parseInt(nextValueString, 10)
            } else {
                const nextValueString = valueString.slice(0, -1)
                nextValue = nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10)
            }
            if (nextValue > max) {
                return
            }
        },
        [max, onValueChange, value]
    )

    // const valueDisplay = (value / 100).toLocaleString('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    // })

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            // DUMMY TO AVOID REACT WARNING
            const newAmount = handleCurrencyInputChange(e.target.value)
            setInputValue(`$${newAmount}`)
            setHasChanged(true)
            onValueChange(convertStringDollarsToCents(newAmount))
        },
        [inputValue, setInputValue, hasChanged, setHasChanged, onValueChange]
    )

    const handleBlur = useCallback(
        (event: any) => {
            if (!hasChanged) {
                return
            }

            let inputAmount = event.target.value
            inputAmount = inputAmount.indexOf('$') > -1 ? inputAmount.split('$')[1] : inputAmount

            // if (
            //     [EhrIntegrationTypesEnum.OPENDENTAL].indexOf(
            //         this.props.integrationType as EhrIntegrationTypesEnum,
            //     ) > -1 &&
            //     isExistingTreatment &&
            //     convertStringDollarsToCents(lineItem.ehrTreatmentData.cost) <
            //         convertStringDollarsToCents(inputAmount)
            // ) {
            //     inputAmount = lineItem.ehrTreatmentData.cost
            //     isFormattingOnly = false
            // }
            const newAmount = handleFormatCurrencyInputBlur(inputAmount)
            setInputValue(`$${newAmount}`)
            setHasChanged(true)
        },
        [inputValue, setInputValue, hasChanged, setHasChanged, onValueChange]
    )
    return (
        <input
            className={`currency-input ${className}`}
            inputMode="numeric"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            value={inputValue}
            disabled={disabled ? disabled : false}
        />
    )
}

export default CurrencyInput
