import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SFDialog, SFSuccessDialogContent } from '@simplifeye/component-library'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import MomentUtils from '@date-io/moment'
import { Button, Grid, Icon, MuiThemeProvider } from '@material-ui/core'

import { calendarTheme } from '../../shared/components/material-ui-themes/materialUIIThemes'
import { PayerInfo } from '../../types/PayerInfo'
import InputBox from '../../ui/Input'
import {
    convertNumCentsToDollars,
    dateToISO,
    formatDate,
    formateDateObject,
    momentFormat,
    validateEmail,
} from '../../shared/utils'
import { RequireAtLeastOne } from '../../types/RequireAtLeastOneProperty'

import './ExternalInvoicePaymentSuccess.scss'
import { sendExternalInvoiceEmailReceipt } from '../../reducers/external-invoice/externalInvoice.action'
import { RootState } from '../../reducers/app/RootState'

interface Props {
    firstName: string
    lastName: string
    invoiceId: string
    amount: number
}

type AppDispatch = ThunkDispatch<any, any, AnyAction>

const ExternalInvoicePaymentSuccess = ({ firstName, lastName, invoiceId, amount }: Props) => {
    const dispatch: AppDispatch = useDispatch()
    const inputCallback = useCallback(inputElem => {
        if (inputElem) {
            inputElem.setFocus()
        }
    }, [])

    const [showEmailDialog, setShowEmailDialog] = useState(false)
    const [emailSuccess, setEmailSuccess] = useState(false)
    const [email, setEmail] = useState('')
    const [errorText, setErrorText] = useState('')
    const [emailErrorText, setEmailErrorText] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    const { chargeEventId } = useSelector((state: RootState) => ({
        chargeEventId: state.stripeManualCheckoutDialog.chargeEventId,
    }))

    useEffect(() => {
        // reset on close of modal
        if (!showEmailDialog) {
            setTimeout(() => {
                setErrorText('')
                setEmail('')
                setEmailSuccess(false)
            }, 500)
        } else {
            // inputRef.current.focus()
        }
    }, [showEmailDialog])

    const validateEmailEI = emailToValidate => {
        if (emailToValidate === '') {
            setEmailErrorText('Email is required.')
        } else if (!validateEmail(emailToValidate)) {
            setEmailErrorText('Email is invalid.')
        } else {
            setEmailErrorText('')
        }
    }

    const closeSendEmailDialog = () => {
        setShowEmailDialog(false)
    }

    const enterEmailContent = {
        content: (
            <div className="ei-send-receipt-wrapper">
                {!!errorText && (
                    <div className="ei-send-receipt-error">
                        <Icon>warning</Icon>
                        <p>{errorText}</p>
                    </div>
                )}
                <p className="ei-send-receipt-text">
                    Please provide your email address and we'll send a receipt for your transaction.
                </p>
                <InputBox
                    ref={inputCallback}
                    labelText="Email Address"
                    value={email}
                    onChange={e => {
                        const newEmail = e.target.value
                        setEmail(newEmail)
                        if (!!emailErrorText) {
                            validateEmailEI(newEmail)
                        }
                    }}
                    onBlur={() => validateEmailEI(email)}
                    errorText={emailErrorText}
                    showError={!!emailErrorText}
                    wrapperClass="ei-receipt-input"
                />
            </div>
        ),
        footer: {
            footerButtons: {
                twoButtons: {
                    leftButton: {
                        text: 'Cancel',
                        action: closeSendEmailDialog,
                        // classes: string[],
                        // hideButton: boolean,
                    },
                    rightButton: {
                        text: 'Submit',
                        action: async () => {
                            setErrorText('')
                            setIsSubmitting(true)

                            try {
                                await dispatch(sendExternalInvoiceEmailReceipt(chargeEventId, email))
                                setEmailSuccess(true)
                            } catch (err) {
                                setErrorText(
                                    'Error sending receipt. Please contact your practice if you require a receipt for your records.'
                                )
                            }

                            setIsSubmitting(false)
                        },
                        disabled: !validateEmail(email) || isSubmitting,
                        // classes: string[],
                        // hideButton: boolean,
                    },
                },
            },
        },
    }

    const emailSuccessContent = {
        content: (
            <div className="ei-send-receipt-wrapper">
                <SFSuccessDialogContent
                    title="Receipt sent!"
                    message={`A copy of your receipt has been sent to ${email}.`}
                />
            </div>
        ),
        footer: {
            footerButtons: {
                centerButton: {
                    text: 'Close',
                    action: closeSendEmailDialog,
                    // classes: string[],
                    // hideButton: boolean,
                },
            },
        },
    }

    return (
        <div className="external-invoice-payment-success">
            <div className="success">
                <Icon>check_circle</Icon>
                <div>Your payment was successful!</div>
            </div>
            <Grid container={true} className="external-invoice-payment-wrapper">
                <Grid item={true} xs={12}>
                    <div className="ei-payment-item">
                        <span>Patient Name</span>
                        <span>{`${firstName} ${lastName}`}</span>
                    </div>
                </Grid>
                <Grid item={true} xs={12}>
                    <div className="ei-payment-item">
                        <span>Payment Date</span>
                        <span>{formateDateObject(new Date(), 'MM/DD/YYYY')}</span>
                    </div>
                </Grid>
                <Grid item={true} xs={12}>
                    <div className="ei-payment-item ei-payment-item--print-only">
                        <span>Invoice ID</span>
                        <span>{invoiceId}</span>
                    </div>
                </Grid>
                <Grid item={true} xs={12}>
                    <div className="ei-payment-item">
                        <span>Payment Amount</span>
                        <span>{convertNumCentsToDollars(amount, true)}</span>
                    </div>
                </Grid>
                <Grid item={true} xs={12} className="ei-payment-buttons">
                    <Button onClick={() => window.print()}>
                        <span className="ei-payment-print-btn">
                            Print Receipt <Icon>print</Icon>
                        </span>
                    </Button>
                    <Button onClick={() => setShowEmailDialog(true)}>Email Receipt</Button>
                </Grid>
            </Grid>
            <SFDialog
                open={showEmailDialog}
                title="Email Receipt"
                onClose={closeSendEmailDialog}
                content={!emailSuccess ? enterEmailContent.content : emailSuccessContent.content}
                footer={!emailSuccess ? enterEmailContent.footer : emailSuccessContent.footer}
                styleClass="external-invoice-receipt-dialog"
            />
        </div>
    )
}

export default ExternalInvoicePaymentSuccess
