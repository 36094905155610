import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { StripeTestPractice } from '../../../types/Constants/StripeTestPractice'

declare var Plaid: any

interface Props {
    plaidLinkToken: string
    practiceId: string
    onPlaidLinkSuccess: (payload: {
        publicToken: string
        accountId: string
        plaidBankName: string
        accountLast4: string
    }) => void
    onPlaidLinkError: (payload: { title: string; message: string; messageJson: string }) => void
    cancelPlaidFlow: () => void
}

const PlaidLink = ({ plaidLinkToken, practiceId, onPlaidLinkSuccess, onPlaidLinkError, cancelPlaidFlow }: Props) => {
    const dispatch = useDispatch()

    return <div></div>

    useEffect(() => {
        let env: string
        if (practiceId === StripeTestPractice.stripeTestPractice) {
            env = process.env.REACT_APP_PLAID_TEST_TEST_TEST_ENV
        } else {
            env = process.env.REACT_APP_PLAID_ENV ? process.env.REACT_APP_PLAID_ENV : ''
        }

        const configs = {
            clientName: 'Stripe/Plaid Test',
            env: env,
            product: ['auth'],
            token: plaidLinkToken,

            // metadata comes from plaid and has a lot more props but we only care about account_id, name, and mask
            onSuccess: async function(
                public_token,
                metadata: {
                    account: {
                        id: string
                        name: string
                        mask: string
                    }
                }
            ) {
                linkHandler.destroy()

                // must use timeout to handle the body: overflow bug
                setTimeout(() => {
                    dispatch(
                        onPlaidLinkSuccess({
                            publicToken: public_token,
                            accountId: metadata.account.id,
                            plaidBankName: metadata.account.name,
                            accountLast4: metadata.account.mask,
                        })
                    )
                }, 400)
            },
            onExit: async function(err, metadata) {
                if (err) {
                    setTimeout(() => {
                        dispatch(
                            onPlaidLinkError({
                                title: 'Bank Transfer Error',
                                message: 'Request with Plaid returned an error',
                                messageJson: err,
                            })
                        )
                    }, 400)
                } else {
                    dispatch(cancelPlaidFlow())
                }
                linkHandler.destroy()
            },
        }
        const linkHandler = Plaid.create(configs)

        // must use timeout to handle the body: overflow bug
        setTimeout(() => {
            linkHandler.open()
        }, 400)
    }, [plaidLinkToken, practiceId, onPlaidLinkSuccess, onPlaidLinkError, cancelPlaidFlow, dispatch])

    return <div></div>
}

export default PlaidLink
