import React from 'react'
import { formatCurrencyCents } from '../../shared/utils'
import './ExternalInvoiceBillingInfo.scss'

interface Props {
    isZeroOrMixedAccount: boolean
    billingTotals: {
        subtotal: number
        total: number
    }
}

const ExternalInvoiceBillingInfo = ({ billingTotals: { subtotal, total, ...all }, isZeroOrMixedAccount }: Props) => {
    return (
        <div className="external-invoice-billing-info">
            <div className="line-item">
                <label htmlFor="subtotal">{isZeroOrMixedAccount ? 'Regular Price (Credit)' : 'Subtotal'}</label>
                <div id="subtotal">
                    {isZeroOrMixedAccount ? formatCurrencyCents(total) : formatCurrencyCents(subtotal)}
                </div>
            </div>
            <div className="total line-item">
                <label htmlFor="total">Payment Due</label>
                <div id="total">{formatCurrencyCents(total)}</div>
            </div>
        </div>
    )
}

export default ExternalInvoiceBillingInfo
