import { types } from './invoiceDetails.action'
import { InvoiceDetailsState } from './invoiceDetails.state'
import { initialState } from './invoiceDetails.initial'
import { InvoiceDetailsResponse } from '../../types/Response/InvoiceDetailsResponse'
import { BillingTotals } from '../../types/BillingTotals'
import { InvoicePaymentMethods } from '../../types/Constants'

const invoiceDetailsReducer = (state: InvoiceDetailsState = initialState, action: any): InvoiceDetailsState => {
    switch (action.type) {
        case types.REQUEST_CREATE_PAYMENT_INTENT: {
            return {
                ...state,
            }
        }
        case types.REQUEST_CREATE_PAYMENT_INTENT_SUCCESS: {
            return {
                ...state,
                stripeData: {
                    clientSecret: action.payload.clientSecret,
                    paymentIntent: action.payload.paymentIntentId,
                },
                dialogLoading: false,
            }
        }
        case types.REQUEST_CREATE_PAYMENT_INTENT_ERROR: {
            return {
                ...state,
                dialogLoading: false,
                stripePaymentError: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                    messageJson: action.payload.messageJson,
                },
            }
        }
        case types.UPDATE_SELECTED_PAYMENT_METHOD: {
            return {
                ...state,
                selectedPaymentMethod: action.paymentMethod,
                billingTotals: mapSelectedPaymentMethodToBillingTotal(
                    state.billingTotals,
                    state.total,
                    state.applicationFees,
                    action.paymentMethod
                ),
                canSubmitPayInvoice: true,
            }
        }
        case types.ON_CALCULATE_FEES_SUCCESS: {
            return {
                ...state,
                total: {
                    ...state.total,
                    ...action.payload.total,
                },
                applicationFees: {
                    ...state.applicationFees,
                    ...action.payload.applicationFees,
                },
            }
        }
        case types.REQUEST_PATIENT_INVOICE_DETAILS: {
            return { ...state }
        }
        case types.REQUEST_PATIENT_INVOICE_DETAILS_SUCCESS:
            return {
                ...state,
                invoiceDetailsResponse: action.payload.invoiceDetailsResponse,
                chargeEventId: action.payload.chargeEventId,
            }
        case types.REQUEST_PATIENT_INVOICE_DETAILS_ERROR: {
            return {
                ...state,
                loading: false,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case types.REQUEST_PRACTICE_ADDRESS: {
            return { ...state }
        }
        case types.REQUEST_PRACTICE_ADDRESS_ERROR: {
            return {
                ...state,
                loading: false,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case types.REQUEST_GET_FEES_FOR_INVOICE: {
            return { ...state, loading: true }
        }
        case types.REQUEST_GET_FEES_FOR_INVOICE_SUCCESS: {
            return {
                ...state,
                creditFee: action.payload.creditFees,
                debitFee: action.payload.debitFees,
                // prepaidFee: action.payload.prepaidFees, // these values are never used, so scrapped adding prepaid
                achFee: action.payload.achFees,
            }
        }
        case types.REQUEST_GET_FEES_FOR_INVOICE_ERROR: {
            return {
                ...state,
                loading: false,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case types.REQUEST_DATA_FOR_INVOICE: {
            return { ...state, loading: true }
        }
        case types.REQUEST_DATA_FOR_INVOICE_ERROR: {
            return {
                ...state,
                loading: false,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case types.SET_UP_BILLING_TOTALS_FOR_INVOICE: {
            return {
                ...state,
                billingTotals: mapInvoiceToBillingTotal(state.invoiceDetailsResponse),
                loading: false,
                error: {
                    showError: false,
                    title: '',
                    message: '',
                },
            }
        }
        case types.REQUEST_UPDATE_CHARGE_EVENT_SELECTED_PAYMENT: {
            return { ...state }
        }
        case types.UPDATE_CHARGE_EVENT_SELECTED_PAYMENT_SUCCESS: {
            return { ...state }
        }
        case types.UPDATE_CHARGE_EVENT_SELECTED_PAYMENT_ERROR: {
            return {
                ...state,
                dialogLoading: false,
                stripePaymentError: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case types.OPEN_INVOICE_DIALOG: {
            return { ...state, showInvoiceDialog: true, dialogLoading: true }
        }
        case types.STRIPE_CARD_INVOICE_PAYMENT_SUCCESS: {
            return { ...state, dialogLoading: false, stripePaymentSuccess: true }
        }
        case types.STRIPE_CARD_INVOICE_PAYMENT_FAILURE: {
            return {
                ...state,
                dialogLoading: false,
                stripePaymentSuccess: false,
                stripePaymentError: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                    messageJson: action.payload.messageJson,
                },
            }
        }
        case types.ON_EXISTING_PAYMENT_METHOD_SELECT: {
            return {
                ...state,
                canSubmitPayInvoice: action.payload.isExistingPayment,
                selectedPaymentMethod: action.payload.paymentMethod,
                isAddingNewPaymentMethod: !action.payload.paymentMethod,
                selectedSavedPaymentMethod: action.payload.savedPaymentMethod,
                isUsingSavedAchMethod: false,
                billingTotals: action.payload.paymentMethod
                    ? mapSelectedPaymentMethodToBillingTotal(
                          state.billingTotals,
                          state.total,
                          state.applicationFees,
                          action.payload.paymentMethod
                      )
                    : state.billingTotals,
            }
        }
        case types.ON_USE_EXISTING_PAYMENT_ACH_SELECT: {
            return {
                ...state,
                isUsingSavedAchMethod: true,
                canSubmitPayInvoice: true,
                selectedPaymentMethod: InvoicePaymentMethods.ACH,
                selectedSavedPaymentMethod: null,
                isAddingNewPaymentMethod: false,
                billingTotals: mapSelectedPaymentMethodToBillingTotal(
                    state.billingTotals,
                    state.total,
                    state.applicationFees,
                    InvoicePaymentMethods.ACH
                ),
            }
        }
        case types.REQUEST_SUBMIT_INVOICE_USE_EXISTING_METHOD: {
            return { ...state, dialogLoading: true }
        }
        case types.SUBMIT_INVOICE_USE_EXISTING_METHOD_SUCCESS: {
            return { ...state, dialogLoading: false, stripePaymentError: null, stripePaymentSuccess: true }
        }
        case types.SUBMIT_INVOICE_USE_EXISTING_METHOD_ERROR: {
            return {
                ...state,
                dialogLoading: false,
                stripePaymentError: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                    messageJson: action.payload.messageJson,
                },
                canSubmitPayInvoice: false,
                selectedPaymentMethod: null,
            }
        }
        case types.ON_PAY_INVOICE_USE_EXISTING_CANCEL: {
            return { ...state, showInvoiceDialog: false }
        }
        case types.ON_INVOICE_DIALOG_ERROR_CANCEL: {
            return {
                ...state,
                showInvoiceDialog: false,
                dialogLoading: true,
                stripePaymentError: {
                    showError: false,
                    title: '',
                    message: '',
                    messageJson: '',
                },
            }
        }
        case types.ON_INVOICE_DIALOG_SUCCESS_BACK_TO_HOME: {
            return { ...initialState }
        }
        case types.ON_PAY_INVOICE_USE_EXISTING_PAYMENT_METHOD: {
            return { ...state, showInvoiceDialog: true, dialogLoading: false }
        }
        case types.STRIPE_CARD_INVOICE_PAYMENT_CANCEL: {
            return {
                ...state,
                showInvoiceDialog: false,
                stripePaymentError: {
                    showError: false,
                    title: '',
                    message: '',
                    messageJson: '',
                },
            }
        }
        case types.CREATE_PLAID_TOKEN_SUCCESS:
            return {
                ...state,
                plaid: {
                    plaidLinkToken: action.payload.linkToken,
                    showPlaid: true,
                    publicToken: '',
                },
                achDialog: {
                    ...state.achDialog,
                    show: false,
                },
            }
        case types.CHECK_USER_USE_EXISTING_ACH:
            return {
                ...state,
                achDialog: {
                    show: true,
                    loading: false,
                    success: false,
                    error: {
                        showError: false,
                        title: '',
                        message: '',
                        messageJson: '',
                    },
                    showUseExisting: true,
                },
            }
        case types.PLAID_FLOW_ERROR:
            return {
                ...state,
                achDialog: {
                    show: true,
                    loading: false,
                    success: false,
                    error: {
                        showError: true,
                        title: action.payload.title,
                        message: action.payload.message,
                        messageJson: action.payload.messageJson,
                    },
                    showUseExisting: false,
                },
            }
        case types.CANCEL_PLAID_FLOW:
            return {
                ...state,
                plaid: {
                    plaidLinkToken: '',
                    showPlaid: false,
                    publicToken: '',
                },
                achDialog: {
                    show: false,
                    loading: false,
                    success: false,
                    error: {
                        showError: false,
                        title: '',
                        message: '',
                        messageJson: '',
                    },
                    showUseExisting: false,
                },
            }

        case types.REQUEST_APPLY_SELECTED_ACH_TO_INVOICE:
            return {
                ...state,
                achDialog: {
                    show: true,
                    loading: true,
                    success: false,
                    error: {
                        showError: false,
                        title: '',
                        message: '',
                        messageJson: '',
                    },
                    showUseExisting: false,
                },
            }
        case types.APPLY_SELECTED_ACH_TO_INVOICE_SUCCESS:
            return {
                ...state,
                achDialog: {
                    show: true,
                    loading: false,
                    success: true,
                    error: {
                        showError: false,
                        title: '',
                        message: '',
                        messageJson: '',
                    },
                    showUseExisting: false,
                },
            }
        case types.ON_ACH_BACK_TO_HOME:
            return { ...initialState }
        default: {
            return { ...state }
        }
    }
}

function mapInvoiceToBillingTotal(invoiceResponse: InvoiceDetailsResponse): BillingTotals {
    return {
        subtotal: invoiceResponse.subtotal,
        taxSubtotal: invoiceResponse.taxAmount,
        convenienceFeeSubtotal: invoiceResponse.applicationFee,
        paymentDue: invoiceResponse.amount,
    }
}

const mapSelectedPaymentMethodToBillingTotal = (
    billingTotal: BillingTotals,
    total: any,
    applicationFees: any,
    selectedPaymentMethod: string
): BillingTotals => {
    if (
        selectedPaymentMethod === InvoicePaymentMethods.CARD ||
        selectedPaymentMethod === InvoicePaymentMethods.CREDIT ||
        selectedPaymentMethod === InvoicePaymentMethods.OTP
    ) {
        if (total.credit) {
            return {
                ...billingTotal,
                paymentDue: total.credit,
                convenienceFeeSubtotal: applicationFees?.credit || 0,
            }
        }
    }

    if (selectedPaymentMethod === InvoicePaymentMethods.DEBIT) {
        if (total.debit) {
            return {
                ...billingTotal,
                paymentDue: total.debit,
                convenienceFeeSubtotal: applicationFees?.debit || 0,
            }
        }
    }

    if (selectedPaymentMethod === InvoicePaymentMethods.PREPAID) {
        if (total.prepaid) {
            return {
                ...billingTotal,
                paymentDue: total.prepaid,
                convenienceFeeSubtotal: applicationFees?.prepaid || 0,
            }
        }
    }

    if (selectedPaymentMethod === InvoicePaymentMethods.ACH && total.ach) {
        return {
            ...billingTotal,
            paymentDue: total.ach,
            convenienceFeeSubtotal: applicationFees?.ach || 0,
        }
    }

    return billingTotal
}

export default invoiceDetailsReducer
