import React from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import thunkMiddleware from 'redux-thunk'
import { applyMiddleware, createStore, combineReducers } from 'redux'
import { Provider } from 'react-redux'

import Api from './modules/api/Api'
import SessionManager from './SessionManager'

import { composeWithDevTools } from 'redux-devtools-extension'
import {
    logUserInFromSession,
    LOG_USER_OUT_FROM_SESSION_SUCCESS,
    selectPracticeFromSession,
    setExternalInvoiceInfoFromSession,
    setLogoUrl,
} from './modules/reducers/session/session.actions'
import PaymentMethodsSection from './modules/payment-methods/PaymentMethodsSection'
import PaymentPlanSection from './modules/payment-plans/PaymentPlansSection'
import OverviewSection from './modules/overview/OverviewSection'
import SelectPractice from './modules/select-practice/SelectPractice'
import paymentMethodReducer from './modules/reducers/payment-methods/paymentMethods.Reducer'
import overviewReducer from './modules/reducers/overview/overview.reducer'
import headerReducer from './modules/reducers/header/header.reducer'
import invoiceDetailsReducer from './modules/reducers/invoice-details/invoiceDetails.reducer'
import paymentPlanReducer from './modules/reducers/payment-plan/paymentPlan.reducer'
import { reducer as notificationReducer } from 'react-notification-system-redux'
import addNewMethodReducer from './modules/reducers/add-new-method/addNewMethod.reducer'
import InvoiceDetails from './modules/invoice-details/InvoiceDetails'
import ExternalInvoice from './modules/external-invoice/ExternalInvoice'
import externalInvoiceReducer from './modules/reducers/external-invoice/externalInvoice.reducer'
import Login from './modules/login-new/login/Login'
import loginReducer from './modules/reducers/login/login.reducer'
import PasswordReset from './modules/login-new/password-reset/PasswordReset'
import Create from './modules/login-new/create/Create'
import sessionReducer from './modules/reducers/session/session.reducer'
import ExternalInvoiceLogin from './modules/login-new/external-invoice-login/ExternalInvoiceLogin'
import stripeManualCheckoutReducer from './modules/reducers/stripe-manual-checkout-dialog/stripeManualCheckoutDialog.reducer'
import InvoiceHistory from './modules/invoice-history/InvoiceHistory'
import invoiceHistoryReducer from './modules/reducers/invoice-history/invoiceHistory.reducer'
import { useTracking } from './modules/google-analytics/useTracking'
import AuthorizedRoute from './modules/authorized-route/AuthorizedRoute'
import payDownPlanReducer from './modules/reducers/pay-down-plan-dialog/payDownPlanDialog.reducer'
import updateFailedPaymentDialogReducer from './modules/reducers/update-failed-payment-dialog/update-failed-payment.reducer'
import platformMaintenanceReducer from './modules/reducers/platform-maintenance/platformMaintenance.reducer'
import PlatfromMaintenanceListener from './modules/platform-maintenance/platform-maintenance-listener/PlatfromMaintenanceListener'
import PlatformMaintenance from './modules/platform-maintenance/PlatformMaintenance'
import TextToPayLogin from './modules/login-new/login/text-to-pay-login/TextToPayLogin'
import InvoiceDetailsGuest from './modules/invoice-details-guest/InvoiceDetailsGuest'

import './App.scss'

// TODO try and remove this reducers and store part out of here, seems like the wrong spot soon
const reducers = combineReducers({
    addNewMethod: addNewMethodReducer,
    externalInvoice: externalInvoiceReducer,
    header: headerReducer,
    invoiceDetails: invoiceDetailsReducer,
    invoiceHistory: invoiceHistoryReducer,
    login: loginReducer,
    overview: overviewReducer,
    payDownPlanDialog: payDownPlanReducer,
    paymentMethods: paymentMethodReducer,
    paymentPlan: paymentPlanReducer,
    session: sessionReducer,
    stripeManualCheckoutDialog: stripeManualCheckoutReducer,
    notifications: notificationReducer,
    updateFailedPaymentDialog: updateFailedPaymentDialogReducer,
    platformMaintenance: platformMaintenanceReducer,
})

const rootReducer = (state, action) => {
    if (action.type === LOG_USER_OUT_FROM_SESSION_SUCCESS) {
        state = undefined
    }

    return reducers(state, action)
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)))

if (SessionManager.isLoggedIn) {
    store.dispatch(logUserInFromSession({ portalUserId: SessionManager.portalUser as string }))
}

if (SessionManager.currentPractice) {
    store.dispatch(selectPracticeFromSession({ selectedPractice: SessionManager.currentPractice }))
}

if (SessionManager.isExternalInvoice) {
    store.dispatch(
        setExternalInvoiceInfoFromSession({
            practiceId: SessionManager.sessionPracticeId,
            locationId: SessionManager.sessionLocationId,
            // patientId: SessionManager.sessionPatientId,
            // payerId: SessionManager.sessionPayerId,
        })
    )
}

if (SessionManager.sessionLogoUrl) {
    store.dispatch(
        setLogoUrl({
            logoUrl: SessionManager.sessionLogoUrl,
            logoBackgroundColor: SessionManager.sessionLogoBackgroundColor,
        })
    )
}

Api.dispatch = store.dispatch

export const App = () => {
    useTracking(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING)
    return (
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route path="/login" component={Login} />
            <Route path="/p" component={TextToPayLogin} />
            <Route path="/reset-password/:code" component={PasswordReset} />
            <Route path="/create/:code" component={Create} />
            {/* <Route path="/login/external-invoice:code" component={Login} /> */}
            <Route path="/create-new-account" component={ExternalInvoiceLogin} />
            <Route path="/maintenance" exact component={PlatformMaintenance} />
            <AuthorizedRoute path="/select-practice" exact={true} component={SelectPractice} />
            <AuthorizedRoute path="/overview" exact={true} component={OverviewSection} />
            <AuthorizedRoute path="/invoice/:invoiceId" exact={true} component={InvoiceDetails} />
            <Route path="/invoice-external/:invoiceId" exact={true} component={InvoiceDetailsGuest} />
            <AuthorizedRoute path="/invoice-history/:invoiceId" exact={true} component={InvoiceHistory} />
            <AuthorizedRoute path="/payment-methods" exact={true} component={PaymentMethodsSection} />
            <AuthorizedRoute path="/payment-plans" exact={true} component={PaymentPlanSection} />
            <Route path="/external-invoice" exact={true} component={ExternalInvoice} />
            <Redirect to="/" />
        </Switch>
    )
}

export default () => (
    <Provider store={store}>
        <Router>
            <PlatfromMaintenanceListener />
            <App />
        </Router>
    </Provider>
)
