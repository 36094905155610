import { initialState } from './header.intitial'
import HeaderState from './header.state'
import { GET_PAYER_INFO_SUCCESS, GET_PAYER_INFO_ERROR, CHANGE_ACTIVE_SECTION, HeaderActions } from './header.action'

const headerReducer = (state: HeaderState = initialState, action: HeaderActions): HeaderState => {
    switch (action.type) {
        case GET_PAYER_INFO_SUCCESS:
            return {
                ...state,
                payer: {
                    firstName: action.payload.payers[0] ? action.payload.payers[0].firstName : '',
                    lastName: action.payload.payers[0] ? action.payload.payers[0].lastName : '',
                },
            }
        case GET_PAYER_INFO_ERROR:
            return {
                ...state,
                payer: {
                    firstName: '',
                    lastName: '',
                },
            }
        case CHANGE_ACTIVE_SECTION:
            return {
                ...state,
                activeSection: action.payload.activeSection,
            }
        default:
            return {
                ...state,
            }
    }
}

export default headerReducer
