import React, { useState, useEffect } from 'react'
import './SelectPractice.scss'
import { PracticePayerResponse } from '../types/Response/PracticePayerResponse'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../reducers/app/RootState'
import { useHistory } from 'react-router'
import { SFAlert } from '@simplifeye/component-library'
import { selectPracticeFromMultiplePractices } from '../reducers/login/login.action'

const SelectPractice = () => {
    const { availablePayers, skipSelectPayerScreen } = useSelector((state: RootState) => state.login)
    const [selectedPracticeId, setSelectedPracticeId] = useState<string>('')

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (skipSelectPayerScreen) {
            history.push('/overview')
        }
    }, [dispatch, history, availablePayers, skipSelectPayerScreen])

    if (!availablePayers && availablePayers.length === 0) {
        return (
            <div className="select-practice">
                <SFAlert
                    showAlert={true}
                    severity={'error'}
                    title={'Multiple Practice Error'}
                    message={'Reached multiple practice screen but no practices to select from'}
                />
            </div>
        )
    } else {
        return (
            <div className="select-practice">
                <div className="section-title">
                    <span>Please select a practice</span>
                </div>
                <div className="practice-container">
                    {availablePayers.map((practice: PracticePayerResponse, index: number) => {
                        return (
                            <div
                                className={`practice ${selectedPracticeId === practice.practiceId ? 'active' : ''}`}
                                key={index}
                                onClick={() => {
                                    setSelectedPracticeId(practice.practiceId)
                                    dispatch(selectPracticeFromMultiplePractices({ practicePayer: practice }))
                                }}
                            >
                                {practice.practiceName}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default SelectPractice
