import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Spinner } from '../../../login/Spinner'
import SimplifeyeVyneLogo from '../../../icons/SimplifeyeVyneLogo'
import Input from '../../../ui/Input'
import LockIcon from '@material-ui/icons/Lock'
import {
    goToForgotPassword,
    userLoginAttempt,
    userLoginAttemptExternalInvoice,
} from '../../../reducers/login/login.action'
import { SFAlert } from '@simplifeye/component-library'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { SFError } from '../../../types/SFError'
import { LockIcon as CustomLockIcon } from '../../../icons/LoginIcons'
import PlatformMaintenanceAlert from '../../../platform-maintenance/platform-maintenance-alert/PlatformMaintenanceAlert'

interface Props {
    goToLoginFromAccountCreation: boolean
    error: SFError
    isLoading: boolean
    inExternalInvoice: boolean
    validateEmail: (emailAddress: string) => string
    validatedPassword: (password: string) => string
    logoUrl: string | null
}

const DefaultLogin = ({
    goToLoginFromAccountCreation,
    error,
    isLoading,
    inExternalInvoice,
    validateEmail,
    validatedPassword,
    logoUrl,
}: Props) => {
    const [email, setEmail] = useState<{ emailAddress: string; error: string }>({
        emailAddress: '',
        error: '',
    })

    const [password, setPassword] = useState<{ passwordText: string; error: string }>({
        passwordText: '',
        error: '',
    })

    const dispatch = useDispatch()
    const history = useHistory()

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        const emailValidation = validateEmail(email.emailAddress)
        const passwordValidation = validatedPassword(password.passwordText)
        setEmail({ ...email, error: emailValidation })
        setPassword({ ...password, error: passwordValidation })
        if (emailValidation === '' && passwordValidation === '') {
            if (!inExternalInvoice) {
                dispatch(userLoginAttempt({ emailAddress: email.emailAddress, password: password.passwordText }))
            } else {
                dispatch(
                    userLoginAttemptExternalInvoice({
                        emailAddress: email.emailAddress,
                        password: password.passwordText,
                    })
                )
            }
        }
    }

    return (
        <div className="login bg-blue">
            <div className="login-box">
                <div className="login-box-inner">
                    {!goToLoginFromAccountCreation ? (
                        <div>
                            <div className="logo-or-lock-icon">
                                {logoUrl ? (
                                    <div className="logo">
                                        <img src={logoUrl} alt="the logo of the practice" />
                                    </div>
                                ) : (
                                    <div className="lock-icon">
                                        <CustomLockIcon />
                                    </div>
                                )}
                            </div>
                            <div className="login-title">Log into your account</div>
                            <PlatformMaintenanceAlert />
                            <div className="error-wrapper">
                                <SFAlert
                                    showAlert={error.showError}
                                    severity={'error'}
                                    title={error.title}
                                    message={error.message}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="lock-icon register-account-success-icon">
                                <CheckCircleIcon />
                            </div>
                            <div className="login-title">Welcome</div>
                            <div className="welcome-text">Your account was successfully created!</div>
                            <div className="error-wrapper">
                                <SFAlert
                                    showAlert={error.showError}
                                    severity={'error'}
                                    title={error.title}
                                    message={error.message}
                                />
                            </div>
                        </div>
                    )}

                    <div>
                        <form onSubmit={onSubmit}>
                            <div className="input-container">
                                <Input
                                    autocorrect="off"
                                    autocapitalize="off"
                                    spellcheck="false"
                                    errorText={email.error}
                                    labelText="Enter your email address"
                                    maxLength={320}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setEmail({
                                            ...email,
                                            emailAddress: e.target.value,
                                        })
                                    }}
                                    showError={email.error !== '' ? true : false}
                                    type="text"
                                    value={email.emailAddress}
                                />
                            </div>
                            <div className="input-container">
                                <Input
                                    errorText={password.error}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setPassword({ ...password, passwordText: e.target.value })
                                    }}
                                    type="password"
                                    labelText="Enter your password"
                                    showError={password.error !== '' ? true : false}
                                />
                            </div>
                            <div className="login-button-wrapper">
                                {isLoading ? (
                                    <div className="sign-in-button">
                                        <Spinner />
                                    </div>
                                ) : (
                                    <button className="sign-in-button" type="submit">
                                        <LockIcon />
                                        <div>Log In</div>
                                    </button>
                                )}
                            </div>
                            {inExternalInvoice ? (
                                <div className="external-invoice-create-links">
                                    <div
                                        className="forgot-password-link"
                                        onClick={() => dispatch(goToForgotPassword())}
                                    >
                                        Forgot your password?
                                    </div>
                                    <div
                                        className="forgot-password-link"
                                        onClick={() => history.push('/create-new-account')}
                                    >
                                        Create new account
                                    </div>
                                </div>
                            ) : (
                                <div className="forgot-password-link" onClick={() => dispatch(goToForgotPassword())}>
                                    Forgot your password?
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <div className="powered-by">
                powered by <SimplifeyeVyneLogo />
            </div>
            <div className="version-number">{process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : null}</div>
        </div>
    )
}

export default DefaultLogin
