import React, { useState } from 'react'
import '../LoginShared.scss'
import './ExternalInvoiceLogin.scss'
import SimplifeyeVyneLogo from '../../icons/SimplifeyeVyneLogo'
import { LockIcon as CustomLockIcon } from '../../icons/LoginIcons'
import Input from '../../ui/Input'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Spinner } from '../../login/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { RootState } from '../../reducers/app/RootState'
import { createNewUserForExternalInvoice } from '../../reducers/login/login.action'
import { SFAlert } from '@simplifeye/component-library'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { formatDate } from '../../shared/utils'

const ExternalInvoiceLogin = () => {
    const {
        externalInvoice: { practiceId, locationId, createNewUserSuccess },
        error,
        logoUrl,
    } = useSelector((state: RootState) => state.login)
    const [loading, setLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<{ emailAddress: string; error: string }>({
        emailAddress: '',
        error: '',
    })

    const [firstName, setFirstName] = useState<{ firstName: string; error: string }>({
        firstName: '',
        error: '',
    })

    const [lastName, setLastName] = useState<{ lastName: string; error: string }>({
        lastName: '',
        error: '',
    })

    const [phoneNumber, setPhoneNumber] = useState<{ phoneNumber: string; error: string }>({
        phoneNumber: '',
        error: '',
    })

    const [dob, setDob] = useState<{ dob: string; error: string }>({
        dob: '',
        error: '',
    })

    const dispatch = useDispatch()
    const history = useHistory()

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        setLoading(true)
        const emailValidation = validateEmail(email.emailAddress)
        const firstNameValidation =
            firstName.firstName && firstName.firstName.length > 0 ? '' : 'First name is required'
        const lastNameValidation = lastName.lastName && lastName.lastName.length > 0 ? '' : 'Last name is required'
        const phoneNumberValidation = validatePhone(phoneNumber.phoneNumber)
        const dobValidation = dob.dob && dob.dob.length > 0 ? '' : 'Date of Birth is required'
        if (
            !emailValidation &&
            !firstNameValidation &&
            !lastNameValidation &&
            !phoneNumberValidation &&
            !dobValidation
        ) {
            dispatch(
                createNewUserForExternalInvoice({
                    firstName: firstName.firstName,
                    lastName: lastName.lastName,
                    phoneNumber: phoneNumber.phoneNumber,
                    dob: formatDate(dob.dob),
                    email: email.emailAddress,
                })
            )
        }
        setEmail({ ...email, error: emailValidation })
        setFirstName({ ...firstName, error: firstNameValidation })
        setLastName({ ...lastName, error: lastNameValidation })
        setPhoneNumber({ ...phoneNumber, error: phoneNumberValidation })
        setDob({ ...dob, error: dobValidation })
        setLoading(false)
    }

    const validateEmail = (emailAddress: string): string => {
        const EMAIL_REGEX = /^.+@.+\..+$/
        if (emailAddress === '') {
            return 'Email is required'
        } else if (!EMAIL_REGEX.test(emailAddress)) {
            return 'Email address is in an invalid format'
        } else {
            return ''
        }
    }

    const validatePhone = (phone: string): string => {
        const cleaned = phone.replace(/\D/g, '')
        const phoneNumbersRegex = /^[\d]{10,13}$/ // requires 10 to 13 numbers in the string
        const phoneCharsRegex = /^[()\- \d]{10,23}$/
        if (phone === '') {
            return `Phone Number is required.`
        } else if (!phoneNumbersRegex.test(cleaned)) {
            return `Phone Number is invalid.`
        } else if (!phoneCharsRegex.test(phone)) {
            return `Only (), -, spaces, and numbers are allowed.`
        } else {
            return ''
        }
    }

    if (createNewUserSuccess) {
        return (
            <div className="login password-reset ">
                {logoUrl ? (
                    <div className="logo">
                        <img src={logoUrl} alt="the logo of the practice" />
                    </div>
                ) : null}
                <div className="login-box">
                    <div className="login-box-inner">
                        <div className="lock-icon reset-password-success-icon ">
                            <CheckCircleIcon />
                        </div>
                        <div className="login-title">Account Created!</div>
                        <div className="info-text">
                            You have successfully created a new payer profile. Please check your email to confirm and
                            finish the registraion .
                        </div>
                        <div>
                            <div className="login-button-wrapper">
                                <button
                                    className="reset-password-button"
                                    onClick={() => {
                                        history.push(
                                            `login/external-invoice?practiceId=${practiceId}&locationId=${locationId}`
                                        )
                                    }}
                                >
                                    <ArrowBackIcon />
                                    <div>Back to Login</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="powered-by">
                    powered by <SimplifeyeVyneLogo />
                </div>
            </div>
        )
    } else {
        return (
            <div className="login create-new-account">
                <div className="login-box">
                    <div className="login-box-inner">
                        {logoUrl ? (
                            <div className="logo">
                                <img src={logoUrl} alt="the logo of the practice" />
                            </div>
                        ) : (
                            <div className="lock-icon">
                                <CustomLockIcon />
                            </div>
                        )}
                        <div className="login-title">Create an account</div>
                        <div className="error-wrapper">
                            <SFAlert
                                showAlert={error.showError}
                                severity={'error'}
                                title={error.title}
                                message={error.message}
                            />
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="input-container">
                                <Input
                                    errorText={email.error}
                                    labelText="Enter your email address"
                                    maxLength={320}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setEmail({
                                            ...email,
                                            emailAddress: e.target.value,
                                        })
                                    }}
                                    showError={email.error !== '' ? true : false}
                                    type="text"
                                    value={email.emailAddress}
                                />
                            </div>
                            <div className="input-side">
                                <div className="input-container">
                                    <Input
                                        errorText={firstName.error}
                                        labelText="First Name"
                                        maxLength={320}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFirstName({
                                                ...firstName,
                                                firstName: e.target.value,
                                            })
                                        }}
                                        showError={firstName.error !== '' ? true : false}
                                        type="text"
                                        value={firstName.firstName}
                                    />
                                </div>
                                <div className="input-container">
                                    <Input
                                        errorText={lastName.error}
                                        labelText="Last Name"
                                        maxLength={320}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setLastName({
                                                ...lastName,
                                                lastName: e.target.value,
                                            })
                                        }}
                                        showError={lastName.error !== '' ? true : false}
                                        type="text"
                                        value={lastName.lastName}
                                    />
                                </div>
                            </div>
                            <div className="input-side">
                                <div className="input-container">
                                    <Input
                                        errorText={phoneNumber.error}
                                        labelText="Phone Number"
                                        maxLength={320}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setPhoneNumber({
                                                ...phoneNumber,
                                                phoneNumber: e.target.value,
                                            })
                                        }}
                                        showError={phoneNumber.error !== '' ? true : false}
                                        type="tel"
                                        value={phoneNumber.phoneNumber}
                                    />
                                </div>
                                <div className="input-container">
                                    <Input
                                        type="date"
                                        errorText={dob.error}
                                        labelText="Date of Birth"
                                        maxLength={320}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setDob({
                                                ...dob,
                                                dob: e.target.value,
                                            })
                                        }}
                                        showError={dob.error !== '' ? true : false}
                                        value={dob.dob}
                                    />
                                </div>
                            </div>
                            <div className="button-container">
                                <button
                                    className="back-button"
                                    onClick={() => {
                                        history.push(
                                            `/login/external-invoice?practiceId=${practiceId}&locationId=${locationId}`
                                        )
                                    }}
                                >
                                    <ArrowBackIcon />
                                    <div>Back</div>
                                </button>
                                {loading ? (
                                    <div className="next-button">
                                        <Spinner />
                                    </div>
                                ) : (
                                    <button className="next-button" type="submit">
                                        <div>Next</div>
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="powered-by">
                    powered by <SimplifeyeVyneLogo />
                </div>
            </div>
        )
    }
}

export default ExternalInvoiceLogin
