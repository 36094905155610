import React, { useState } from 'react'
import { StripeTestPractice } from '../../types/Constants/StripeTestPractice'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import AddPaymentForm from './AddPaymentForm/AddPaymentForm'
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/app/RootState'

import '../../invoice-details/stripe-manual-checkout/checkout-form/CheckoutForm.scss'
import './StripeAddPaymentMethod.scss'

interface Props {
    addMethodError: (payload: { title: string; message: string; messageJson: string }) => void
    cancel: () => void
    onStripeAddCardSuccess: (payload: { paymentMethodCardId: string }) => void
}

const StripeAddPaymentMethod = ({ addMethodError, cancel, onStripeAddCardSuccess }: Props) => {
    const getStripeApiKey = (currentPracticeId: string): string => {
        if (currentPracticeId === StripeTestPractice.stripeTestPractice) {
            return process.env.REACT_APP_TEST_TEST_TEST_STRIPE_API_KEY
        } else {
            return process.env.REACT_APP_STRIPE_API_KEY ? process.env.REACT_APP_STRIPE_API_KEY : ''
        }
    }

    const { practiceId } = useSelector((state: RootState) => state.session)
    const [stripePromise] = useState(loadStripe(getStripeApiKey(practiceId)))

    return (
        <div className="stripe-add-payment-method">
            <Elements stripe={stripePromise}>
                <AddPaymentForm
                    onStripeAddCardSuccess={onStripeAddCardSuccess}
                    addMethodError={addMethodError}
                    cancel={cancel}
                />
            </Elements>
        </div>
    )
}

export default StripeAddPaymentMethod
