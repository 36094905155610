import { useMemo } from 'react'
import { StripePaymentRequestButtonElementOptions } from '@stripe/stripe-js'

// types
export type OneTapPayButtonOptions = StripePaymentRequestButtonElementOptions
export type OneTapPayStylingOptions = Omit<StripePaymentRequestButtonElementOptions, 'paymentRequest'>

// default style
const defaultOneTapPayButtonStyle: OneTapPayStylingOptions = {
    style: {
        paymentRequestButton: {
            theme: 'dark',
            type: 'default',
            height: '52px'
        },
    },
}

const useOneTapPayButtonOptions = ({ paymentRequest, ...rest }: OneTapPayButtonOptions): OneTapPayButtonOptions => {
    // memoized options in case same params repeat
    const options: OneTapPayButtonOptions = useMemo(
        () => ({
            paymentRequest,
            ...defaultOneTapPayButtonStyle,
            ...rest,
        }),
        [paymentRequest, rest]
    )

    return options
}

export default useOneTapPayButtonOptions;