import { AddNewMethodState } from './addNewMethod.state'
import { initialState } from './addNewMethod.initial'
import {
    Action,
    SHOW_ADD_NEW_METHOD_DIALOG,
    ADD_NEW_METHOD_SETUP_SUCCESS,
    ADD_NEW_METHOD_SETUP_ERROR,
    CANCEL_ADD_NEW_METHOD,
    ADD_NEW_PAYMENT_METHOD_SUCCESS,
    ADD_NEW_METHOD_SETUP_SUCCESS_DEFAULT_PLAN,
    ON_CREDIT_DEBIT_METHOD_SELECTION_SUCCESS,
    CREATE_PLAID_TOKEN_SUCCESS,
    ON_PLAID_LINK_ERROR,
    GET_PLAID_ACCOUNT_BALANCES_SUCCESS,
    ADD_NEW_METHOD_LOADING,
    GO_TO_PAYMENT_PLAN_SELECTION_SUCCESS,
    REQUEST_PAYMENT_METHOD_STRIPE_INFO,
    REQUEST_PAYMENT_PLANS,
    HIDE_ADD_NEW_METHOD_DIALOG,
} from './addNewMethod.actions'
import { PaymentPlanBasicInfo } from '../../types/PaymentPlanBasicInfo'

const addNewMethodReducer = (state: AddNewMethodState = initialState, action: Action): AddNewMethodState => {
    switch (action.type) {
        case SHOW_ADD_NEW_METHOD_DIALOG:
            return {
                ...state,
                show: true,
                plaid: {
                    ...initialState.plaid,
                },
            }
        case ADD_NEW_METHOD_SETUP_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentPlans: mapPaymentPlanResponseToPaymentPlans(action.payload.paymentPlans),
                isSelectingPaymentMethod: true,
            }
        case CANCEL_ADD_NEW_METHOD:
            return {
                ...initialState,
            }
        case ADD_NEW_METHOD_SETUP_ERROR:
            return {
                ...state,
                loading: false,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                    messageJson: action.payload.messageJson,
                },
            }
        case ADD_NEW_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
            }
        case ADD_NEW_METHOD_SETUP_SUCCESS_DEFAULT_PLAN:
            return {
                ...state,
                loading: false,
                isSelectingPaymentMethod: true,
                paymentPlans: mapPaymentPlanResponseToPaymentPlans(action.payload.paymentPlans),
                defaultSelectedPaymentPlan: action.payload.defaultSelectedPaymentPlan,
            }
        case ON_CREDIT_DEBIT_METHOD_SELECTION_SUCCESS:
            return {
                ...state,
                isSelectingPaymentMethod: false,
                setupIntentId: action.payload.addNewResponse.setupIntentId,
                clientSecret: action.payload.addNewResponse.clientSecret,
                selectedPaymentMethod: action.payload.selectedMethod,
                isAddingNewCard: true,
                loading: false,
            }
        case CREATE_PLAID_TOKEN_SUCCESS:
            return {
                ...state,
                show: false,
                loading: true,
                isSelectingPaymentMethod: false,
                plaid: {
                    linkToken: action.payload.linkToken,
                    show: true,
                    accounts: null,
                    publicToken: '',
                },
            }

        case ON_PLAID_LINK_ERROR:
            return {
                ...state,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                    messageJson: action.payload.messageJson,
                },
            }
        case GET_PLAID_ACCOUNT_BALANCES_SUCCESS:
            return {
                ...state,
                loading: false,
                plaid: {
                    linkToken: '',
                    show: false,
                    accounts: action.payload.accounts,
                    publicToken: action.payload.publicToken,
                },
                isSelectingNewAccount: true,
            }
        case ADD_NEW_METHOD_LOADING:
            return {
                ...state,
                loading: true,
            }
        case GO_TO_PAYMENT_PLAN_SELECTION_SUCCESS:
            return {
                ...state,
                paymentPlansNew: action.payload.paymentPlans,
                isSelectingPaymentPlans: true,
                isAddingAch: action.payload.isAddingAch,
                isSelectingNewAccount: false,
                isAddingNewCard: false,
                paymentMethodCardId: action.payload.paymentMethodCardId,
                paymentMethodAchId: action.payload.paymentMethodAchId,
                loading: false,
            }
        case REQUEST_PAYMENT_METHOD_STRIPE_INFO:
            return {
                ...state,
                loading: true,
            }
        case REQUEST_PAYMENT_PLANS:
            return {
                ...state,
                loading: true,
            }
        case HIDE_ADD_NEW_METHOD_DIALOG:
            return {
                ...state,
                show: false,
            }
        default:
            return {
                ...state,
            }
    }
}

const mapPaymentPlanResponseToPaymentPlans = (
    paymentPlans: Array<PaymentPlanBasicInfo>
): Array<{ paymentPlanId: string; description: string }> => {
    if (paymentPlans.length > 0) {
        return paymentPlans.map((plan) => ({
            paymentPlanId: plan.paymentPlanId,
            description: plan.description,
        }))
    } else {
        return null
    }
}

export default addNewMethodReducer
