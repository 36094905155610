import React from 'react'
import { SFLoading } from '@simplifeye/component-library'

import './LoadingPage.scss'

const LoadingPage = () => {
    return (
        <div className="loading-page">
            <SFLoading fullScreen={false} />
        </div>
    )
}

export default LoadingPage
