import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { StripeTestPractice } from '../../../types/Constants/StripeTestPractice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducers/app/RootState'
import { SFDialog, SFErrorDialogContent, SFLoading, SFSuccessDialogContent } from '@simplifeye/component-library'
import DialogFooterProps from '@simplifeye/component-library/lib/component/SFDialog/types/DialogFooter.types'
import StripeCheckoutForm from './stripe-checkout-form/StripeCheckoutForm'
import { Redirect } from 'react-router'
import { InvoicePaymentMethodsType } from '../../../types/Constants'
import { formatCurrencyCents } from '../../utils'
import { CircularProgress } from '@material-ui/core'

import './StripeManualCheckoutDialog.scss'

interface Props {
    isExternalInvoice?: boolean
    onClose: (isError?: boolean) => void
    onSuccessClick: () => void
    successButtonText?: string
    shouldPreventStripePending?: boolean
    shouldByPassSuccessModal?: boolean
    showNewUIForZeroAndMixed?: boolean
    isZeroOrMixedAccount?: boolean
    updatePaymentMethod?: (paymentMethod: InvoicePaymentMethodsType) => void
    isUseExistingPaymentMethod?: boolean
    useExistingDetails?: {
        rows: Array<{
            label: string
            description: string
        }>
        subtotal: number
        tax: number
        total: number
        onSubmit: () => Promise<void>
        onCancel: () => void
    }
}

const StripeManualCheckoutDialog = ({
    isExternalInvoice,
    onClose,
    onSuccessClick,
    successButtonText,
    shouldPreventStripePending,
    shouldByPassSuccessModal,
    showNewUIForZeroAndMixed,
    isZeroOrMixedAccount,
    updatePaymentMethod,
    isUseExistingPaymentMethod,
    useExistingDetails,
}: Props) => {
    const [stripePromise, setStripePromise] = useState(null)
    const { practiceId } = useSelector((state: RootState) => state.session)
    const { loading, show, success, error, redirect } = useSelector(
        (state: RootState) => state.stripeManualCheckoutDialog
    )

    const [isSubmittingPayment, setIsSubmittingPayment] = useState(false)
    const [dialogContent, setDialogContent] = useState<{
        content: any
        footer: DialogFooterProps
        title: string
        onClose: () => void
    }>({
        content: null,
        footer: null,
        title: '',
        onClose: () => {},
    })

    useEffect(() => {
        if (shouldByPassSuccessModal && success) {
            onSuccessClick()
        }
    }, [success])

    useEffect(() => {
        let stripeKey: string
        if (practiceId === StripeTestPractice.stripeTestPractice) {
            stripeKey = process.env.REACT_APP_TEST_TEST_TEST_STRIPE_API_KEY
        } else {
            stripeKey = process.env.REACT_APP_STRIPE_API_KEY ? process.env.REACT_APP_STRIPE_API_KEY : ''
        }
        setStripePromise(loadStripe(stripeKey))
    }, [practiceId])

    useEffect(() => {
        if (loading) {
            setDialogContent({
                title: 'Make a Payment',
                content: (
                    <div className="stripe-dialog-content stripe-dialog-loading">
                        <SFLoading />
                    </div>
                ),
                footer: {
                    footerButtons: {
                        centerButton: {
                            text: 'Cancel',
                            action: () => {},
                            classes: [],
                            disabled: true,
                        },
                    },
                },
                onClose: () => {},
            })
        } else if (error.showError) {
            setDialogContent({
                title: 'Error Submitting Payment',
                content: (
                    <div className="stripe-dialog-content">
                        <div className="success-error-wrapper">
                            <SFErrorDialogContent
                                title={error.title}
                                message={error.message}
                                messageJson={error.messageJson}
                            />
                        </div>
                    </div>
                ),
                footer: {
                    footerButtons: {
                        centerButton: {
                            text: 'Dismiss',
                            action: () => {
                                onClose(true)
                            },
                            classes: [],
                        },
                    },
                },
                onClose: () => {
                    onClose(true)
                },
            })
        } else if (success && shouldByPassSuccessModal !== true) {
            setDialogContent({
                title: 'Payment Submitted Successfully',
                content: (
                    <div className="stripe-dialog-content">
                        <div className="success-error-wrapper">
                            <SFSuccessDialogContent
                                title="Payment Succeeded"
                                message={`A receipt has been emailed to you! This payment may take a few minutes to reflect on your profile`}
                            />
                        </div>
                    </div>
                ),
                footer: {
                    footerButtons: {
                        centerButton: {
                            text: successButtonText || 'Logout',
                            action: () => {
                                onSuccessClick()
                            },
                            classes: [],
                        },
                    },
                },
                onClose: () => {
                    onSuccessClick()
                },
            })
        } else if (isUseExistingPaymentMethod !== true) {
            setDialogContent({
                title: 'Make a Payment',
                content: (
                    <div className="stripe-dialog-content">
                        <div className="stripe-manual-checkout">
                            <Elements stripe={stripePromise}>
                                <StripeCheckoutForm
                                    isExternalInvoice={isExternalInvoice}
                                    shouldPreventStripePending={shouldPreventStripePending}
                                    onClose={onClose}
                                    showNewUIForZeroAndMixed={showNewUIForZeroAndMixed && isZeroOrMixedAccount}
                                    isZeroOrMixedAccount={isZeroOrMixedAccount}
                                    updatePaymentMethod={updatePaymentMethod}
                                />
                            </Elements>
                        </div>
                    </div>
                ),
                footer: null,
                onClose: () => {
                    onClose()
                },
            })
        } else {
            // use existing payment method
            setDialogContent({
                title: 'Make a Payment',
                content: (
                    <div className="stripe-dialog-content">
                        <div className="existing-payment-details">
                            <h3>You're about to submit a payment. Please confirm the details below:</h3>
                            <div className="details-wrapper">
                                {useExistingDetails.rows.map(row => (
                                    <div className="detail">
                                        <label>{row.label}</label>
                                        <div id="description">{row.description}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="cost-summary-wrapper">
                            <div className="cost-summary treatment-cost">
                                <h4>Subtotal</h4>
                                <span>{formatCurrencyCents(useExistingDetails.subtotal)}</span>
                            </div>
                            {useExistingDetails.tax > 0 ? (
                                <div className="cost-summary treatment-cost">
                                    <h4>Sales Tax</h4>
                                    <span>{formatCurrencyCents(useExistingDetails.tax)}</span>
                                </div>
                            ) : null}
                            <div className="cost-summary payment-due">
                                <h4>Payment Due</h4>
                                <span>{formatCurrencyCents(useExistingDetails.total)}</span>
                            </div>
                        </div>
                    </div>
                ),
                footer: {
                    footerButtons: {
                        twoButtons: {
                            leftButton: {
                                text: 'Cancel',
                                action: () => {
                                    useExistingDetails.onCancel()
                                },
                                disabled: isSubmittingPayment,
                            },
                            rightButton: {
                                classes: [
                                    'checkout-submit-btn',
                                    isSubmittingPayment ? 'checkout-submit-btn--submitting' : '',
                                ],
                                text: !isSubmittingPayment
                                    ? 'Submit Payment'
                                    : (((
                                          <CircularProgress className="checkout-submit-loading" size={25} />
                                      ) as unknown) as string),
                                // text: 'Submit Payment',
                                action: async () => {
                                    if (!isSubmittingPayment) {
                                        setIsSubmittingPayment(true)
                                        await useExistingDetails.onSubmit()
                                        setIsSubmittingPayment(false)
                                    }
                                },
                            },
                        },
                    },
                },
                onClose: () => {
                    if (!isSubmittingPayment) {
                        useExistingDetails.onCancel()
                    }
                },
            })
        }
    }, [loading, show, error, success, isUseExistingPaymentMethod, successButtonText, useExistingDetails])

    if (redirect) {
        return <Redirect to={'login'} />
    } else {
        return (
            <SFDialog
                open={show}
                title={dialogContent.title}
                onClose={dialogContent.onClose}
                content={dialogContent.content}
                footer={dialogContent.footer}
                styleClass="stripe-confirm-payment-dialog"
            />
        )
    }
}

export default StripeManualCheckoutDialog
