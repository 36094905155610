import React, { useEffect, useState } from 'react'
import InputBox from '../../ui/Input'
import CurrencyInput from './currency-input/CurrencyInput'
import { Button } from '@material-ui/core'
import './ExternalInvoiceCostInfo.scss'

interface Props {
    amount: number
    onUpdateAmount: (amount: number) => void
}

const ExternalInvoiceCostInfo = ({ amount, onUpdateAmount }: Props) => {
    // useEffect(() => {
    //     const valid = isValid(invoiceNumber, amount, description)
    //     setCanSelectPayment(valid)
    // }, [invoiceNumber, amount, description])

    // const isValid = (invoiceNumber: string, amount: number, description: string): boolean => {
    //     return invoiceNumber && description && amount && amount > 0
    // }

    return (
        <div className="external-invoice-cost-info">
            <div className="external-invoice-input-wrapper">
                <div className="input-container">
                    <label>Payment Amount:</label>
                    <CurrencyInput value={amount} onValueChange={onUpdateAmount} />
                </div>
            </div>
        </div>
    )
}

export default ExternalInvoiceCostInfo
