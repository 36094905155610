import React, { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import classNames from 'classnames'

import Input from '../../ui/Input'
import './PasswordContainer.scss'

interface Props {
    isError: boolean
    password: string
    setPassword: (string) => void
    setMetAllRequirements: (boolean) => void
}

const PasswordContainer = ({ password, isError, setPassword, setMetAllRequirements }: Props) => {
    const [showPassword, setShowPassword] = useState(false)
    const [passwordRequirements, setPasswordRequirements] = useState<{
        eightChars: boolean
        upperAndLower: boolean
        oneNumber: boolean
        oneSpecial: boolean
    }>({
        eightChars: false,
        upperAndLower: false,
        oneNumber: false,
        oneSpecial: false,
    })

    const onPasswordChange = (currentPassword: string) => {
        const eightChars = currentPassword && currentPassword.length >= 8
        const upperAndLower = /(?=.*[a-z])(?=.*[A-Z])/.test(currentPassword)
        const oneNumber = /(?=.*[\d])/.test(currentPassword)
        const oneSpecial = /(?=.*[^a-zA-Z0-9])/.test(currentPassword)
        setPasswordRequirements({
            eightChars,
            upperAndLower,
            oneNumber,
            oneSpecial,
        })
        setPassword(currentPassword)
        setMetAllRequirements(eightChars && upperAndLower && oneNumber && oneSpecial)
    }

    return (
        <div className="password-container">
            <div className="password-requirements">
                <div className="password-reset-header">Your password should contain:</div>
                <ul>
                    <li>
                        <div className="icon">{passwordRequirements.eightChars ? <CheckCircleIcon /> : null}</div>
                        <div className="requirement">At least 8 characters</div>
                    </li>
                    <li>
                        <div className="icon">{passwordRequirements.upperAndLower ? <CheckCircleIcon /> : null}</div>
                        <div className="requirement">Upper and lowercase letters (e.g. Aa)</div>
                    </li>
                    <li>
                        <div className="icon">{passwordRequirements.oneNumber ? <CheckCircleIcon /> : null}</div>
                        <div className="requirement">At least one number (e.g. 1234)</div>
                    </li>
                    <li>
                        <div className="icon">{passwordRequirements.oneSpecial ? <CheckCircleIcon /> : null}</div>
                        <div className="requirement">At least one special character (e.g. !#$@)</div>
                    </li>
                </ul>
            </div>
            <div>
                <div className="input-container">
                    <Input
                        className={classNames({
                            'input-password-bottom-squared': showPassword,
                        })}
                        labelText="Enter a new password"
                        maxLength={320}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onPasswordChange(e.target.value)
                        }}
                        showError={isError}
                        type="password"
                        value={password}
                    />
                    {showPassword && <div className="show-password-text">{password}</div>}
                    <div className="show-password-checkbox-wrapper">
                        <Checkbox
                            color="primary"
                            className={classNames('show-password-checkbox', {
                                'show-password-checkbox--checked': showPassword,
                            })}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setShowPassword(e.target.checked)
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            id="show-password-checkbox"
                        />
                        <label htmlFor="show-password-checkbox" className="show-password-checkbox-label">
                            Show Password
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PasswordContainer
