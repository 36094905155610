import { SFAlert, SFLoading } from '@simplifeye/component-library'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { RootState } from '../reducers/app/RootState'
import {
    onUseExistingPaymentMethod,
    loadExternalInvoiceData,
    onNewPaymentMethodSelectAction,
    onPayExternalInvoiceStart,
    costInfoComplete,
    editCostInfo,
    onAddNewPaymentMethod,
    onPayExternalInvoiceUseNewSuccess,
    onPayExternalInvoiceUseExistingCancel,
} from '../reducers/external-invoice/externalInvoice.action'
import SelectPaymentMethod from '../shared/components/select-payment-method/SelectPaymentMethod'
import { AchPaymentMethod } from '../types/AchPaymentMethod'
import { InvoicePaymentMethods, InvoicePaymentMethodsType } from '../types/Constants'
import { PayerPaymentMethods } from '../types/Response/PayerPaymentMethods'
import ExternalInvoiceBillingInfo from './external-invoice-billing-info/ExternalInvoiceBillingInfo'
import ExternalInvoiceCostInfo from './external-invoice-cost-info/ExternalInvoiceCostInfo'
import ExternalInvoicePatientInfo, { PatientInfo } from './external-invoice-patient-info/ExternalInvoicePatientInfo'
import { Button, Icon } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import './ExternalInvoice.scss'
import ExternalInvoiceDialog from './external-invoice-dialog/ExternalInvoiceDialog'
import StripeManualCheckoutDialog from '../shared/components/stripe-manual-checkout-dialog/StripeManualCheckoutDialog'
import InvoiceDetailsInfoAlert from '../invoice-details/invoice-details-info-alert/InvoiceDetailsInfoAlert'
import { getMainAppLocationLogo, setLogoUrl } from '../reducers/session/session.actions'
import SubmitPaymentEnterInfoButton from '../shared/components/submit-payment-enter-info-button/SubmitPaymentEnterInfoButton'
import { useLocation } from 'react-router'
import HeaderUI from '../header/HeaderUI'
import Footer from '../footer/Footer'
import { convertStringDollarsToCents } from '../shared/utils'
import { getAddressForLocation } from '../reducers/invoice-details/invoiceDetails.action'
import ExternalInvoicePaymentSuccess from './external-invoice-payment-success/ExternalInvoicePaymentSuccess'
import classNames from 'classnames'

type AppDispatch = ThunkDispatch<any, any, AnyAction>

const ExternalInvoice = () => {
    const {
        accountType,
        loading,
        selectedPaymentMethod,
        error,
        billingTotals,
        costInfo,
        isAddingNewPaymentMethod,
        savedPaymentMethod,
        full,
    } = useSelector((state: RootState) => {
        return {
            ...state.externalInvoice,
            full: state.externalInvoice,
        }
    })

    const { paymentMethods, achPaymentMethod } = useSelector((state: RootState) => state.paymentMethods)
    const dispatch: AppDispatch = useDispatch()
    const location = useLocation()

    const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(false)
    const [practiceId, setPracticeId] = useState('')
    const [locationId, setLocationId] = useState('')
    const [paymentSuccess, setPaymentSuccess] = useState(false)
    const [errorType, setErrorType] = useState('')
    const [errorName, setErrorName] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [patientInfo, setPatientInfo] = useState<PatientInfo>({
        firstName: '',
        lastName: '',
        dob: '',
        dobError: false,
        invoiceId: '',
    })
    const [amount, setAmount] = useState(0)
    const [usedPaymentMethod, setUsedPaymentMethod] = useState('')

    useEffect(() => {
        const queryObj = new URLSearchParams(location.search)
        const practiceIdFromUrl = queryObj.get('practiceId')
        const locationIdFromUrl = queryObj.get('locationId')
        setPracticeId(practiceIdFromUrl)
        setLocationId(locationIdFromUrl)
        dispatch(loadExternalInvoiceData(practiceIdFromUrl, locationIdFromUrl))
    }, [location, dispatch])

    useEffect(() => {
        if (!practiceId || !locationId) {
            return
        }
        const loadLogo = async () => {
            try {
                await dispatch(getMainAppLocationLogo({ practiceId, locationId }))
            } catch (err) {}
        }
        loadLogo()
    }, [practiceId, locationId])

    useEffect(() => {
        if (!!selectedPaymentMethod) {
            onNewPaymentMethodSelect(selectedPaymentMethod)
        }
    }, [amount])

    const onCostInfoCompleted = async (invoiceNumber: string, amount: number, description: string) => {
        setIsLoadingPaymentMethods(true)
        await dispatch(costInfoComplete({ invoiceNumber, amount, description }))
        setIsLoadingPaymentMethods(false)
    }

    const onNewPaymentMethodSelect = (paymentMethodType: InvoicePaymentMethodsType) => {
        dispatch(
            onNewPaymentMethodSelectAction({
                paymentMethodType,
                practiceId,
                locationId,
                amount,
            })
        )
    }

    const onPaymentMethodSelectChange = (
        paymentMethod: string,
        isExistingPayment: boolean,
        savedPaymentMethod?: PayerPaymentMethods,
        achPaymentMethod?: AchPaymentMethod
    ) => {
        if (isExistingPayment) {
            dispatch(onUseExistingPaymentMethod({ savedPaymentMethod }))
        } else {
            dispatch(onAddNewPaymentMethod())
        }
    }

    const handleSubmit = async () => {
        setErrorName('')
        setErrorType('')
        setErrorMessage('')

        const createInvoiceResult = await dispatch(
            onPayExternalInvoiceStart(
                {
                    invoiceNumber: patientInfo.invoiceId,
                    amount: amount,
                    description: patientInfo.invoiceId || 'No invoice id provided',
                    complete: true,
                },
                patientInfo
            )
        )

        if (['patient-error', 'invoice-error'].indexOf(createInvoiceResult.type) > -1) {
            setErrorName(createInvoiceResult.name || '')
            setErrorType(createInvoiceResult.type)
            if (!!createInvoiceResult.message) {
                setErrorMessage(createInvoiceResult.message)
            }
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        }
    }

    const isZeroOrMixedAccount = accountType === 'simplyzero' || accountType === 'mixed'

    if (loading) {
        return (
            <div className="external-invoice">
                <SFLoading fullScreen={true} />
            </div>
        )
    } else if (error.showError) {
        return (
            <div className="unauthorized-route">
                <header>
                    <HeaderUI hideLogout={true} overrideBannerText="Guest Checkout" />
                </header>
                <section className="main-content">
                    <div className="external-invoice">
                        <div className="external-invoice-inner">
                            <SFAlert
                                showAlert={error.showError}
                                severity={'error'}
                                title={error.title}
                                message={error.message}
                            />
                        </div>
                    </div>
                </section>
                <footer>
                    <Footer />
                </footer>
            </div>
        )
    } else if (paymentSuccess) {
        return (
            <div className="unauthorized-route">
                <header>
                    <HeaderUI hideLogout={true} overrideBannerText="Guest Checkout" />
                </header>
                <section className="main-content">
                    <div className="external-invoice">
                        <section className="external-invoice-header">
                            <div className="external-invoice-inner">
                                <h3>Pay as Guest</h3>
                            </div>
                        </section>
                        <div className="external-invoice-inner">
                            <ExternalInvoicePaymentSuccess
                                firstName={patientInfo.firstName}
                                lastName={patientInfo.lastName}
                                invoiceId={patientInfo.invoiceId}
                                amount={!!usedPaymentMethod ? billingTotals.allTotals[usedPaymentMethod] : amount}
                            />
                        </div>
                    </div>
                </section>
                <footer>
                    <Footer />
                </footer>
            </div>
        )
    } else {
        return (
            <div className="unauthorized-route">
                <header>
                    <HeaderUI hideLogout={true} overrideBannerText="Guest Checkout" />
                </header>
                <section className="main-content">
                    <div className="external-invoice">
                        <section className="external-invoice-header">
                            <div className="external-invoice-inner">
                                <h3>Pay as Guest</h3>
                            </div>
                        </section>
                        <section className="external-invoice-inner">
                            {errorType !== '' && (
                                <div
                                    className={classNames('ei-patient-error-wrapper', {
                                        'ei-patient-error-wrapper--center': errorType === 'invoice-error',
                                    })}
                                >
                                    <Icon>warning</Icon>
                                    <div className="ei-patient-error-inner">
                                        {errorType === 'patient-error' && (
                                            <>
                                                <span className="ei-patient-error-title">
                                                    Unable to accept a payment at this time.
                                                </span>
                                                <p>
                                                    {errorMessage !== ''
                                                        ? errorMessage
                                                        : 'Multiple records found for {errorName}. Please contact our office to arrange payment with the correct patient.'}
                                                </p>
                                            </>
                                        )}
                                        {errorType === 'invoice-error' && (
                                            <>
                                                <p>Unable to create invoice.</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="title">Patient Information</div>
                            <ExternalInvoicePatientInfo
                                patientInfo={patientInfo}
                                onUpdatePatientInfo={newPatientInfo =>
                                    setPatientInfo({ ...patientInfo, ...newPatientInfo })
                                }
                            />
                        </section>
                        <section className="external-invoice-inner">
                            <div className="billing-info-header">
                                <div className="title">How much would you like to pay today?</div>
                            </div>
                            <ExternalInvoiceCostInfo
                                amount={amount}
                                onUpdateAmount={newAmount => setAmount(newAmount)}
                            />
                        </section>
                        <div>
                            <section className="external-invoice-inner">
                                <SelectPaymentMethod
                                    isLoading={isLoadingPaymentMethods}
                                    selectedPaymentMethod={selectedPaymentMethod}
                                    savedPaymentMethods={paymentMethods}
                                    isAddingNewPaymentMethod={isAddingNewPaymentMethod}
                                    achPaymentMethod={achPaymentMethod}
                                    onNewPaymentMethodSelect={onNewPaymentMethodSelect}
                                    onPaymentMethodSelectChange={onPaymentMethodSelectChange}
                                    isExternalInvoice={true}
                                    allowOTP={false}
                                />
                            </section>
                            <section className="external-invoice-inner">
                                <InvoiceDetailsInfoAlert
                                    showAlert={
                                        billingTotals.allTotals['debit'] &&
                                        isZeroOrMixedAccount &&
                                        isAddingNewPaymentMethod &&
                                        (selectedPaymentMethod === InvoicePaymentMethods.CARD ||
                                            selectedPaymentMethod === InvoicePaymentMethods.OTP)
                                    }
                                    amount={billingTotals.allTotals['debit'] || 0}
                                />
                            </section>
                            <section className="external-invoice-inner">
                                <ExternalInvoiceBillingInfo
                                    billingTotals={billingTotals}
                                    isZeroOrMixedAccount={isZeroOrMixedAccount}
                                />
                            </section>
                            <section>
                                <SubmitPaymentEnterInfoButton
                                    customText="Next"
                                    onClick={handleSubmit}
                                    disabled={
                                        (!savedPaymentMethod && !selectedPaymentMethod) ||
                                        !amount ||
                                        !patientInfo.firstName ||
                                        !patientInfo.lastName ||
                                        !patientInfo.dob ||
                                        patientInfo.dobError
                                    }
                                    id="reviewPaymentButton"
                                    selectedMethod={selectedPaymentMethod}
                                />
                            </section>
                        </div>
                        <ExternalInvoiceDialog />
                        <StripeManualCheckoutDialog
                            isExternalInvoice={true}
                            onClose={() => {
                                dispatch(onPayExternalInvoiceUseExistingCancel())
                            }}
                            onSuccessClick={() => {
                                setPaymentSuccess(true)
                            }}
                            shouldPreventStripePending={true}
                            shouldByPassSuccessModal={true}
                            showNewUIForZeroAndMixed={true}
                            isZeroOrMixedAccount={isZeroOrMixedAccount}
                            updatePaymentMethod={(newMethod: InvoicePaymentMethodsType) =>
                                setUsedPaymentMethod(newMethod)
                            }
                        />
                    </div>
                </section>
                <footer>
                    <Footer />
                </footer>
            </div>
        )
    }
}

export default ExternalInvoice
