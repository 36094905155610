import React from 'react'
import { PayerFailedPayment } from '../../../types/Response/PayerFailedPayment'
import { Grid, Button, Link } from '@material-ui/core'
import './FailedPayment.scss'
import { formatDate, formatCurrencyCents } from '../../../shared/utils'
import WarningIcon from '@material-ui/icons/Warning'
import {
    onFailedPaymentRetryExistingAch,
    onFailedPaymentRetryExistingCard,
} from '../../../reducers/overview/overview.action'
import { useDispatch } from 'react-redux'
import { SFLoading } from '@simplifeye/component-library'
import { showUpdateFailedPaymentDialog } from '../../../reducers/update-failed-payment-dialog/update-failed-payment-dialog.action'
interface Props {
    failedPayment: PayerFailedPayment
    failedPaymentLoading: boolean
}

const FailedPayment = ({ failedPayment, failedPaymentLoading }: Props) => {
    const dispatch = useDispatch()

    const isUsingAch = (failedPayment: PayerFailedPayment): boolean => {
        return !!failedPayment.ach.achId
    }

    const displayPaymentMethod = (failedPayment: PayerFailedPayment): string => {
        if (isUsingAch(failedPayment)) {
            return `${failedPayment.ach.bankName} ****${failedPayment.ach.accountLast4}`
        } else {
            return `${failedPayment.card.cardType} ****${failedPayment.card.last4}`
        }
    }

    return (
        <div className="failed-payment">
            <Grid container={true}>
                <Grid item={true} xs={12} lg={8}>
                    <div className="header">
                        <WarningIcon className="icon" />
                        <div>We've detected with an error with your payment method</div>
                    </div>
                    <Grid container={true} className="failed-payment-info">
                        <Grid item={true} xs={12} sm={3} lg={3}>
                            <label>Payment Plan</label>
                            <div>{failedPayment.chargeEvent.paymentPlanId}</div>
                        </Grid>
                        <Grid item={true} xs={12} sm={3} lg={3}>
                            <label>Payment Method</label>
                            <div>{displayPaymentMethod(failedPayment)}</div>
                        </Grid>
                        <Grid item={true} xs={12} sm={3} lg={3}>
                            <label>Due By</label>
                            <div>{formatDate(failedPayment.chargeEvent.scheduledTs)}</div>
                        </Grid>
                        <Grid item={true} xs={12} sm={3} lg={3}>
                            <label>Amount</label>
                            <div>{formatCurrencyCents(failedPayment.chargeEvent.totalAmount)}</div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} lg={4} className="failed-payment-btn-wrapper">
                    {failedPaymentLoading ? (
                        <SFLoading width={50} />
                    ) : (
                        <React.Fragment>
                            <Button
                                className="payment-btn"
                                onClick={() => {
                                    if (isUsingAch(failedPayment)) {
                                        dispatch(
                                            onFailedPaymentRetryExistingAch({
                                                chargeEventId: failedPayment.chargeEvent.chargeEventId,
                                                achId: failedPayment.ach.achId,
                                            })
                                        )
                                    } else {
                                        dispatch(
                                            onFailedPaymentRetryExistingCard({
                                                chargeEventId: failedPayment.chargeEvent.chargeEventId,
                                                tokenId: failedPayment.card.tokenId,
                                            })
                                        )
                                    }
                                }}
                                id="updateButton"
                                variant="contained"
                            >
                                Retry
                            </Button>
                            <Link
                                href="#"
                                onClick={() => {
                                    dispatch(showUpdateFailedPaymentDialog({ failedPayment }))
                                }}
                                className="update-method-link"
                            >
                                Update Payment Method
                            </Link>
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

export default FailedPayment
