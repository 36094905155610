import SessionManager from '../../../SessionManager'
import Api from '../../api/Api'
import { promiseTo } from '../../shared/utils'
import { LoginResponse } from '../../types/Response/LoginResponse'
import { PracticePayerResponse } from '../../types/Response/PracticePayerResponse'
import { RequestPasswordResetResponse } from '../../types/Response/RequestPasswordResetResponse'
import {
    logUserInFromSession,
    selectPracticeFromSession,
    setExternalInvoiceInfoFromSession,
    setLogoUrl,
} from '../session/session.actions'
import { getPaymentPlans } from '../payment-plan/paymentPlan.action'
import { ExternalInvoiceLoginResponse } from '../../types/Response/ExternalInvoiceLoginResponse'
import { Dispatch } from 'react'
import { RootState } from '../app/RootState'

const USER_LOGIN_ERROR = '[LOGIN] USER LOGIN ERROR'
const MULTIPLE_PAYERS_GO_TO_SELECT_PRACTICE = '[LOGIN] MULTIPLE PAYERS GO TO SELECT PRACTICE'
const ONLY_ONE_PAYER_GO_TO_OVERVIEW = '[LOGIN] ONLY ONE PAYER GO TO OVERVIEW'
const REQUEST_PASSWORD_RESET_SUCCESS = '[LOGIN] REQUEST PASSWORD RESET SUCCESS'
const REQUEST_PASSWORD_RESET_ERROR = '[LOGIN] REQUEST PASSWORD RESET ERROR'
const GET_RESET_CODE_SUCCESS = '[LOGIN] GET RESET CODE SUCCESS'
const GO_TO_FORGOT_PASSWORD = '[LOGIN] GO TO FORGOT PASSWORD'
const GO_BACK_TO_LOGIN = '[LOGIN] GO BACK TO LOGIN'
const REQUEST_LOGIN_ATTEMPT = '[LOGIN] REQUEST LOGIN ATTEMPT'
const REQUEST_PASSWORD_RESET_ATTEMPT = '[LOGIN] REQUEST PASSEWORD RESET ATTEMPT'
const COMPLETE_PASSWORD_RESET_SUCCESS = '[LOGIN] COMPLETE PASSWORD RESET SUCCESS'
const COMPLETE_PASSWORD_RESET_ERROR = '[LOGIN] COMPLETE PASSWORD RESET ERROR'
const BACK_TO_LOGIN_FROM_RESET_SUCCESS = '[LOGIN] BACK TO LOGIN FROM RESET SUCCESS'
const COMPLETE_PASSWORD_RESET_REQUEST = '[LOGIN] COMPLETE PASSWORD RESET REQUEST'
const REGISTER_NEW_ACCOUNT_REQUEST = '[LOGIN] REGISTER NEW ACCOUNT REQUEST'
const REGISTER_NEW_ACCOUNT_SUCCESS = '[LOGIN] REGISTER NEW ACCOUNT SUCCESS'
const REGISTER_NEW_ACCOUNT_ERROR = '[LOGIN] REGISTER NEW ACCOUNT ERROR'
const NO_PRACTICE_LOGO = '[LOGIN] NO PRACTICE LOGO'
const LOGIN_USE_EXTERNAL_INVOICE = '[LOGIN] USE EXTERNAL INVOICE'
const EXTERNAL_INVOICE_LOGIN_SUCCESS = '[LOGIN] EXTERNAL INVOICE LOGIN SUCCESS'
const CREATE_NEW_USER_FOR_EXTERNAL_INVOICE_SUCCESS = '[LOGIN] CREATE NEW USER FOR EXTERNAL INVOICE SUCCESS'
const CREATE_NEW_USER_FOR_EXTERNAL_INVOICE_ERROR = '[LOGIN] CREATE NEW USER FOR EXTERNAL INVOICE ERROR'
const REGISTER_NEW_ACCOUNT_SUCCESS_EXTERNAL_INVOICE = '[LOGIN] REGISTER NEW ACCOUNT SUCCESS EXTERNAL INVOICE'
const SELECT_PRACTICE_FROM_MULTIPLE_PRACTICES_SUCCESS = '[LOGIN] SELECT PRACTICE FROM MULTIPLE PRACTICES SUCCESS'
const GET_LOCATION_LOGO_SUCCESS = '[LOGIN] GET LOCATION LOGO SUCCESS'
const GET_GUEST_USER_DETAILS_SUCCESS = '[LOGIN] GET ACCOUNT BY EMAIL SUCCESS'
const GET_GUEST_USER_DETAILS_ERROR = '[LOGIN] GET ACCOUNT BY EMAIL ERROR'
const GET_ACCOUNT_STATUS_SUCCESS = '[LOGIN] GET ACCOUNT STATUS SUCCESS'

export type Action =
    | { type: typeof USER_LOGIN_ERROR; payload: { title: string; message: string } }
    | { type: typeof MULTIPLE_PAYERS_GO_TO_SELECT_PRACTICE; payload: { availablePayers: Array<PracticePayerResponse> } }
    | { type: typeof ONLY_ONE_PAYER_GO_TO_OVERVIEW }
    | { type: typeof REQUEST_PASSWORD_RESET_SUCCESS }
    | { type: typeof REQUEST_PASSWORD_RESET_ERROR; payload: { title: string; message: string } }
    | { type: typeof GET_RESET_CODE_SUCCESS; payload: { response: RequestPasswordResetResponse } }
    | { type: typeof GO_TO_FORGOT_PASSWORD }
    | { type: typeof GO_BACK_TO_LOGIN }
    | { type: typeof REQUEST_LOGIN_ATTEMPT }
    | { type: typeof REQUEST_PASSWORD_RESET_ATTEMPT }
    | { type: typeof COMPLETE_PASSWORD_RESET_SUCCESS }
    | { type: typeof COMPLETE_PASSWORD_RESET_ERROR; payload: { title: string; message: string } }
    | { type: typeof BACK_TO_LOGIN_FROM_RESET_SUCCESS }
    | { type: typeof COMPLETE_PASSWORD_RESET_REQUEST }
    | { type: typeof REGISTER_NEW_ACCOUNT_REQUEST }
    | { type: typeof REGISTER_NEW_ACCOUNT_SUCCESS }
    | { type: typeof REGISTER_NEW_ACCOUNT_ERROR; payload: { title: string; message: string } }
    | { type: typeof NO_PRACTICE_LOGO }
    | {
          type: typeof LOGIN_USE_EXTERNAL_INVOICE
          payload: { practiceId: string; locationId: string; practiceLogoUrl: string | null }
      }
    | { type: typeof EXTERNAL_INVOICE_LOGIN_SUCCESS; payload: { loginResponse: ExternalInvoiceLoginResponse } }
    | { type: typeof CREATE_NEW_USER_FOR_EXTERNAL_INVOICE_SUCCESS }
    | { type: typeof CREATE_NEW_USER_FOR_EXTERNAL_INVOICE_ERROR; payload: { title: string; message: string } }
    | { type: typeof REGISTER_NEW_ACCOUNT_SUCCESS_EXTERNAL_INVOICE }
    | { type: typeof SELECT_PRACTICE_FROM_MULTIPLE_PRACTICES_SUCCESS }
    | { type: typeof GET_LOCATION_LOGO_SUCCESS; payload: { logoUrl: string; logoBackgroundColor: string } }
    | { type: typeof GET_GUEST_USER_DETAILS_SUCCESS; payload: { accountStatus: string; enableGuestLink: boolean } }
    | { type: typeof GET_GUEST_USER_DETAILS_ERROR }
    | { type: typeof GET_ACCOUNT_STATUS_SUCCESS; payload: { accountStatus: string } }

const userLoginAttempt = (payload: { emailAddress: string; password: string }) => {
    return async (dispatch: any) => {
        dispatch({ type: REQUEST_LOGIN_ATTEMPT })
        try {
            const {
                response: {
                    data: { portalUserId },
                },
                errors: loginErrors,
            } = await promiseTo<LoginResponse>(Api.login(payload.emailAddress, payload.password))

            if (loginErrors) {
                return dispatch(userLoginError({ title: 'Error', message: 'Email Address or Password is incorrect' }))
            }

            SessionManager.sessionToken = Api.token
            SessionManager.portalUser = portalUserId

            dispatch(logUserInFromSession({ portalUserId }))

            const {
                response: {
                    data: { practices },
                },
                errors: getAllPayerErrors,
            } = await promiseTo<{ practices: PracticePayerResponse[] }>(Api.getAllPayers(portalUserId))

            if (getAllPayerErrors) {
                dispatch(userLoginError({ title: 'Error', message: 'Unable to get payers for user' }))
            }

            if (practices.length === 1) {
                return dispatch(onlyOnePayer({ practicePayer: practices[0] }))
            } else {
                return dispatch(multiplePayersGoToSelectPractice({ availablePayers: practices }))
            }
        } catch (err) {
            return dispatch(userLoginError({ title: 'Error', message: 'Email Address or Password is incorrect' }))
        }
    }
}

const userLoginAttemptExternalInvoice = (payload: { emailAddress: string; password: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            login: { externalInvoice },
        } = getState()

        dispatch({ type: REQUEST_LOGIN_ATTEMPT })
        try {
            const {
                response: { data },
                errors: loginErrors,
            } = await promiseTo<ExternalInvoiceLoginResponse>(
                Api.loginExternalInvoice(payload.emailAddress, payload.password, externalInvoice.practiceId)
            )

            if (loginErrors) {
                return dispatch(userLoginError({ title: 'Error', message: 'Email Address or Password is incorrect' }))
            }
            SessionManager.sessionToken = Api.token
            SessionManager.portalUser = data.portalUserId
            SessionManager.sessionLocationId = externalInvoice.locationId
            SessionManager.sessionPracticeId = externalInvoice.practiceId
            SessionManager.sessionPayerId = data.payer.payerId
            SessionManager.sessionPatientId = data.patient.patientId

            dispatch(
                setExternalInvoiceInfoFromSession({
                    practiceId: externalInvoice.practiceId,
                    locationId: externalInvoice.locationId,
                })
            )
            dispatch(logUserInFromSession({ portalUserId: data.portalUserId }))
            return dispatch(externalInvoiceLoginSuccess({ loginResponse: data }))
        } catch (err) {
            return dispatch(userLoginError({ title: 'Error', message: 'Email Address or Password is incorrect' }))
        }
    }
}

const externalInvoiceLoginSuccess = (payload: { loginResponse: ExternalInvoiceLoginResponse }) => {
    return {
        type: EXTERNAL_INVOICE_LOGIN_SUCCESS,
        payload,
    }
}

const onlyOnePayer = (payload: { practicePayer: PracticePayerResponse }) => {
    return async (dispatch: Dispatch<any>) => {
        SessionManager.currentPractice = payload.practicePayer
        dispatch(selectPracticeFromSession({ selectedPractice: payload.practicePayer }))

        try {
            const { data } = await Api.getLogoFromPatientId(
                payload.practicePayer.practiceId,
                payload.practicePayer.payerId
            )
            SessionManager.sessinoLogoUrl = data.url
            SessionManager.sessinoLogoBackgroundColor = data.backgroundColorCode
            dispatch(setLogoUrl({ logoUrl: data.url, logoBackgroundColor: data.backgroundColorCode }))
            dispatch(getPaymentPlans())
        } catch (err) {
            dispatch(setLogoUrl({ logoUrl: '', logoBackgroundColor: '' }))
        }

        return dispatch(onlyOnePayerGoToOverview())
    }
}

const onlyOnePayerGoToOverview = () => {
    return {
        type: ONLY_ONE_PAYER_GO_TO_OVERVIEW,
    }
}

const multiplePayersGoToSelectPractice = (payload: { availablePayers: Array<PracticePayerResponse> }) => {
    return {
        type: MULTIPLE_PAYERS_GO_TO_SELECT_PRACTICE,
        payload,
    }
}

const userLoginError = (payload: { title: string; message: string }) => {
    return {
        type: USER_LOGIN_ERROR,
        payload,
    }
}

const requestPasswordReset = (payload: { emailAddress: string }) => {
    return async (dispatch: any) => {
        dispatch({ type: REQUEST_PASSWORD_RESET_ATTEMPT })
        try {
            const { data } = await Api.requestPasswordReset(payload.emailAddress, null)
            if (data.success) {
                return dispatch(requestPasswordResetSuccess({ response: data }))
            } else {
                throw data
            }
        } catch (err) {
            return dispatch(
                requestPasswordResetError({
                    title: 'Error',
                    message: 'Unable to send link. Please contact help@simplifeye.co if this problem persists',
                })
            )
        }
    }
}

const requestPasswordResetSuccess = (payload: { response: RequestPasswordResetResponse }) => {
    return {
        type: REQUEST_PASSWORD_RESET_SUCCESS,
    }
}

const requestPasswordResetError = (payload: { title: string; message: string }) => {
    return {
        type: REQUEST_PASSWORD_RESET_ERROR,
        payload,
    }
}

const getResetCode = (payload: { emailAddress: string }) => {
    return async (dispatch: any) => {
        dispatch({ type: REQUEST_PASSWORD_RESET_ATTEMPT })
        try {
            const { data } = await Api.getResetCode(payload.emailAddress)
            if (data.success) {
                return dispatch(getResetCodeSuccess({ response: data }))
            } else {
                throw data
            }
        } catch (err) {
            return dispatch(
                requestPasswordResetError({
                    title: 'Error',
                    message: 'Unable to send link. Please contact help@simplifeye.co if this problem persists',
                })
            )
        }
    }
}

const getResetCodeSuccess = (payload: { response: RequestPasswordResetResponse }) => {
    return {
        type: GET_RESET_CODE_SUCCESS,
        payload,
    }
}

const goToForgotPassword = () => {
    return {
        type: GO_TO_FORGOT_PASSWORD,
    }
}

const goBackToLogin = () => {
    return {
        type: GO_BACK_TO_LOGIN,
    }
}

const completePasswordReset = (payload: { emailAddress: string; password: string; code: string }) => {
    return async (dispatch: any) => {
        dispatch(completePasswordResetRequest())
        try {
            // eslint-disable-next-line
            const { data } = await Api.completePasswordReset(payload.emailAddress, payload.password, payload.code)
            return dispatch(completePasswordResetSuccess())
        } catch (err) {
            return dispatch(
                completePasswordResetError({ title: 'Error', message: 'Unable to reset password at this time' })
            )
        }
    }
}

const completePasswordResetSuccess = () => {
    return {
        type: COMPLETE_PASSWORD_RESET_SUCCESS,
    }
}

const completePasswordResetError = (payload: { title: string; message: string }) => {
    return {
        type: COMPLETE_PASSWORD_RESET_ERROR,
        payload,
    }
}

const backToLoginFromResetSuccess = () => {
    return {
        type: BACK_TO_LOGIN_FROM_RESET_SUCCESS,
    }
}

const completePasswordResetRequest = () => {
    return {
        type: COMPLETE_PASSWORD_RESET_REQUEST,
    }
}

const registerNewUserAccount = (payload: { email: string; password: string; code: string }) => {
    return async (dispatch: any) => {
        dispatch(registerNewUserAccountRequest())
        try {
            // eslint-disable-next-line
            const { data } = await Api.putRegisterAccount(payload.email, payload.password, payload.code)
            return dispatch(registerNewUserAccountSucces())
        } catch (err) {
            return dispatch(
                registerNewUserAccountError({
                    title: 'Error',
                    message:
                        'Unable to register new account. Please contact help@simplifeye.co if this problem persists',
                })
            )
        }
    }
}

const registerNewUserAccountExternalInvoice = (payload: {
    email: string
    password: string
    code: string
    practiceId: string
    locationId: string
}) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(registerNewUserAccountRequest())
        try {
            // eslint-disable-next-line
            const { data } = await Api.putRegisterAccount(payload.email, payload.password, payload.code)
            return dispatch(registerNewAccountSuccessExternalInvoice())
        } catch (err) {
            return dispatch(
                registerNewUserAccountError({
                    title: 'Error',
                    message:
                        'Unable to register new account. Please contact help@simplifeye.co if this problem persists',
                })
            )
        }
    }
}

const registerNewUserAccountRequest = () => {
    return {
        type: REGISTER_NEW_ACCOUNT_REQUEST,
    }
}

const registerNewUserAccountSucces = () => {
    return {
        type: REGISTER_NEW_ACCOUNT_SUCCESS,
    }
}

const registerNewUserAccountError = (payload: { title: string; message: string }) => {
    return {
        type: REGISTER_NEW_ACCOUNT_ERROR,
        payload,
    }
}

const registerNewAccountSuccessExternalInvoice = () => {
    return {
        type: REGISTER_NEW_ACCOUNT_SUCCESS_EXTERNAL_INVOICE,
    }
}

const noPracticeLogo = () => {
    return {
        type: NO_PRACTICE_LOGO,
    }
}

const getLocationLogo = (payload: { practiceId: string; locationId: string }) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await Api.getLocationLogo(payload.practiceId, payload.locationId)
            if (data && data.url) {
                return dispatch(
                    getLocationLogoSuccess({
                        logoUrl: data.url,
                        logoBackgroundColor: data.locationLogoBackgroundColorCode,
                    })
                )
            } else {
                return dispatch(noPracticeLogo())
            }
        } catch (err) {
            return dispatch(noPracticeLogo())
        }
    }
}

const getLocationLogoSuccess = (payload: { logoUrl: string; logoBackgroundColor: string }) => {
    return {
        type: GET_LOCATION_LOGO_SUCCESS,
        payload,
    }
}

const loginUseExternalInvoiceStart = (payload: { practiceId: string; locationId: string }) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await Api.getLocationLogo(payload.practiceId, payload.locationId)
            dispatch(
                loginUseExternalInvoiceStartSuccess({
                    practiceId: payload.practiceId,
                    locationId: payload.locationId,
                    practiceLogoUrl: data.url,
                })
            )
        } catch (err) {
            dispatch(
                loginUseExternalInvoiceStartSuccess({
                    practiceId: payload.practiceId,
                    locationId: payload.locationId,
                    practiceLogoUrl: '',
                })
            )
        }
    }
}

const loginUseExternalInvoiceStartSuccess = (payload: {
    practiceId: string
    locationId: string
    practiceLogoUrl: string | null
}) => {
    return {
        type: LOGIN_USE_EXTERNAL_INVOICE,
        payload,
    }
}

const createNewUserForExternalInvoice = (payload: {
    firstName: string
    lastName: string
    phoneNumber: string
    dob: string
    email: string
}) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            login: {
                externalInvoice: { practiceId, locationId },
            },
        } = getState()

        try {
            const { data } = await Api.createNewUserForExternalInvoice(practiceId, locationId, {
                firstName: payload.firstName,
                lastName: payload.lastName,
                phoneNumber: payload.phoneNumber,
                dob: payload.dob,
                email: payload.email,
            })
            return dispatch(createNewUserForExternalInvoiceSuccess())
        } catch (err) {
            return dispatch(
                createNewUserForExternalInvoiceError({
                    title: 'User Creation Error',
                    message: 'Unable to create a new user from the provided information',
                })
            )
        }
    }
}

const createNewUserForExternalInvoiceSuccess = () => {
    return {
        type: CREATE_NEW_USER_FOR_EXTERNAL_INVOICE_SUCCESS,
    }
}

const createNewUserForExternalInvoiceError = (payload: { title: string; message: string }) => {
    return {
        type: CREATE_NEW_USER_FOR_EXTERNAL_INVOICE_ERROR,
        payload,
    }
}

const selectPracticeFromMultiplePractices = (payload: { practicePayer: PracticePayerResponse }) => {
    return async (dispatch: Dispatch<any>) => {
        SessionManager.currentPractice = payload.practicePayer
        dispatch(selectPracticeFromSession({ selectedPractice: payload.practicePayer }))
        try {
            const { data } = await Api.getLogoFromPatientId(
                payload.practicePayer.practiceId,
                payload.practicePayer.payerId
            )
            SessionManager.sessinoLogoUrl = data.url
            dispatch(setLogoUrl({ logoUrl: data.url, logoBackgroundColor: data.backgroundColorCode }))
            dispatch(getPaymentPlans())
        } catch (err) {
            dispatch(setLogoUrl({ logoUrl: '', logoBackgroundColor: '' }))
        }
        return dispatch(selectPracticeFromMultiplePracticesSuccess())
    }
}

const selectPracticeFromMultiplePracticesSuccess = () => {
    return {
        type: SELECT_PRACTICE_FROM_MULTIPLE_PRACTICES_SUCCESS,
    }
}

const getGuestUserDetails = (payload: { email: string; chargeEventId: string; practiceId: string }) => {
    return async (dispatch: any) => {
        try {
            const response = await Api.getExistingPortalUser(payload.email, payload.chargeEventId, payload.practiceId)
            SessionManager.sessionEmail = payload.email
            SessionManager.portalUser = response.data.portalUserId
            SessionManager.sessionGuestToken = response.data.token
            Api.bearer = response.data.token

            dispatch(
                getGuestUserDetailsSuccess({
                    accountStatus: response.data.accountStatus,
                    enableGuestLink: response.data.enableGuestLink,
                })
            )
        } catch (err) {
            SessionManager.sessionEmail = ''
            dispatch(getGuestUserDetailsError())
        }
    }
}
const getGuestUserDetailsSuccess = (payload: { accountStatus: string; enableGuestLink: boolean }) => {
    return {
        type: GET_GUEST_USER_DETAILS_SUCCESS,
        payload,
    }
}
const getGuestUserDetailsError = () => {
    return {
        type: GET_GUEST_USER_DETAILS_ERROR,
    }
}

const getPortalAccountStatus = (payload: { emailAddress: string }) => {
    return async (dispatch: any) => {
        let accountStatus = ''
        try {
            const { data } = await Api.getPortalUserByEmail(payload.emailAddress)
            SessionManager.sessionEmail = data.email
            accountStatus = data.accountStatus
        } catch (err) {
            accountStatus = 'pending'
        }
        dispatch(getPortalAccountStatusSuccess({ accountStatus }))
    }
}

const getPortalAccountStatusSuccess = (payload: { accountStatus: string }) => {
    return {
        type: GET_ACCOUNT_STATUS_SUCCESS,
        payload,
    }
}

export {
    USER_LOGIN_ERROR,
    MULTIPLE_PAYERS_GO_TO_SELECT_PRACTICE,
    ONLY_ONE_PAYER_GO_TO_OVERVIEW,
    REQUEST_PASSWORD_RESET_SUCCESS,
    REQUEST_PASSWORD_RESET_ERROR,
    GET_RESET_CODE_SUCCESS,
    GO_TO_FORGOT_PASSWORD,
    REQUEST_LOGIN_ATTEMPT,
    GO_BACK_TO_LOGIN,
    REQUEST_PASSWORD_RESET_ATTEMPT,
    COMPLETE_PASSWORD_RESET_SUCCESS,
    COMPLETE_PASSWORD_RESET_ERROR,
    BACK_TO_LOGIN_FROM_RESET_SUCCESS,
    COMPLETE_PASSWORD_RESET_REQUEST,
    REGISTER_NEW_ACCOUNT_REQUEST,
    REGISTER_NEW_ACCOUNT_SUCCESS,
    REGISTER_NEW_ACCOUNT_ERROR,
    NO_PRACTICE_LOGO,
    LOGIN_USE_EXTERNAL_INVOICE,
    EXTERNAL_INVOICE_LOGIN_SUCCESS,
    CREATE_NEW_USER_FOR_EXTERNAL_INVOICE_SUCCESS,
    CREATE_NEW_USER_FOR_EXTERNAL_INVOICE_ERROR,
    REGISTER_NEW_ACCOUNT_SUCCESS_EXTERNAL_INVOICE,
    SELECT_PRACTICE_FROM_MULTIPLE_PRACTICES_SUCCESS,
    GET_LOCATION_LOGO_SUCCESS,
    GET_GUEST_USER_DETAILS_SUCCESS,
    GET_GUEST_USER_DETAILS_ERROR,
    GET_ACCOUNT_STATUS_SUCCESS,
    userLoginAttempt,
    requestPasswordReset,
    getResetCode,
    goToForgotPassword,
    goBackToLogin,
    completePasswordReset,
    completePasswordResetError,
    backToLoginFromResetSuccess,
    registerNewUserAccount,
    registerNewUserAccountError,
    loginUseExternalInvoiceStart,
    userLoginAttemptExternalInvoice,
    createNewUserForExternalInvoice,
    registerNewUserAccountExternalInvoice,
    selectPracticeFromMultiplePractices,
    getLocationLogo,
    getGuestUserDetails,
    getPortalAccountStatus,
}
