import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import {
    PatientOverviewActiveIcon,
    PatientOverviewInActiveIcon,
    PatientPaymentMethodsActiveIcon,
    PatientPaymentMethodsInActiveIcon,
    PatientPaymentPlanActiveIcon,
    PatientPaymentPlanInActiveIcon,
} from '../../icons/PaymentIcons'
import { changeActiveSection } from '../../reducers/header/header.action'
import { RootState } from '../../reducers/app/RootState'

import './SectionSelect.scss'

interface Props {
    activeSection: '/overview' | '/payment-methods' | '/payment-plans'
}

const SectionSelect = ({ activeSection }: Props) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { paymentPlanCards, paymentPlansLoading } = useSelector((state: RootState) => {
        return {
            paymentPlanCards: state.paymentPlan.paymentPlanCards,
            paymentPlansLoading: state.paymentPlan.loading,
        }
    })

    return (
        <div className="section-select">
            <div className="section-select-inner">
                <div
                    id="overviewSectionSelection"
                    className={`selection ${activeSection === '/overview' ? 'active' : ''}`}
                    onClick={() => {
                        dispatch(changeActiveSection({ activeSection: '/overview' }))
                        history.push('/overview')
                    }}
                >
                    <div className="icon-container">
                        {activeSection === '/overview' ? (
                            <PatientOverviewActiveIcon className={'section-icon'} />
                        ) : (
                            <PatientOverviewInActiveIcon className={'section-icon'} />
                        )}
                    </div>
                    <label htmlFor="overviewSectionSelection">Overview</label>
                    <div className="box"></div>
                </div>
                {!paymentPlansLoading && paymentPlanCards && paymentPlanCards.length > 0 && (
                    <div
                        id="paymentPlanSectionSelection"
                        className={`selection ${activeSection === '/payment-plans' ? 'active' : ''}`}
                        onClick={() => {
                            dispatch(changeActiveSection({ activeSection: '/payment-plans' }))
                            history.push('/payment-plans')
                        }}
                    >
                        <div className="icon-container">
                            {activeSection === '/payment-plans' ? (
                                <PatientPaymentPlanActiveIcon className={'section-icon'} />
                            ) : (
                                <PatientPaymentPlanInActiveIcon className={'section-icon'} />
                            )}
                        </div>
                        <label htmlFor="paymentPlanSectionSelection">Payment Plans</label>
                        <div className="box"></div>
                    </div>
                )}
                {!paymentPlansLoading && (
                    <div
                        id="paymentMethodSectionSelection"
                        className={`selection ${activeSection === '/payment-methods' ? 'active' : ''}`}
                        onClick={() => {
                            dispatch(changeActiveSection({ activeSection: '/payment-methods' }))
                            history.push('/payment-methods')
                        }}
                    >
                        <div className="icon-container">
                            {activeSection === '/payment-methods' ? (
                                <PatientPaymentMethodsActiveIcon className={'section-icon'} />
                            ) : (
                                <PatientPaymentMethodsInActiveIcon className={'section-icon'} />
                            )}
                        </div>
                        <label htmlFor="paymentMethodSectionSelection">Payment Methods</label>
                        <div className="box"></div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SectionSelect
