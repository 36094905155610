import React, { useEffect, useState } from 'react'
import { SFDialog, SFErrorDialogContent, SFLoading } from '@simplifeye/component-library'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../reducers/app/RootState'
import { stopDeletePaymentMethod, deletePaymentMethod } from '../../reducers/payment-methods/paymentMethods.action'
import './DeletePaymentMethodDialog.scss'

const DeletePaymentMethodDialog = () => {
    const {
        deleteDialog: { show, loading, cardMethodToDelete, achMethodToDelete, error },
    } = useSelector((state: RootState) => state.paymentMethods)
    const [content, setContent] = useState<React.ReactNode>(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (loading) {
            setContent(
                <div className="delete-loading">
                    <SFLoading />
                </div>
            )
        } else if (error.showError) {
            setContent(
                <div>
                    <SFErrorDialogContent title={error.title} message={error.message} />
                </div>
            )
        } else if (cardMethodToDelete !== null) {
            setContent(
                <div>
                    <div className="card-info">
                        <div className="row">
                            <div className="card-name">{`${cardMethodToDelete.cardType} ****${cardMethodToDelete.last4}`}</div>
                        </div>
                        <div className="row">
                            <div className="info">
                                <label htmlFor="nameOnCard">Name on card</label>
                                <div id="nameOnCard">{cardMethodToDelete.nameOnCard}</div>
                            </div>
                            <div className="info">
                                <label htmlFor="expDate">Exp Date</label>
                                <div id="expDate">{cardMethodToDelete.cardExpirationDate}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            setContent(
                <div>
                    <div className="card-info">
                        <div className="row">
                            <div className="card-name">{`${achMethodToDelete.bankName} ****${achMethodToDelete.accountLast4}`}</div>
                        </div>
                    </div>
                </div>
            )
        }
    }, [show, loading, cardMethodToDelete, achMethodToDelete, error])

    return (
        <SFDialog
            open={show}
            title={'Delete Method?'}
            content={content}
            onClose={() => {
                dispatch(stopDeletePaymentMethod())
            }}
            styleClass="delete-payment-method-dialog"
            footer={{
                footerButtons: {
                    twoButtons: {
                        leftButton: {
                            text: 'Cancel',
                            action: () => {
                                dispatch(stopDeletePaymentMethod())
                            },
                        },
                        rightButton: {
                            text: 'Delete',
                            action: () => {
                                dispatch(deletePaymentMethod({ cardMethodToDelete, achMethodToDelete }))
                            },
                            classes: ['delete-button'],
                        },
                    },
                },
            }}
        />
    )
}

export default DeletePaymentMethodDialog
