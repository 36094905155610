import { SessionState } from './session.state'

export const initialState: SessionState = {
    portalUserId: '',
    practiceId: '',
    locationId: '',
    patientId: '',
    payerId: '',
    practiceInfo: {
        practiceName: '',
        address: {
            addressStreet1: '',
            addressStreet2: '',
            unit: '',
            city: '',
            state: '',
            zip: '',
        },
        phoneNumber: '',
        practiceLogoURL: '',
    },
    isUserTimeout: false,
    logoUrl: '',
    logoBackgroundColor: '#ffffff',
}
