import {
    Action,
    USER_LOGIN_ERROR,
    MULTIPLE_PAYERS_GO_TO_SELECT_PRACTICE,
    ONLY_ONE_PAYER_GO_TO_OVERVIEW,
    REQUEST_PASSWORD_RESET_SUCCESS,
    REQUEST_PASSWORD_RESET_ERROR,
    GET_RESET_CODE_SUCCESS,
    GO_TO_FORGOT_PASSWORD,
    GO_BACK_TO_LOGIN,
    REQUEST_LOGIN_ATTEMPT,
    REQUEST_PASSWORD_RESET_ATTEMPT,
    COMPLETE_PASSWORD_RESET_SUCCESS,
    COMPLETE_PASSWORD_RESET_ERROR,
    BACK_TO_LOGIN_FROM_RESET_SUCCESS,
    COMPLETE_PASSWORD_RESET_REQUEST,
    REGISTER_NEW_ACCOUNT_REQUEST,
    REGISTER_NEW_ACCOUNT_SUCCESS,
    REGISTER_NEW_ACCOUNT_ERROR,
    NO_PRACTICE_LOGO,
    LOGIN_USE_EXTERNAL_INVOICE,
    EXTERNAL_INVOICE_LOGIN_SUCCESS,
    CREATE_NEW_USER_FOR_EXTERNAL_INVOICE_SUCCESS,
    CREATE_NEW_USER_FOR_EXTERNAL_INVOICE_ERROR,
    REGISTER_NEW_ACCOUNT_SUCCESS_EXTERNAL_INVOICE,
    SELECT_PRACTICE_FROM_MULTIPLE_PRACTICES_SUCCESS,
    GET_LOCATION_LOGO_SUCCESS,
    GET_GUEST_USER_DETAILS_SUCCESS,
    GET_GUEST_USER_DETAILS_ERROR,
    GET_ACCOUNT_STATUS_SUCCESS,
} from './login.action'
import { initialState } from './login.initial'
import { LoginState } from './login.state'

const loginReducer = (state: LoginState = initialState, action: Action): LoginState => {
    switch (action.type) {
        case USER_LOGIN_ERROR: {
            return {
                ...state,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
                isUserLoggedIn: false,
                isLoading: false,
            }
        }
        case MULTIPLE_PAYERS_GO_TO_SELECT_PRACTICE: {
            return {
                ...state,
                availablePayers: action.payload.availablePayers,
                skipSelectPayerScreen: false,
                isUserLoggedIn: true,
            }
        }
        case ONLY_ONE_PAYER_GO_TO_OVERVIEW: {
            return {
                ...state,
                isUserLoggedIn: true,
                skipSelectPayerScreen: true,
            }
        }
        case REQUEST_PASSWORD_RESET_SUCCESS: {
            return {
                ...state,
                isOnForgotPasswordSuccess: true,
            }
        }
        case REQUEST_PASSWORD_RESET_ERROR: {
            return {
                ...state,
                isLoading: false,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case GET_RESET_CODE_SUCCESS: {
            return {
                ...state,
                isOnForgotPasswordSuccess: true,
                code: action.payload.response.code,
                isLoading: false,
            }
        }
        case GO_TO_FORGOT_PASSWORD: {
            return {
                ...state,
                isOnForgotPassword: true,
                isLoading: false,
                error: {
                    showError: false,
                    title: '',
                    message: '',
                },
            }
        }
        case GO_BACK_TO_LOGIN: {
            return {
                ...state,
                isOnForgotPassword: false,
                isLoading: false,
                error: {
                    showError: false,
                    title: '',
                    message: '',
                },
            }
        }
        case REQUEST_LOGIN_ATTEMPT: {
            return {
                ...state,
                isLoading: true,
                error: {
                    showError: false,
                    title: '',
                    message: '',
                },
            }
        }
        case REQUEST_PASSWORD_RESET_ATTEMPT: {
            return {
                ...state,
                isLoading: true,
                error: {
                    showError: false,
                    title: '',
                    message: '',
                },
            }
        }
        case COMPLETE_PASSWORD_RESET_SUCCESS: {
            return {
                ...state,
                isOnForgotPasswordSuccess: true,
            }
        }
        case COMPLETE_PASSWORD_RESET_ERROR: {
            return {
                ...state,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case BACK_TO_LOGIN_FROM_RESET_SUCCESS: {
            return {
                ...state,
                goToLoginFromResetSuccess: true,
                isLoading: false,
            }
        }
        case COMPLETE_PASSWORD_RESET_REQUEST: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case REGISTER_NEW_ACCOUNT_REQUEST: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case REGISTER_NEW_ACCOUNT_SUCCESS: {
            return {
                ...state,
                goToLoginFromAccountCreation: true,
                isLoading: false,
            }
        }
        case REGISTER_NEW_ACCOUNT_SUCCESS_EXTERNAL_INVOICE: {
            return {
                ...state,
                goToLoginFromAccountCreation: true,
                isLoading: false,
                externalInvoice: {
                    ...state.externalInvoice,
                    inExternalInvoice: true,
                },
            }
        }
        case REGISTER_NEW_ACCOUNT_ERROR: {
            return {
                ...state,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
                isLoading: false,
            }
        }
        case GET_LOCATION_LOGO_SUCCESS: {
            return {
                ...state,
                logoUrl: action.payload.logoUrl,
                logoBackgroundColor: action.payload.logoBackgroundColor,
            }
        }
        case NO_PRACTICE_LOGO: {
            return {
                ...state,
                logoUrl: '',
            }
        }
        case LOGIN_USE_EXTERNAL_INVOICE: {
            return {
                ...state,
                logoUrl: action.payload.practiceLogoUrl,
                externalInvoice: {
                    practiceId: action.payload.practiceId,
                    locationId: action.payload.locationId,
                    inExternalInvoice: true,
                    createNewUserSuccess: false,
                    logoUrl: null,
                },
            }
        }
        case EXTERNAL_INVOICE_LOGIN_SUCCESS: {
            return {
                ...state,
                isUserLoggedIn: true,
            }
        }
        case CREATE_NEW_USER_FOR_EXTERNAL_INVOICE_SUCCESS: {
            return {
                ...state,
                externalInvoice: {
                    ...state.externalInvoice,
                    createNewUserSuccess: true,
                },
            }
        }
        case CREATE_NEW_USER_FOR_EXTERNAL_INVOICE_ERROR: {
            return {
                ...state,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }

        case SELECT_PRACTICE_FROM_MULTIPLE_PRACTICES_SUCCESS: {
            return {
                ...state,
                skipSelectPayerScreen: true,
            }
        }

        case GET_GUEST_USER_DETAILS_SUCCESS: {
            return {
                ...state,
                hasAccount: true,
                accountStatus: action.payload.accountStatus,
                enableGuestLink: action.payload.enableGuestLink,
            }
        }
        case GET_GUEST_USER_DETAILS_ERROR: {
            return {
                ...state,
                hasAccount: false,
                accountStatus: 'pending',
            }
        }
        case GET_ACCOUNT_STATUS_SUCCESS: {
            return {
                ...state,
                accountStatus: action.payload.accountStatus,
            }
        }
        default:
            return { ...state }
    }
}

export default loginReducer
