import React from 'react'
import { Button } from '@material-ui/core'
import { OneTapPayButtonWithElements as OneTapPayButton } from '../../shared/components/one-tap-pay-button/OneTapPayButton'
import { formatCurrencyCents } from '../../shared/utils'
import './BillingTotalsSection.scss'
import { useDispatch } from 'react-redux'
import { PaymentRequestPaymentMethodEvent, Stripe } from '@stripe/stripe-js'
import { InvoicePaymentMethods } from '../../types/Constants'
import SubmitPaymentEnterInfoButton from '../../shared/components/submit-payment-enter-info-button/SubmitPaymentEnterInfoButton'

export interface BaseBillingTotalsProps {
    subtotal: number
    taxSubtotal: number
    paymentDue: number
    canSubmitReview: boolean
    isNewMethod?: boolean
    selectedMethod?: InvoicePaymentMethods
    isZeroOrMixedAccount?: boolean
}
// items will be displayed in invoice on onetappay provider (Apple/Google/BrowserApi)
type DisplayBillingItem = {
    label: string
    amount: number
}

export interface OneTapPaymentBillingTotalsProps extends BaseBillingTotalsProps {
    useOneTapPay: true
    displayItems: Array<DisplayBillingItem>
    onOneTapPaymentSubmit: (event: PaymentRequestPaymentMethodEvent, stripe: Stripe) => void
    onReviewPaymentSubmit?: undefined
}

export interface ClassicPaymentBillingTotalsProps extends BaseBillingTotalsProps {
    onReviewPaymentSubmit: () => () => Promise<void>
    useOneTapPay?: false
    displayItems?: undefined
    onOneTapPaymentSubmit?: undefined
}
type Props = OneTapPaymentBillingTotalsProps | ClassicPaymentBillingTotalsProps

const BillingTotalsSection = ({
    subtotal,
    taxSubtotal,
    paymentDue,
    canSubmitReview,
    selectedMethod,
    onReviewPaymentSubmit,
    onOneTapPaymentSubmit,
    useOneTapPay = false,
    displayItems = [],
    isNewMethod = false,
    isZeroOrMixedAccount = false,
}: Props) => {
    const dispatch = useDispatch()
    const taxable = taxSubtotal > 0
    const regularPrice = selectedMethod === InvoicePaymentMethods.CARD && isNewMethod && isZeroOrMixedAccount

    // render fn
    const renderPayButton = () => {
        if (useOneTapPay) {
            return (
                <div className="tap-payment-wrapper">
                    <OneTapPayButton
                        className="tap-payment-btn"
                        requestOptions={{
                            total: {
                                label: 'Payment Due',
                                amount: paymentDue,
                            },
                            displayItems,
                        }}
                        onPaymentMethod={onOneTapPaymentSubmit}
                    />
                </div>
            )
        }

        return (
            <SubmitPaymentEnterInfoButton
                onClick={async () => {
                    await dispatch(onReviewPaymentSubmit())
                }}
                disabled={!canSubmitReview}
                id="reviewPaymentButton"
                selectedMethod={selectedMethod}
            />
        )
    }

    return (
        <div className="billing-totals-section">
            <div className="cost-summary-wrapper">
                <div className="cost-summary treatment-cost">
                    {regularPrice ? <h4>Regular Price (Credit)</h4> : <h4>Subtotal</h4>}
                    <span>{formatCurrencyCents(subtotal)}</span>
                </div>
                {taxable && (
                    <div className="cost-summary treatment-cost">
                        <h4>Sales Tax</h4>
                        <span>{formatCurrencyCents(taxSubtotal)}</span>
                    </div>
                )}
                <div className="cost-summary payment-due">
                    <h4>Payment Due</h4>
                    <span>{formatCurrencyCents(paymentDue)}</span>
                </div>
            </div>
            {renderPayButton()}
        </div>
    )
}

export default BillingTotalsSection
