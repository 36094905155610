import { Dispatch } from 'react'
import { Stripe, PaymentRequestEvent } from '@stripe/stripe-js'
import Api from '../../api/Api'
import { PaymentIntentResponse } from '../../types/Response/PaymentIntentResponse'
import { InvoiceFee } from '../../types/InvoiceFee'
import { InvoiceDetailsResponse } from '../../types/Response/InvoiceDetailsResponse'
import { UpdateChargeEventPaymentResponse } from '../../types/Response/UpdateChargeEventPaymentResponse'
import { apiResponseContainErrorCode, promiseTo } from '../../shared/utils'
import { PayerPaymentMethods } from '../../types/Response/PayerPaymentMethods'
import { RootState } from '../app/RootState'
import { getPaymentMethods } from '../payment-methods/paymentMethods.action'
import { InvoicePaymentMethods } from '../../types/Constants'
import { AchPaymentMethod } from '../../types/AchPaymentMethod'
import { CreateAchChargeForPortalResponse } from '../../types/Response/CreateAchChargeForPortalResponse'
import { selectPracticeFromSession } from '../session/session.actions'
import { onStripeManualCheckoutSuccess } from '../stripe-manual-checkout-dialog/stripeManualCheckoutDialog.actions'

const types = {
    REQUEST_CREATE_PAYMENT_INTENT: '[INVOICE DETAILS] REQUEST CREATE PAYMENT INTENT',
    REQUEST_CREATE_PAYMENT_INTENT_ERROR: '[INVOICE DETAILS] REQUEST CREATE PAYMENT INTENT ERROR',
    REQUEST_CREATE_PAYMENT_INTENT_SUCCESS: '[INVOICE DETAILS] REQUEST CREATE PAYMENT INTENT SUCCESS',
    UPDATE_SELECTED_PAYMENT_METHOD: '[INVOICE DETAILS] UPDATE SELECTED PAYMENT METHOD',
    REQUEST_PATIENT_INVOICE_DETAILS: '[INVOICE DETAILS] REQUEST PATIENT INVOICE DETAILS',
    REQUEST_PATIENT_INVOICE_DETAILS_SUCCESS: '[INVOICE DETAILS] REQUEST PATIENT INVOICE DETAILS SUCCESS',
    REQUEST_PATIENT_INVOICE_DETAILS_ERROR: '[INVOICE DETAILS] REQUEST PATIENT INVOICE DETAILS ERROR',
    REQUEST_PRACTICE_ADDRESS: '[INVOICE DETAILS REQUEST PRACTICE ADDRESS',
    REQUEST_PRACTICE_ADDRESS_ERROR: '[INVOICE DETAILS REQUEST PRACTICE ADDRESS ERROR',
    REQUEST_PRACTICE_FEE_AND_TAX: '[INVOICE DETAILS] REQUEST PRACTICE FEE AND TAX',
    REQUEST_PRACTICE_FEE_AND_TAX_SUCCESS: '[INVOICE DETAILS] REQUEST PRACTICE FEE AND TAX SUCCESS',
    REQUEST_PRACTICE_FEE_AND_TAX_ERROR: '[INVOICE DETAILS] REQUEST PRACTICE FEE AND TAX ERROR',
    REQUEST_GET_FEES_FOR_INVOICE: '[INVOICE DETAILS] REQUEST GET GEES FOR INVOICE',
    REQUEST_GET_FEES_FOR_INVOICE_SUCCESS: '[INVOICE DETAILS] REQUEST GET GEES FOR INVOICE SUCCESS',
    REQUEST_GET_FEES_FOR_INVOICE_ERROR: '[INVOICE DETAILS] REQUEST GET GEES FOR INVOICE ERROR',
    REQUEST_DATA_FOR_INVOICE: '[INVOICE DETAILS] REQUEST DATA FOR INVOICE',
    REQUEST_DATA_FOR_INVOICE_ERROR: '[INVOICE DETAILS] REQUEST DATA FOR INVOICE ERROR',
    SET_UP_BILLING_TOTALS_FOR_INVOICE: '[INVOICE DETAILS] SET_UP_BILLING_TOTALS_FOR_INVOICE',
    REQUEST_UPDATE_CHARGE_EVENT_SELECTED_PAYMENT: '[INVOICE DETAILS] REQUEST UPDATE CHARGE EVENT SELECTED PAYMENT',
    UPDATE_CHARGE_EVENT_SELECTED_PAYMENT_SUCCESS:
        '[INVOICE DETAILS] REQUEST UPDATE CHARGE EVENT SELECTED PAYMENT SUCCESS',
    UPDATE_CHARGE_EVENT_SELECTED_PAYMENT_ERROR: '[INVOICE DETAILS] REQUEST UPDATE CHARGE EVENT SELECTED PAYMENT ERROR',
    OPEN_INVOICE_DIALOG: '[INVOICE DETAILS] OPEN INVOICE DIALOG',
    STRIPE_CARD_INVOICE_PAYMENT_SUCCESS: '[INVOICE DETAILS] STRIPE CARD INVOICE PAYMENT SUCCESS',
    STRIPE_CARD_INVOICE_PAYMENT_FAILURE: '[INVOICE DETAILS] STRIPE CARD INVOICE PAYMENT FAILURE',
    ON_EXISTING_PAYMENT_METHOD_SELECT: '[INVOICE DETAILS] ON EXISTING PAYMENT METHOD SELECT',
    ON_PAY_INVOICE_USE_EXISTING_PAYMENT_METHOD: '[INVOICE DETAILS] ON PAY INVOICE USE EXISTING PAYMENT METHOD',
    REQUEST_SUBMIT_INVOICE_USE_EXISTING_METHOD: '[INVOICE DETAILS] REQUEST SUBMIT INVOICE USE EXISTING METHOD',
    SUBMIT_INVOICE_USE_EXISTING_METHOD_SUCCESS: '[INVOICE DETAILS] SUBMIT INVOICE USE EXISTING METHOD SUCCESS',
    SUBMIT_INVOICE_USE_EXISTING_METHOD_ERROR: '[INVOICE DETAILS] SUBMIT INVOICE USE EXISTING METHOD ERROR',
    ON_PAY_INVOICE_USE_EXISTING_CANCEL: '[INVOICE DETAILS] ON PAY INVOICE USE EXISTING CANCEL',
    ON_INVOICE_DIALOG_ERROR_CANCEL: '[INVOICE DETAILS] ON INVOICE DIALOG ERROR CANCEL',
    ON_INVOICE_DIALOG_SUCCESS_BACK_TO_HOME: '[INVOICE DETAILS] ON INVOICE DIALOG SUCCESS BACK TO HOME',
    STRIPE_CARD_INVOICE_PAYMENT_CANCEL: '[INVOICE DETAILS] STRIPE CARD INVOICE PAYMENT CANCEL',
    CREATE_PLAID_TOKEN_SUCCESS: '[INVOICE DETAILS] CREATE PLAID TOKEN SUCCESS',
    CHECK_USER_USE_EXISTING_ACH: '[INVOICE DETAILS] CHECK USER_USE_EXISTING_ACH',
    REQUEST_GET_PLAID_ACCOUNT_BALANCES: '[INVOICE DETAILS] REQUEST GET PLAID ACCOUNT BALANCES',
    PLAID_FLOW_ERROR: '[INVOICE DETAILS] PLAID FLOW ERROR',
    CANCEL_PLAID_FLOW: '[INVOICE DETAILS] CANCEL ACH FLOW',
    REQUEST_APPLY_SELECTED_ACH_TO_INVOICE: '[INVOICE DETAILS] REQUEST APPLY SELECTED ACH TO INVOICE',
    APPLY_SELECTED_ACH_TO_INVOICE_SUCCESS: '[INVOICE DETAILS] APPLY SELECTED ACH TO INVOICE SUCCESS',
    ON_ACH_BACK_TO_HOME: '[INVOICE DETAILS] ON ACH SUCCESS BACK TO HOME',
    REQUEST_USE_EXISTING_ACH_ON_INVOICE: '[INVOICE DETAILS] REQUEST USE EXISTING ACH ON INVOICE',
    ON_USE_EXISTING_PAYMENT_ACH_SELECT: '[INVOICE DETAILS] ON USE EXISTING PAYMENT ACH SELECT',
    ON_CALCULATE_FEES_SUCCESS: '[INVOICE DETAILS] ON CALCULATE FEES SUCCESS',
}

const requestCreatePaymentIntent = (payload: { practiceId: string; chargeEventId: string }) => {
    return {
        type: types.REQUEST_CREATE_PAYMENT_INTENT,
        payload,
    }
}

const requestCreatePaymentIntentError = (payload: { title: string; message: string; messageJson: string }) => {
    return {
        type: types.REQUEST_CREATE_PAYMENT_INTENT_ERROR,
        payload,
    }
}

const requestCreatePaymentIntentSuccess = (payload: PaymentIntentResponse) => {
    return {
        type: types.REQUEST_CREATE_PAYMENT_INTENT_SUCCESS,
        payload,
    }
}

const requestCaculateFeesSuccess = (payload: any) => {
    return {
        type: types.ON_CALCULATE_FEES_SUCCESS,
        payload,
    }
}

const updatePaymentMethodSelect = (paymentMethod: string) => {
    return {
        type: types.UPDATE_SELECTED_PAYMENT_METHOD,
        paymentMethod,
    }
}

const paymentMethodSelect = (paymentMethod: string) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            invoiceDetails: { chargeEventId, invoiceDetailsResponse },
        } = getState()

        try {
            if (paymentMethod.trim() === '') {
                return dispatch(updatePaymentMethodSelect(paymentMethod))
            }
            if (invoiceDetailsResponse) {
                const { data } = await Api.calculateFeesForGivenSubtotal(
                    invoiceDetailsResponse.practiceId,
                    invoiceDetailsResponse.locationId,
                    paymentMethod === 'otp' ? 'card' : paymentMethod,
                    [...invoiceDetailsResponse.procedureDescriptions.map(procedure => procedure.amount)],
                    'invoice',
                    [...invoiceDetailsResponse.procedureDescriptions.map(procedure => procedure.isTaxable)]
                )
                // dispatch action to update totals and selected method
                dispatch(requestCaculateFeesSuccess(data))
                dispatch(updatePaymentMethodSelect(paymentMethod))
            }
        } catch (err) {
            const error = {
                title: 'Invoice Fees Error',
                message: `Error getting fee's for invoiceId: ${chargeEventId}`,
            }
            dispatch(requestGetFeesForInvoiceError(error))
            throw error
        }
    }
}

const requestInvoiceDetails = (payload: any) => {
    return {
        type: types.REQUEST_PATIENT_INVOICE_DETAILS,
        payload,
    }
}

const receiveInvoiceDetailsSuccess = (payload: {
    invoiceDetailsResponse: InvoiceDetailsResponse
    chargeEventId: string
}) => {
    return {
        type: types.REQUEST_PATIENT_INVOICE_DETAILS_SUCCESS,
        payload,
    }
}

const receiveInvoiceDetailsError = (payload: { title: string; message: string }) => {
    return {
        type: types.REQUEST_PATIENT_INVOICE_DETAILS_ERROR,
        payload,
    }
}

const getInvoiceDetails = (practiceId: string, chargeEventId: string) => {
    return async (dispatch: any) => {
        dispatch(requestInvoiceDetails({ chargeEventId, practiceId }))
        try {
            const { data } = await Api.getInvoiceDetails(practiceId, chargeEventId)
            if (!data) {
                throw Error()
            }
            return dispatch(receiveInvoiceDetailsSuccess({ invoiceDetailsResponse: data, chargeEventId }))
        } catch (err) {
            const error = {
                title: 'Get Invoice Details Error',
                message: `Couldn't load data for currentPractice: ${practiceId} and invoiceId: ${chargeEventId}`,
            }
            dispatch(receiveInvoiceDetailsError(error))
            throw error
        }
    }
}

const getInvoiceDetailsGuest = (chargeEventId: string) => {
    return async (dispatch: any) => {
        dispatch(requestInvoiceDetails({ chargeEventId }))
        try {
            const { data } = await Api.getInvoiceDetailsGuest(chargeEventId)
            if (!data) {
                throw Error()
            }
            return dispatch(receiveInvoiceDetailsSuccess({ invoiceDetailsResponse: data, chargeEventId }))
        } catch (err) {
            const error = {
                title: 'Get Invoice Details Error',
                message: `Couldn't load data for invoiceId: ${chargeEventId}`,
            }
            dispatch(receiveInvoiceDetailsError(error))
            throw error
        }
    }
}

const getAddressForLocation = (practiceId: string, locationId: string) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(requestGetAddressForLocation(practiceId, locationId))
        try {
            const { data } = await Api.getLocationInfo(practiceId, locationId)
            if (!data) {
                throw Error()
            }
            return dispatch(selectPracticeFromSession({ selectedPractice: data.locations[0] }))
        } catch (err) {
            const error = {
                title: 'Get Practice Details Error',
                message: `Couldn't load data for practiceId: ${practiceId}`,
            }
            dispatch(requestGetAddressForLocationError(error))
            throw error
        }
    }
}

const requestGetAddressForLocation = (practiceId: string, locationId: string) => {
    return {
        type: types.REQUEST_PRACTICE_ADDRESS,
    }
}

const requestGetAddressForLocationError = (payload: { title: string; message: string }) => {
    return {
        type: types.REQUEST_PRACTICE_ADDRESS_ERROR,
        payload,
    }
}

const getFeesForInvoice = (chargeEventId: string) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(requestGetFeesForInvoice(chargeEventId))
        try {
            const {
                data: {
                    invoiceFees: [creditFees, debitFees, achFees],
                },
            } = await Api.getFeesForInvoice(chargeEventId)
            dispatch(requestGetFeesForInvoiceSuccess({ creditFees, debitFees, achFees }))
        } catch (err) {
            const error = {
                title: 'Invoice Credit/Debit Fees Error',
                message: `Error getting fee's for invoiceId: ${chargeEventId}`,
            }
            dispatch(requestGetFeesForInvoiceError(error))
            throw error
        }
    }
}

const requestGetFeesForInvoice = (chargeEventId: string) => {
    return {
        type: types.REQUEST_GET_FEES_FOR_INVOICE,
    }
}

const requestGetFeesForInvoiceSuccess = (payload: {
    creditFees: InvoiceFee
    debitFees: InvoiceFee
    // prepaidFees: InvoiceFee // these values are never used, so scrapped adding prepaid
    achFees: InvoiceFee
}) => {
    return {
        type: types.REQUEST_GET_FEES_FOR_INVOICE_SUCCESS,
        payload,
    }
}

const requestGetFeesForInvoiceError = (payload: { title: string; message: string }) => {
    return {
        type: types.REQUEST_GET_FEES_FOR_INVOICE_ERROR,
        payload,
    }
}

const dataForInvoiceDetails = (chargeEventId: string) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { practiceId },
        } = getState()

        dispatch(requestDataForInvoiceDetails(practiceId, chargeEventId))
        Promise.all([
            dispatch(getInvoiceDetails(practiceId, chargeEventId)),
            dispatch(getFeesForInvoice(chargeEventId)),
            dispatch(getPaymentMethods()),
        ])
            .then(() => {
                return dispatch(setUpBillingTotalsForInvoice())
            })
            .catch((err: { title: string; message: string }) => {
                return dispatch(requestDataForInvoiceDetailsError(err))
            })
    }
}

const dataForInvoiceDetailsGuest = (chargeEventId: string) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(requestDataForInvoiceDetailsGuest(chargeEventId))
        Promise.all([dispatch(getInvoiceDetailsGuest(chargeEventId)), dispatch(getFeesForInvoice(chargeEventId))])
            .then(() => {
                return dispatch(setUpBillingTotalsForInvoice())
            })
            .catch((err: { title: string; message: string }) => {
                return dispatch(requestDataForInvoiceDetailsError(err))
            })
    }
}

const requestDataForInvoiceDetailsGuest = (chargeEventId: string): any => {
    return {
        type: types.REQUEST_DATA_FOR_INVOICE,
    }
}

const requestDataForInvoiceDetails = (currentPracticeId: string, chargeEventId: string): any => {
    return {
        type: types.REQUEST_DATA_FOR_INVOICE,
    }
}

const requestDataForInvoiceDetailsError = (payload: { title: string; message: string }) => {
    return {
        type: types.REQUEST_DATA_FOR_INVOICE_ERROR,
        payload,
    }
}

const setUpBillingTotalsForInvoice = () => {
    return {
        type: types.SET_UP_BILLING_TOTALS_FOR_INVOICE,
    }
}

const onOneTapPayInvoice = (
    paymentMethodId: string,
    typeofCard: string,
    onCompletePaymentEvent: PaymentRequestEvent['complete'],
    stripe: Stripe
) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            invoiceDetails: {
                invoiceDetailsResponse: { transactionId: chargeEventId },
            },
            session: { practiceId },
        } = getState()

        dispatch(requestUpdateChargeEventSelectedPayment())

        const { response: updatePaymentResponse, errors: updatePaymentErrors } = await promiseTo<
            UpdateChargeEventPaymentResponse
        >(Api.updateChargeEventSelectedPaymentMethod(chargeEventId, typeofCard))
        if (updatePaymentErrors) {
            const error = {
                title: 'Update Charge Event',
                message: `Error updating charge event payment method to ${typeofCard} for invoiceId: ${chargeEventId}`,
            }
            return dispatch(updateChargeEventSelectedPaymentError(error))
        }

        dispatch(updateChargeEventSelectedPaymentSuccess(updatePaymentResponse.data))

        dispatch(requestCreatePaymentIntent({ practiceId, chargeEventId }))

        const { response: createPaymentIntentResponse, errors: createPaymentIntentErrors } = await promiseTo<
            PaymentIntentResponse
        >(Api.createPaymentIntent(practiceId, chargeEventId))

        if (createPaymentIntentErrors) {
            const error = {
                title: 'Creating Payment Intent',
                message: `Error creating payment intent for practiceId: ${practiceId} and invoiceId: ${chargeEventId}`,
                messageJson: createPaymentIntentErrors,
            }
            return dispatch(requestCreatePaymentIntentError(error))
        }

        const { clientSecret } = createPaymentIntentResponse.data

        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethodId,
        })

        if (paymentIntent) {
            dispatch(stripeCardInvoicePaymentSuccess())
            dispatch(onStripeManualCheckoutSuccess())
            onCompletePaymentEvent('success')
        } else {
            Api.changeChargeEventStatusToDefault(chargeEventId)
            const stripeErr = !!error && !!error.message ? error.message : 'Error submitting payment.'
            dispatch(
                stripeCardInvoicePaymentFailure({
                    message: stripeErr,
                    messageJson: '',
                })
            )
            onCompletePaymentEvent('fail')
        }

        dispatch(showPaymentDialog())

        return dispatch(requestCreatePaymentIntentSuccess(createPaymentIntentResponse.data))
    }
}

const onPayInvoice = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            invoiceDetails: {
                invoiceDetailsResponse: { transactionId: chargeEventId },
                selectedPaymentMethod,
                isAddingNewPaymentMethod,
            },
            session: { practiceId },
        } = getState()

        dispatch(requestUpdateChargeEventSelectedPayment())

        const { response: updatePaymentResponse, errors: updatePaymentErrors } = await promiseTo<
            UpdateChargeEventPaymentResponse
        >(Api.updateChargeEventSelectedPaymentMethod(chargeEventId, selectedPaymentMethod))
        if (updatePaymentErrors) {
            const error = {
                title: 'Update Charge Event',
                message: `Error updating charge event payment method to ${selectedPaymentMethod} for invoiceId: ${chargeEventId}`,
            }
            return dispatch(updateChargeEventSelectedPaymentError(error))
        }

        dispatch(updateChargeEventSelectedPaymentSuccess(updatePaymentResponse.data))

        if (!isAddingNewPaymentMethod) {
            dispatch(onPayInvoiceUseExistingPaymentMethod())
        } else {
            if (selectedPaymentMethod === InvoicePaymentMethods.ACH) {
                dispatch(onPayInvoiceAddNewUseAch())
            } else {
                await dispatch(onPayInvoiceAddNewPaymentMethod(practiceId, chargeEventId))
            }
        }

        if (isAddingNewPaymentMethod && selectedPaymentMethod !== InvoicePaymentMethods.ACH) {
            dispatch(showPaymentDialog())
        }
    }
}

const updateChargeEventSelectedPaymentSuccess = (payload: UpdateChargeEventPaymentResponse) => {
    return {
        type: types.UPDATE_CHARGE_EVENT_SELECTED_PAYMENT_SUCCESS,
    }
}
const updateChargeEventSelectedPaymentError = (payload: { title: string; message: string }) => {
    return {
        type: types.UPDATE_CHARGE_EVENT_SELECTED_PAYMENT_ERROR,
        payload,
    }
}
const requestUpdateChargeEventSelectedPayment = () => {
    return {
        type: types.REQUEST_UPDATE_CHARGE_EVENT_SELECTED_PAYMENT,
    }
}

const showPaymentDialog = () => {
    return {
        type: types.OPEN_INVOICE_DIALOG,
    }
}

const stripeCardInvoicePaymentSuccess = () => {
    return {
        type: types.STRIPE_CARD_INVOICE_PAYMENT_SUCCESS,
    }
}

const stripeCardInvoicePaymentFailure = (payload: { message: string; messageJson: string }) => {
    return {
        type: types.STRIPE_CARD_INVOICE_PAYMENT_FAILURE,
        payload: { title: 'Error Submitting Payment', message: payload.message, messageJson: payload.messageJson },
    }
}

const onUpdateExistingPaymentSelect = (
    paymentMethod: string,
    isExistingPayment: boolean,
    savedPaymentMethod?: PayerPaymentMethods
) => {
    return {
        type: types.ON_EXISTING_PAYMENT_METHOD_SELECT,
        payload: {
            paymentMethod,
            isExistingPayment,
            savedPaymentMethod,
        },
    }
}

const onUseExistingPaymentSelect = (
    paymentMethod: string,
    isExistingPayment: boolean,
    savedPaymentMethod?: PayerPaymentMethods
) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            invoiceDetails: { chargeEventId, invoiceDetailsResponse },
        } = getState()

        try {
            if (paymentMethod.trim() === '') {
                return dispatch(onUpdateExistingPaymentSelect(paymentMethod, isExistingPayment, savedPaymentMethod))
            }
            if (invoiceDetailsResponse) {
                const { data } = await Api.calculateFeesForGivenSubtotal(
                    invoiceDetailsResponse.practiceId,
                    invoiceDetailsResponse.locationId,
                    paymentMethod === 'otp' ? 'debit' : paymentMethod,
                    [...invoiceDetailsResponse.procedureDescriptions.map(procedure => procedure.amount)],
                    'invoice',
                    [...invoiceDetailsResponse.procedureDescriptions.map(procedure => procedure.isTaxable)]
                )
                // dispatch action to update totals and selected method
                dispatch(requestCaculateFeesSuccess(data))
                dispatch(onUpdateExistingPaymentSelect(paymentMethod, isExistingPayment, savedPaymentMethod))
            }
        } catch (err) {
            const error = {
                title: 'Invoice Fees Error',
                message: `Error getting fee's for invoiceId: ${chargeEventId}`,
            }
            dispatch(requestGetFeesForInvoiceError(error))
            throw error
        }
    }
}

const onUpdateExistingPaymentAchSelect = () => {
    return {
        type: types.ON_USE_EXISTING_PAYMENT_ACH_SELECT,
    }
}

const onUseExistingPaymentAchSelect = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            invoiceDetails: { chargeEventId, invoiceDetailsResponse },
        } = getState()

        try {
            if (invoiceDetailsResponse) {
                const { data } = await Api.calculateFeesForGivenSubtotal(
                    invoiceDetailsResponse.practiceId,
                    invoiceDetailsResponse.locationId,
                    'ach',
                    [...invoiceDetailsResponse.procedureDescriptions.map(procedure => procedure.amount)],
                    'invoice',
                    [...invoiceDetailsResponse.procedureDescriptions.map(procedure => procedure.isTaxable)]
                )
                // dispatch action to update totals and selected method
                dispatch(requestCaculateFeesSuccess(data))
                dispatch(onUpdateExistingPaymentAchSelect())
            }
        } catch (err) {
            const error = {
                title: 'Invoice Fees Error',
                message: `Error getting fee's for invoiceId: ${chargeEventId}`,
            }
            dispatch(requestGetFeesForInvoiceError(error))
            throw error
        }
    }
}

const onPayInvoiceAddNewPaymentMethod = (currentPracticeId: any, chargeEventId: any) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(requestCreatePaymentIntent({ practiceId: currentPracticeId, chargeEventId }))

        const { response: createPaymentIntentResponse, errors: createPaymentIntentErrors } = await promiseTo<
            PaymentIntentResponse
        >(Api.createPaymentIntent(currentPracticeId, chargeEventId))

        if (createPaymentIntentErrors) {
            const error = {
                title: 'Creating Payment Intent',
                message: `Error creating payment intent for practiceId: ${currentPracticeId} and invoiceId: ${chargeEventId}`,
                messageJson: createPaymentIntentErrors,
            }
            return await dispatch(requestCreatePaymentIntentError(error))
        }

        return await dispatch(requestCreatePaymentIntentSuccess(createPaymentIntentResponse.data))
    }
}

const onPayInvoiceUseExistingPaymentMethod = () => {
    return {
        type: types.ON_PAY_INVOICE_USE_EXISTING_PAYMENT_METHOD,
    }
}

const submitInvoiceUseExistingMethod = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            invoiceDetails: {
                isUsingSavedAchMethod,
                invoiceDetailsResponse: { transactionId: chargeEventId },
            },
        } = getState()

        dispatch(requestSubmitInvoiceUseExistingMethod())
        try {
            dispatch(changeChargeEventStatusToPending({ chargeEventId }))
            if (isUsingSavedAchMethod) {
                return dispatch(submitInvoiceUseExistingMethodAch())
            } else {
                return dispatch(submitInvoiceUseExistingMethodCard())
            }
        } catch (err) {
            return dispatch(
                submitInvoiceUseExistingMethodError({
                    title: 'Error Submitting Payment',
                    message: 'Error changing charge event status to pending',
                    messageJson: err,
                })
            )
        }
    }
}

const submitInvoiceUseExistingMethodCard = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            invoiceDetails: {
                invoiceDetailsResponse: { transactionId: chargeEventId },
                selectedSavedPaymentMethod: { tokenId },
            },
            session: { practiceId },
        } = getState()
        try {
            // eslint-disable-next-line
            const { data } = await Api.payInvoiceUseExistingPaymentMethod(practiceId, chargeEventId, tokenId)
            dispatch(onStripeManualCheckoutSuccess())
            return dispatch(submitInvoiceUseExistingMethodSuccess())
        } catch (err) {
            return dispatch(
                submitInvoiceUseExistingMethodError({
                    title: 'Error Submitting Payment',
                    message: `Error creating payment.`,
                    messageJson: err,
                })
            )
        }
    }
}

const submitInvoiceUseExistingMethodAch = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            paymentMethods: { achPaymentMethod },
            invoiceDetails: {
                invoiceDetailsResponse: { transactionId: chargeEventId },
            },
        } = getState()
        try {
            const { errors } = await promiseTo<CreateAchChargeForPortalResponse>(
                Api.createAchChargeForPortal(chargeEventId, achPaymentMethod.customerId, achPaymentMethod.achId)
            )

            if (!errors) {
                return dispatch(submitInvoiceUseExistingMethodSuccess())
            } else {
                if (apiResponseContainErrorCode(errors, 428)) {
                    await Api.changeChargeEventStatusToDefault(chargeEventId)
                    return dispatch(
                        submitInvoiceUseExistingMethodError({
                            title: 'Insufficient funds',
                            message:
                                'This account lacks the funds required to complete the transfer. Please select a different payment method',
                            messageJson: '',
                        })
                    )
                } else {
                    throw errors
                }
            }
        } catch (err) {
            return dispatch(
                submitInvoiceUseExistingMethodError({
                    title: 'Error Submitting Payment',
                    message: 'Error using existing ACH method to make payment',
                    messageJson: err,
                })
            )
        }
    }
}

const requestSubmitInvoiceUseExistingMethod = () => {
    return {
        type: types.REQUEST_SUBMIT_INVOICE_USE_EXISTING_METHOD,
    }
}

const submitInvoiceUseExistingMethodSuccess = () => {
    return {
        type: types.SUBMIT_INVOICE_USE_EXISTING_METHOD_SUCCESS,
    }
}

const submitInvoiceUseExistingMethodError = (payload: { title: string; message: string; messageJson: any }) => {
    return {
        type: types.SUBMIT_INVOICE_USE_EXISTING_METHOD_ERROR,
        payload,
    }
}

const onPayInvoiceUseExistingCancel = () => {
    return {
        type: types.ON_PAY_INVOICE_USE_EXISTING_CANCEL,
    }
}

const onInvoiceDialogErrorCancel = () => {
    return {
        type: types.ON_INVOICE_DIALOG_ERROR_CANCEL,
    }
}

const onInvoiceDialogSuccessBackToHome = () => {
    return {
        type: types.ON_INVOICE_DIALOG_SUCCESS_BACK_TO_HOME,
    }
}

const onStripeManualCheckoutCancel = () => {
    return {
        type: types.STRIPE_CARD_INVOICE_PAYMENT_CANCEL,
    }
}

const changeChargeEventStatusToPending = (payload: { chargeEventId: string }) => {
    return async () => {
        try {
            await Api.changeChargeEventStatusToPending(payload.chargeEventId)
        } catch (err) {
            throw err
        }
    }
}

const onPayInvoiceAddNewUseAch = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            paymentMethods: { achPaymentMethod },
        } = getState()

        if (achPaymentMethod) {
            return dispatch(checkUserUseExistingAch())
        } else {
            return dispatch(requestPlaidLinkToken())
        }
    }
}

const requestPlaidLinkToken = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId, practiceId },
        } = getState()

        try {
            const { data } = await Api.createPlaidLinkToken(practiceId, payerId)
            return dispatch(createPlaidTokenSuccess({ linkToken: data.linkToken }))
        } catch (err) {
            return dispatch(
                plaidFlowError({
                    title: 'Bank Transfer Error',
                    message: `Unable to create a plaid link token for plaid for payerId: ${payerId}`,
                    messageJson: err.toString(),
                })
            )
        }
    }
}

const createPlaidTokenSuccess = (payload: { linkToken: string }) => {
    return {
        type: types.CREATE_PLAID_TOKEN_SUCCESS,
        payload,
    }
}

const plaidFlowError = (payload: { title: string; message: string; messageJson: string }) => {
    return {
        type: types.PLAID_FLOW_ERROR,
        payload,
    }
}

const checkUserUseExistingAch = () => {
    return {
        type: types.CHECK_USER_USE_EXISTING_ACH,
    }
}

const cancelPlaidFlow = () => {
    return {
        type: types.CANCEL_PLAID_FLOW,
    }
}

const requestApplySelectedAchToInvoice = () => {
    return {
        type: types.REQUEST_APPLY_SELECTED_ACH_TO_INVOICE,
    }
}

const applySelectedAchToInvoiceSuccess = () => {
    return {
        type: types.APPLY_SELECTED_ACH_TO_INVOICE_SUCCESS,
    }
}

const onAchSuccessBackToHome = () => {
    return {
        type: types.ON_ACH_BACK_TO_HOME,
    }
}

const applyAchToInvoice = (payload: { customerId: string; achId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            invoiceDetails: { chargeEventId },
        } = getState()

        try {
            dispatch(changeChargeEventStatusToPending({ chargeEventId }))

            const { errors } = await promiseTo<CreateAchChargeForPortalResponse>(
                Api.createAchChargeForPortal(chargeEventId, payload.customerId, payload.achId)
            )

            if (!errors) {
                return dispatch(applySelectedAchToInvoiceSuccess())
            } else {
                if (apiResponseContainErrorCode(errors, 428)) {
                    await Api.changeChargeEventStatusToDefault(chargeEventId)
                    return dispatch(
                        plaidFlowError({
                            title: 'Insufficient funds',
                            message:
                                'This account lacks the funds required to complete the transfer. Please select a different payment method',
                            messageJson: '',
                        })
                    )
                } else {
                    throw errors
                }
            }
        } catch (err) {
            dispatch(
                plaidFlowError({
                    title: 'Bank Transfer Error',
                    message: `Unable to process bank account for invoice: ${chargeEventId}`,
                    messageJson: err.toString(),
                })
            )
        }
    }
}

const applyUseExistingAchOnInvoice = (payload: { achPaymentMethod: AchPaymentMethod }) => {
    return async (dispatch: Dispatch<any>) => {
        return dispatch(
            applyAchToInvoice({
                customerId: payload.achPaymentMethod.customerId,
                achId: payload.achPaymentMethod.achId,
            })
        )
    }
}

const onPlaidLinkSuccess = (payload: {
    publicToken: string
    accountId: string
    plaidBankName: string
    accountLast4: string
}) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId, practiceId },
            invoiceDetails: { chargeEventId },
        } = getState()

        dispatch(requestApplySelectedAchToInvoice())

        try {
            dispatch(changeChargeEventStatusToPending({ chargeEventId }))

            const { response: createCustomerResponse, errors: createCustomerErrors } = await promiseTo<{
                achId: string
                customerId: string
            }>(Api.createAchCustomerForPortal(payerId, practiceId, payload.publicToken, payload.accountId))

            if (createCustomerErrors) {
                return dispatch(
                    plaidFlowError({
                        title: 'Bank Transfer Error',
                        message: `Unable to add bank account to user`,
                        messageJson: createCustomerErrors,
                    })
                )
            }

            const { errors } = await promiseTo<CreateAchChargeForPortalResponse>(
                Api.createAchChargeForPortal(
                    chargeEventId,
                    createCustomerResponse.data.customerId,
                    createCustomerResponse.data.achId
                )
            )

            if (!errors) {
                return dispatch(applySelectedAchToInvoiceSuccess())
            } else {
                if (apiResponseContainErrorCode(errors, 428)) {
                    await Api.changeChargeEventStatusToDefault(chargeEventId)
                    return dispatch(
                        plaidFlowError({
                            title: 'Insufficient funds',
                            message:
                                'This account lacks the funds required to complete the transfer. Please select a different payment method',
                            messageJson: '',
                        })
                    )
                } else {
                    throw errors
                }
            }
        } catch (err) {
            return dispatch(
                plaidFlowError({
                    title: 'Bank Transfer Error',
                    message: `Unable to process bank account for invoice`,
                    messageJson: err.toString(),
                })
            )
        }
    }
}

const submitCardPayment = (
    practiceId: string,
    paymentMethodId: string,
    stripeInstance: Stripe,
    total: number,
    userFees?: number
) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            invoiceDetails: {
                stripeData: { clientSecret, paymentIntent: paymentIntentId },
                invoiceDetailsResponse: { transactionId: chargeEventId },
            },
        } = getState()
        try {
            Api.updateMismatchCardPaymentAmount({
                practiceId,
                paymentIntentId,
                amount: total,
                enableSavePaymentMethod: false,
                applicationFeeAmount: userFees,
            }).then(async () => {
                await dispatch(changeChargeEventStatusToPending({ chargeEventId }))
                const { paymentIntent, error } = await stripeInstance.confirmCardPayment(clientSecret, {
                    payment_method: paymentMethodId,
                })

                // dispatch success or failure
                if (paymentIntent) {
                    dispatch(stripeCardInvoicePaymentSuccess())
                } else {
                    Api.changeChargeEventStatusToDefault(chargeEventId)
                    const stripeErr = !!error && !!error.message ? error.message : 'Error submitting payment.'
                    dispatch(
                        stripeCardInvoicePaymentFailure({
                            message: stripeErr,
                            messageJson: '',
                        })
                    )
                }
            })
        } catch (err) {
            stripeCardInvoicePaymentFailure({
                message: 'Error submitting payment.',
                messageJson: JSON.stringify(err),
            })
        }
    }
}

const getPracticeLogo = (practiceId: string) => {
    return async (dispatch): Promise<string | false> => {
        try {
            const practiceLogo = await Api.getPracticeLogo(practiceId)

            if (practiceLogo.data.url) {
                return practiceLogo.data.url
            } else {
                return practiceLogo.data.fallback
            }
        } catch (err) {
            throw err
        }
    }
}

const fetchPracticeLogoBgColor = (practiceId: string) => {
    return async dispatch => {
        try {
            const practiceLogoBgColor = await Api.fetchPracticeLogoBgColor(practiceId)
            return practiceLogoBgColor.data.logoBackgroundColorCode
        } catch (err) {
            throw err
        }
    }
}

export {
    types,
    getInvoiceDetails,
    paymentMethodSelect,
    getFeesForInvoice,
    dataForInvoiceDetails,
    dataForInvoiceDetailsGuest,
    getAddressForLocation,
    onPayInvoice,
    stripeCardInvoicePaymentSuccess,
    onUseExistingPaymentSelect,
    submitInvoiceUseExistingMethod,
    onPayInvoiceUseExistingCancel,
    onInvoiceDialogErrorCancel,
    onInvoiceDialogSuccessBackToHome,
    stripeCardInvoicePaymentFailure,
    onStripeManualCheckoutCancel,
    changeChargeEventStatusToPending,
    plaidFlowError,
    cancelPlaidFlow,
    onAchSuccessBackToHome,
    requestPlaidLinkToken,
    applyAchToInvoice,
    applyUseExistingAchOnInvoice,
    onPlaidLinkSuccess,
    onUseExistingPaymentAchSelect,
    onOneTapPayInvoice,
    submitCardPayment,
    getPracticeLogo,
    fetchPracticeLogoBgColor,
}
