import React, { FC, useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducers/app/RootState'
import { StripeTestPractice } from '../../../types/Constants/StripeTestPractice'

const StripeElementsProvider: FC = ({ children }) => {
    // local state
    const [stripePromise, setStripePromise] = useState(null)

    // selector
    const { practiceId } = useSelector((state: RootState) => state.session)

    // init stripe effect
    useEffect(() => {
        let stripeKey: string
        if (practiceId === StripeTestPractice.stripeTestPractice) {
            stripeKey = process.env.REACT_APP_TEST_TEST_TEST_STRIPE_API_KEY
        } else {
            stripeKey = process.env.REACT_APP_STRIPE_API_KEY ? process.env.REACT_APP_STRIPE_API_KEY : ''
        }
        setStripePromise(loadStripe(stripeKey))
    }, [practiceId])

    return <Elements stripe={stripePromise}>{children}</Elements>
}

export default StripeElementsProvider
