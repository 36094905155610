import { PayDownPlanDialogState } from './payDownPlanDialog.state'

export const initialState: PayDownPlanDialogState = {
    show: false,
    error: {
        showError: false,
        title: '',
        message: '',
        messageJson: '',
    },
    success: false,
    loading: true,
    paymentPlans: null,
    paymentPlanSelect: null,
    paymentPlanSelectedOption: null,
    paymentPlanSelected: '',
    paymentPlanSelectedInfo: null,
    onSelectPaymentPlan: false,
    onSelectPaymentMethod: false,
    onChooseHowMuch: false,
    balanceRemainingMarks: null,
    amountPaid: '',
    amountPaidPercentage: 0,
    savedCreditDebit: null,
    savedAchPaymentMethod: null,
    paymentPlanDetails: null,
    maxAllowedPayment: 0,
    amountToPayDown: 0,
    amountToPayDownLessThanMax: false,
    isOnPayDownPlanFlow: false,
    isAddingNewPaymentMethod: false,
    isOnConfirmation: false,
    fees: null,
    offCyclePaymentResponse: null,
}
