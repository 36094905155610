import { Dispatch } from 'react'
import { RootState } from '../app/RootState'
import { PlatformMaintenanceResponse } from '../../types/Response/PlatformMaintenanceResponse'
import { PLATFORM_MAINTENANCE_SCOPE_ENUM } from '../../types/Constants/PlatformMaintenanceContants'

// CONSTANTS
const PLATFORM_MAINTENANCE_REQUEST = '[PLATFORM MAINTENANCE] PLATFORM MAINTENANCE CHECK REQUEST'
const PLATFORM_MAINTENANCE_SUCCESS = '[PLATFORM MAINTENANCE] PLATFORM MAINTENANCE CHECK SUCCESS'
const PLATFORM_MAINTENANCE_FAILURE = '[PLATFORM MAINTENANCE] PLATFORM MAINTENANCE CHECK FAILURE'

const MAINTENANCE_URL = process.env.REACT_APP_PLATFORM_MAINTENANCE_URL || '';

// ACTION TYPE
export type Action =
    | { type: typeof PLATFORM_MAINTENANCE_REQUEST }
    | {
          type: typeof PLATFORM_MAINTENANCE_SUCCESS
          payload: { startDate: string; endDate: string}
      }
    | { type: typeof PLATFORM_MAINTENANCE_FAILURE }

const platformMaintenanceCheck = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            platformMaintenance: { loading },
        } = getState()

        // if the request is already loading, return
        if (loading) {
            return
        }

        dispatch(platformMaintenanceCheckRequest())

        try {
            const response = await fetch(`${MAINTENANCE_URL}/window.json`, { mode: 'cors' })

            if (!response.ok) {
                return dispatch(platformMaintenanceCheckFailure())
            }

            const maintenanceData: PlatformMaintenanceResponse = await response.json()

            const isPortalMaintained =
                maintenanceData.scope.includes(PLATFORM_MAINTENANCE_SCOPE_ENUM.PORTAL) ||
                maintenanceData.scope.includes(PLATFORM_MAINTENANCE_SCOPE_ENUM.ALL)

            const data = isPortalMaintained
                ? { startDate: maintenanceData.start_date, endDate: maintenanceData.end_date }
                : { startDate: '', endDate: '' }
            return dispatch(platformMaintenanceCheckSuccess(data))
        } catch (err) {
            return dispatch(platformMaintenanceCheckFailure())
        }
    }
}

const platformMaintenanceCheckRequest = () => {
    return {
        type: PLATFORM_MAINTENANCE_REQUEST,
    }
}

const platformMaintenanceCheckSuccess = (payload: { startDate: string, endDate: string }) => {
    return {
        type: PLATFORM_MAINTENANCE_SUCCESS,
        payload,
    }
}

const platformMaintenanceCheckFailure = () => {
    return {
        type: PLATFORM_MAINTENANCE_FAILURE,
    }
}

export {
    PLATFORM_MAINTENANCE_REQUEST,
    PLATFORM_MAINTENANCE_SUCCESS,
    PLATFORM_MAINTENANCE_FAILURE,
    platformMaintenanceCheck,
    platformMaintenanceCheckSuccess,
    platformMaintenanceCheckFailure,
    platformMaintenanceCheckRequest,
}
