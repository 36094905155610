import {
    Action,
    UPDATE_FAILED_PAYMENT_DIALOG_LOADING,
    UPDATE_FAILED_PAYMENT_DIALOG_ERROR,
    UPDATE_FAILED_PAYMENT_DIALOG_SUCCESS,
    ON_FAILED_PAYMENT_DIALOG_USE_EXISTING_ACH,
    HIDE_UPDATE_FAILED_PAYMENT_DIALOG,
    UNHIDE_UPDATE_FAILED_PAYMENT_DIALOG,
    ON_FAILED_PAYMENT_DIALOG_USE_EXISTING_CREDIT,
    FAILED_PAYMENT_GO_TO_PROCEED,
    ON_FAILED_PAYMENT_ADD_NEW_METHOD_SELECTION,
    CANCEL_UPDATE_FAILED_PAYMENT_DIALOG,
    SHOW_UPDATE_FAILED_PAYMENT_DIALOG,
} from './update-failed-payment-dialog.action'
import initialState from './update-failed-payment-dialog.initial'
import { UpdateFailedPaymentDiaogState } from './update-failed-payment-dialog.state'

const updateFailedPaymentDialogReducer = (
    state: UpdateFailedPaymentDiaogState = initialState,
    action: Action
): UpdateFailedPaymentDiaogState => {
    switch (action.type) {
        case SHOW_UPDATE_FAILED_PAYMENT_DIALOG: {
            return {
                ...initialState,
                show: true,
                loading: false,
                failedPayment: action.payload.failedPayment,
            }
        }
        case CANCEL_UPDATE_FAILED_PAYMENT_DIALOG: {
            return {
                ...initialState,
            }
        }
        case ON_FAILED_PAYMENT_DIALOG_USE_EXISTING_CREDIT: {
            return {
                ...state,
                canProceedFromSelection: true,
                savedCreditMethod: action.payload.savedPaymentMethod,
                savedAchPaymentMethod: null,
                isAddingNewPaymentMethod: false,
            }
        }
        case FAILED_PAYMENT_GO_TO_PROCEED: {
            return {
                ...state,
                isOnConfirm: true,
                isOnSelection: false,
            }
        }
        case ON_FAILED_PAYMENT_ADD_NEW_METHOD_SELECTION: {
            return {
                ...state,
                canProceedFromSelection: true,
                isAddingNewPaymentMethod: true,
            }
        }
        case UPDATE_FAILED_PAYMENT_DIALOG_LOADING: {
            return {
                ...state,
                loading: true,
                show: true,
            }
        }
        case UPDATE_FAILED_PAYMENT_DIALOG_ERROR: {
            return {
                ...state,
                loading: false,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case UPDATE_FAILED_PAYMENT_DIALOG_SUCCESS: {
            return {
                ...state,
                loading: false,
                success: {
                    show: true,
                    message: action.payload.message,
                },
            }
        }
        case ON_FAILED_PAYMENT_DIALOG_USE_EXISTING_ACH: {
            return {
                ...state,
                savedAchPaymentMethod: action.payload.achPaymentMethod,
                canProceedFromSelection: true,
                savedCreditMethod: null,
            }
        }
        case HIDE_UPDATE_FAILED_PAYMENT_DIALOG: {
            return {
                ...state,
                show: false,
            }
        }
        case UNHIDE_UPDATE_FAILED_PAYMENT_DIALOG: {
            return {
                ...state,
                show: true,
                loading: true,
            }
        }
        default:
            return { ...state }
    }
}

export default updateFailedPaymentDialogReducer
