import React from 'react'
import './Footer.scss'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import { formatPhoneNumber } from '../shared/utils'
import { useSelector } from 'react-redux'
import { RootState } from '../reducers/app/RootState'
import { useLocation } from 'react-router-dom'

const Footer = () => {
    const { practiceInfo, practiceId } = useSelector((state: RootState) => state.session)

    const formatAddress = (address: {
        addressStreet1: string
        addressStreet2: string | null
        unit: string
        city: string
        state: string
        zip: string
    }): string => {
        return `${address.addressStreet1} ${address.addressStreet2 ? address.addressStreet2 : ''} ${
            address.unit ? address.unit : ''
        }, ${address.city}, ${address.state} ${address.zip}`
    }

    const location = useLocation()

    if (!practiceId) {
        return null
    } else {
        return (
            <div className="home-footer">
                <div className="wrapper">
                    {location.pathname.includes('external-invoice') ? null : (
                        <React.Fragment>
                            <div className="practice-name">{practiceInfo.practiceName}</div>
                            <div className="practice-location">
                                <div className="item">
                                    <div className="icon">
                                        <LocationOnIcon />
                                    </div>
                                    <div className="value">{formatAddress(practiceInfo.address)}</div>
                                </div>
                                <div className="item">
                                    <div className="icon">
                                        <PhoneIphoneIcon />
                                    </div>
                                    <div className="value">{formatPhoneNumber(practiceInfo.phoneNumber)}</div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        )
    }
}

export default Footer
