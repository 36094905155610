import { Dispatch } from 'react'
import Api from '../../api/Api'
import { InvoiceDetailsResponse } from '../../types/Response/InvoiceDetailsResponse'
import { RootState } from '../app/RootState'

const LOAD_INVOICE_HISTORY_SUCCESS = '[INVOICE HISTORY] LOAD INVOICE HISTORY SUCCESS'
const LOAD_INVOICE_HISTORY_ERROR = '[INVOICE HISTORY] LOAD INVOICE HISTORY ERROR'

export type Action =
    | { type: typeof LOAD_INVOICE_HISTORY_ERROR; payload: { title: string; message: string } }
    | { type: typeof LOAD_INVOICE_HISTORY_SUCCESS; payload: { invoiceDetailsResponse: InvoiceDetailsResponse } }

const loadInvoiceHistory = (payload: { chargeEventId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            const {
                session: { practiceId },
            } = getState()

            const { data } = await Api.getInvoiceDetails(practiceId, payload.chargeEventId)
            return dispatch(loadInvoiceHistorySuccess({ invoiceDetailsResponse: data }))
        } catch (err) {
            return dispatch(
                loadInvoiceHistoryError({
                    title: 'Invoice History Error',
                    message: `Unable to load details for invoice id: ${payload.chargeEventId}`,
                })
            )
        }
    }
}

const loadInvoiceHistoryError = (payload: { title: string; message: string }) => {
    return {
        type: LOAD_INVOICE_HISTORY_ERROR,
        payload,
    }
}

const loadInvoiceHistorySuccess = (payload: { invoiceDetailsResponse: InvoiceDetailsResponse }) => {
    return {
        type: LOAD_INVOICE_HISTORY_SUCCESS,
        payload,
    }
}

export { LOAD_INVOICE_HISTORY_ERROR, LOAD_INVOICE_HISTORY_SUCCESS, loadInvoiceHistory, loadInvoiceHistoryError }
