import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SFDialog, SFLoading, SFSuccessDialogContent, SFErrorDialogContent } from '@simplifeye/component-library'
import DialogFooterProps from '@simplifeye/component-library/lib/component/SFDialog/types/DialogFooter.types'

import { RootState } from '../../reducers/app/RootState'
import {
    onFailedPaymentAddNewMethodSelection,
    onFailedPaymentUseExistingAch,
    onFailedPaymentUseExistingCredit,
    onFailedPaymentDialogProceed,
    onFailedPaymentUpdateAndPay,
    onUpdateFailedPaymentDialogClose,
} from '../../reducers/update-failed-payment-dialog/update-failed-payment-dialog.action'
import SelectPaymentMethod from '../../shared/components/select-payment-method/SelectPaymentMethod'
import { formateDateObject } from '../../shared/utils'
import { AchPaymentMethod } from '../../types/AchPaymentMethod'
import * as Constants from '../../types/Constants'
import { PayerPaymentMethods } from '../../types/Response/PayerPaymentMethods'

import './UpdateFailedPaymentDialog.scss'
import { CircularProgress } from '@material-ui/core'

const UpdateFailedPaymentDialog = () => {
    const {
        show,
        loading,
        canProceedFromSelection,
        isOnConfirm,
        isOnSelection,
        failedPayment,
        savedCreditMethod,
        savedAchPaymentMethod,
        success,
        error,
        isLoadingPaymentMethods,
    } = useSelector((state: RootState) => {
        return {
            ...state.updateFailedPaymentDialog,
            isLoadingPaymentMethods: state.paymentMethods.loading,
        }
    })

    const { paymentMethods, achPaymentMethod } = useSelector((state: RootState) => state.paymentMethods)

    const [isSubmittingPayment, setIsSubmittingPayment] = useState(false)
    const [dialog, setDialog] = useState<{ content: React.ReactNode; footer: DialogFooterProps }>({
        content: null,
        footer: null,
    })

    const dispatch = useDispatch()

    const onPaymentMethodSelectChange = (
        paymentMethod: string,
        isExistingPayment: boolean,
        savedPaymentMethod?: PayerPaymentMethods,
        achPaymentMethod?: AchPaymentMethod
    ) => {
        if (!isExistingPayment) {
            dispatch(onFailedPaymentAddNewMethodSelection())
        } else if (achPaymentMethod) {
            dispatch(onFailedPaymentUseExistingAch({ achPaymentMethod }))
        } else {
            dispatch(onFailedPaymentUseExistingCredit({ paymentMethod, isExistingPayment: true, savedPaymentMethod }))
        }
    }

    const displayPaymentMethod = (
        savedCreditDebit: PayerPaymentMethods,
        savedAchPaymentMethod: AchPaymentMethod
    ): string => {
        if (savedCreditDebit) {
            return `${savedCreditDebit.cardType} ****${savedCreditDebit.last4}`
        } else {
            return `${savedAchPaymentMethod.bankName} ****${savedAchPaymentMethod.accountLast4}`
        }
    }

    useEffect(() => {
        if (loading) {
            setDialog({
                content: (
                    <div className="update-loading">
                        <SFLoading />
                    </div>
                ),
                footer: null,
            })
        } else if (success.show) {
            setDialog({
                content: (
                    <div className="update-loading">
                        <SFSuccessDialogContent title="Success" message={success.message} />
                    </div>
                ),
                footer: {
                    footerButtons: {
                        centerButton: {
                            text: 'Close',
                            action: () => {
                                dispatch(onUpdateFailedPaymentDialogClose())
                            },
                        },
                    },
                },
            })
        } else if (error.showError) {
            setDialog({
                content: (
                    <div className="update-loading">
                        <SFErrorDialogContent title={error.title} message={error.message} />
                    </div>
                ),
                footer: {
                    footerButtons: {
                        centerButton: {
                            text: 'Close',
                            action: () => {
                                dispatch(onUpdateFailedPaymentDialogClose())
                            },
                        },
                    },
                },
            })
        } else if (isOnSelection) {
            setDialog({
                content: (
                    <div className="update-failed-payment-dialog-inner">
                        <SelectPaymentMethod
                            isLoading={isLoadingPaymentMethods}
                            selectedPaymentMethod={Constants.InvoicePaymentMethods.CREDIT}
                            savedPaymentMethods={paymentMethods}
                            isAddingNewPaymentMethod={false}
                            achPaymentMethod={achPaymentMethod}
                            onNewPaymentMethodSelect={() => {}}
                            onPaymentMethodSelectChange={onPaymentMethodSelectChange}
                            isExternalInvoice={false}
                        ></SelectPaymentMethod>
                    </div>
                ),
                footer: {
                    footerButtons: {
                        twoButtons: {
                            leftButton: {
                                text: 'Cancel',
                                action: () => {
                                    dispatch(onUpdateFailedPaymentDialogClose())
                                },
                            },
                            rightButton: {
                                text: 'Proceed',
                                action: () => {
                                    dispatch(onFailedPaymentDialogProceed())
                                },
                                disabled: !canProceedFromSelection,
                            },
                        },
                    },
                },
            })
        } else {
            setDialog({
                content: (
                    <div className="failed-payment-confirmation">
                        <div className="existing-payment-details">
                            <h3>You're about to submit a payment. Please confirm the details below:</h3>
                            <div className="details-wrapper">
                                <div className="detail">
                                    <label htmlFor="description">Payment Plan</label>
                                    <div id="description">{failedPayment.chargeEvent.paymentPlanId}</div>
                                </div>
                                <div className="detail">
                                    <label htmlFor="date">Date</label>
                                    <div id="date">{formateDateObject(new Date(), 'MM/DD/YYYY')}</div>
                                </div>
                                <div className="detail">
                                    <label htmlFor="method">Payment Method</label>
                                    <div id="method">
                                        {displayPaymentMethod(savedCreditMethod, savedAchPaymentMethod)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ),
                footer: {
                    footerButtons: {
                        twoButtons: {
                            leftButton: {
                                text: 'Cancel',
                                action: () => {
                                    dispatch(onUpdateFailedPaymentDialogClose())
                                },
                                disabled: isSubmittingPayment,
                            },
                            rightButton: {
                                classes: [
                                    'update-failed-submit-btn',
                                    isSubmittingPayment ? 'update-failed-submit-btn--submitting' : '',
                                ],
                                text: !isSubmittingPayment
                                    ? 'Update and Pay'
                                    : (((
                                          <CircularProgress className="update-failed-submit-loading" size={25} />
                                      ) as unknown) as string),
                                action: async () => {
                                    if (!isSubmittingPayment) {
                                        setIsSubmittingPayment(true)
                                        await dispatch(onFailedPaymentUpdateAndPay())
                                        setIsSubmittingPayment(false)
                                    }
                                },
                                disabled: false,
                            },
                        },
                    },
                },
            })
        }
    }, [
        dispatch,
        show,
        loading,
        paymentMethods,
        achPaymentMethod,
        canProceedFromSelection,
        isOnConfirm,
        failedPayment,
        savedAchPaymentMethod,
        savedCreditMethod,
        success,
        isOnSelection,
    ])

    return (
        <SFDialog
            open={show}
            title="Update Method"
            onClose={() => {
                if (!isSubmittingPayment) {
                    dispatch(onUpdateFailedPaymentDialogClose())
                }
            }}
            content={dialog.content}
            footer={dialog.footer}
            styleClass="update-failed-payment-dialog"
        />
    )
}

export default UpdateFailedPaymentDialog
