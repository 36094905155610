import React, { useEffect, useState } from 'react'
import '../LoginShared.scss'
import './PasswordReset.scss'
import SimplifeyeVyneLogo from '../../icons/SimplifeyeVyneLogo'
import { SFAlert } from '@simplifeye/component-library'
import { ResetCompleteIcon } from '../../icons/LoginIcons'
import { Spinner } from '../../login/Spinner'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {
    completePasswordReset,
    completePasswordResetError,
    backToLoginFromResetSuccess,
} from '../../reducers/login/login.action'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../reducers/app/RootState'
import { useHistory, useLocation } from 'react-router-dom'
import PasswordContainer from '../password-container/PasswordContainer'

const PasswordReset = () => {
    const { error, isOnForgotPasswordSuccess, goToLoginFromResetSuccess, isLoading } = useSelector(
        (state: RootState) => state.login
    )
    const [password, setPassword] = useState<string>('')
    const [metAllRequirements, setMetAllRequirements] = useState<boolean>(false)
    const [code, setCode] = useState<string>('')
    const [email, setEmail] = useState<string>('')

    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()

    const onPasswordReset = () => {
        dispatch(completePasswordReset({ emailAddress: email, password, code }))
    }

    const getEmailAndCodeFromUrl = () => {
        try {
            const queryObj = new URLSearchParams(location.search)
            const emailFromUrl = atob(queryObj.get('s'))
            setEmail(emailFromUrl)
            const codeFromUrl = location.pathname.split('/').pop()
            setCode(codeFromUrl)
            if (!emailFromUrl || !codeFromUrl) {
                throw 'Email or code is missing'
            }
        } catch (err) {
            dispatch(completePasswordResetError({ title: 'Error', message: 'Unable to get email or code from url' }))
        }
    }

    useEffect(() => {
        if (!goToLoginFromResetSuccess) {
            getEmailAndCodeFromUrl()
        } else {
            history.push('/login')
        }
    }, [location, goToLoginFromResetSuccess])

    if (!isOnForgotPasswordSuccess) {
        return (
            <div className="login password-reset ">
                <div className="logo"></div>
                <div className="login-box">
                    <div className="login-box-inner">
                        <div className="lock-icon">
                            <ResetCompleteIcon />
                        </div>
                        <div className="login-title">Reset your password</div>
                        <div className="error-wrapper">
                            <SFAlert
                                showAlert={error.showError}
                                severity={'error'}
                                title={error.title}
                                message={error.message}
                            />
                        </div>
                        <div>
                            <PasswordContainer
                                password={password}
                                isError={error.showError}
                                setPassword={setPassword}
                                setMetAllRequirements={setMetAllRequirements}
                            />
                        </div>
                        <div>
                            <div className="login-button-wrapper">
                                {isLoading ? (
                                    <div className="reset-password-button-spinner">
                                        <Spinner />
                                    </div>
                                ) : (
                                    <button
                                        className="reset-password-button"
                                        type="submit"
                                        onClick={() => {
                                            onPasswordReset()
                                        }}
                                        disabled={!metAllRequirements}
                                    >
                                        <LockOpenIcon />
                                        <div>Reset Password</div>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="powered-by">
                    powered by <SimplifeyeVyneLogo />
                </div>
            </div>
        )
    } else {
        return (
            <div className="login password-reset ">
                <div className="logo"></div>
                <div className="login-box">
                    <div className="login-box-inner">
                        <div className="lock-icon reset-password-success-icon ">
                            <CheckCircleIcon />
                        </div>
                        <div className="login-title">Password succesfully reset!</div>
                        <div className="info-text">
                            You have successfully reset your password. If you are still experiencing issues, please
                            reach out to
                            <a href="mailto:help@simplifeye.co" className="email-link">
                                help@simplifeye.co
                            </a>
                            .
                        </div>
                        <div>
                            <div className="login-button-wrapper">
                                <button
                                    className="reset-password-button"
                                    onClick={() => {
                                        dispatch(backToLoginFromResetSuccess())
                                    }}
                                >
                                    <ArrowBackIcon />
                                    <div>Back to Login</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="powered-by">
                    powered by <SimplifeyeVyneLogo />
                </div>
            </div>
        )
    }
}

export default PasswordReset
