import React from 'react'
import { UnpaidInvoice } from '../../types/UnpaidInvoice'
import moment from 'moment'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { useHistory } from 'react-router'
import UnpaidInvoiceNotice from './unpaid-invoice-notice/UnpaidInvoiceNotice'
import './UnpaidInvoiceSection.scss'
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/app/RootState'
import SessionManager from '../../../SessionManager'

const UnpaidInvoiceSection = () => {
    const { unpaidInvoices, lateInvoices, pendingInvoices, failedInvoices } = useSelector(
        (state: RootState) => state.overview
    )
    const history = useHistory()

    const getTimeMinusThreeMinutes = () => {
        return moment(new Date())
            .subtract(3, 'minutes')
            .format('hh:mm a')
    }

    const lateInvoiceContent = lateInvoices.map((invoice: UnpaidInvoice, index: number) => {
        return (
            <UnpaidInvoiceNotice
                key={index}
                invoice={invoice}
                onPay={(invoiceId: number | string) => {
                    if (SessionManager.isLoggedIn) {
                        history.push(`/invoice/${invoiceId}`)
                    } else {
                        history.push(`/invoice-external/${invoiceId}`)
                    }
                }}
                isLate={true}
                index={index}
            />
        )
    })

    const unpaidInvoiceContent = unpaidInvoices.map((invoice: any, index: number) => {
        return (
            <UnpaidInvoiceNotice
                key={index}
                invoice={invoice}
                onPay={(invoiceId: number | string) => {
                    if (SessionManager.isLoggedIn) {
                        history.push(`/invoice/${invoiceId}`)
                    } else {
                        history.push(`/invoice-external/${invoiceId}`)
                    }
                }}
                index={index}
            />
        )
    })

    const pendingInvoiceContent = pendingInvoices.map((invoice: any, index: number) => {
        return (
            <UnpaidInvoiceNotice
                key={index}
                invoice={invoice}
                onPay={(invoiceId: number | string) => {
                    if (SessionManager.isLoggedIn) {
                        history.push(`/invoice/${invoiceId}`)
                    } else {
                        history.push(`/invoice-external/${invoiceId}`)
                    }
                }}
                index={index}
                isPending={true}
            />
        )
    })

    const failedInvoiceContent = failedInvoices.map((invoice: any, index: number) => {
        return (
            <UnpaidInvoiceNotice
                key={index}
                invoice={invoice}
                onPay={(invoiceId: number | string) => {
                    if (SessionManager.isLoggedIn) {
                        history.push(`/invoice/${invoiceId}`)
                    } else {
                        history.push(`/invoice-external/${invoiceId}`)
                    }
                }}
                index={index}
                isFailed={true}
            />
        )
    })

    if (
        (unpaidInvoices && unpaidInvoices.length > 0) ||
        (lateInvoices && lateInvoices.length > 0) ||
        (pendingInvoiceContent && pendingInvoiceContent.length > 0) ||
        (failedInvoiceContent && failedInvoiceContent.length > 0)
    ) {
        return (
            <section className="unpaid-invoice-section">
                <div className="unpaid-notice-header">
                    <h3>Payments Due</h3>
                    <div className="last-update-wrapper">
                        <AccessTimeIcon />
                        last update {getTimeMinusThreeMinutes()}
                    </div>
                </div>
                {lateInvoiceContent}
                {failedInvoiceContent}
                {unpaidInvoiceContent}
                {pendingInvoiceContent}
            </section>
        )
    } else {
        return null
    }
}

export default UnpaidInvoiceSection
