import React, { useEffect } from 'react'
import './PaymentPlansSection.scss'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../reducers/app/RootState'
import { SFAlert, SFLoading } from '@simplifeye/component-library'
import { Button } from '@material-ui/core'

import {
    onStripeAddCardSuccess,
    onPlaidLinkSuccess,
    onPaymentPlanSelectionComplete,
    editPaymentMethodAddCardFailure,
    editPaymentMethodAddCardCancel,
    closeSelectPaymentPlanDialog,
} from '../reducers/payment-plan/paymentPlan.action'
import PaymentPlanCard from './payment-plan-card/PaymentPlanCard'
import { PaymentPlanCardType } from '../types/PaymentPlanCardType'
import AddPaymentMethodDialog from '../add-new-method-dialog/AddPaymentMethodDialog'
import SelectPaymentPlanDialog from '../shared/components/select-payment-plan-dialog/SelectPaymentPlanDialog'
import { payDownPlanStart } from '../reducers/pay-down-plan-dialog/payDownPlanDialog.actions'
import PayDownPlanDialog from './pay-down-plan-dialog/PayDownPlanDialog'
import LoadingPage from '../shared/components/loading-page/LoadingPage'

const PaymentPlanSection = () => {
    const {
        loading,
        error,
        showInfo,
        paymentPlanCards,
        paymentPlans,
        showSelectPaymentPlanDialog,
        activePlanId,
        paymentPlanDetailsLoading,
        paymentPlanDetailsError,
        paymentPlanDetails,
    } = useSelector((state: RootState) => state.paymentPlan)
    const dispatch = useDispatch()

    if (loading) {
        return (
            <div className="payment-plan-section">
                <div className="payment-plan-inner">
                    <LoadingPage />
                </div>
            </div>
        )
    } else if (showInfo) {
        return (
            <div className="payment-plan-section">
                <div className="payment-plan-inner">
                    <h3>Payment Plans</h3>
                    <SFAlert
                        severity="info"
                        title="Payment Plans"
                        message="No payment plans found for your payer profile"
                        showAlert={true}
                    />
                </div>
            </div>
        )
    } else if (error.showError) {
        return (
            <div className="payment-plan-section">
                <div className="payment-plan-inner">
                    <h3>Payment Plans</h3>
                    <SFAlert severity="error" title={error.title} message={error.message} showAlert={true} />
                </div>
            </div>
        )
    } else {
        const hasPlanForPayingDown = paymentPlanCards.some(plan => {
            return plan.paymentPlanBasicInfo.paymentPlanStatus === 'active'
        })
        return (
            <div className="payment-plan-section">
                <div className="payment-plan-inner">
                    <div className="payment-plan-header">
                        <h3>Payment Plans</h3>
                        {hasPlanForPayingDown && (
                            <>
                                <div className="pay-off-plan-link">
                                    Want to pay off your plan early?{' '}
                                    <a
                                        onClick={() => {
                                            dispatch(payDownPlanStart())
                                        }}
                                    >
                                        Make an extra payment
                                    </a>
                                </div>
                                <Button
                                    className="pay-off-plan-btn"
                                    onClick={() => {
                                        dispatch(payDownPlanStart())
                                    }}
                                >
                                    <span className="review-payment">Make an extra payment</span>
                                </Button>
                            </>
                        )}
                    </div>

                    {paymentPlanCards.map((plan: PaymentPlanCardType, index: number) => {
                        return (
                            <PaymentPlanCard
                                plan={plan}
                                key={index}
                                index={index}
                                paymentPlanDetailsLoading={paymentPlanDetailsLoading}
                                error={paymentPlanDetailsError}
                                paymentPlanDetails={paymentPlanDetails}
                                isCompleted={plan.isPlanCompleted}
                            />
                        )
                    })}
                </div>
                <AddPaymentMethodDialog
                    onStripeAddCardSuccess={onStripeAddCardSuccess}
                    addMethodError={editPaymentMethodAddCardFailure}
                    cancel={editPaymentMethodAddCardCancel}
                    onPlaidLinkSuccess={onPlaidLinkSuccess}
                />
                <SelectPaymentPlanDialog
                    show={showSelectPaymentPlanDialog}
                    cancel={closeSelectPaymentPlanDialog}
                    paymentPlans={paymentPlans}
                    activePlanId={activePlanId}
                    onPaymentPlanSelectionComplete={onPaymentPlanSelectionComplete}
                />
                <PayDownPlanDialog />
            </div>
        )
    }
}

export default PaymentPlanSection
