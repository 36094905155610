import React from 'react'
import { SFSelect } from '@simplifeye/component-library'
import { useDispatch } from 'react-redux'
import { onPaymentPlanSelect } from '../../../reducers/pay-down-plan-dialog/payDownPlanDialog.actions'

interface Props {
    paymentPlanSelect: Array<{ value: string; text: string }>
    paymentPlanSelected: string
}

const PayDownPlanSelectPlan = ({ paymentPlanSelect, paymentPlanSelected }: Props) => {
    const dispatch = useDispatch()
    return (
        <div className="pay-down-plan-dialog-inner">
            <div className="select-wrapper">
                <h3 className="section-title">Which plan do you want to make a payment on?</h3>
                <label>Payment Plan</label>
                <SFSelect
                    defaultText="Select Payment Plan"
                    options={paymentPlanSelect}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        dispatch(onPaymentPlanSelect({ paymentPlanId: e.target.value }))
                    }}
                    selectedOption={{ value: paymentPlanSelected, text: '' }}
                />
            </div>
        </div>
    )
}

export default PayDownPlanSelectPlan
