import React from 'react'
import { InvoiceDetailsResponse } from '../../types/Response/InvoiceDetailsResponse'
import './InvoiceDetailsDataGuest.scss'
import { formatDate, formatCurrencyCents } from '../../shared/utils'
import { ProcedureDescription } from '../../types/Response/ProcedureDescription'
import { Link } from 'react-router-dom'

interface Props {
    invoiceDetails: InvoiceDetailsResponse
    isPaid: boolean
}

const InvoiceDetailsDataGuest = ({ invoiceDetails, isPaid }: Props) => {
    // const dispatch = useDispatch()

    return (
        <div className="invoice-details-data">
            <section>
                <div className="title">Billing Information</div>
                <div className="row row-hipaa">
                    For HIPAA privacy, we cannot provide details on specific treatments during guest checkout. Please{' '}
                    <Link to={'/login'} className="hipaa-link">
                        log in
                    </Link>{' '}
                    for details.
                </div>
                <div className="row">
                    <div className="item">
                        <label htmlFor="billingDate">Billing Date</label>
                        <div id="billingDate" className="value">
                            {formatDate(invoiceDetails.transactionDate)}
                        </div>
                    </div>
                    <div className="item">
                        <label htmlFor="paymentDue">{isPaid ? 'Amount Paid' : 'Payment Due'}</label>
                        <div id="paymentDue" className="value">
                            {formatCurrencyCents(invoiceDetails.amount)}
                        </div>
                    </div>
                </div>
                {invoiceDetails.procedureDescriptions.map((treatment: ProcedureDescription, index) => (
                    <div className="item" key={index}>
                        {invoiceDetails.procedureDescriptions.length > 1 && (
                            <div className="row">
                                <div className="item description">
                                    <label htmlFor={`treatmentDescription${index}`}>
                                        {treatment.treatmentDescription}
                                    </label>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="item">
                                <label htmlFor={`treatmentDate${index}`}>Treatment Date</label>
                                <div id={`treatmentDate${index}`} className="value">
                                    {formatDate(treatment.treatmentDate)}
                                </div>
                            </div>
                            <div className="item">
                                <label htmlFor={`treatmentCost${index}`}>Treatment Cost</label>
                                <div id={`treatmentCost${index}`} className="value">
                                    {formatCurrencyCents(treatment.amount)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        </div>
    )
}

export default InvoiceDetailsDataGuest
