import React from 'react'

export const LockIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 86 86">
            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                <circle cx="42" cy="42" r="42" stroke="#3DA50C" />
                <path
                    fill="#D1D4D1"
                    d="M56.771 65H27.229A6.23 6.23 0 0 1 21 58.771V39.229A6.23 6.23 0 0 1 27.229 33h29.542A6.23 6.23 0 0 1 63 39.229v19.542A6.23 6.23 0 0 1 56.771 65"
                />
                <path
                    fill="#ECF0F1"
                    d="M56.771 33H42v32h14.771A6.23 6.23 0 0 0 63 58.771V39.229A6.23 6.23 0 0 0 56.771 33"
                />
                <path
                    fill="#5E5F62"
                    d="M42 53c-1.65 0-3-1.35-3-3v-6c0-1.65 1.35-3 3-3s3 1.35 3 3v6c0 1.65-1.35 3-3 3"
                />
                <path
                    fill="#667D8C"
                    d="M27.229 33H31v-6.5c0-5.238 4.935-9.5 11-9.5s11 4.262 11 9.5V33h3.771c.077 0 .152.009.229.012V26.5C57 19.075 50.25 13 42 13s-15 6.075-15 13.5v6.512c.077-.003.152-.012.229-.012"
                />
                <path
                    stroke="#231117"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M56.771 65H27.229A6.23 6.23 0 0 1 21 58.771V39.229A6.23 6.23 0 0 1 27.229 33h29.542A6.23 6.23 0 0 1 63 39.229v19.542A6.23 6.23 0 0 1 56.771 65zM27 33v-6.5C27 19.075 33.75 13 42 13s15 6.075 15 13.5V33H27z"
                />
            </g>
        </svg>
    )
}

export const ResetIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="96"
            height="80"
            viewBox="0 0 96 80"
        >
            <defs>
                <path id="a" d="M0 80h96V0H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <path fill="#E4C05C" d="M35 34h24V15H35z" />
                <g>
                    <path
                        fill="#556080"
                        d="M56 14.4h-3.2V8.8c0-3.088-2.512-5.6-5.6-5.6a5.606 5.606 0 0 0-5.6 5.6v5.6h-3.2V8.8c0-4.853 3.949-8.8 8.8-8.8C52.051 0 56 3.947 56 8.8v5.6zM78.4 78.4H17.6c-8.8 0-16-7.2-16-16s7.2-16 16-16h60.8c8.8 0 16 7.2 16 16s-7.2 16-16 16"
                    />
                    <path
                        fill="#8697CB"
                        d="M24.884 57.769L20.8 59.81V54.4a1.6 1.6 0 1 0-3.2 0v5.41l-4.084-2.041a1.6 1.6 0 0 0-1.432 2.862l4.687 2.343-3.651 4.865a1.598 1.598 0 0 0 .321 2.24 1.6 1.6 0 0 0 2.238-.318l3.52-4.695 3.52 4.695c.316.417.796.64 1.282.64.335 0 .67-.104.959-.322.707-.53.85-1.533.32-2.24l-3.65-4.865 4.686-2.343a1.6 1.6 0 0 0-1.432-2.862M44.084 57.769L40 59.81V54.4a1.6 1.6 0 1 0-3.2 0v5.41l-4.084-2.041a1.6 1.6 0 0 0-1.432 2.862l4.687 2.343-3.651 4.865a1.598 1.598 0 0 0 .321 2.24 1.6 1.6 0 0 0 2.238-.318l3.52-4.695 3.52 4.695c.316.417.796.64 1.282.64.335 0 .67-.104.959-.322.707-.53.85-1.533.32-2.24l-3.65-4.865 4.686-2.343a1.6 1.6 0 0 0-1.432-2.862M63.284 57.769L59.2 59.81V54.4a1.6 1.6 0 1 0-3.2 0v5.41l-4.084-2.041a1.6 1.6 0 1 0-1.432 2.862l4.687 2.343-3.651 4.865a1.598 1.598 0 0 0 .321 2.24 1.6 1.6 0 0 0 2.238-.318l3.52-4.695 3.52 4.695c.316.417.796.64 1.282.64.335 0 .67-.104.959-.322.707-.53.85-1.533.32-2.24l-3.65-4.865 4.686-2.343a1.6 1.6 0 0 0-1.432-2.862M84.631 58.484a1.6 1.6 0 0 0-2.147-.715l-4.085 2.042V54.4a1.6 1.6 0 1 0-3.2 0v5.41l-4.083-2.04a1.6 1.6 0 1 0-1.432 2.862l4.686 2.342-3.65 4.866a1.597 1.597 0 0 0 1.278 2.56c.488 0 .968-.221 1.281-.64l3.52-4.693 3.522 4.693a1.594 1.594 0 0 0 2.238.32c.707-.53.851-1.533.32-2.24l-3.65-4.866 4.687-2.342a1.6 1.6 0 0 0 .715-2.148"
                    />
                    <mask id="b" fill="#fff">
                        <use xlinkHref="#a" />
                    </mask>
                    <path
                        stroke="#231317"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M35.2 33.6h24V14.4h-24zM40 14.4V8.8c0-3.96 3.24-7.2 7.2-7.2s7.2 3.24 7.2 7.2v5.6H40z"
                        mask="url(#b)"
                    />
                    <path
                        stroke="#070707"
                        strokeWidth="2"
                        d="M78.4 78.4H17.6c-8.8 0-16-7.2-16-16s7.2-16 16-16h60.8c8.8 0 16 7.2 16 16s-7.2 16-16 16z"
                        mask="url(#b)"
                    />
                </g>
            </g>
        </svg>
    )
}

export const ResetCompleteIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="72"
            height="80"
            viewBox="0 0 72 80"
        >
            <defs>
                <path id="a" d="M0 80h72V0H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    stroke="#A5A5A4"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M42.667 32H29.333V21.333a6.667 6.667 0 1 1 13.334 0V32z"
                />
                <path
                    stroke="#485762"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M36 70.667C16.855 70.667 1.333 55.145 1.333 36 1.333 16.855 16.855 1.333 36 1.333c19.145 0 34.667 15.522 34.667 34.667 0 9.513-3.832 18.132-10.036 24.396M36 70.667l-8 8M36 70.667l-8-8"
                />
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <path fill="#F18D46" d="M22.667 52h26.666V29.333H22.667z" mask="url(#b)" />
                <path
                    fill="#FFF"
                    d="M40 38.667a4 4 0 1 0-8 0 3.986 3.986 0 0 0 2.713 3.769c-.014.079-.046.148-.046.23v4a1.333 1.333 0 1 0 2.666 0v-4c0-.082-.032-.151-.046-.23A3.986 3.986 0 0 0 40 38.666"
                    mask="url(#b)"
                />
                <path
                    stroke="#231117"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M22.667 52h26.666V29.333H22.667zM42.667 29.333H29.333v-8a6.667 6.667 0 1 1 13.334 0v8z"
                    mask="url(#b)"
                />
                <path
                    stroke="#231117"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M36 70.667C16.855 70.667 1.333 55.145 1.333 36 1.333 16.855 16.855 1.333 36 1.333c19.145 0 34.667 15.522 34.667 34.667 0 9.513-3.832 18.132-10.036 24.396M36 70.667l-8 8M36 70.667l-8-8"
                    mask="url(#b)"
                />
            </g>
        </svg>
    )
}

export const RegisterAccountIcon = () => {
    return (
        <svg
            width="61px"
            height="80px"
            viewBox="0 0 61 80"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <polygon id="path-1" points="0 80 60.6896552 80 60.6896552 0 0 0"></polygon>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="White-label-AMP-261-Reset-Password" transform="translate(-510.000000, -151.000000)">
                    <g id="icon-lock" transform="translate(510.000000, 151.000000)">
                        <path
                            d="M13.7931034,38.6206897 L13.7931034,17.3806897 C13.7931034,9.33931034 20.9310345,2.75862069 29.6551724,2.75862069 C38.3793103,2.75862069 45.5172414,9.33931034 45.5172414,17.3806897 L45.5172414,38.6206897 L13.7931034,38.6206897 Z"
                            id="Stroke-1"
                            stroke="#002873"
                            strokeWidth="4"
                            strokeLinecap="round"
                        ></path>
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1"></use>
                        </mask>
                        <g id="Clip-4"></g>
                        <polygon
                            id="Fill-3"
                            fill="#F0C419"
                            mask="url(#mask-2)"
                            points="1.37931034 78.6206897 59.3103448 78.6206897 59.3103448 34.4827586 1.37931034 34.4827586"
                        ></polygon>
                        <path
                            d="M30.3448276,62.0689655 L30.3448276,62.0689655 C28.0689655,62.0689655 26.2068966,60.2068966 26.2068966,57.9310345 L26.2068966,49.6551724 C26.2068966,47.3793103 28.0689655,45.5172414 30.3448276,45.5172414 C32.6206897,45.5172414 34.4827586,47.3793103 34.4827586,49.6551724 L34.4827586,57.9310345 C34.4827586,60.2068966 32.6206897,62.0689655 30.3448276,62.0689655"
                            id="Fill-5"
                            fill="#002873"
                            mask="url(#mask-2)"
                        ></path>
                        <polygon
                            id="Fill-6"
                            fill="#485762"
                            mask="url(#mask-2)"
                            points="1.37931034 78.6206897 59.3103448 78.6206897 59.3103448 70.3448276 1.37931034 70.3448276"
                        ></polygon>
                        <polygon
                            id="Stroke-7"
                            stroke="#231117"
                            strokeWidth="2"
                            strokeLinecap="round"
                            mask="url(#mask-2)"
                            points="1.37931034 78.6206897 59.3103448 78.6206897 59.3103448 34.4827586 1.37931034 34.4827586"
                        ></polygon>
                        <path
                            d="M13.7931034,34.4827586 L13.7931034,17.3806897 C13.7931034,9.33931034 20.9310345,2.75862069 29.6551724,2.75862069 C38.3793103,2.75862069 45.5172414,9.33931034 45.5172414,17.3806897 L45.5172414,34.4827586 L13.7931034,34.4827586 Z"
                            id="Stroke-8"
                            stroke="#231117"
                            strokeWidth="2"
                            strokeLinecap="round"
                            mask="url(#mask-2)"
                        ></path>
                        <polygon
                            id="Stroke-9"
                            stroke="#231117"
                            strokeWidth="2"
                            strokeLinecap="round"
                            mask="url(#mask-2)"
                            points="1.37931034 78.6206897 59.3103448 78.6206897 59.3103448 70.3448276 1.37931034 70.3448276"
                        ></polygon>
                    </g>
                </g>
            </g>
        </svg>
    )
}
