import { ExternalInvoiceState } from './externalInvoice.state'

export const initialState: ExternalInvoiceState = {
    loading: true,
    selectedPaymentMethod: null,
    error: {
        title: '',
        message: '',
        showError: false,
    },
    payerInfo: null,
    billingTotals: {
        subtotal: 0,
        fees: 0,
        total: 0,
        allFees: {},
        allTotals: {},
    },
    costInfo: {
        invoiceNumber: '',
        amount: 0,
        description: '',
        complete: false,
    },
    savedPaymentMethod: null,
    dialog: {
        open: false,
        loading: false,
        error: {
            title: '',
            message: '',
            showError: false,
        },
        success: false,
    },
    isAddingNewPaymentMethod: false,
    accountType: '',
}
