import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../reducers/app/RootState'
import { useHistory } from 'react-router'
import { Banner } from '@simplifeye/component-library'
import { Button } from '@material-ui/core'
import { logUserOutFromSession } from '../reducers/session/session.actions'
import { Redirect, useLocation } from 'react-router-dom'
import SectionSelect from './section-select/SectionSelect'
import { getPayerInfo, changeActiveSection } from '../reducers/header/header.action'
import { getPaymentPlans } from '../reducers/payment-plan/paymentPlan.action'
import { ActiveSection } from '../reducers/header/header.state'
import { shouldHeaderRedirectToExternalInvoiceLogin, shouldHeaderRedirectToLogin } from '../shared/utils'

import './Header.scss'
import HeaderUI from './HeaderUI'

const Header = () => {
    const [statePracticeId, setStatePracticeId] = useState('')
    const [stateLocationId, setStateLocationId] = useState('')
    const { portalUserId, payerId, activeSection, practiceId, locationId } = useSelector((state: RootState) => ({
        portalUserId: state.session.portalUserId,
        payerId: state.session.payerId,
        activeSection: state.header.activeSection,
        practiceId: state.session.practiceId,
        locationId: state.session.locationId,
    }))

    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
        dispatch(getPayerInfo(portalUserId))
        if (payerId !== '') {
            dispatch(getPaymentPlans())
        }
    }, [portalUserId, dispatch])

    useEffect(() => {
        setStatePracticeId(practiceId)
        setStateLocationId(locationId)
    }, [practiceId, locationId])

    useEffect(() => {
        if (activeSection !== location.pathname) {
            dispatch(changeActiveSection({ activeSection: location.pathname as ActiveSection }))
        }
    }, [activeSection, location, dispatch])

    if (!portalUserId) {
        if (shouldHeaderRedirectToLogin(location.pathname)) {
            return <Redirect to="/login" />
        } else {
            return null
        }
    } else {
        return <HeaderUI />
    }
}

export default Header
