import { PaymentMethodState } from './paymentMethods.state'
import { initialState } from './paymentMethods.initial'
import {
    REQUEST_PAYMENT_METHODS_SUCCESS,
    REQUEST_PAYMENT_METHODS_ERROR,
    NO_PAYMENT_METHODS_FOUND,
    START_DELETE_PAYMENT_METHOD,
    STOP_DELETE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD_SUCCESS,
    DELETE_PAYMENT_METHOD_ERROR,
    REQUEST_DELETE_PAYMENT_METHOD,
    START_DELETE_ACH_PAYMENT_METHOD,
    REQUEST_PAYMENT_METHODS,
} from './paymentMethods.action'

const paymentMethodReducer = (state: PaymentMethodState = initialState, action: any): PaymentMethodState => {
    switch (action.type) {
        case REQUEST_PAYMENT_METHODS:
            return {
                ...state,
                loading: true,
            }
        case REQUEST_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentMethods: action.payload.paymentMethods,
                achPaymentMethod: action.payload.achPaymentMethod,
                error: {
                    ...initialState.error,
                },
            }
        case REQUEST_PAYMENT_METHODS_ERROR:
            return {
                ...state,
                loading: false,
                error: { ...action.payload, showError: true },
            }
        case NO_PAYMENT_METHODS_FOUND:
            return {
                ...state,
                loading: false,
                paymentMethods: [],
                achPaymentMethod: null,
                error: {
                    showError: false,
                    title: '',
                    message: '',
                },
            }
        case START_DELETE_PAYMENT_METHOD:
            return {
                ...state,
                deleteDialog: {
                    show: true,
                    loading: false,
                    cardMethodToDelete: action.payload.method,
                    achMethodToDelete: null,
                    error: {
                        showError: false,
                        title: '',
                        message: '',
                    },
                },
            }
        case START_DELETE_ACH_PAYMENT_METHOD:
            return {
                ...state,
                deleteDialog: {
                    show: true,
                    loading: false,
                    cardMethodToDelete: null,
                    achMethodToDelete: action.payload.achMethod,
                    error: {
                        showError: false,
                        title: '',
                        message: '',
                    },
                },
            }
        case STOP_DELETE_PAYMENT_METHOD:
            return {
                ...state,
                deleteDialog: {
                    show: false,
                    loading: true,
                    cardMethodToDelete: null,
                    achMethodToDelete: null,
                    error: {
                        showError: false,
                        title: '',
                        message: '',
                    },
                },
            }

        case DELETE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                loading: true,
                deleteDialog: {
                    ...initialState.deleteDialog,
                },
            }

        case DELETE_PAYMENT_METHOD_ERROR:
            return {
                ...state,
                deleteDialog: {
                    ...state.deleteDialog,
                    loading: false,
                    error: {
                        showError: true,
                        title: action.payload.title,
                        message: action.payload.message,
                    },
                },
            }

        case REQUEST_DELETE_PAYMENT_METHOD:
            return {
                ...state,
                deleteDialog: {
                    ...state.deleteDialog,
                    show: true,
                    loading: true,
                },
            }
        default: {
            return { ...state }
        }
    }
}

export default paymentMethodReducer
