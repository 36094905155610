import { SFAlert } from '@simplifeye/component-library'
import React from 'react'
import { useDispatch } from 'react-redux'
import CurrencyInput from '../../../external-invoice/external-invoice-cost-info/currency-input/CurrencyInput'
import { onAmountToPayDownChange } from '../../../reducers/pay-down-plan-dialog/payDownPlanDialog.actions'
import { formatCurrencyCents } from '../../../shared/utils'
import { AchPaymentMethod } from '../../../types/AchPaymentMethod'
import { PaymentPlan } from '../../../types/PaymentPlan'
import { PayerPaymentMethods } from '../../../types/Response/PayerPaymentMethods'
import PaymentPlanExpander from '../../payment-plan-card/payment-plan-expander/PaymentPlanExpander'
import './PayDownPlanHowMuch.scss'

interface Props {
    paymentPlanSelected: string
    savedCreditDebit: PayerPaymentMethods
    savedAchPaymentMethod: AchPaymentMethod
    paymentPlanDetails: PaymentPlan
    amountToPayDown: number
    amountToPayDownLessThanMax: boolean
}

const PayDownPlanHowMuch = ({
    paymentPlanSelected,
    savedCreditDebit,
    savedAchPaymentMethod,
    paymentPlanDetails,
    amountToPayDown,
    amountToPayDownLessThanMax,
}: Props) => {
    const dispatch = useDispatch()

    const displayPaymentMethod = (
        savedCreditDebit: PayerPaymentMethods,
        savedAchPaymentMethod: AchPaymentMethod
    ): string => {
        if (savedCreditDebit) {
            return `${savedCreditDebit.cardType} ****${savedCreditDebit.last4}`
        } else {
            return `${savedAchPaymentMethod.bankName} ****${savedAchPaymentMethod.accountLast4}`
        }
    }

    return (
        <div className="pay-down-plan-dialog-inner pay-down-plan-how-much">
            <div className="selected-wrapper">
                <label htmlFor="description">Payment Plan</label>
                <div id="description">{paymentPlanSelected}</div>
            </div>
            <div className="selected-wrapper">
                <label htmlFor="description">Payment Method</label>
                <div id="description">{displayPaymentMethod(savedCreditDebit, savedAchPaymentMethod)}</div>
            </div>
            <div className="selected-wrapper">
                <label htmlFor="description">Balance Remaining</label>
                <div id="description">{formatCurrencyCents(paymentPlanDetails.balanceRemaining.subtotalBalance)}</div>
            </div>
            <div className="info">* Balance shown here does not include any tax or fees associated with the plan</div>
            <div className="select-wrapper">
                <h3 className="section-title">How much would you like to pay down?</h3>
                <label>Payment Amount *</label>
                <CurrencyInput
                    value={amountToPayDown}
                    onValueChange={(amountToPayDown) => {
                        dispatch(onAmountToPayDownChange({ amountToPayDown }))
                    }}
                />
                {amountToPayDown > 0 && !amountToPayDownLessThanMax ? (
                    <div className="above-amount-remaining">
                        <SFAlert showAlert={true} severity="error" message="Above balance remaining" title="Payment" />
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default PayDownPlanHowMuch
