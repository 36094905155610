import './InvoiceDetailsInfoAlert.scss'
import React from 'react'
import { formatCurrencyCents } from '../../shared/utils'
import { SFAlert } from '@simplifeye/component-library'

const InvoiceDetailsInfoAlert = ({ showAlert, amount }) => (
    <div className="invoice-details-new-method-alert">
        <SFAlert
            showAlert={showAlert}
            severity="info"
            title=""
            message={`Use Debit card for a discounted price of ${formatCurrencyCents(amount)}`}
        />
    </div>
)

export default InvoiceDetailsInfoAlert
