import { ApiResponse } from '../types/Response/ApiResponse'
import { PromiseToWrapper } from '../types/PromiseToWrapper'
import moment from 'moment'
import _ from 'lodash'

export const momentFormat = 'MM/DD/YYYY'

export const formatCurrency = (amount: number) => {
    if (amount === null || amount === undefined) {
        return '$0.00'
    }
    return '$' + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const formatCurrencyCents = (amount: number) => {
    return formatCurrency(amount / 100)
}

export const formatPercentRate = (decimal: number): string => {
    return `${decimal * 100}%`
}

export const promiseTo = <T>(promise: Promise<ApiResponse<T>>): Promise<PromiseToWrapper<ApiResponse<T>>> => {
    return promise
        .then((data: ApiResponse<T>) => {
            return { response: data, errors: null }
        })
        .catch(err => {
            return { response: null, errors: err }
        })
}

export const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return phoneNumberString
}

export const formatDate = (unformattedDate: string, format?: string, formatOfUnformattedData?: string) => {
    const unformatted = formatOfUnformattedData
        ? moment(unformattedDate, formatOfUnformattedData)
        : moment(unformattedDate)
    format = format ? format : momentFormat
    return unformatted.format(format)
}

export const apiResponseContainErrorCode = (response: ApiResponse<any>, errorCode: number): boolean => {
    return (
        response &&
        response.status === 'error' &&
        response.errors.filter(error => error.statusCode === errorCode).length >= 1
    )
}

export const formateDateObject = (unformatedDate: Date, format?: string) => {
    format = format ? format : momentFormat
    return moment(unformatedDate).format(format)
}

export const getDayOfMonth = (date: string, formatOfString?: string): string => {
    let momentDate

    if (formatOfString) {
        momentDate = moment(date, formatOfString).format('Do')
    } else {
        momentDate = moment(date).format('Do')
    }

    if (!momentDate) {
        return '-'
    } else {
        return momentDate
    }
}

export const validateEmail = (username: string): boolean => {
    const EMAIL_REGEX = /^.+@.+\..+$/

    if (username === '') {
        return false
    } else if (!EMAIL_REGEX.test(username)) {
        return false
    } else {
        return true
    }
}

export const handleCurrencyInputChange = (amount: string): string => {
    const costRegex = /[^0-9\.]*/g
    let newAmount = amount.replace(costRegex, '')
    const periodFirstIndex = amount.indexOf('.')

    // replace extra periods
    if (periodFirstIndex > -1 && amount.lastIndexOf('.') !== periodFirstIndex) {
        newAmount = newAmount.replace(/\./g, (val, index) => {
            return index === periodFirstIndex ? val : ''
        })
    }

    if (periodFirstIndex > -1) {
        // remove more than 2 numbers after decimal
        newAmount = newAmount.substr(0, periodFirstIndex + 2)
    }
    if (newAmount === '.') {
        return '0.'
    }
    return isNaN(parseFloat(newAmount)) ? '' : newAmount
}

export const handleFormatCurrencyInputBlur = (amount: string): string => {
    if (amount === '') {
        return amount
    }
    const costParts = amount.split('.')
    const secondPart = costParts.length === 2 ? `.${costParts[1]}${costParts[1].length === 1 ? '0' : ''}` : ''
    const newAmount = `${costParts[0].length > 0 ? parseInt(costParts[0]) : 0}${secondPart}`

    return isNaN(parseFloat(newAmount)) ? '' : newAmount
}

export const convertStringDollarsToCents = (amount: string): number => {
    const parsedAmount = parseFloat(amount)
    const finalAmount = isNaN(parsedAmount) ? 0 : parsedAmount
    return _.round(finalAmount * 100, 0)
}

export const shouldHeaderRedirectToLogin = (locationPathname: string) => {
    return (
        !locationPathname.includes('create') &&
        !locationPathname.includes('reset-password') &&
        !locationPathname.includes('/login/external-invoice') &&
        !locationPathname.includes('invoice')
    )
}

export const shouldHeaderRedirectToExternalInvoiceLogin = (locationPathname: string) => {
    return locationPathname.includes('/external-invoice') && !locationPathname.includes('/login/external-invoice')
}

export function dateToISO(mmddyyyy: string) {
    var date = mmddyyyy.split('/')
    return new Date(parseInt(date[2]), parseInt(date[0]) - 1, parseInt(date[1])).toISOString()
}

export const formatCurrency2 = (amount: number, shouldFormatAmount: boolean = true, customDollarSign?: string) => {
    if (amount === null || amount === undefined) {
        amount = 0
    }

    let stringAmt = ''
    const dollarSign = shouldFormatAmount ? (customDollarSign !== undefined ? customDollarSign : '$') : ''
    let roundedAmt = _.round(amount, 2)

    if (Number.isInteger(roundedAmt)) {
        stringAmt = `${roundedAmt}.00`
    } else if (Number.isInteger(roundedAmt * 10)) {
        stringAmt = `${roundedAmt}0`
    } else {
        stringAmt = `${roundedAmt}`
    }

    const finalAmt = shouldFormatAmount ? stringAmt.replace(/\d(?=(\d{3})+\.)/g, '$&,') : stringAmt

    return `${dollarSign}${finalAmt}`
}

export const convertNumCentsToDollars = (
    amount: number,
    shouldFormatAmount: boolean = false,
    customDollarSign?: string
): string => {
    return formatCurrency2(amount / 100, shouldFormatAmount, customDollarSign)
}
