import { SFDialog, SFErrorDialogContent, SFLoading } from '@simplifeye/component-library'
import DialogFooterProps from '@simplifeye/component-library/lib/component/SFDialog/types/DialogFooter.types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../reducers/app/RootState'
import './PayDownPlanDialog.scss'
import {
    onBackToSelectPaymentPlan,
    onGoToSelectPaymentMethod,
    payDownPlanCancel,
    onGoToHowMuch,
    onBackToSelectPaymentMethod,
    makeOffCyclePayment,
    onGoToConfirmation,
    onBackToChooseHowMuch,
} from '../../reducers/pay-down-plan-dialog/payDownPlanDialog.actions'
import { getPaymentPlans } from '../../reducers/payment-plan/paymentPlan.action'
import PayDownPlanSelectPlan from './pay-down-plan-select-plan/PayDownPlanSelectPlan'
import PayDownPlanSelectMethod from './pay-down-plan-select-method/PayDownPlanSelectMethod'
import PayDownPlanHowMuch from './pay-down-plan-how-much/PayDownPlanHowMuch'
import PayDownPlanConfirmation from './pay-down-plan-confirmation/PayDownPlanConfirmation'
import PayDownPlanSuccess from './pay-down-plan-success/PayDownPlanSuccess'
import { CircularProgress } from '@material-ui/core'

const PayDownPlanDialog = () => {
    const {
        show,
        loading,
        error,
        success,
        paymentPlanSelect,
        onSelectPaymentPlan,
        onSelectPaymentMethod,
        onChooseHowMuch,
        paymentPlanSelectedOption,
        balanceRemainingMarks,
        amountPaid,
        amountPaidPercentage,
        savedCreditDebit,
        savedAchPaymentMethod,
        paymentPlanSelected,
        paymentPlanDetails,
        amountToPayDown,
        amountToPayDownLessThanMax,
        isAddingNewPaymentMethod,
        isOnConfirmation,
        fees,
        offCyclePaymentResponse,
    } = useSelector((state: RootState) => state.payDownPlanDialog)
    const { paymentMethods, achPaymentMethod, loading: isLoadingPaymentMethods } = useSelector(
        (state: RootState) => state.paymentMethods
    )

    const [isSubmittingPayment, setIsSubmittingPayment] = useState(false)
    const [dialog, setDialog] = useState<{ content: React.ReactNode; footer: DialogFooterProps }>({
        content: null,
        footer: null,
    })

    const dispatch = useDispatch()

    useEffect(() => {
        if (loading) {
            setDialog({
                content: (
                    <div className="pay-down-loading">
                        <SFLoading />
                    </div>
                ),
                footer: null,
            })
        } else if (error.showError) {
            setDialog({
                content: (
                    <div className="pay-down-message">
                        <SFErrorDialogContent
                            title={error.title}
                            message={error.message}
                            messageJson={error.messageJson}
                        />
                    </div>
                ),
                footer: {
                    footerButtons: {
                        centerButton: {
                            text: 'Close',
                            action: () => {
                                dispatch(payDownPlanCancel())
                            },
                        },
                    },
                },
            })
        } else if (onSelectPaymentPlan) {
            setDialog({
                content: (
                    <PayDownPlanSelectPlan
                        paymentPlanSelect={paymentPlanSelect}
                        paymentPlanSelected={paymentPlanSelected}
                    />
                ),
                footer: {
                    footerButtons: {
                        twoButtons: {
                            leftButton: {
                                text: 'Cancel',
                                action: () => {
                                    dispatch(payDownPlanCancel())
                                },
                            },
                            rightButton: {
                                text: 'Next',
                                action: () => {
                                    dispatch(onGoToSelectPaymentMethod())
                                },
                                disabled: !paymentPlanSelected,
                            },
                        },
                    },
                },
            })
        } else if (onSelectPaymentMethod) {
            const planTextIndex = paymentPlanSelect.findIndex(pps => pps.value === paymentPlanSelected)

            setDialog({
                content: (
                    <PayDownPlanSelectMethod
                        isLoadingPaymentMethods={isLoadingPaymentMethods}
                        paymentPlanSelected={paymentPlanSelect[planTextIndex].text}
                        paymentMethods={paymentMethods}
                        achPaymentMethod={achPaymentMethod}
                    />
                ),
                footer: {
                    footerButtons: {
                        twoButtons: {
                            leftButton: {
                                classes: paymentPlanSelect.length === 1 ? ['extra-pay-hide-btn'] : undefined,
                                text: 'Back',
                                action: () => {
                                    dispatch(onBackToSelectPaymentPlan())
                                },
                            },
                            rightButton: {
                                text: 'Next',
                                action: () => {
                                    dispatch(onGoToHowMuch())
                                },
                                disabled: !savedCreditDebit && !savedAchPaymentMethod && !isAddingNewPaymentMethod,
                            },
                        },
                    },
                },
            })
        } else if (onChooseHowMuch) {
            setDialog({
                content: (
                    <PayDownPlanHowMuch
                        paymentPlanSelected={paymentPlanSelected}
                        savedCreditDebit={savedCreditDebit}
                        savedAchPaymentMethod={savedAchPaymentMethod}
                        paymentPlanDetails={paymentPlanDetails}
                        amountToPayDown={amountToPayDown}
                        amountToPayDownLessThanMax={amountToPayDownLessThanMax}
                    />
                ),
                footer: {
                    footerButtons: {
                        twoButtons: {
                            leftButton: {
                                text: 'Back',
                                action: () => {
                                    dispatch(onBackToSelectPaymentMethod())
                                },
                            },
                            rightButton: {
                                text: 'Next',
                                action: () => {
                                    dispatch(onGoToConfirmation())
                                },
                                disabled: amountToPayDown === 0 || !amountToPayDownLessThanMax,
                            },
                        },
                    },
                },
            })
        } else if (isOnConfirmation) {
            setDialog({
                content: (
                    <PayDownPlanConfirmation
                        paymentPlanSelected={paymentPlanSelected}
                        savedCreditDebit={savedCreditDebit}
                        savedAchPaymentMethod={savedAchPaymentMethod}
                        fees={fees}
                    />
                ),
                footer: {
                    footerButtons: {
                        twoButtons: {
                            leftButton: {
                                text: 'Back',
                                action: () => {
                                    dispatch(onBackToChooseHowMuch())
                                },
                                disabled: isSubmittingPayment,
                            },
                            rightButton: {
                                classes: [
                                    'pay-down-submit-btn',
                                    isSubmittingPayment ? 'pay-down-submit-btn--submitting' : '',
                                ],
                                text: !isSubmittingPayment
                                    ? 'Make Payment'
                                    : (((
                                          <CircularProgress className="pay-down-submit-loading" size={25} />
                                      ) as unknown) as string),
                                action: async () => {
                                    if (!isSubmittingPayment) {
                                        setIsSubmittingPayment(true)
                                        await dispatch(makeOffCyclePayment())
                                        setIsSubmittingPayment(false)
                                    }
                                },
                            },
                        },
                    },
                },
            })
        } else if (success) {
            setDialog({
                content: (
                    <PayDownPlanSuccess
                        monthlyRemaining={offCyclePaymentResponse.monthlyPayment}
                        balanceRemaining={offCyclePaymentResponse.balanceRemaining.total}
                        isPaidOff={offCyclePaymentResponse.paymentPlanStatus === 'paid_off'}
                    />
                ),
                footer: {
                    footerButtons: {
                        centerButton: {
                            text: 'Close',
                            action: () => {
                                dispatch(payDownPlanCancel())
                                dispatch(getPaymentPlans())
                            },
                        },
                    },
                },
            })
        }
    }, [
        show,
        loading,
        error,
        success,
        paymentPlanSelect,
        paymentPlanSelectedOption,
        balanceRemainingMarks,
        amountPaid,
        amountPaidPercentage,
        onSelectPaymentPlan,
        paymentMethods,
        achPaymentMethod,
        savedCreditDebit,
        savedAchPaymentMethod,
        paymentPlanSelected,
        paymentPlanDetails,
        amountToPayDown,
        amountToPayDownLessThanMax,
        isAddingNewPaymentMethod,
        isOnConfirmation,
        fees,
        onChooseHowMuch,
        onSelectPaymentMethod,
        offCyclePaymentResponse,
        dispatch,
    ])

    return (
        <SFDialog
            open={show}
            title="Make an Extra Payment"
            onClose={() => {
                if (!isSubmittingPayment) {
                    dispatch(payDownPlanCancel())
                    dispatch(getPaymentPlans())
                }
            }}
            content={dialog.content}
            footer={dialog.footer}
            styleClass="pay-down-plan-dialog"
        />
    )
}

export default PayDownPlanDialog
