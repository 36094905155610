import { StripeManualCheckoutDialogState } from './stripeManualCheckoutDialog.state'

export const initialState: StripeManualCheckoutDialogState = {
    loading: false,
    show: false,
    clientSecret: '',
    chargeEventId: '',
    paymentIntentId: '',
    success: false,
    paymentMethod: null,
    error: {
        title: '',
        message: '',
        messageJson: '',
        showError: false,
    },
    billingTotals: {
        subtotal: 0,
        tax: 0,
        fee: 0,
        total: 0,
        allFees: {},
        allTotals: {},
    },
    redirect: false,
}
