import { SFDialog, SFErrorDialogContent, SFLoading, SFSuccessDialogContent } from '@simplifeye/component-library'
import DialogFooterProps from '@simplifeye/component-library/lib/component/SFDialog/types/DialogFooter.types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { RootState } from '../../reducers/app/RootState'
import {
    onPayExternalInvoiceUseExisting,
    onPayExternalInvoiceCancel,
} from '../../reducers/external-invoice/externalInvoice.action'
import { logUserOutFromSession } from '../../reducers/session/session.actions'
import { formatCurrencyCents, formateDateObject } from '../../shared/utils'
import './ExternalInvoiceDialog.scss'
import { CircularProgress } from '@material-ui/core'

const ExternalInvoiceDialog = () => {
    const { dialog, billingTotals, costInfo, savedPaymentMethod } = useSelector(
        (state: RootState) => state.externalInvoice
    )

    const dispatch = useDispatch()
    const history = useHistory()

    const [isSubmittingPayment, setIsSubmittingPayment] = useState(false)
    const [dialogContent, setDialogContent] = useState<{
        content: any
        footer: DialogFooterProps
        title: string
        onClose: () => void
    }>({
        content: null,
        footer: null,
        title: '',
        onClose: () => {},
    })

    useEffect(() => {
        if (dialog.loading) {
            setDialogContent({
                title: 'Make a Payment',
                content: (
                    <div className="external-invoice-loading">
                        <SFLoading />
                    </div>
                ),
                footer: {
                    footerButtons: {
                        centerButton: {
                            text: 'Cancel',
                            action: () => {},
                            classes: [],
                            disabled: true,
                        },
                    },
                },
                onClose: () => {},
            })
        } else if (dialog.error.showError) {
            setDialogContent({
                title: 'Error Submitting Payment',
                content: (
                    <div className="stripe-dialog-content">
                        <div className="success-error-wrapper">
                            <SFErrorDialogContent title={dialog.error.title} message={dialog.error.message} />
                        </div>
                    </div>
                ),
                footer: {
                    footerButtons: {
                        centerButton: {
                            text: 'Dismiss',
                            action: () => {
                                dispatch(onPayExternalInvoiceCancel())
                            },
                            classes: [],
                        },
                    },
                },
                onClose: () => {
                    dispatch(onPayExternalInvoiceCancel())
                },
            })
        } else if (dialog.success) {
            setDialogContent({
                title: 'Payment Successfull',
                content: (
                    <div className="stripe-dialog-content">
                        <div className="success-error-wrapper">
                            <SFSuccessDialogContent
                                title="Payment Succeeded"
                                message={`A receipt has been emailed to you! This payment may take a few minutes to reflect on your profile`}
                            />
                        </div>
                    </div>
                ),
                footer: {
                    footerButtons: {
                        centerButton: {
                            text: 'Logout',
                            action: () => {
                                dispatch(logUserOutFromSession())
                                history.push('/login')
                            },
                            classes: [],
                        },
                    },
                },
                onClose: () => {
                    dispatch(logUserOutFromSession())
                    history.push('/login')
                },
            })
        } else {
            setDialogContent({
                title: 'Make a Payment',
                content: (
                    <div className="stripe-dialog-content">
                        <div className="existing-payment-details">
                            <h3>You're about to submit a payment. Please confirm the details below:</h3>
                            <div className="details-wrapper">
                                <div className="detail">
                                    <label htmlFor="description">Invoice #</label>
                                    <div id="description">{costInfo.invoiceNumber}</div>
                                </div>
                                <div className="detail">
                                    <label htmlFor="date">Date</label>
                                    <div id="date">{formateDateObject(new Date(), 'MM/DD/YYYY')}</div>
                                </div>
                                <div className="detail">
                                    <label htmlFor="method">Payment Method</label>
                                    {savedPaymentMethod ? (
                                        <div id="method">{`${savedPaymentMethod.cardType} ****${savedPaymentMethod.last4}`}</div>
                                    ) : null}

                                    {/* {isUsingSavedAchMethod ? (
                                        <div id="method">{`${achPaymentMethod.bankName} ****${achPaymentMethod.accountLast4}`}</div>
                                    ) : (
                                        <div id="method">{`${paymentMethod.cardType} ****${paymentMethod.last4}`}</div>
                                    )} */}
                                </div>
                            </div>
                        </div>
                        <div className="cost-summary-wrapper">
                            <div className="cost-summary treatment-cost">
                                <h4>Subtotal</h4>
                                <span>{formatCurrencyCents(billingTotals.subtotal)}</span>
                            </div>
                            <div className="cost-summary convenience-fee">
                                <h4>Convenience Fee</h4>
                                <span>{formatCurrencyCents(billingTotals.fees)}</span>
                            </div>
                            <div className="cost-summary payment-due">
                                <h4>Payment Due</h4>
                                <span>{formatCurrencyCents(billingTotals.total)}</span>
                            </div>
                        </div>
                    </div>
                ),
                footer: {
                    footerButtons: {
                        twoButtons: {
                            leftButton: {
                                text: 'Cancel',
                                action: () => {
                                    dispatch(onPayExternalInvoiceCancel())
                                },
                                disabled: isSubmittingPayment,
                            },
                            rightButton: {
                                classes: [
                                    'external-invoice-submit-btn',
                                    isSubmittingPayment ? 'external-invoice-submit-btn--submitting' : '',
                                ],
                                text: !isSubmittingPayment
                                    ? 'Submit Payment'
                                    : (((
                                          <CircularProgress className="external-invoice-submit-loading" size={25} />
                                      ) as unknown) as string),
                                action: async () => {
                                    if (!isSubmittingPayment) {
                                        setIsSubmittingPayment(true)
                                        await dispatch(onPayExternalInvoiceUseExisting())
                                        setIsSubmittingPayment(false)
                                    }
                                },
                            },
                        },
                    },
                },
                onClose: () => {
                    if (!isSubmittingPayment) {
                        dispatch(onPayExternalInvoiceCancel())
                    }
                },
            })
        }
    }, [billingTotals, costInfo, dialog])

    return (
        <SFDialog
            open={dialog.open}
            title={dialogContent.title}
            onClose={dialogContent.onClose}
            content={dialogContent.content}
            footer={dialogContent.footer}
            styleClass="external-invoice-dialog"
        />
    )
}

export default ExternalInvoiceDialog
