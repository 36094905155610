import React, { useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { RootState } from '../../../reducers/app/RootState'
import InputBox from '../../../ui/Input'
import { SFLoading } from '@simplifeye/component-library'

import './AddPaymentForm.scss'

interface Props {
    addMethodError: (payload: { title: string; message: string; messageJson: string }) => void
    cancel: () => void
    onStripeAddCardSuccess: (payload: { paymentMethodCardId: string }) => void
}

const AddPaymentForm = ({ onStripeAddCardSuccess, addMethodError, cancel }: Props) => {
    const stripe = useStripe()
    const elements = useElements()
    const [name, setName] = useState('')
    const [showNameError, setShowNameError] = useState(false)
    const [stripeInfoComplete, setStripeInfoComplete] = useState(false)
    const [stripeErrorMessage, setStripeErrorMessage] = useState('')
    const [canSubmitStripe, setCanSubmitStripe] = useState(false)
    const [requestSent, setRequestSent] = useState(false)
    const dispatch = useDispatch()

    const { clientSecret } = useSelector((state: RootState) => {
        return {
            clientSecret: state.addNewMethod.clientSecret,
        }
    })

    const cardStyleOptions = {
        style: {
            base: {
                backgroundColor: '#ffffff',
                fontSize: '16px',
                color: '#ABABAB',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#ABABAB',
                },
            },
            invalid: {
                color: '#c82323',
            },
        },
    }

    const handleSubmitPayment = async () => {
        if (!elements || !stripe) {
            return
        }
        const cardElement = elements.getElement(CardElement)

        if (cardElement) {
            setCanSubmitStripe(false)
            setRequestSent(true)
            const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: name,
                    },
                },
            })
            if (setupIntent) {
                dispatch(onStripeAddCardSuccess({ paymentMethodCardId: setupIntent.payment_method }))
            } else {
                dispatch(
                    addMethodError({ title: error.code, message: error.message, messageJson: JSON.stringify(error) })
                )
            }
        }
    }

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        setShowNameError(!e.target.value)
        checkCanSubmitStripe(e.target.value, stripeInfoComplete)
    }

    const handleStripeInputChange = (event: StripeCardElementChangeEvent) => {
        if (event.complete) {
            setStripeInfoComplete(true)
            setStripeErrorMessage('')
            checkCanSubmitStripe(name, true)
        } else {
            setStripeInfoComplete(false)
            checkCanSubmitStripe(name, false)
            if (event.error) {
                setStripeErrorMessage(event.error.message)
            }
        }
    }

    const checkCanSubmitStripe = (name: string, stripeInfoComplete: boolean) => {
        if (stripeInfoComplete && !!name) {
            setCanSubmitStripe(true)
        } else {
            setCanSubmitStripe(false)
        }
    }

    if (!stripe && !elements) {
        return <div>Error setting up stripe</div>
    } else {
        return (
            <div className="checkout-form add-payment-form">
                {requestSent ? (
                    <div className="loading">
                        <SFLoading />
                    </div>
                ) : null}
                <form>
                    <div className="center-wrapper">
                        <div className="checkout-header">
                            <span className="big">Card Information</span>
                            <span className="small">(all fields required)</span>
                        </div>
                        <div className="input-container">
                            <label>Card</label>
                            <div className="card-element-wrapper">
                                <CardElement onChange={handleStripeInputChange} options={cardStyleOptions} />
                            </div>
                            <div className="error" role="alert">
                                {stripeErrorMessage}
                            </div>
                        </div>
                        <div className="input-container">
                            <label>Name on Card</label>
                            <InputBox
                                name="cardHolderName"
                                value={name}
                                onChange={handleNameChange}
                                showError={showNameError}
                                errorText="Name is required"
                            />
                        </div>
                    </div>
                    <div className="button-wrapper">
                        <div className="center-wrapper">
                            <Button
                                className="review-payment-btn cancel"
                                onClick={() => {
                                    dispatch(cancel())
                                }}
                                disabled={false}
                            >
                                <span className="review-payment">Cancel</span>
                            </Button>
                            <Button
                                className="review-payment-btn pay"
                                onClick={handleSubmitPayment}
                                disabled={!canSubmitStripe}
                            >
                                <span className="review-payment">Add New Method</span>
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default AddPaymentForm
