import { Dispatch } from 'react'
import { AccountProcessingTypes, InvoicePaymentMethodsType } from '../../types/Constants'
import { RootState } from '../app/RootState'
import { getPaymentMethods } from '../payment-methods/paymentMethods.action'
import Api from '../../api/Api'
import { PayerInfoResponse } from '../../types/Response/PayerInfoResponse'
import { PayerPaymentMethods } from '../../types/Response/PayerPaymentMethods'
import { CalculateFeesForSubtotalResponse } from '../../types/Response/CalculateFeesForSubtotalResponse'
import {
    resetStripeManualDialog,
    onSuccessRedirect,
    setupStripeDialog,
} from '../stripe-manual-checkout-dialog/stripeManualCheckoutDialog.actions'
import { logUserOutFromSession, setExternalInvoiceInfoFromSession } from '../session/session.actions'
import { addNewMethodLoading } from '../add-new-method/addNewMethod.actions'
import SessionManager from '../../../SessionManager'
import { ExternalInvoiceBillingTotals, ExternalInvoiceCostInfo } from './externalInvoice.state'
import { PatientInfo } from '../../external-invoice/external-invoice-patient-info/ExternalInvoicePatientInfo'

const LOAD_EXTERNAL_INVOICE_DATA_SUCCESS = '[EXTERNAL INVOICE] LOAD EXTERNAL INVOICE DATA SUCCESS'
const LOAD_EXTERNAL_INVOICE_DATA_ERROR = '[EXTERNAL INVOICE] LOAD EXTRNAL INVOICE DATA FAILURE'
const ON_PAY_EXTERNAL_INVOICE_SUCCESS = '[EXTERNAL INVOICE] ON PAY EXTERNAL INVOICE SUCCESS'
const ON_PAY_EXTERNAL_INVOICE_ERROR = '[EXTERNAL INVOICE] ON PAY EXTERNAL INVOICE ERROR'
const CALCULATE_FEES_ERROR = '[EXTERNAL INVOICE] CALCULATE FEES ERROR'
const ON_COST_INFO_COMPLETE = '[EXTERNAL INVOICE] ON_COST_INFO COMPLETE'
const UPDATE_COST_INFO = '[EXTERNAL INVOICE] UPDATE COST INFO'
const EDIT_COST_INFO = '[EXTERNAL INVOICE] EDIT COST INFO'
const ON_USE_EXISTING_PAYMENT_METHOD_SUCCESS = '[EXTERNAL INVOICE] ON USE EXISTING PAYMENT METHOD SUCCESS'
const ON_PAY_EXTERNAL_INVOICE_CANCEL = '[EXTERNAL INVOICE] ON PAY EXTERNAL INVOICE CANCEL'
const REQUEST_CREATE_VOLUNTARY_INVOICE = '[EXTERNAL INVOICE] REQUEST CREATE VOLUNTARY INVOICE'
const ON_ADD_NEW_PAYMENT_METHOD = '[EXTERNAL INVOICE] ON ADD NEW PAYMENT METHOD'
const UPDATE_NEW_PAYMENT_METHOD_SELECT = '[EXTERNAL INVOICE] UPDATE NEW PAYMENT METHOD SELECT'
const ON_NEW_PAYMENT_METHOD_SELECTION_ACTION_SUCCESS =
    '[EXTERNAL INVOICE] ON NEW PAYMENT METHOD SELECTION ACTION SUCCESS'
const SHOW_EXTERNAL_INVOICE_DIALOG = '[EXTERNAL INVOICE] SHOW EXTERNAL INVOICE DIALOG'
const CREATE_INVOICE_FOR_ADDING_NEW_METHOD_ERROR = '[EXTERNAL INVOICE] CREATE INVOICE FOR ADDING NEW METHOD ERROR'

export type Action =
    | {
          type: typeof LOAD_EXTERNAL_INVOICE_DATA_SUCCESS
          payload: { payerInfoResponse: PayerInfoResponse; accountType: AccountProcessingTypes | '' }
      }
    | { type: typeof LOAD_EXTERNAL_INVOICE_DATA_ERROR; payload: { title: string; message: string } }
    | { type: typeof ON_PAY_EXTERNAL_INVOICE_SUCCESS }
    | { type: typeof ON_PAY_EXTERNAL_INVOICE_ERROR; payload: { title: string; message: string } }
    | { type: typeof ON_COST_INFO_COMPLETE; payload: { invoiceNumber: string; amount: number; description: string } }
    | { type: typeof UPDATE_COST_INFO; payload: { invoiceNumber: string; amount: number; description: string } }
    | { type: typeof EDIT_COST_INFO }
    | { type: typeof CALCULATE_FEES_ERROR; payload: { title: string; message: string } }
    | {
          type: typeof ON_USE_EXISTING_PAYMENT_METHOD_SUCCESS
          payload: {
              savedPaymentMethod: PayerPaymentMethods
              calculateFeeResponse: CalculateFeesForSubtotalResponse
          }
          cardType: InvoicePaymentMethodsType
      }
    | { type: typeof ON_PAY_EXTERNAL_INVOICE_CANCEL }
    | { type: typeof REQUEST_CREATE_VOLUNTARY_INVOICE }
    | { type: typeof ON_ADD_NEW_PAYMENT_METHOD }
    | { type: typeof UPDATE_NEW_PAYMENT_METHOD_SELECT; payload: { paymentMethodType: InvoicePaymentMethodsType } }
    | {
          type: typeof ON_NEW_PAYMENT_METHOD_SELECTION_ACTION_SUCCESS
          payload: { calculateFeeResponse: CalculateFeesForSubtotalResponse }
          cardType: InvoicePaymentMethodsType
      }
    | { type: typeof SHOW_EXTERNAL_INVOICE_DIALOG }
    | { type: typeof CREATE_INVOICE_FOR_ADDING_NEW_METHOD_ERROR; payload: { title: string; message: string } }

const loadExternalInvoiceData = (practiceId: string, locationId: string) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            // const {
            //     data: { accountType },
            // } = await Api.getPracticeLocationFee(practiceId, locationId)

            // const { data } = await Api.getPayerInfo(portalUserId)
            SessionManager.sessionLocationId = locationId
            SessionManager.sessionPracticeId = practiceId

            dispatch(
                setExternalInvoiceInfoFromSession({
                    practiceId: practiceId,
                    locationId: locationId,
                })
            )
            return dispatch(loadExternalInvoiceDataSuccess({ payerInfoResponse: { payers: [] }, accountType: '' }))
        } catch (err) {
            return dispatch(
                loadExternalInvoiceDataFailure({
                    title: 'External Invoice Error',
                    message: `Unable to get needed information to setup payment process`,
                })
            )
        }
    }
}

const loadExternalInvoiceDataSuccess = (payload: {
    payerInfoResponse: PayerInfoResponse
    accountType: AccountProcessingTypes | ''
}) => {
    return {
        type: LOAD_EXTERNAL_INVOICE_DATA_SUCCESS,
        payload,
    }
}

const loadExternalInvoiceDataFailure = (payload: { title: string; message: string }) => {
    return {
        type: LOAD_EXTERNAL_INVOICE_DATA_ERROR,
        payload,
    }
}

const onNewPaymentMethodSelectAction = (payload: {
    paymentMethodType: InvoicePaymentMethodsType
    amount: number
    practiceId: string
    locationId: string
}) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        // const {
        //     session: { practiceId, locationId },
        //     externalInvoice: {
        //         costInfo: { amount },
        //     },
        // } = getState()

        dispatch(updateNewPaymentMethodSelect(payload))

        try {
            const { data } = await Api.calculateFeesForGivenSubtotal(
                payload.practiceId,
                payload.locationId,
                payload.paymentMethodType,
                [payload.amount],
                'external_invoice',
                false
            )
            const finalPaymentMethod =
                payload.paymentMethodType === 'otp' ? ('card' as InvoicePaymentMethodsType) : payload.paymentMethodType

            dispatch(
                loadExternalInvoiceDataSuccess({ payerInfoResponse: { payers: [] }, accountType: data.accountType })
            )
            return dispatch(onNewPaymentMethodSelectActionSuccess({ calculateFeeResponse: data }, finalPaymentMethod))
        } catch (err) {
            return dispatch(
                calculateFeesError({
                    title: 'Calculate Fees',
                    message: `Unable to calculate fees for ${payload.practiceId} and ${payload.locationId}`,
                })
            )
        }
    }
}

const onNewPaymentMethodSelectActionSuccess = (
    payload: { calculateFeeResponse: CalculateFeesForSubtotalResponse },
    cardType: InvoicePaymentMethodsType
) => {
    return {
        type: ON_NEW_PAYMENT_METHOD_SELECTION_ACTION_SUCCESS,
        payload,
        cardType,
    }
}

const updateNewPaymentMethodSelect = (payload: { paymentMethodType: InvoicePaymentMethodsType }) => {
    return {
        type: UPDATE_NEW_PAYMENT_METHOD_SELECT,
        payload,
    }
}

const onAddNewPaymentMethod = () => {
    return {
        type: ON_ADD_NEW_PAYMENT_METHOD,
    }
}

const onPayExternalInvoiceStart = (costInfo: ExternalInvoiceCostInfo, patientInfo: PatientInfo) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState): Promise<any> => {
        const {
            externalInvoice: { isAddingNewPaymentMethod },
        } = getState()

        if (isAddingNewPaymentMethod) {
            return dispatch(createInvoiceForAddingNewMethod(costInfo, patientInfo))
        } else {
            return dispatch(showExternalInvoiceDialog())
        }
    }
}

const createInvoiceForAddingNewMethod = (costInfo: ExternalInvoiceCostInfo, patientInfo: PatientInfo) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { practiceId, locationId, payerId },
            externalInvoice: { selectedPaymentMethod, billingTotals, accountType },
        } = getState()

        let getPatientResult
        try {
            getPatientResult = await Api.getExternalInvoiceExistingPatient(
                practiceId,
                patientInfo.firstName,
                patientInfo.lastName,
                patientInfo.dob
            )
        } catch (err) {
            return {
                type: 'patient-error',
                name: `${patientInfo.firstName} ${patientInfo.lastName}`,
                message: (err as any).errors && (err as any).errors[0] ? (err as any).errors[0].message : null,
            }
        }

        try {
            const { data } = await Api.createVoluntaryInvoice(
                practiceId,
                locationId,
                // payerId,
                getPatientResult.data.patientId,
                {
                    paymentMethod: selectedPaymentMethod,
                    description: patientInfo.invoiceId || 'None provided',
                    subtotal: costInfo.amount,
                },
                accountType
            )
            return dispatch(
                setupStripeDialog({
                    clientSecret: data.clientSecret,
                    chargeEventId: data.chargeEventId,
                    paymentIntentId: data.paymentIntentId,
                    paymentMethod: selectedPaymentMethod,
                    billingTotals: {
                        subtotal: billingTotals.subtotal,
                        tax: 0,
                        fee: billingTotals.fees,
                        total: billingTotals.total,
                        allFees: billingTotals.allFees,
                        allTotals: billingTotals.allTotals,
                    },
                })
            )
        } catch (err) {
            return { type: 'invoice-error' }
            // return dispatch(
            //     createInvoiceForAddingNewMethodError({
            //         title: 'Invoice Creation',
            //         message: `Unable to create invoice for ${payerId} and ${getPatientResult.data.patientId}`,
            //     })
            // )
        }
    }
}

const createInvoiceForAddingNewMethodError = (payload: { title: string; message: string }) => {
    return {
        type: CREATE_INVOICE_FOR_ADDING_NEW_METHOD_ERROR,
        payload,
    }
}

const showExternalInvoiceDialog = () => {
    return {
        type: SHOW_EXTERNAL_INVOICE_DIALOG,
    }
}

const calculateFeesError = (payload: { title: string; message: string }) => {
    return {
        type: CALCULATE_FEES_ERROR,
        payload,
    }
}

const costInfoComplete = (payload: { invoiceNumber: string; amount: number; description: string }) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch(updateCostInfo(payload))
            return dispatch(getPaymentMethods())
        } catch (err) {
            return dispatch(
                loadExternalInvoiceDataFailure({ title: 'Error', message: 'Error getting payment methods' })
            )
        }
    }
}

const updateCostInfo = (payload: { invoiceNumber: string; amount: number; description: string }) => {
    return {
        type: UPDATE_COST_INFO,
        payload,
    }
}

const editCostInfo = () => {
    return {
        type: EDIT_COST_INFO,
    }
}

const onUseExistingPaymentMethod = (payload: { savedPaymentMethod: PayerPaymentMethods }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { practiceId, locationId },
            externalInvoice: {
                costInfo: { amount },
            },
        } = getState()

        try {
            const { data } = await Api.calculateFeesForGivenSubtotal(
                practiceId,
                locationId,
                payload.savedPaymentMethod.cardFundingType,
                [amount],
                'external_invoice',
                false
            )
            return dispatch(
                onUseExistingPaymentMethodSuccess(
                    {
                        savedPaymentMethod: payload.savedPaymentMethod,
                        calculateFeeResponse: data,
                    },
                    payload.savedPaymentMethod.cardFundingType as InvoicePaymentMethodsType
                )
            )
        } catch (err) {
            return dispatch(
                calculateFeesError({
                    title: 'Calculate Fees',
                    message: `Unable to calculate fees for ${practiceId} and ${locationId}`,
                })
            )
        }
    }
}

const onUseExistingPaymentMethodSuccess = (
    payload: {
        savedPaymentMethod: PayerPaymentMethods
        calculateFeeResponse: CalculateFeesForSubtotalResponse
    },
    cardType: InvoicePaymentMethodsType
) => {
    return {
        type: ON_USE_EXISTING_PAYMENT_METHOD_SUCCESS,
        payload,
        cardType,
    }
}

const onPayExternalInvoiceUseExisting = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { practiceId, locationId, payerId, patientId },
            externalInvoice: { savedPaymentMethod, costInfo, accountType },
        } = getState()

        dispatch(requestCreateVoluntaryInvoice())

        try {
            await Api.createAndChargeVoluntaryInvoice(
                practiceId,
                locationId,
                payerId,
                patientId,
                {
                    paymentMethod: savedPaymentMethod.cardFundingType,
                    description: costInfo.description,
                    subtotal: costInfo.amount,
                    tokenId: savedPaymentMethod.tokenId,
                },
                accountType
            )
            return dispatch(onPayExternalInvoiceSuccess())
        } catch (err) {
            return dispatch(
                onPayExternalInvoiceError({ title: 'Error', message: 'Unable to process payment at this time' })
            )
        }
    }
}

const onPayExternalInvoiceSuccess = () => {
    return {
        type: ON_PAY_EXTERNAL_INVOICE_SUCCESS,
    }
}

const onPayExternalInvoiceError = (payload: { title: string; message: string }) => {
    return {
        type: ON_PAY_EXTERNAL_INVOICE_ERROR,
        payload,
    }
}

const onPayExternalInvoiceCancel = () => {
    return {
        type: ON_PAY_EXTERNAL_INVOICE_CANCEL,
    }
}

const requestCreateVoluntaryInvoice = () => {
    return {
        type: REQUEST_CREATE_VOLUNTARY_INVOICE,
    }
}

const onPayExternalInvoiceUseNewSuccess = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(onSuccessRedirect())
        dispatch(logUserOutFromSession())
    }
}

const onPayExternalInvoiceUseExistingCancel = () => {
    return async (dispatch: Dispatch<any>) => {
        return dispatch(resetStripeManualDialog())
    }
}

const sendExternalInvoiceEmailReceipt = (chargeEventId: string, email: string) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const sendEmailData = await Api.sendExternalInvoiceEmailReceipt(chargeEventId, email)
            return
        } catch (err) {
            dispatch(onPayExternalInvoiceError({ title: 'Error', message: 'Unable to process payment at this time' }))
            throw err
        }
    }
}

export {
    LOAD_EXTERNAL_INVOICE_DATA_SUCCESS,
    LOAD_EXTERNAL_INVOICE_DATA_ERROR,
    ON_PAY_EXTERNAL_INVOICE_SUCCESS,
    ON_PAY_EXTERNAL_INVOICE_ERROR,
    UPDATE_COST_INFO,
    EDIT_COST_INFO,
    CALCULATE_FEES_ERROR,
    ON_USE_EXISTING_PAYMENT_METHOD_SUCCESS,
    ON_PAY_EXTERNAL_INVOICE_CANCEL,
    REQUEST_CREATE_VOLUNTARY_INVOICE,
    ON_ADD_NEW_PAYMENT_METHOD,
    UPDATE_NEW_PAYMENT_METHOD_SELECT,
    ON_NEW_PAYMENT_METHOD_SELECTION_ACTION_SUCCESS,
    SHOW_EXTERNAL_INVOICE_DIALOG,
    CREATE_INVOICE_FOR_ADDING_NEW_METHOD_ERROR,
    loadExternalInvoiceData,
    onNewPaymentMethodSelectAction,
    onPayExternalInvoiceStart,
    costInfoComplete,
    editCostInfo,
    onUseExistingPaymentMethod,
    onPayExternalInvoiceUseExisting,
    onPayExternalInvoiceCancel,
    onAddNewPaymentMethod,
    onPayExternalInvoiceUseNewSuccess,
    onPayExternalInvoiceUseExistingCancel,
    sendExternalInvoiceEmailReceipt,
}
