import React, { useEffect, useState } from 'react'
import { SFDialog, SFErrorDialogContent, SFSuccessDialogContent, SFLoading } from '@simplifeye/component-library'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../reducers/app/RootState'
import './AddPaymentMethodDialog.scss'
import StripeAddPaymentMethod from './StripeAddPaymentMethod/StripeAddPaymentMethod'
import CardOrBankSelect from './card-or-bank-select/CardOrBankSelect'
import { InvoicePaymentMethodsType } from '../types/Constants'
import DialogFooterProps from '@simplifeye/component-library/lib/component/SFDialog/types/DialogFooter.types'
import {
    onAddNewMethodSelection,
    onPlaidLinkError,
    cancelAddNewMethod,
} from '../reducers/add-new-method/addNewMethod.actions'
import PlaidLink from '../shared/components/plaid-link/PlaidLink'

interface Props {
    addMethodError: (payload: { title: string; message: string; messageJson: string }) => void
    cancel: () => void
    onPlaidLinkSuccess: (payload: {
        publicToken: string
        accountId: string
        plaidBankName: string
        accountLast4: string
    }) => void
    onStripeAddCardSuccess: (payload: { paymentMethodCardId: string }) => void
}

const AddPaymentMethodDialog = ({ addMethodError, cancel, onPlaidLinkSuccess, onStripeAddCardSuccess }: Props) => {
    const {
        show,
        loading,
        error,
        success,
        isSelectingPaymentMethod,
        selectedPaymentMethod,
        plaid,
        isSelectingPaymentPlans,
        isAddingNewCard,
        paymentPlansNew,
        paymentMethodCardId,
        paymentMethodAchId,
        isAddingAch,
    } = useSelector((state: RootState) => state.addNewMethod)
    const { practiceId } = useSelector((state: RootState) => state.session)
    const [selectedMethod, setSelectedMethod] = useState<InvoicePaymentMethodsType | null>(null)
    const [dialog, setDialog] = useState<{ content: React.ReactNode; footer: DialogFooterProps }>({
        content: null,
        footer: null,
    })

    const dispatch = useDispatch()

    const onCardOrBankSelect = (selectedMethod: InvoicePaymentMethodsType) => {
        setSelectedMethod(selectedMethod)
    }

    useEffect(() => {
        if (loading) {
            setDialog({
                content: (
                    <div className="add-loading">
                        <SFLoading />
                    </div>
                ),
                footer: null,
            })
        } else if (error.showError) {
            setDialog({
                content: (
                    <div>
                        <SFErrorDialogContent
                            title={error.title}
                            message={error.message}
                            messageJson={error.messageJson}
                        />
                    </div>
                ),
                footer: null,
            })
        } else if (success) {
            setDialog({
                content: (
                    <div>
                        <SFSuccessDialogContent title="Success" message="New payment method added" />
                    </div>
                ),
                footer: null,
            })
        } else if (isSelectingPaymentMethod) {
            setDialog({
                content: <CardOrBankSelect onCardOrBankSelect={onCardOrBankSelect} selectedMethod={selectedMethod} />,
                footer: {
                    footerButtons: {
                        twoButtons: {
                            leftButton: {
                                text: 'Cancel',
                                action: () => {
                                    dispatch(cancel())
                                },
                            },
                            rightButton: {
                                text: 'Next',
                                action: () => {
                                    dispatch(onAddNewMethodSelection({ selectedMethod }))
                                },
                                disabled: selectedMethod === null,
                            },
                        },
                    },
                },
            })
        } else if (isAddingNewCard) {
            setDialog({
                content: (
                    <StripeAddPaymentMethod
                        onStripeAddCardSuccess={onStripeAddCardSuccess}
                        addMethodError={addMethodError}
                        cancel={cancel}
                    />
                ),
                footer: null,
            })
        }
    }, [
        show,
        loading,
        error,
        success,
        selectedPaymentMethod,
        plaid,
        selectedMethod,
        isSelectingPaymentMethod,
        isSelectingPaymentPlans,
        isAddingNewCard,
        paymentPlansNew,
        paymentMethodCardId,
        paymentMethodAchId,
        isAddingAch,
        addMethodError,
        cancel,
        dispatch,
        onStripeAddCardSuccess,
    ])

    return (
        <div>
            <SFDialog
                open={show && !plaid.show}
                title="Add New Method"
                onClose={() => {
                    dispatch(cancel())
                }}
                content={dialog.content}
                footer={dialog.footer}
                styleClass="add-payment-method-dialog"
            />
            {plaid.show ? (
                <PlaidLink
                    plaidLinkToken={plaid.linkToken}
                    practiceId={practiceId}
                    onPlaidLinkSuccess={onPlaidLinkSuccess}
                    onPlaidLinkError={onPlaidLinkError}
                    cancelPlaidFlow={cancelAddNewMethod}
                />
            ) : null}
        </div>
    )
}

export default AddPaymentMethodDialog
