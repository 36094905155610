import { PaymentPlanState } from './paymentPlan.state'

export const initialState: PaymentPlanState = {
    error: {
        showError: false,
        title: '',
        message: '',
    },
    showInfo: false,
    loading: true,
    paymentPlanCards: null,
    paymentPlans: null,
    editPaymentPlan: {
        active: false,
        loading: false,
        paymentMethods: null,
        achPaymentMethod: null,
        error: false,
        selectedMethod: '',
        success: false,
        updatedPaymentPlanMethod: '',
        tokenId: '',
        achId: '',
    },
    showSelectPaymentPlanDialog: false,
    activePlanId: '',
    paymentPlanDetailsLoading: true,
    paymentPlanDetailsError: {
        showError: false,
        title: '',
        message: '',
    },
    paymentPlanDetails: null,
}
