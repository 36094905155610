import React from 'react'
import { Button } from '@material-ui/core'
import './AchUseExistingAccount.scss'
import { AchPaymentMethod } from '../../../types/AchPaymentMethod'
import { useDispatch } from 'react-redux'
import {
    applyUseExistingAchOnInvoice,
    requestPlaidLinkToken,
} from '../../../reducers/invoice-details/invoiceDetails.action'

interface Props {
    achPaymentMethod: AchPaymentMethod
}

const AchUseExistingAccount = ({ achPaymentMethod }: Props) => {
    const dispatch = useDispatch()
    return (
        <div className="ach-select-existing-content test">
            <div className="title">You have an ACH account on file with us. Would you like to use this account?</div>
            <div className="checking-info">
                <div>{`${achPaymentMethod.bankName} ****${achPaymentMethod.accountLast4}`}</div>
            </div>
            <div className="button-wrapper">
                <Button
                    onClick={() => {
                        dispatch(applyUseExistingAchOnInvoice({ achPaymentMethod }))
                    }}
                    id="useExistingAccountButton"
                >
                    Use this account
                </Button>
                <Button
                    onClick={() => {
                        dispatch(requestPlaidLinkToken())
                    }}
                    id="selectNewAccountButton"
                >
                    Choose a different account
                </Button>
            </div>
        </div>
    )
}

export default AchUseExistingAccount
