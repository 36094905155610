import React from 'react'
import { useSelector } from 'react-redux'
import { Route } from 'react-router'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import { RootState } from '../reducers/app/RootState'
import './AuthorizedRoute.scss'
import TimeoutDialog from './timeout-dialog/TimeoutDialog'

interface Props {
    path: string
    exact: boolean
    component: any
}

const AuthorizedRoute = ({ path, exact, component }: Props) => {
    const { isUserTimeout } = useSelector((state: RootState) => state.session)

    // if (!SessionManager.isLoggedIn) {
    //     return <Redirect to="/login" />
    // }
    return (
        <div className="authorized-route">
            <header>
                <Header />
            </header>
            <section className="main-content">
                {!isUserTimeout ? (
                    <Route path={path} exact={exact} component={component} />
                ) : (
                    <TimeoutDialog isUserTimeout={isUserTimeout} />
                )}
            </section>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}

export default AuthorizedRoute
