import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@material-ui/core'
import classNames from 'classnames'

import { InvoicePaymentMethods } from '../../../types/Constants'

import './SubmitPaymentEnterInfoButton.scss'
import Tooltip from '../tooltip/Tooltip'

interface Props {
    className?: string
    customText?: string
    id?: string
    onClick: () => Promise<void>
    disabled?: boolean
    selectedMethod: InvoicePaymentMethods
}

const SubmitPaymentEnterInfoButton = ({ className, customText, onClick, disabled, id, selectedMethod }: Props) => {
    const [showTooltip, setShowTooltip] = useState(false)
    const [isButtonClickPending, setIsButtonClickPending] = useState(false)
    const timer = useRef(null)

    const onShowTooltip = () => {
        clearTimeout(timer.current)
        setShowTooltip(true)
        timer.current = setTimeout(() => {
            setShowTooltip(false)
        }, 10000)
    }

    const onButtonClick = async () => {
        if (!isButtonClickPending) {
            setIsButtonClickPending(true)
            await onClick()
            setIsButtonClickPending(false)
        }
    }

    let buttonText = customText
    if (!buttonText) {
        buttonText = selectedMethod !== 'card' ? 'Pay Invoice' : 'Enter Card Info'
    }

    return (
        <>
            <Button
                className={classNames('review-payment-btn', className, {
                    'review-payment-btn--disabled': disabled,
                })}
                onClick={
                    !disabled
                        ? onButtonClick
                        : e => {
                              e.nativeEvent.preventDefault()
                              e.nativeEvent.stopPropagation()
                              e.preventDefault()
                              e.stopPropagation()
                              onShowTooltip()
                          }
                }
                onMouseOver={() => {
                    if (disabled) {
                        onShowTooltip()
                    }
                }}
                onMouseOut={() => {
                    clearTimeout(timer.current)
                    setShowTooltip(false)
                }}
                id={id}
            >
                <Tooltip
                    className="submit-payment-btn-tooltip"
                    text="Please select a payment method above."
                    hideIcon={true}
                    forceShow={showTooltip}
                />
                <span className="review-payment">{buttonText}</span>
            </Button>
        </>
    )
}

export default SubmitPaymentEnterInfoButton
