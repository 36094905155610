import api from '../../api/Api'
import SessionManager from '../../../SessionManager'
import { PracticePayerResponse } from '../../types/Response/PracticePayerResponse'
import { Dispatch } from 'redux'
import Api from '../../api/Api'

const LOG_USER_IN_FROM_SESSION = '[SESSION] LOG USER IN FROM SESSION'
const SELECT_PRACTICE_FROM_SESSION = '[SESSION] SELECT PRACTICE FROM SESSION'
const LOG_USER_OUT_FROM_SESSION_SUCCESS = '[SESSION] LOG USER OUT FROM SESSION SUCCESS'
const SET_EXTERNAL_INVOICE_INFO_FROM_SESSION = '[SESSION] SET EXTERNAL INVOICE INFO FROM SESSION'
const ON_USER_TIMEOUT = '[SESSION] ON USER TIMEOUT'
const SET_LOGO_URL = '[SESSION] SET LOGO URL'
const ADD_PRACTICE_AND_PAYER_TO_SESSION = '[SESSION] ADD PRACTICE AND PAYER TO SESSION'

export type Action =
    | { type: typeof LOG_USER_IN_FROM_SESSION; payload: { portalUserId: string } }
    | { type: typeof SELECT_PRACTICE_FROM_SESSION; payload: { selectedPractice: PracticePayerResponse } }
    | { type: typeof LOG_USER_OUT_FROM_SESSION_SUCCESS }
    | {
          type: typeof SET_EXTERNAL_INVOICE_INFO_FROM_SESSION
          payload: {
              practiceId: string
              locationId: string
              patientId: string
              payerId: string
          }
      }
    | { type: typeof ON_USER_TIMEOUT }
    | { type: typeof SET_LOGO_URL; payload: { logoUrl: string; logoBackgroundColor: string } }
    | { type: typeof ADD_PRACTICE_AND_PAYER_TO_SESSION; payload: { payerId: string; practiceId: string } }

const logUserInFromSession = (payload: { portalUserId: string }) => {
    return {
        type: LOG_USER_IN_FROM_SESSION,
        payload,
    }
}

const selectPracticeFromSession = (payload: { selectedPractice: PracticePayerResponse }) => {
    return {
        type: SELECT_PRACTICE_FROM_SESSION,
        payload,
    }
}

const logUserOutFromSession = () => {
    return async (dispatch: any) => {
        try {
            SessionManager.logUserOut()
            dispatch(logUserOutSuccess())
            const { data } = await api.logUserOut()
        } catch (err) {
            return dispatch(logUserOutSuccess())
        }
    }
}

const logUserOutSuccess = () => {
    // This actually goes to the rootReducer in App.tsx
    return {
        type: LOG_USER_OUT_FROM_SESSION_SUCCESS,
    }
}

const logUserOutFromTimeoutDialog = () => {
    return async (dispatch: any) => {
        try {
            SessionManager.logUserOut()
            dispatch(logUserOutSuccess())
        } catch (err) {
            return dispatch(logUserOutSuccess())
        }
    }
}

const setExternalInvoiceInfoFromSession = (payload: { practiceId: string; locationId: string }) => {
    return {
        type: SET_EXTERNAL_INVOICE_INFO_FROM_SESSION,
        payload,
    }
}

const onUserTimeout = () => {
    return {
        type: ON_USER_TIMEOUT,
    }
}

const getMainAppLocationLogo = (payload: { practiceId: string; locationId: string }) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await Api.getLocationLogo(payload.practiceId, payload.locationId)
            if (data && data.url) {
                SessionManager.sessinoLogoUrl = data.url
                SessionManager.sessinoLogoBackgroundColor = data.locationLogoBackgroundColorCode

                return dispatch(
                    setLogoUrl({
                        logoUrl: data.url,
                        logoBackgroundColor: data.locationLogoBackgroundColorCode,
                    })
                )
            }
        } catch (err) {}

        try {
            const practiceLogo = await api.getPracticeLogo(payload.practiceId)
            if (practiceLogo.data.url) {
                const practiceLogoBgColor = await api.fetchPracticeLogoBgColor(payload.practiceId)

                SessionManager.sessinoLogoUrl = practiceLogo.data.url
                SessionManager.sessinoLogoBackgroundColor = practiceLogoBgColor.data.logoBackgroundColorCode || ''

                return dispatch(
                    setLogoUrl({
                        logoUrl: practiceLogo.data.url,
                        logoBackgroundColor: practiceLogoBgColor.data.logoBackgroundColorCode || '',
                    })
                )
            }
        } catch (err) {}
    }
}

const setLogoUrl = (payload: { logoUrl: string; logoBackgroundColor: string }) => {
    return {
        type: SET_LOGO_URL,
        payload,
    }
}

const addPracticeAndPayerToSession = (payload: { payerId: string; practiceId: string }) => {
    SessionManager.sessionPayerId = payload.payerId
    SessionManager.sessionPracticeId = payload.practiceId
    return {
        type: ADD_PRACTICE_AND_PAYER_TO_SESSION,
        payload,
    }
}

export {
    LOG_USER_IN_FROM_SESSION,
    SELECT_PRACTICE_FROM_SESSION,
    LOG_USER_OUT_FROM_SESSION_SUCCESS,
    SET_EXTERNAL_INVOICE_INFO_FROM_SESSION,
    ON_USER_TIMEOUT,
    SET_LOGO_URL,
    ADD_PRACTICE_AND_PAYER_TO_SESSION,
    logUserInFromSession,
    selectPracticeFromSession,
    logUserOutFromSession,
    logUserOutSuccess,
    setExternalInvoiceInfoFromSession,
    onUserTimeout,
    logUserOutFromTimeoutDialog,
    setLogoUrl,
    addPracticeAndPayerToSession,
    getMainAppLocationLogo,
}
