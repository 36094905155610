import { PracticePayerResponse } from './modules/types/Response/PracticePayerResponse'

export default class SessionManager {
    private static token?: string
    private static guestToken?: string
    private static email?: string
    private static portalUserId?: string
    private static portalCurrentPractice?: PracticePayerResponse
    private static locationId?: string
    private static payerId?: string
    private static practiceId?: string
    private static patientId?: string
    private static logoUrl?: string
    private static logoBackgroundColor?: string
    static sessionKey: string = 'portal-session'
    static sessionGuestKey: string = 'portal-guest-session'
    static sessionEmailKey: string = 'portal-email'
    static portalUserIdKey: string = 'portal-user-id'
    static portalCurrentPracticeKey: string = 'portal-current-practice'
    static locationIdKey: string = 'location-id'
    static payerIdKey: string = 'payer-id'
    static practiceIdKey: string = 'practice-id'
    static patientIdKey: string = 'patient-id'
    static logoUrlKey: string = 'logo-url'
    static logoBackgroundColorKey: string = 'logo-bg-color'

    static get sessionToken() {
        return this.token
    }

    static get sessionGuestToken() {
        return this.guestToken
    }

    static get sessionEmail() {
        return this.email
    }

    static get portalUser() {
        return this.portalUserId
    }

    static get currentPractice() {
        return this.portalCurrentPractice
    }

    static get sessionLocationId() {
        return this.locationId
    }

    static get sessionPayerId() {
        return this.payerId
    }

    static get sessionPracticeId() {
        return this.practiceId
    }

    static get sessionPatientId() {
        return this.patientId
    }

    static get sessionLogoUrl() {
        return this.logoUrl
    }

    static get sessionLogoBackgroundColor() {
        return this.logoBackgroundColor
    }

    static set sessionToken(token: string | undefined) {
        this.token = token
        if (token) {
            window.sessionStorage.setItem(this.sessionKey, token)
        }
    }

    static set sessionGuestToken(token: string | undefined) {
        this.guestToken = token
        if (token) {
            window.sessionStorage.setItem(this.sessionGuestKey, token)
        }
    }

    static set sessionEmail(email: string | undefined) {
        this.email = email
        if (email) {
            window.sessionStorage.setItem(this.sessionEmailKey, email)
        }
    }

    static set portalUser(portalUserId: string | undefined) {
        this.portalUserId = portalUserId
        if (portalUserId) {
            window.sessionStorage.setItem(this.portalUserIdKey, portalUserId)
        }
    }

    static set currentPractice(portalCurrentPractice: PracticePayerResponse | undefined) {
        this.portalCurrentPractice = portalCurrentPractice
        if (portalCurrentPractice) {
            window.sessionStorage.setItem(this.portalCurrentPracticeKey, JSON.stringify(portalCurrentPractice))
        }
    }

    static set sessionLocationId(locationId: string | undefined) {
        this.locationId = locationId
        if (locationId) {
            window.sessionStorage.setItem(this.locationIdKey, locationId)
        }
    }

    static set sessionPayerId(payerId: string | undefined) {
        this.payerId = payerId
        if (payerId) {
            window.sessionStorage.setItem(this.payerIdKey, payerId)
        }
    }

    static set sessionPracticeId(practiceId: string | undefined) {
        this.practiceId = practiceId
        if (practiceId) {
            window.sessionStorage.setItem(this.practiceIdKey, practiceId)
        }
    }

    static set sessionPatientId(patientId: string | undefined) {
        this.patientId = patientId
        if (patientId) {
            window.sessionStorage.setItem(this.patientIdKey, patientId)
        }
    }

    static set sessinoLogoUrl(logoUrl: string | undefined) {
        this.logoUrl = logoUrl
        if (logoUrl) {
            window.sessionStorage.setItem(this.logoUrlKey, logoUrl)
        }
    }

    static set sessinoLogoBackgroundColor(logoBackgroundColor: string | undefined) {
        this.logoBackgroundColor = logoBackgroundColor
        if (logoBackgroundColor) {
            window.sessionStorage.setItem(this.logoBackgroundColorKey, logoBackgroundColor)
        }
    }

    static logUserOut() {
        this.sessionToken = undefined
        this.sessionGuestToken = undefined
        this.sessionEmail = undefined
        this.portalUserId = undefined
        this.portalCurrentPractice = undefined
        this.locationId = undefined
        this.payerId = undefined
        this.practiceId = undefined
        this.patientId = undefined
        this.logoUrl = undefined
        this.logoBackgroundColor = undefined
        window.sessionStorage.removeItem(this.sessionKey)
        window.sessionStorage.removeItem(this.sessionGuestKey)
        window.sessionStorage.removeItem(this.sessionEmailKey)
        window.sessionStorage.removeItem(this.portalUserIdKey)
        window.sessionStorage.removeItem(this.portalCurrentPracticeKey)
        window.sessionStorage.removeItem(this.locationIdKey)
        window.sessionStorage.removeItem(this.payerIdKey)
        window.sessionStorage.removeItem(this.practiceIdKey)
        window.sessionStorage.removeItem(this.patientIdKey)
        window.sessionStorage.removeItem(this.logoUrlKey)
        window.sessionStorage.removeItem(this.logoBackgroundColorKey)
    }

    static get isLoggedIn(): boolean {
        return Boolean(this.token)
    }

    static get isExternalInvoice(): boolean {
        return Boolean(this.locationId && this.payerId && this.patientId)
    }
}

// TODO this entire session manager needs to be rewritten
try {
    const session = window.sessionStorage.getItem(SessionManager.sessionKey) || ''
    const sessionGuest = window.sessionStorage.getItem(SessionManager.sessionGuestKey) || ''
    const sessionEmail = window.sessionStorage.getItem(SessionManager.sessionEmailKey) || ''
    const portalUserId = window.sessionStorage.getItem(SessionManager.portalUserIdKey) || ''
    const locationId = window.sessionStorage.getItem(SessionManager.locationIdKey) || ''
    const payerId = window.sessionStorage.getItem(SessionManager.payerIdKey) || ''
    const practiceId = window.sessionStorage.getItem(SessionManager.practiceIdKey) || ''
    const patientId = window.sessionStorage.getItem(SessionManager.patientIdKey) || ''
    const logoUrl = window.sessionStorage.getItem(SessionManager.logoUrlKey) || ''
    const logoBackgroundColor = window.sessionStorage.getItem(SessionManager.logoBackgroundColorKey) || ''
    SessionManager.sessionToken = session
    SessionManager.sessionGuestToken = sessionGuest
    SessionManager.sessionEmail = sessionEmail
    SessionManager.portalUser = portalUserId
    SessionManager.sessionLocationId = locationId
    SessionManager.sessionPayerId = payerId
    SessionManager.sessionPracticeId = practiceId
    SessionManager.sessionPatientId = patientId
    SessionManager.sessinoLogoUrl = logoUrl
    SessionManager.sessinoLogoBackgroundColor = logoBackgroundColor
} catch (err) {
    SessionManager.sessionToken = undefined
    SessionManager.sessionGuestToken = undefined
    SessionManager.sessionEmail = undefined
    SessionManager.portalUser = undefined
    SessionManager.sessionLocationId = undefined
    SessionManager.sessionPayerId = undefined
    SessionManager.sessionPracticeId = undefined
    SessionManager.sessionPatientId = undefined
    SessionManager.sessinoLogoUrl = undefined
    SessionManager.sessinoLogoBackgroundColor = undefined
}

try {
    const currentPractice = window.sessionStorage.getItem(SessionManager.portalCurrentPracticeKey) || ''
    SessionManager.currentPractice = JSON.parse(currentPractice)
} catch (err) {
    SessionManager.currentPractice = undefined
}
