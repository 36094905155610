import React from 'react'
import { ScheduledPayment } from '../../../types/ScheduledPayment'
import './ScheduledPaymentNotice.scss'
import { formatCurrencyCents, formatDate } from '../../../shared/utils'
import { Grid } from '@material-ui/core'

interface Props {
    scheduledPayment: ScheduledPayment
}

const ScheduledPaymentNotice = ({ scheduledPayment }: Props) => {
    return (
        <div className="scheduled-payment">
            <Grid container={true}>
                <Grid item={true} sm={12} lg={6}>
                    <div className="item">
                        <label htmlFor="description">Description</label>
                        <div id="description" className="value">
                            {scheduledPayment.description}
                        </div>
                    </div>
                </Grid>
                <Grid item={true} xs={12} sm={4} lg={2}>
                    <div className="item">
                        <label htmlFor="paymentMethod">Payment Method</label>
                        <div id="paymentMethod" className="value">
                            {scheduledPayment.paymentMethod.cardType !== null
                                ? `${scheduledPayment.paymentMethod.cardType} ****${scheduledPayment.paymentMethod.last4}`
                                : `${scheduledPayment.paymentMethod.bankName} ****${scheduledPayment.paymentMethod.bankLast4}`}
                        </div>
                    </div>
                </Grid>
                <Grid item={true} xs={6} sm={4} lg={2}>
                    <div className="item">
                        <label htmlFor="dueBy">Due By</label>
                        <div id="dueBy" className="value">
                            {formatDate(scheduledPayment.dueDate)}
                        </div>
                    </div>
                </Grid>
                <Grid item={true} xs={6} sm={4} lg={2}>
                    <div className="item">
                        <label htmlFor="amount">Amount</label>
                        <div id="amount" className="value">
                            {formatCurrencyCents(scheduledPayment.amount)}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default ScheduledPaymentNotice
