import React, { useEffect } from 'react'
import AddPaymentMethodDialog from '../../add-new-method-dialog/AddPaymentMethodDialog'
import UpdateFailedPaymentDialog from '../update-failed-payment-dialog/UpdateFailedPaymentDialog'
import FailedPayment from './FailedPayment/FailedPayment'
import { failedPaymentaddNewPaymentMethodError, getPayerFailedPayments } from '../../reducers/overview/overview.action'
import { cancelAddNewMethod } from '../../reducers/add-new-method/addNewMethod.actions'
import { SFAlert } from '@simplifeye/component-library'
import './FailedPaymentSection.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../reducers/app/RootState'
import {
    onFailedPaymentPlaidLinkSuccess,
    onFailedPaymentStripeAddCardSuccess,
} from '../../reducers/update-failed-payment-dialog/update-failed-payment-dialog.action'

const FailedPaymentSection = () => {
    const { failedPayments, failedPaymentLoading, failedPaymentError, failedPaymentSuccess } = useSelector(
        (state: RootState) => state.overview
    )

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPayerFailedPayments())
    }, [dispatch, failedPaymentSuccess])

    if (failedPayments && failedPayments.length > 0) {
        return (
            <div className="failed-payment-section">
                <div className="failed-payment-error">
                    <SFAlert
                        severity="error"
                        showAlert={failedPaymentError.showError}
                        title={failedPaymentError.title}
                        message={failedPaymentError.message}
                    />
                </div>
                <div className="failed-payment-error">
                    <SFAlert
                        severity="success"
                        showAlert={failedPaymentSuccess}
                        title={'Success'}
                        message={'Payment successfully processed'}
                    />
                </div>
                {failedPayments.map((fp, index) => (
                    <FailedPayment failedPayment={fp} key={index} failedPaymentLoading={failedPaymentLoading} />
                ))}
                <UpdateFailedPaymentDialog />
                <AddPaymentMethodDialog
                    addMethodError={failedPaymentaddNewPaymentMethodError}
                    cancel={cancelAddNewMethod}
                    onPlaidLinkSuccess={onFailedPaymentPlaidLinkSuccess}
                    onStripeAddCardSuccess={onFailedPaymentStripeAddCardSuccess}
                />
            </div>
        )
    } else if (failedPaymentSuccess) {
        return (
            <div className="failed-payment-section">
                <div className="failed-payment-error">
                    <SFAlert
                        severity="success"
                        showAlert={failedPaymentSuccess}
                        title={'Success'}
                        message={'Payment successfully processed'}
                    />
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default FailedPaymentSection
