import './PlatformMaintenanceAlert.scss'
import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { SFAlert } from '@simplifeye/component-library'
import { RootState } from '../../reducers/app/RootState'
import moment from 'moment-timezone'

interface Props {
    className?: string
}

const START_DATE_FORMAT = 'MMMM D, YYYY h:mm A z'
const END_DATE_FORMAT = 'h:mm A z'

const formatDateWithTimezone = (date: string, format: string) => moment(date).tz('America/New_York').format(format)

const PlatformMaintenanceAlert = ({ className = '' }: Props) => {
    const { startDate, endDate } = useSelector((state: RootState) => state.platformMaintenance)

    if (!startDate || !endDate) {
        return null
    }

    if (moment.utc(endDate).diff(moment().utc(), 'seconds') <= 0) {
        return null
    }

    return (
        <div className={classNames('platform-maintenance-alert', className)}>
            <SFAlert
                showAlert
                severity="info"
                title=""
                message={`On ${formatDateWithTimezone(startDate, START_DATE_FORMAT)} to ${formatDateWithTimezone(
                    endDate,
                    END_DATE_FORMAT
                )}  we will be  performing scheduled maintenance.
                    As a result, you may be unable to log in to your Simplifeye Dashboard.`}
            />
        </div>
    )
}

export default PlatformMaintenanceAlert
