import { InvoiceDetailsState } from './invoiceDetails.state'

export const initialState: InvoiceDetailsState = {
    chargeEventId: '',
    error: {
        showError: false,
        title: '',
        message: '',
    },
    invoicePaymentSuccess: false,
    clientSecret: '',
    paymentIntentId: '',
    selectedPaymentMethod: null,
    invoiceDetailsResponse: null,
    practiceFeeAndTax: null,
    loading: true,
    creditFee: null,
    debitFee: null,
    prepaidFee: null,
    achFee: null,
    billingTotals: {
        subtotal: 0,
        taxSubtotal: 0,
        convenienceFeeSubtotal: 0,
        paymentDue: 0,
    },
    canSubmitPayInvoice: false,
    showInvoiceDialog: false,
    stripeData: {
        clientSecret: '',
        paymentIntent: '',
    },
    canSubmitStripe: false,
    dialogLoading: true,
    stripePaymentSuccess: false,
    stripePaymentError: {
        showError: false,
        title: '',
        message: '',
        messageJson: '',
    },
    isAddingNewPaymentMethod: false,
    selectedSavedPaymentMethod: null,
    isUsingSavedAchMethod: false,
    plaid: {
        plaidLinkToken: '',
        showPlaid: false,
        publicToken: '',
    },
    achDialog: {
        show: false,
        loading: false,
        success: false,
        error: {
            showError: false,
            title: '',
            message: '',
            messageJson: '',
        },
        showUseExisting: false,
    },
    applicationFees: {},
    total: {},
}
