import React from 'react'
import { Icon } from '@material-ui/core'
import { SFAlert } from '@simplifeye/component-library'

import { InvoicePaymentMethods, InvoicePaymentMethodsType } from '../../types/Constants'
import { IS_ACH_DISABLED } from '../../shared/defaults'
import {
    CreditCardActiveIcon,
    CreditCardInActiveIcon,
    CheckActiveIcon,
    CheckInActiveIcon,
} from '../../icons/PaymentIcons'

import './CardOrBankSelect.scss'
interface Props {
    selectedMethod: InvoicePaymentMethodsType | null
    onCardOrBankSelect: (selectedMethod: InvoicePaymentMethodsType) => void
}

const CardOrBankSelect = ({ selectedMethod, onCardOrBankSelect }: Props) => {
    return (
        <div className="card-or-bank-select">
            <div className="selected-method-options">
                <button
                    className={`selection ${selectedMethod === InvoicePaymentMethods.CARD ? 'active' : ''}`}
                    onClick={() => {
                        onCardOrBankSelect(InvoicePaymentMethods.CARD)
                    }}
                >
                    {selectedMethod === InvoicePaymentMethods.CARD ? (
                        <CreditCardActiveIcon />
                    ) : (
                        <CreditCardInActiveIcon />
                    )}
                    <div className="section-title active">Credit/Debit</div>
                </button>
                <button
                    className={`selection ${selectedMethod === InvoicePaymentMethods.ACH ? 'active' : ''}`}
                    onClick={() => {
                        onCardOrBankSelect(InvoicePaymentMethods.ACH)
                    }}
                    disabled={IS_ACH_DISABLED}
                >
                    {selectedMethod === InvoicePaymentMethods.ACH ? <CheckActiveIcon /> : <CheckInActiveIcon />}
                    <div className="section-title active">Bank</div>
                </button>
            </div>
            {IS_ACH_DISABLED && (
                // <div className="ach-info-text-error">
                //     <Icon>info</Icon> <span>Bank payment method is currently disabled.</span>
                // </div>
                <div className="ach-info-text">
                    <SFAlert
                        severity="error"
                        title=""
                        message="Bank payment method is currently disabled."
                        showAlert={true}
                    />
                </div>
            )}
            {selectedMethod === InvoicePaymentMethods.ACH ? (
                <div className="ach-info-text">
                    *This will update your bank account information on all existing payment plans
                </div>
            ) : null}
        </div>
    )
}

export default CardOrBankSelect
