import { UpdateFailedPaymentDiaogState } from './update-failed-payment-dialog.state'

const initialState: UpdateFailedPaymentDiaogState = {
    show: false,
    loading: true,
    success: {
        show: false,
        message: '',
    },
    error: {
        showError: false,
        title: '',
        message: '',
    },
    isAddingNewPaymentMethod: false,
    canProceedFromSelection: false,
    savedCreditMethod: null,
    savedAchPaymentMethod: null,
    isOnConfirm: false,
    isOnSelection: true,
    failedPayment: null,
}

export default initialState
