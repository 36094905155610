import { SFAlert } from '@simplifeye/component-library'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ResetIcon } from '../../../icons/LoginIcons'
import { goBackToLogin, requestPasswordReset } from '../../../reducers/login/login.action'
import { SFError } from '../../../types/SFError'
import Input from '../../../ui/Input'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MailIcon from '@material-ui/icons/Mail'
import { Spinner } from '../../../login/Spinner'
import SimplifeyeVyneLogo from '../../../icons/SimplifeyeVyneLogo'

interface Props {
    error: SFError
    isLoading: boolean
    isOnForgotPasswordSuccess: boolean
    validateEmail: (emailAddress: string) => string
    logoUrl: string | null
}

const ForgotPassword = ({ error, isLoading, isOnForgotPasswordSuccess, logoUrl, validateEmail }: Props) => {
    const [email, setEmail] = useState<{ emailAddress: string; error: string }>({
        emailAddress: '',
        error: '',
    })

    const dispatch = useDispatch()

    const onForgotPasswordSubmit = () => {
        const emailValidation = validateEmail(email.emailAddress)
        setEmail({ ...email, error: emailValidation })
        if (emailValidation === '') {
            dispatch(requestPasswordReset({ emailAddress: email.emailAddress }))
        }
    }

    return (
        <div className="login">
            <div className="login-box">
                <div className="login-box-inner">
                    {logoUrl ? (
                        <div className="logo">
                            <img src={logoUrl} alt="the logo of the practice" />
                        </div>
                    ) : (
                        <div className="lock-icon">
                            <ResetIcon />
                        </div>
                    )}
                    <div className="login-title">
                        {!isOnForgotPasswordSuccess ? 'Forgot your password' : 'Check your email'}
                    </div>
                    {!error.showError ? (
                        !isOnForgotPasswordSuccess ? (
                            <div className="info-text">
                                Please enter your email address and we'll send you instructions to reset your password .
                            </div>
                        ) : (
                            <div className="info-text">
                                If you have an active simplifeye account, you will receive an email with instructions to
                                reset your password within a few minutes. If you have not received an email, you may
                                contact support at{' '}
                                <a href="mailto:help@simplifeye.co" className="email-link">
                                    help@simplifeye.co
                                </a>
                                .
                            </div>
                        )
                    ) : null}
                    <div className="error-wrapper">
                        <SFAlert
                            showAlert={error.showError}
                            severity={'error'}
                            title={error.title}
                            message={error.message}
                        />
                    </div>
                    <div className="input-container">
                        {!isOnForgotPasswordSuccess ? (
                            <Input
                                autocorrect="off"
                                autocapitalize="off"
                                spellcheck="false"
                                errorText={email.error}
                                labelText="Enter your email address"
                                maxLength={320}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setEmail({
                                        ...email,
                                        emailAddress: e.target.value,
                                    })
                                }}
                                showError={email.error !== '' ? true : false}
                                type="text"
                                value={email.emailAddress}
                            />
                        ) : null}

                        <div className="forgot-password-wrapper">
                            <button
                                className="back-button"
                                onClick={() => {
                                    dispatch(goBackToLogin())
                                }}
                            >
                                <ArrowBackIcon />
                                <div>Back</div>
                            </button>
                            {!isOnForgotPasswordSuccess ? (
                                isLoading ? (
                                    <div className="send-reset-button">
                                        <Spinner />
                                    </div>
                                ) : (
                                    <button
                                        className="send-reset-button"
                                        onClick={() => {
                                            onForgotPasswordSubmit()
                                        }}
                                    >
                                        <MailIcon />
                                        <div>Send Reset Instructions</div>
                                    </button>
                                )
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="powered-by">
                powered by <SimplifeyeVyneLogo />
            </div>
        </div>
    )
}

export default ForgotPassword
