import { LoginState } from './login.state'

export const initialState: LoginState = {
    isUserLoggedIn: false,
    error: {
        showError: false,
        title: '',
        message: '',
    },
    availablePayers: [],
    skipSelectPayerScreen: false,
    isOnForgotPassword: false,
    isLoading: false,
    isOnForgotPasswordSuccess: false,
    goToLoginFromResetSuccess: false,
    goToLoginFromAccountCreation: false,
    logoUrl: '',
    code: '',
    accountStatus: '',
    enableGuestLink: undefined,
    externalInvoice: {
        practiceId: '',
        locationId: '',
        inExternalInvoice: false,
        createNewUserSuccess: false,
        logoUrl: null,
    },
    logoBackgroundColor: '#ffffff',
    hasAccount: false,
}
