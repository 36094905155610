import { Dispatch } from 'react'
import { RootState } from '../app/RootState'
import Api from '../../api/Api'
import { PayerAddNewPaymentMethodResponse } from '../../types/Response/PayerAddNewPaymentMethodResponse'
import { PaymentPlan } from '../../types/PaymentPlan'
import { InvoicePaymentMethods, InvoicePaymentMethodsType } from '../../types/Constants'
import { PlaidAccount } from '../../types/PlaidAccount'
import { PaymentPlanBasicInfo } from '../../types/PaymentPlanBasicInfo'

const SHOW_ADD_NEW_METHOD_DIALOG = '[ADD NEW METHOD] SHOW ADD NEW METHOD DIALOG'
const ADD_NEW_METHOD_SETUP_SUCCESS = '[ADD NEW METHOD] ADD NEW METHOD SETUP SUCCESS'
const ADD_NEW_METHOD_SETUP_ERROR = '[ADD NEW METHOD] ADD NEW METHOD SETUP ERROR'
const CANCEL_ADD_NEW_METHOD = '[ADD NEW METHOD] CANCEL ADD NEW METHOD'
const ADD_NEW_PAYMENT_METHOD_SUCCESS = '[ADD NEW METHOD] ADD NEW PAYMENT METHOD SUCCESS'
const ADD_NEW_METHOD_SETUP_SUCCESS_DEFAULT_PLAN = '[ADD NEW METHOD] ADD NEW METHOD SETUP SUCCESS DEFAULT PLAN'
const CREATE_PLAID_TOKEN_SUCCESS = '[ADD NEW METHOD] CREATE PLAID TOKEN SUCCESS'
const ON_PLAID_LINK_ERROR = '[ADD NEW METHOD] ON PLAID LINK ERROR'
const GET_PLAID_ACCOUNT_BALANCES_SUCCESS = '[ADD NEW METHOD] GET PLAID ACCOUNT BALANCES SUCCESS'
const ADD_NEW_METHOD_LOADING = '[ADD NEW METHOD] ADD NEW METHOD LOADING'
const ON_CREDIT_DEBIT_METHOD_SELECTION_SUCCESS = '[ADD NEW METHOD] ON CREDIT DEBIT METHOD SELECTION SUCCESS'
const GO_TO_PAYMENT_PLAN_SELECTION_SUCCESS = '[ADD NEW METHOD] GO TO PAYMENT PLAN SELECTION'
const REQUEST_PAYMENT_METHOD_STRIPE_INFO = '[ADD NEW METHOD] REQUEST PAYMENT METHOD STRIPE INFO'
const REQUEST_PAYMENT_PLANS = '[ADD NEW METHOD] REQUEST_PAYMENT_PLANS'
const HIDE_ADD_NEW_METHOD_DIALOG = '[ADD NEW METHOD] HIDE ADD NEW METHOD DIALOG'

export type Action =
    | { type: typeof SHOW_ADD_NEW_METHOD_DIALOG }
    | {
          type: typeof ADD_NEW_METHOD_SETUP_SUCCESS
          payload: { paymentPlans: Array<PaymentPlanBasicInfo> }
      }
    | {
          type: typeof ADD_NEW_METHOD_SETUP_ERROR
          payload: { title: string; message: string; messageJson: string }
      }
    | { type: typeof CANCEL_ADD_NEW_METHOD }
    | { type: typeof ADD_NEW_PAYMENT_METHOD_SUCCESS }
    | {
          type: typeof ADD_NEW_METHOD_SETUP_SUCCESS_DEFAULT_PLAN
          payload: {
              paymentPlans: Array<PaymentPlan>
              defaultSelectedPaymentPlan: string
          }
      }
    | {
          type: typeof ON_CREDIT_DEBIT_METHOD_SELECTION_SUCCESS
          payload: { addNewResponse: PayerAddNewPaymentMethodResponse; selectedMethod: InvoicePaymentMethodsType }
      }
    | { type: typeof CREATE_PLAID_TOKEN_SUCCESS; payload: { linkToken: string } }
    | { type: typeof ON_PLAID_LINK_ERROR; payload: { title: string; message: string; messageJson: string } }
    | {
          type: typeof GET_PLAID_ACCOUNT_BALANCES_SUCCESS
          payload: { accounts: Array<PlaidAccount>; publicToken: string }
      }
    | { type: typeof ADD_NEW_METHOD_LOADING }
    | {
          type: typeof GO_TO_PAYMENT_PLAN_SELECTION_SUCCESS
          payload: {
              paymentPlans: PaymentPlan[]
              paymentMethodCardId: string
              paymentMethodAchId: string
              isAddingAch: boolean
          }
      }
    | { type: typeof REQUEST_PAYMENT_METHOD_STRIPE_INFO }
    | { type: typeof REQUEST_PAYMENT_PLANS }
    | { type: typeof HIDE_ADD_NEW_METHOD_DIALOG }

const addNewPaymentMethodSetup = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(showAddNewMethodDialog())
        const {
            session: { payerId },
        } = getState()

        let paymentPlans: Array<PaymentPlanBasicInfo>

        try {
            const { data } = await Api.getPaymentPlans(payerId)
            paymentPlans = data.paymentPlans
        } catch (err) {
            paymentPlans = []
        }

        return dispatch(addNewPaymentMethodSetupSuccess({ paymentPlans }))
    }
}

const addNewPaymentMethodSetupError = (payload: { title: string; message: string; messageJson: string }) => {
    return {
        type: ADD_NEW_METHOD_SETUP_ERROR,
        payload,
    }
}

const showAddNewMethodDialog = () => {
    return {
        type: SHOW_ADD_NEW_METHOD_DIALOG,
    }
}

const addNewPaymentMethodSetupSuccess = (payload: { paymentPlans: Array<PaymentPlanBasicInfo> }) => {
    return {
        type: ADD_NEW_METHOD_SETUP_SUCCESS,
        payload,
    }
}

const cancelAddNewMethod = () => {
    return {
        type: CANCEL_ADD_NEW_METHOD,
    }
}

const addNewPaymentMethodSuccess = () => {
    return {
        type: ADD_NEW_PAYMENT_METHOD_SUCCESS,
    }
}

const addNewPaymentMethodSetupDefaultPlan = (payload: { defaultSelectedPaymentPlan: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(showAddNewMethodDialog())
        const {
            session: { payerId },
        } = getState()

        let paymentPlans: Array<PaymentPlanBasicInfo>

        try {
            const { data } = await Api.getPaymentPlans(payerId)
            paymentPlans = data.paymentPlans
        } catch (err) {
            paymentPlans = []
        }

        return dispatch(
            addNewPaymentMethodSetupSuccessDefaultPlan({
                paymentPlans,
                defaultSelectedPaymentPlan: payload.defaultSelectedPaymentPlan,
            })
        )
    }
}

const addNewPaymentMethodSetupSuccessDefaultPlan = (payload: {
    paymentPlans: Array<PaymentPlanBasicInfo>
    defaultSelectedPaymentPlan: string
}) => {
    return {
        type: ADD_NEW_METHOD_SETUP_SUCCESS_DEFAULT_PLAN,
        payload,
    }
}

const onAddNewMethodSelection = (payload: { selectedMethod: InvoicePaymentMethodsType }) => {
    return async (dispatch: Dispatch<any>) => {
        if (payload.selectedMethod !== InvoicePaymentMethods.ACH) {
            return dispatch(onCreditDebitMethodSelection(payload))
        } else {
            dispatch(onAchMethodSelection())
        }
    }
}

const onCreditDebitMethodSelection = (payload: { selectedMethod: InvoicePaymentMethodsType }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId, practiceId },
        } = getState()

        dispatch(requestPaymentMethodStripeInfo())
        try {
            const { data: addNewResponse } = await Api.getPayerAddNewPaymentMethodStripeInfo(payerId, practiceId)
            return dispatch(
                onCreditDebitMethodSelectionSuccess({ addNewResponse, selectedMethod: payload.selectedMethod })
            )
        } catch (err) {
            return dispatch(
                addNewPaymentMethodSetupError({
                    title: 'API Error',
                    message: `Error setting up add new payment method for ${payerId}`,
                    messageJson: err as any,
                })
            )
        }
    }
}

const requestPaymentMethodStripeInfo = () => {
    return {
        type: REQUEST_PAYMENT_METHOD_STRIPE_INFO,
    }
}

const onCreditDebitMethodSelectionSuccess = (payload: {
    addNewResponse: PayerAddNewPaymentMethodResponse
    selectedMethod: InvoicePaymentMethodsType
}) => {
    return {
        type: ON_CREDIT_DEBIT_METHOD_SELECTION_SUCCESS,
        payload,
    }
}

const onAchMethodSelection = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId, practiceId },
        } = getState()

        try {
            const { data } = await Api.createPlaidLinkToken(practiceId, payerId)
            return dispatch(createPlaidTokenSuccess({ linkToken: data.linkToken }))
        } catch (err) {
            dispatch(
                addNewPaymentMethodSetupError({
                    title: 'API Error',
                    message: `Error setting up bank account for ${payerId}`,
                    messageJson: err as any,
                })
            )
        }
    }
}

const hideAddNewMethodDialog = () => {
    return {
        type: HIDE_ADD_NEW_METHOD_DIALOG,
    }
}

const createPlaidTokenSuccess = (payload: { linkToken: string }) => {
    return {
        type: CREATE_PLAID_TOKEN_SUCCESS,
        payload,
    }
}

const onPlaidLinkError = (payload: { title: string; message: string; messageJson: string }) => {
    return {
        type: ON_PLAID_LINK_ERROR,
        payload,
    }
}

const addNewMethodLoading = () => {
    return {
        type: ADD_NEW_METHOD_LOADING,
    }
}

const goToPaymentPlanSelection = (payload: {
    paymentMethodCardId: string
    paymentMethodAchId: string
    isAddingAch: boolean
    addMethodSuccess: (payload: {
        isAddingAch: boolean
        paymentMethodId?: string
        selectedPlans?: string[]
        accountId?: string
    }) => void
}) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(showAddNewMethodDialog())
        const {
            session: { payerId },
        } = getState()

        dispatch(requestPaymentPlans())
        try {
            const {
                data: { paymentPlans },
            } = await Api.getPaymentPlans(payerId)
            if (paymentPlans && paymentPlans.length > 0) {
                return dispatch(
                    goToPaymentPlanSelectionSuccess({
                        paymentPlans,
                        paymentMethodCardId: payload.paymentMethodCardId,
                        paymentMethodAchId: payload.paymentMethodAchId,
                        isAddingAch: payload.isAddingAch,
                    })
                )
            } else {
                return dispatch(
                    payload.addMethodSuccess({ isAddingAch: false, paymentMethodId: payload.paymentMethodCardId })
                )
            }
        } catch (err) {
            // 409 means no payment plans, can skip the select payment plan screen
            return dispatch(
                payload.addMethodSuccess({ isAddingAch: false, paymentMethodId: payload.paymentMethodCardId })
            )
        }
    }
}

const requestPaymentPlans = () => {
    return {
        type: REQUEST_PAYMENT_PLANS,
    }
}

const goToPaymentPlanSelectionSuccess = (payload: {
    paymentPlans: Array<PaymentPlanBasicInfo>
    paymentMethodCardId: string
    paymentMethodAchId: string
    isAddingAch: boolean
}) => {
    return {
        type: GO_TO_PAYMENT_PLAN_SELECTION_SUCCESS,
        payload,
    }
}

export {
    SHOW_ADD_NEW_METHOD_DIALOG,
    ADD_NEW_METHOD_SETUP_SUCCESS,
    ADD_NEW_METHOD_SETUP_ERROR,
    CANCEL_ADD_NEW_METHOD,
    ADD_NEW_PAYMENT_METHOD_SUCCESS,
    ADD_NEW_METHOD_SETUP_SUCCESS_DEFAULT_PLAN,
    ON_CREDIT_DEBIT_METHOD_SELECTION_SUCCESS,
    CREATE_PLAID_TOKEN_SUCCESS,
    ON_PLAID_LINK_ERROR,
    GET_PLAID_ACCOUNT_BALANCES_SUCCESS,
    ADD_NEW_METHOD_LOADING,
    GO_TO_PAYMENT_PLAN_SELECTION_SUCCESS,
    REQUEST_PAYMENT_METHOD_STRIPE_INFO,
    REQUEST_PAYMENT_PLANS,
    HIDE_ADD_NEW_METHOD_DIALOG,
    addNewPaymentMethodSetup,
    cancelAddNewMethod,
    addNewPaymentMethodSuccess,
    addNewPaymentMethodSetupDefaultPlan,
    addNewPaymentMethodSetupError,
    onAddNewMethodSelection,
    onPlaidLinkError,
    addNewMethodLoading,
    goToPaymentPlanSelection,
    showAddNewMethodDialog,
    hideAddNewMethodDialog,
}
