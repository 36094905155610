export enum InvoicePaymentMethods {
    CREDIT = 'credit',
    DEBIT = 'debit',
    CARD = 'card',
    PREPAID = 'prepaid',
    ACH = 'ach',
    OTP = 'otp',
}

export type InvoicePaymentMethodsType = InvoicePaymentMethods

export const ConvienceFeeTypes = {
    SIMPLY_ZERO: 'simplyzero',
    MIXED: 'mixed',
    STANDARD: 'standard',
}

export type AccountProcessingTypes = 'simplyzero' | 'mixed' | 'standard'
