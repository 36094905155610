import './PlatfromMaintenance.scss'
import React from 'react'
import { Redirect } from 'react-router-dom'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import MaintenanceArtwork from '../icons/MaintenanceArtwork'
import { RootState } from '../reducers/app/RootState'
import SimplifeyeVyneLogo from '../icons/SimplifeyeVyneLogo'

const PlatformMaintenance = () => {
    const { startDate, endDate } = useSelector((state: RootState) => state.platformMaintenance)
    const now = moment().utc()
    const endDateUTC = moment.utc(endDate)
    const startDateUTC = moment.utc(startDate)
    const isActive = now.isBetween(startDateUTC, endDateUTC)
    const minutes = endDateUTC.diff(now, 'minutes')

    const calculateMaintenancePeriod = () => {
        const hours = Math.ceil(minutes / 60)
        const leftoverMinutes = minutes % 60

        if (hours > 0) {
            if (leftoverMinutes > 0) {
                return `${hours} hour(s) and ${leftoverMinutes} minute(s)`
            }
            return `${hours} hour(s)`
        }
        return `${minutes} minute(s)`
    }

    // redirect to login if not in maintenance mode
    if (!isActive || !minutes || isNaN(minutes) || minutes <= 0) {
        return <Redirect to="/login" />
    }

    return (
        <section className="platfrom-maintenance">
            <div className="platfrom-maintenance-header">
                <SimplifeyeVyneLogo />
            </div>
            <div className="platfrom-maintenance-info-box">
                <div className="info-box-artwork">
                    <MaintenanceArtwork />
                </div>
                <div className="info-box-text">
                    <h5>We’ll be back shortly!</h5>
                    <p>
                        This site is temporarily unavailable for the next {calculateMaintenancePeriod()} for scheduled
                        maintentance.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default PlatformMaintenance
