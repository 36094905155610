import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

import { PayerInfo } from '../../types/PayerInfo'
import InputBox from '../../ui/Input'
import './ExternalInvoicePatientInfo.scss'
import MomentUtils from '@date-io/moment'
import { MuiThemeProvider } from '@material-ui/core'
import { calendarTheme } from '../../shared/components/material-ui-themes/materialUIIThemes'
import { dateToISO, formatDate, momentFormat } from '../../shared/utils'
import { RequireAtLeastOne } from '../../types/RequireAtLeastOneProperty'

export interface PatientInfo {
    firstName: string
    lastName: string
    dob: string
    dobError: boolean
    invoiceId: string
}

interface Props {
    patientInfo: PatientInfo
    onUpdatePatientInfo: (patientInfo: RequireAtLeastOne<PatientInfo>) => void
}

const ExternalInvoicePatientInfo = ({ patientInfo, onUpdatePatientInfo }: Props) => {
    return (
        <div className="external-invoice-patient-info">
            <div className="details">
                <div className="row">
                    <div className="item">
                        <label htmlFor="firstName">Patient First Name*</label>
                        <div id="firstName">
                            <InputBox
                                name="patientFirstName"
                                value={patientInfo.firstName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    onUpdatePatientInfo({ firstName: e.target.value })
                                }
                            />
                        </div>
                    </div>
                    <div className="item">
                        <label htmlFor="lastName">Patient Last Name*</label>
                        <div id="lastName">
                            <InputBox
                                name="patientLastName"
                                value={patientInfo.lastName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    onUpdatePatientInfo({ lastName: e.target.value })
                                }
                            />
                        </div>
                    </div>
                    <div className="item">
                        <label htmlFor="patientDob">Patient Date of Birth*</label>
                        <div className="external-patient-dob" id="patientDob">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <MuiThemeProvider theme={calendarTheme}>
                                    <KeyboardDatePicker
                                        allowKeyboardControl={true}
                                        clearable={true}
                                        disableFuture={true}
                                        variant="inline"
                                        inputVariant="outlined"
                                        value={!!patientInfo.dob ? dateToISO(patientInfo.dob) : null}
                                        onChange={dateObj => {
                                            if (dateObj === null || !dateObj.isValid()) {
                                                return
                                            }
                                            onUpdatePatientInfo({
                                                dob: dateObj.format(momentFormat),
                                            })
                                        }}
                                        onError={(err, ...err2) => {
                                            if (patientInfo.dobError !== !!err) {
                                                onUpdatePatientInfo({
                                                    dobError: !!err,
                                                })
                                            }
                                        }}
                                        placeholder="MM/DD/YYYY"
                                        format={'MM/DD/YYYY'}
                                    />
                                </MuiThemeProvider>
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="item">
                        <label htmlFor="patientInvoiceId">Invoice or Account # (optional)</label>
                        <div id="patientInvoiceId">
                            <InputBox
                                name="patientInvoice"
                                value={patientInfo.invoiceId}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    onUpdatePatientInfo({ invoiceId: e.target.value })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExternalInvoicePatientInfo
