import React, { useState } from 'react'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Collapse } from '@material-ui/core'
import { formatCurrencyCents } from '../../../shared/utils'
import './PaymentPlanExpander.scss'
import InfoIcon from '@material-ui/icons/Info'

interface Props {
    headerText: string
    total: number
    costBreakdown: Array<{ label: string; value: number; ariaLabelId: string }>
    isExpanded: boolean
    ariaLabelId: string
    infoMessage?: string
}

const PaymentPlanExpander = ({ headerText, total, costBreakdown, ariaLabelId, infoMessage }: Props) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    return (
        <div className="payment-plan-expander">
            <div
                className="total-cost-header"
                onClick={() => {
                    setIsExpanded(!isExpanded)
                }}
            >
                <div className="inner">
                    <div className="left">
                        <label htmlFor={`${ariaLabelId}Expanded`}>{headerText}</label>
                    </div>
                    <div className="right">
                        <div id={`${ariaLabelId}Expanded`} className="value">
                            {formatCurrencyCents(total)}
                        </div>
                        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </div>
                </div>
            </div>
            <Collapse in={isExpanded}>
                {costBreakdown.map((cb, i) => (
                    <div className="total-cost-expanded" key={i}>
                        <div className="total-cost-row">
                            <div className="total-cost-item">
                                <label htmlFor={cb.ariaLabelId}>{cb.label}</label>
                                <div id={cb.ariaLabelId} className="value">
                                    {formatCurrencyCents(cb.value)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {infoMessage ? (
                    <div className="total-cost-info">
                        <InfoIcon />
                        <div>{infoMessage}</div>
                    </div>
                ) : null}
            </Collapse>
        </div>
    )
}

export default PaymentPlanExpander
