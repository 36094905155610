import React, { useEffect, useCallback, useState } from 'react'
import moment from 'moment-timezone'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useCallbackInterval } from '../hooks/useCallbackInterval'
import { PLATFORM_MAINTENANCE_POLL_INTERVAL } from '../../types/Constants/PlatformMaintenanceContants'
import { platformMaintenanceCheck } from '../../reducers/platform-maintenance/platformMaintenance.action'
import { RootState } from '../../reducers/app/RootState'

const PlatfromMaintenanceListener = () => {
    // state
    const [isUnderMaintenance, setIsUnderMaintenance] = useState<boolean>(false)

    // redux
    const dispatch = useDispatch()
    const { startDate, endDate } = useSelector((state: RootState) => state.platformMaintenance)

    // callback
    const checkForMaintenance = useCallback(() => {
        dispatch(platformMaintenanceCheck())
    }, [dispatch])

    // polling interval
    useCallbackInterval(checkForMaintenance, PLATFORM_MAINTENANCE_POLL_INTERVAL)

    // init effect
    useEffect(() => {
        checkForMaintenance()
    }, [checkForMaintenance])

    // maintenance check effect
    useEffect(() => {
        if (!startDate && !endDate) {
            setIsUnderMaintenance(false)
        } else {
            const currentDateInUTC = moment.utc()
            const startDateInUTC = moment.utc(startDate)
            const endDateInUTC = moment.utc(endDate)
            setIsUnderMaintenance(currentDateInUTC.isBetween(startDateInUTC, endDateInUTC))
        }
    }, [startDate, endDate])

    if (isUnderMaintenance) {
        return <Redirect to="/maintenance" />
    }

    return null
}

export default PlatfromMaintenanceListener
