import React, { useEffect } from 'react'
import { PaymentRequestButtonElement, PaymentRequestButtonElementProps } from '@stripe/react-stripe-js'
import { PaymentRequestOptions, PaymentRequestPaymentMethodEvent, Stripe } from '@stripe/stripe-js'
import useOneTapPayButtonOptions from './hooks/useOneTapPayButtonOptions'
import useOneTapPayButtonRequest, { OneTapPayButtonStatusEnum } from './hooks/useOneTapPayButtonRequest'
import StripeElementsProvider from '../stripe-elements-provider/StripeElementsProvider'
import { SFAlert } from '@simplifeye/component-library'

import './OneTapPayButton.scss'

// split in two to support default options
type PaymentRequestBaseOptions = Pick<PaymentRequestOptions, 'country' | 'currency' | 'requestPayerName'>
type PaymentRequestOtherOptions = Omit<PaymentRequestOptions, 'country' | 'currency' | 'requestPayerName'>

// default options
const defaultPaymentRequestOptions: PaymentRequestBaseOptions = {
    country: 'US',
    currency: 'usd',
    requestPayerName: true,
}

interface Props extends Omit<PaymentRequestButtonElementProps, 'options'> {
    requestOptions: PaymentRequestOtherOptions | PaymentRequestOptions
    onPaymentMethod: (customerData: PaymentRequestPaymentMethodEvent, stripe: Stripe) => void
    onButtonStatusChange?: (status: OneTapPayButtonStatusEnum) => void
}

const OneTapPayButton = ({ className = '', requestOptions, onPaymentMethod, onButtonStatusChange, ...rest }: Props) => {
    // hooks
    const { paymentRequest, buttonStatus, stripe } = useOneTapPayButtonRequest({
        options: {
            ...defaultPaymentRequestOptions,
            ...requestOptions,
        },
        onPaymentMethod: event => onPaymentMethod(event, stripe),
    })
    const options = useOneTapPayButtonOptions({ paymentRequest })

    // status controller effect
    useEffect(() => {
        if (onButtonStatusChange) {
            onButtonStatusChange(buttonStatus)
        }
    }, [buttonStatus, onButtonStatusChange])

    if (buttonStatus === OneTapPayButtonStatusEnum.NOT_AVAILABLE) {
        return (
            <div className="no-wallet-error">
                <SFAlert
                    severity="error"
                    title="Pay with wallet error."
                    message="No wallet available."
                    showAlert={true}
                />
            </div>
        )
    }

    // early exit if status isnt ready
    if (buttonStatus !== OneTapPayButtonStatusEnum.READY) return null

    return <PaymentRequestButtonElement {...rest} className={className} options={options} />
}

// Pay button with stripe elements
export const OneTapPayButtonWithElements = (props: Props) => (
    <StripeElementsProvider>
        <OneTapPayButton {...props} />
    </StripeElementsProvider>
)

export default OneTapPayButton
