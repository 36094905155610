import { useState, useEffect, useRef } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { Stripe, PaymentRequest, PaymentRequestOptions, PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js'

export interface PaymentRequestInstance extends PaymentRequest {
    // had to add this to interface since new stripe package uses 'off' event
    // payment request is generated by stripe web services so although this is not part of our package it will still work
    off(eventType: 'paymentmethod', handler?: (event: PaymentRequestPaymentMethodEvent) => any): this
}

export enum OneTapPayButtonStatusEnum {
    LOADING,
    NOT_AVAILABLE,
    READY,
}

export type OneTapPayButtonRequestHookProps = {
    options: PaymentRequestOptions
    onPaymentMethod: (event: PaymentRequestPaymentMethodEvent) => any
}

export type OneTapPayButtonRequestHookResult = {
    paymentRequest: PaymentRequestInstance | null
    buttonStatus: OneTapPayButtonStatusEnum
    stripe: Stripe
}

const useOneTapPayButtonRequest = ({
    options,
    onPaymentMethod,
}: OneTapPayButtonRequestHookProps): OneTapPayButtonRequestHookResult => {
    // local state
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequestInstance | null>(null)
    const [buttonStatus, setButtonStatus] = useState<OneTapPayButtonStatusEnum>(OneTapPayButtonStatusEnum.LOADING)

    // subscription reference
    const subscriptionRef = useRef(false)

    // stripe instance
    const stripeInstance = useStripe()

    // creates payment request
    useEffect(() => {
        if (stripeInstance && paymentRequest === null) {
            const _paymentRequest = stripeInstance.paymentRequest(options) as PaymentRequestInstance
            setPaymentRequest(_paymentRequest)
        }
    }, [stripeInstance, options, paymentRequest])

    // checks if user can make payment
    useEffect(() => {
        // subscribe
        subscriptionRef.current = true

        if (paymentRequest) {
            paymentRequest
                .canMakePayment()
                .then(response => {
                    console.log('response')
                    console.log(response)
                    if (response && (response.applePay || (response as any).googlePay) && subscriptionRef.current) {
                        setButtonStatus(OneTapPayButtonStatusEnum.READY)
                    } else {
                        setButtonStatus(OneTapPayButtonStatusEnum.NOT_AVAILABLE)
                    }
                })
                .catch(_ => setButtonStatus(OneTapPayButtonStatusEnum.NOT_AVAILABLE))
        }

        // on unmount unsubscribe
        return () => {
            subscriptionRef.current = false
        }
    }, [paymentRequest])

    // subscribes payment method to payment request
    useEffect(() => {
        if (paymentRequest) {
            paymentRequest.on('paymentmethod', onPaymentMethod)
        }
        return () => {
            if (paymentRequest) {
                paymentRequest.off('paymentmethod', onPaymentMethod)
            }
        }
    }, [paymentRequest, onPaymentMethod])

    return {
        paymentRequest,
        buttonStatus,
        stripe: stripeInstance,
    }
}

export default useOneTapPayButtonRequest
