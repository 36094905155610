import React, { useEffect } from 'react'
import PaymentMethod from './PaymentMethod/PaymentMethod'
import './PaymentMethodsSection.scss'
import { SFAlert, SFLoading } from '@simplifeye/component-library'
import { PayerPaymentMethods } from '../types/Response/PayerPaymentMethods'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../reducers/app/RootState'
import { getPaymentMethods, onStripeAddCardSuccess } from '../reducers/payment-methods/paymentMethods.action'
import AddIcon from '@material-ui/icons/Add'
import DeletePaymentMethodDialog from './DeletePaymentMethodDialog/DeletePaymentMethodDialog'
import AddPaymentMethodDialog from '../add-new-method-dialog/AddPaymentMethodDialog'

import { addNewPaymentMethodError, onPlaidLinkSuccess } from '../reducers/payment-methods/paymentMethods.action'
import { addNewPaymentMethodSetup, cancelAddNewMethod } from '../reducers/add-new-method/addNewMethod.actions'
import AchMethod from './AchMethod/AchMethod'
import LoadingPage from '../shared/components/loading-page/LoadingPage'

const PaymentMethodsSection = () => {
    const { loading, error, paymentMethods, achPaymentMethod } = useSelector((state: RootState) => state.paymentMethods)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPaymentMethods())
    }, [dispatch])

    if (loading) {
        return (
            <div className="patient-section ">
                <div className="payment-method-section">
                    <div className="payment-method-section-inner">
                        <LoadingPage />
                    </div>
                </div>
            </div>
        )
    } else if (error.showError) {
        return (
            <div className="patient-section ">
                <div className="payment-method-section">
                    <div className="payment-method-section-inner">
                        <h3>Payment Methods</h3>
                        <SFAlert severity="error" title={error.title} message={error.message} showAlert={true} />
                    </div>
                </div>
            </div>
        )
    } else if (!paymentMethods || (paymentMethods.length === 0 && !achPaymentMethod)) {
        return (
            <div className="patient-section ">
                <div className="payment-method-section">
                    <div className="payment-method-section-inner">
                        <div className="payment-method-header">
                            <h3>Payment Methods</h3>
                            <div
                                className="new-payment-btn"
                                onClick={() => {
                                    dispatch(addNewPaymentMethodSetup())
                                }}
                            >
                                <div className="icon">
                                    <AddIcon />
                                </div>
                                <span>Add Payment Method</span>
                            </div>
                        </div>
                        <SFAlert
                            severity="info"
                            title="Payment Methods"
                            message="No payment methods found for your payer profile"
                            showAlert={true}
                        />
                    </div>
                </div>
                <AddPaymentMethodDialog
                    addMethodError={addNewPaymentMethodError}
                    cancel={cancelAddNewMethod}
                    onPlaidLinkSuccess={onPlaidLinkSuccess}
                    onStripeAddCardSuccess={onStripeAddCardSuccess}
                />
            </div>
        )
    } else {
        return (
            <div className={'patient-section'}>
                <div className="payment-method-section">
                    <div className="payment-method-section-inner">
                        <div className="payment-method-header">
                            <h3>Payment Methods</h3>
                            <div
                                className="new-payment-btn"
                                onClick={() => {
                                    dispatch(addNewPaymentMethodSetup())
                                }}
                            >
                                <div className="icon">
                                    <AddIcon />
                                </div>
                                <span>Add Payment Method</span>
                            </div>
                        </div>
                        {paymentMethods &&
                            paymentMethods.map((method: PayerPaymentMethods, index: number) => {
                                return <PaymentMethod method={method} key={index} index={index} />
                            })}
                        {achPaymentMethod ? <AchMethod achPaymentMethod={achPaymentMethod} /> : null}
                    </div>
                </div>

                <AddPaymentMethodDialog
                    addMethodError={addNewPaymentMethodError}
                    cancel={cancelAddNewMethod}
                    onPlaidLinkSuccess={onPlaidLinkSuccess}
                    onStripeAddCardSuccess={onStripeAddCardSuccess}
                />
                <DeletePaymentMethodDialog />
            </div>
        )
    }
}

export default PaymentMethodsSection
