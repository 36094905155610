import {
    Action,
    PLATFORM_MAINTENANCE_REQUEST,
    PLATFORM_MAINTENANCE_SUCCESS,
    PLATFORM_MAINTENANCE_FAILURE,
} from './platformMaintenance.action'
import { initialState } from './platformMaintenance.initial'
import { PlatformMaintenanceState } from './platformMaintenance.state'

const platformMaintenanceReducer = (
    state: PlatformMaintenanceState = initialState,
    action: Action
): PlatformMaintenanceState => {
    switch (action.type) {
        case PLATFORM_MAINTENANCE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PLATFORM_MAINTENANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
            }
        case PLATFORM_MAINTENANCE_FAILURE:
            return {
                ...state,
                loading: false,
                startDate: '',
                endDate: '',
            }
        default:
            return state
    }
}

export default platformMaintenanceReducer
