import { AddNewMethodState } from './addNewMethod.state'

export const initialState: AddNewMethodState = {
    show: false,
    loading: true,
    error: {
        showError: false,
        title: '',
        message: '',
    },
    success: false,
    clientSecret: '',
    setupIntentId: '',
    paymentPlans: null,
    defaultSelectedPaymentPlan: '',
    isSelectingPaymentMethod: false,
    selectedPaymentMethod: null,
    plaid: {
        linkToken: '',
        show: false,
        accounts: null,
        publicToken: '',
    },
    isSelectingNewAccount: false,
    isSelectingPaymentPlans: false,
    isAddingNewCard: false,
    paymentPlansNew: [],
    paymentMethodCardId: '',
    paymentMethodAchId: '',
    isAddingAch: false,
}
