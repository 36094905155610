import React from 'react'
import { InvoiceDetailsResponse } from '../../types/Response/InvoiceDetailsResponse'
import './InvoiceDetailsData.scss'
import { formatDate, formatCurrencyCents } from '../../shared/utils'
import { ProcedureDescription } from '../../types/Response/ProcedureDescription'

interface Props {
    invoiceDetails: InvoiceDetailsResponse
    isPaid: boolean
}

const InvoiceDetailsData = ({ invoiceDetails, isPaid }: Props) => {
    // we assume it is a depended if payer and patient names don't match
    const showDependentInfo = (
        payer: { firstName: string; lastName: string },
        patient: { firstName: string; lastName: string }
    ) => {
        return payer.firstName !== patient.firstName || payer.lastName !== patient.lastName
    }

    return (
        <div className="invoice-details-data">
            <section>
                <div className="title">Payer Information</div>
                <div className="details">
                    <div className="row">
                        <div className="item">
                            <label htmlFor="firstName">First Name</label>
                            <div id="firstName" className="value">
                                {invoiceDetails.payer.firstName}
                            </div>
                        </div>
                        <div className="item">
                            <label htmlFor="lastName">Last Name</label>
                            <div id="lastName" className="value">
                                {invoiceDetails.payer.lastName}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="item">
                            <label htmlFor="email">Email</label>
                            <div id="email" className="value">
                                {invoiceDetails.payer.email}
                            </div>
                        </div>
                        <div className="item">
                            <label htmlFor="phone">Phone</label>
                            <div id="phone" className="value">
                                {invoiceDetails.payer.phoneNumber}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {invoiceDetails.patient && showDependentInfo(
                { firstName: invoiceDetails.payer.firstName, lastName: invoiceDetails.payer.lastName },
                { firstName: invoiceDetails.patient.firstName, lastName: invoiceDetails.patient.lastName }
            ) ? (
                <section>
                    <div className="title">Patient Information</div>
                    <div className="details">
                        <div className="row">
                            <div className="item">
                                <label htmlFor="patientFirstName">First Name</label>
                                <div id="patientFirstName" className="value">
                                    {invoiceDetails.patient.firstName}
                                </div>
                            </div>
                            <div className="item">
                                <label htmlFor="patientLastName">Last Name</label>
                                <div id="patientLastName" className="value">
                                    {invoiceDetails.patient.lastName}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : null}
            <section>
                <div className="title">Billing Information</div>
                <div className="row">
                    <div className="item">
                        <label htmlFor="billingDate">Billing Date</label>
                        <div id="billingDate" className="value">
                            {formatDate(invoiceDetails.transactionDate)}
                        </div>
                    </div>
                    <div className="item">
                        <label htmlFor="paymentDue">{isPaid ? 'Amount Paid' : 'Payment Due'}</label>
                        <div id="paymentDue" className="value">
                            {formatCurrencyCents(invoiceDetails.amount)}
                        </div>
                    </div>
                </div>
                {invoiceDetails.procedureDescriptions.map((treatment: ProcedureDescription, index) => (
                    <div className="treatment-description" key={index}>
                        <div className="row">
                            <div className="item description">
                                <label htmlFor={`treatmentDescription${index}`}>Treatment Description</label>
                                <div id={`treatmentDescription${index}`} className="value">
                                    {treatment.treatmentDescription}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="item">
                                <label htmlFor={`treatmentDate${index}`}>Treatment Date</label>
                                <div id={`treatmentDate${index}`} className="value">
                                    {formatDate(treatment.treatmentDate)}
                                </div>
                            </div>
                            <div className="item">
                                <label htmlFor={`treatmentCost${index}`}>Treatment Cost</label>
                                <div id={`treatmentCost${index}`} className="value">
                                    {formatCurrencyCents(treatment.amount)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        </div>
    )
}

export default InvoiceDetailsData
