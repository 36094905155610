import { Dispatch } from 'react'
import Api from '../../api/Api'
import { promiseTo } from '../../shared/utils'
import { AchPaymentMethod } from '../../types/AchPaymentMethod'
import { PayerFailedPayment } from '../../types/Response/PayerFailedPayment'
import { PayerPaymentMethods } from '../../types/Response/PayerPaymentMethods'
import { UpdatePaymentPlanResponse } from '../../types/Response/UpdatePaymentPlanResponse'
import {
    addNewPaymentMethodSetup,
    addNewPaymentMethodSetupError,
    cancelAddNewMethod,
    showAddNewMethodDialog,
} from '../add-new-method/addNewMethod.actions'
import { RootState } from '../app/RootState'
import { getPayerFailedPayments } from '../overview/overview.action'
import { getPaymentMethods } from '../payment-methods/paymentMethods.action'

const SHOW_UPDATE_FAILED_PAYMENT_DIALOG = '[OVERVIEW] SHOW UPDATE FAILED PAYMENT DIALOG'
const UPDATE_FAILED_PAYMENT_DIALOG_LOADING = '[OVERVIEW] UPDATE FAILED PAYMENT DIALOG LOADING'
const UPDATE_FAILED_PAYMENT_DIALOG_ERROR = '[OVERVIEW] UPDATE FAILED PAYMENT DIALOG ERROR'
const UPDATE_FAILED_PAYMENT_DIALOG_SUCCESS = '[OVERVIEW] UPDATE FAILED PAYMENT DIALOG SUCCESS'
const ON_FAILED_PAYMENT_DIALOG_USE_EXISTING_ACH = '[OVERVIEW] ON FAILED PAYMENT DIALOG USE EXISTING ACH'
const HIDE_UPDATE_FAILED_PAYMENT_DIALOG = '[OVERVIEW] HIDE UPDATE FAILED PAYMENT DIALOG'
const UNHIDE_UPDATE_FAILED_PAYMENT_DIALOG = '[OVERVIEW] UNHIDE UPDATE FAILED PAYMENT DIALOG'
const ON_FAILED_PAYMENT_DIALOG_USE_EXISTING_CREDIT = '[OVERVIEW] ON FAILED PAYMENT DIALOG USE EXISTING'
const FAILED_PAYMENT_GO_TO_PROCEED = '[OVERVIEW] FAILED PAYMENT GO TO PROCEED'
const ON_FAILED_PAYMENT_ADD_NEW_METHOD_SELECTION = '[OVERVIEW] ON FAILED PAYMENT ADD NEW METHOD SELECTION'
const CANCEL_UPDATE_FAILED_PAYMENT_DIALOG = '[OVERVIEW] CANCEL UPDATE FAILED PAYMENT DIALOG'

export type Action =
    | { type: typeof SHOW_UPDATE_FAILED_PAYMENT_DIALOG; payload: { failedPayment: PayerFailedPayment } }
    | { type: typeof UPDATE_FAILED_PAYMENT_DIALOG_LOADING }
    | { type: typeof UPDATE_FAILED_PAYMENT_DIALOG_ERROR; payload: { title: string; message: string } }
    | { type: typeof UPDATE_FAILED_PAYMENT_DIALOG_SUCCESS; payload: { message: string } }
    | { type: typeof ON_FAILED_PAYMENT_DIALOG_USE_EXISTING_ACH; payload: { achPaymentMethod: AchPaymentMethod } }
    | { type: typeof HIDE_UPDATE_FAILED_PAYMENT_DIALOG }
    | { type: typeof UNHIDE_UPDATE_FAILED_PAYMENT_DIALOG }
    | {
          type: typeof ON_FAILED_PAYMENT_DIALOG_USE_EXISTING_CREDIT
          payload: {
              paymentMethod: string
              isExistingPayment: boolean
              savedPaymentMethod?: PayerPaymentMethods
          }
      }
    | { type: typeof FAILED_PAYMENT_GO_TO_PROCEED }
    | { type: typeof ON_FAILED_PAYMENT_ADD_NEW_METHOD_SELECTION }
    | { type: typeof CANCEL_UPDATE_FAILED_PAYMENT_DIALOG }

const showUpdateFailedPaymentDialog = (payload: { failedPayment: PayerFailedPayment }) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch(getPaymentMethods())
            return dispatch(showUpdateFailedPaymentDialogSuccess(payload))
        } catch (err) {}
    }
}

const showUpdateFailedPaymentDialogSuccess = (payload: { failedPayment: PayerFailedPayment }) => {
    return {
        type: SHOW_UPDATE_FAILED_PAYMENT_DIALOG,
        payload,
    }
}

const onFailedPaymentUseExistingCredit = (payload: {
    paymentMethod: string
    isExistingPayment: boolean
    savedPaymentMethod?: PayerPaymentMethods
}) => {
    return {
        type: ON_FAILED_PAYMENT_DIALOG_USE_EXISTING_CREDIT,
        payload,
    }
}

const onFailedPaymentUseExistingAch = (payload: { achPaymentMethod: AchPaymentMethod }) => {
    return {
        type: ON_FAILED_PAYMENT_DIALOG_USE_EXISTING_ACH,
        payload,
    }
}

const onFailedPaymentUpdateAndPay = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            updateFailedPaymentDialog: { savedCreditMethod, savedAchPaymentMethod },
        } = getState()

        if (savedCreditMethod) {
            return dispatch(failedPaymentUpdateAndPayCredit({ tokenId: savedCreditMethod.tokenId }))
        } else {
            return dispatch(
                failedPaymentUpdateAndPayAch({
                    achId: savedAchPaymentMethod.achId,
                    customerId: savedAchPaymentMethod.customerId,
                })
            )
        }
    }
}

const failedPaymentUpdateAndPayCredit = (payload: { tokenId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { practiceId },
            updateFailedPaymentDialog: { failedPayment },
        } = getState()
        dispatch(updateFailedPaymentDialogLoading())

        const { response: updatePlanResponse, errors: updatePlanError } = await promiseTo<UpdatePaymentPlanResponse>(
            Api.updatePaymentPlanPaymentMethodUseCard(failedPayment.chargeEvent.paymentPlanId, payload.tokenId)
        )

        if (updatePlanError) {
            return dispatch(
                updateFailedPaymentDialogError({
                    title: 'Payment Error',
                    message: 'Unable to update plan to provided payment method',
                })
            )
        }

        const { response: payFailedResponse, errors: payFailedError } = await promiseTo<any>(
            Api.retryFailedPaymentCredit(practiceId, failedPayment.chargeEvent.chargeEventId, payload.tokenId)
        )

        if (payFailedError) {
            return dispatch(
                updateFailedPaymentDialogError({
                    title: 'Payment Error',
                    message: 'Unable to process your outstanding payment using the provided payment method',
                })
            )
        } else {
            return dispatch(
                updateFailedPaymentDialogSuccess({
                    message:
                        'Your payment was processed successfully and the new payment method was added to your payment plan',
                })
            )
        }
    }
}

const failedPaymentUpdateAndPayAch = (payload: { achId: string; customerId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { practiceId },
            updateFailedPaymentDialog: { failedPayment },
        } = getState()
        dispatch(updateFailedPaymentDialogLoading())

        const { response: updatePlanResponse, errors: updatePlanError } = await promiseTo<UpdatePaymentPlanResponse>(
            Api.updatePaymentPlanPaymentMethodUseAch(failedPayment.chargeEvent.paymentPlanId, payload.achId)
        )

        if (updatePlanError) {
            return dispatch(
                updateFailedPaymentDialogError({
                    title: 'Payment Error',
                    message: 'Unable to update plan to provided payment method',
                })
            )
        }

        const { response: payAchResponse, errors: payAchError } = await promiseTo<any>(
            Api.retryFailedPaymentAch(practiceId, failedPayment.chargeEvent.chargeEventId, payload.achId)
        )

        if (payAchError) {
            return dispatch(
                updateFailedPaymentDialogError({
                    title: 'Payment Error',
                    message: 'Unable to process your outstanding payment using the provided payment method',
                })
            )
        } else {
            return dispatch(
                updateFailedPaymentDialogSuccess({
                    message:
                        'Your payment was processed successfully and the new payment method was added to your payment plan',
                })
            )
        }
    }
}

const onFailedPaymentStripeAddCardSuccess = (payload: { paymentMethodCardId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            const {
                session: { payerId },
            } = getState()

            const { data } = await Api.getPaymentMethod(payload.paymentMethodCardId, payerId)
            dispatch(cancelAddNewMethod())
            dispatch(unHideUpdateFailedPaymentDialog())
            return dispatch(failedPaymentUpdateAndPayCredit({ tokenId: data.tokenId }))
        } catch (err) {
            return dispatch(
                addNewPaymentMethodSetupError({
                    title: 'Card Error',
                    message: 'Unable add card token to user',
                    messageJson: err as any,
                })
            )
        }
    }
}

const onFailedPaymentPlaidLinkSuccess = (payload: {
    publicToken: string
    accountId: string
    plaidBankName: string
    accountLast4: string
}) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { practiceId, payerId },
        } = getState()

        try {
            const { data } = await Api.createAchCustomerForPortal(
                payerId,
                practiceId,
                payload.publicToken,
                payload.accountId
            )
            dispatch(cancelAddNewMethod())
            dispatch(unHideUpdateFailedPaymentDialog())

            return dispatch(failedPaymentUpdateAndPayAch(data))
        } catch (err) {
            return dispatch(
                addNewPaymentMethodSetupError({
                    title: 'Bank Error',
                    message: 'Unable to add bank to user',
                    messageJson: err as any,
                })
            )
        }
    }
}

const updateFailedPaymentDialogLoading = () => {
    return {
        type: UPDATE_FAILED_PAYMENT_DIALOG_LOADING,
    }
}

const updateFailedPaymentDialogError = (payload: { title: string; message: string }) => {
    return {
        type: UPDATE_FAILED_PAYMENT_DIALOG_ERROR,
        payload,
    }
}

const updateFailedPaymentDialogSuccess = (payload: { message: string }) => {
    return {
        type: UPDATE_FAILED_PAYMENT_DIALOG_SUCCESS,
        payload,
    }
}

const hideUpdateFailedPaymentDialog = () => {
    return {
        type: HIDE_UPDATE_FAILED_PAYMENT_DIALOG,
    }
}

const unHideUpdateFailedPaymentDialog = () => {
    return {
        type: UNHIDE_UPDATE_FAILED_PAYMENT_DIALOG,
    }
}

const failedPaymentGoToConfirmation = () => {
    return {
        type: FAILED_PAYMENT_GO_TO_PROCEED,
    }
}

const onFailedPaymentAddNewMethodSelection = () => {
    return {
        type: ON_FAILED_PAYMENT_ADD_NEW_METHOD_SELECTION,
    }
}

const onFailedPaymentDialogProceed = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            updateFailedPaymentDialog: { isAddingNewPaymentMethod },
        } = getState()

        if (isAddingNewPaymentMethod) {
            dispatch(hideUpdateFailedPaymentDialog())
            dispatch(addNewPaymentMethodSetup())
            return dispatch(showAddNewMethodDialog())
        } else {
            return dispatch(failedPaymentGoToConfirmation())
        }
    }
}

const onUpdateFailedPaymentDialogClose = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(getPayerFailedPayments())
        return dispatch(cancelUpdatedFailedPaymentDialog())
    }
}

const cancelUpdatedFailedPaymentDialog = () => {
    return {
        type: CANCEL_UPDATE_FAILED_PAYMENT_DIALOG,
    }
}

export {
    UPDATE_FAILED_PAYMENT_DIALOG_LOADING,
    UPDATE_FAILED_PAYMENT_DIALOG_ERROR,
    UPDATE_FAILED_PAYMENT_DIALOG_SUCCESS,
    ON_FAILED_PAYMENT_DIALOG_USE_EXISTING_ACH,
    HIDE_UPDATE_FAILED_PAYMENT_DIALOG,
    UNHIDE_UPDATE_FAILED_PAYMENT_DIALOG,
    ON_FAILED_PAYMENT_DIALOG_USE_EXISTING_CREDIT,
    FAILED_PAYMENT_GO_TO_PROCEED,
    ON_FAILED_PAYMENT_ADD_NEW_METHOD_SELECTION,
    CANCEL_UPDATE_FAILED_PAYMENT_DIALOG,
    SHOW_UPDATE_FAILED_PAYMENT_DIALOG,
    showUpdateFailedPaymentDialog,
    onFailedPaymentUseExistingCredit,
    onFailedPaymentUseExistingAch,
    onFailedPaymentDialogProceed,
    onFailedPaymentAddNewMethodSelection,
    onFailedPaymentPlaidLinkSuccess,
    onFailedPaymentStripeAddCardSuccess,
    onFailedPaymentUpdateAndPay,
    onUpdateFailedPaymentDialogClose,
    updateFailedPaymentDialogLoading,
    updateFailedPaymentDialogSuccess,
    updateFailedPaymentDialogError,
}
