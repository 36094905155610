import { RootState } from '../app/RootState'
import { Dispatch } from 'react'
import Api from '../../api/Api'
import { PayerPaymentMethods } from '../../types/Response/PayerPaymentMethods'
import { apiResponseContainErrorCode } from '../../shared/utils'
import {
    addNewPaymentMethodSuccess,
    addNewPaymentMethodSetupError,
    addNewMethodLoading,
    showAddNewMethodDialog,
} from '../add-new-method/addNewMethod.actions'
import { AchPaymentMethod } from '../../types/AchPaymentMethod'

const REQUEST_PAYMENT_METHODS = '[PAYMENT METHODS] REQUEST PAYMENT METHODS'
const REQUEST_PAYMENT_METHODS_SUCCESS = '[PAYMENT METHODS] REQUEST PAYMENT METHODS SUCCESS'
const REQUEST_PAYMENT_METHODS_ERROR = '[PAYMENT METHODS] REQUEST PAYMENT METHODS ERROR'
const NO_PAYMENT_METHODS_FOUND = '[PAYMENT METHODS] NO PAYMENT METHODS FOUND'
const START_DELETE_PAYMENT_METHOD = '[PAYMENT METHODS] START DELETE PAYMENT METHOD'
const STOP_DELETE_PAYMENT_METHOD = '[PAYMENT METHODS] STOP DELETE PAYMENT METHOD'
const DELETE_PAYMENT_METHOD_SUCCESS = '[PAYMENT METHODS] DELETE PAYMENT METHOD SUCCESS'
const DELETE_PAYMENT_METHOD_ERROR = '[PAYMENT METHODS] DELETE PAYMENT METHOD ERROR'
const REQUEST_DELETE_PAYMENT_METHOD = '[PAYMENT METHODS] REQUEST DELETE PAYMENT METHOD'
const START_DELETE_ACH_PAYMENT_METHOD = '[PAYMENT METHODS] START DELETE ACH PAYMENT METHOD'

export type Action =
    | { type: typeof REQUEST_PAYMENT_METHODS_SUCCESS }
    | { type: typeof REQUEST_PAYMENT_METHODS_ERROR; payload: { title: string; message: string } }
    | { type: typeof NO_PAYMENT_METHODS_FOUND }
    | { type: typeof START_DELETE_PAYMENT_METHOD; payload: { method: PayerPaymentMethods } }
    | { type: typeof STOP_DELETE_PAYMENT_METHOD }
    | { type: typeof DELETE_PAYMENT_METHOD_SUCCESS; payload: { message: string } }
    | { type: typeof DELETE_PAYMENT_METHOD_ERROR; payload: { title: string; message: string } }
    | { type: typeof REQUEST_DELETE_PAYMENT_METHOD }
    | { type: typeof START_DELETE_ACH_PAYMENT_METHOD; payload: { achMethod: AchPaymentMethod } }
    | { type: typeof REQUEST_PAYMENT_METHODS }

const getPaymentMethods = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId },
        } = getState()

        dispatch(requestPaymentMethods())

        try {
            const { data } = await Api.getPatientPaymentMethods(payerId)
            if (data.paymentMethods.length === 0 && data.achPaymentMethod === null) {
                return dispatch(noPatientPaymentMethods())
            } else {
                return dispatch(
                    receivePatientPaymentMethods({
                        paymentMethods: data.paymentMethods,
                        achPaymentMethod: data.achPaymentMethod,
                    })
                )
            }
        } catch (err) {
            const error = {
                title: 'Payment Methods',
                message: `Error getting payment methods for payerId: ${payerId}`,
            }
            dispatch(receivePatientPaymentMethodsError(error))
            throw error
        }
    }
}

const requestPaymentMethods = () => {
    return {
        type: REQUEST_PAYMENT_METHODS,
    }
}

const receivePatientPaymentMethods = (payload: {
    paymentMethods: Array<PayerPaymentMethods>
    achPaymentMethod: AchPaymentMethod
}) => {
    return {
        type: REQUEST_PAYMENT_METHODS_SUCCESS,
        payload,
    }
}

const receivePatientPaymentMethodsError = (payload: { title: string; message: string }) => {
    return {
        type: REQUEST_PAYMENT_METHODS_ERROR,
        payload,
    }
}

const noPatientPaymentMethods = () => {
    return {
        type: NO_PAYMENT_METHODS_FOUND,
    }
}

const startDeletePaymentMethod = (payload: { method: PayerPaymentMethods }) => {
    return {
        type: START_DELETE_PAYMENT_METHOD,
        payload,
    }
}

const startDeleteAchPaymentMethod = (payload: { achMethod: AchPaymentMethod }) => {
    return {
        type: START_DELETE_ACH_PAYMENT_METHOD,
        payload,
    }
}

const stopDeletePaymentMethod = () => {
    return {
        type: STOP_DELETE_PAYMENT_METHOD,
    }
}

const deletePaymentMethod = (payload: {
    cardMethodToDelete?: PayerPaymentMethods
    achMethodToDelete?: AchPaymentMethod
}) => {
    return async (dispatch: Dispatch<any>) => {
        if (payload.cardMethodToDelete) {
            return dispatch(deleteCardPaymentMethod({ tokenId: payload.cardMethodToDelete.tokenId }))
        } else {
            return dispatch(deleteAchPaymentMethod({ achId: payload.achMethodToDelete.achId }))
        }
    }
}

const deleteCardPaymentMethod = (payload: { tokenId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId },
        } = getState()

        dispatch(requestDeletePaymentMethod())

        try {
            const { data } = await Api.deleteCardPaymentMethod(payerId, payload.tokenId)
            dispatch(deletePaymentMethodSuccess(data))
            return dispatch(getPaymentMethods())
        } catch (err) {
            let error
            if (apiResponseContainErrorCode(err as any, 409)) {
                error = {
                    title: 'Unable to Delete',
                    message: 'Payment method is attached to a payment plan',
                }
            } else {
                error = {
                    title: 'Unable to Delete',
                    message: 'Error deleting payment method',
                }
            }
            return dispatch(deletePaymentMethodError(error))
        }
    }
}

const deleteAchPaymentMethod = (payload: { achId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { practiceId },
        } = getState()

        dispatch(requestDeletePaymentMethod())

        try {
            const { data } = await Api.deleteAchPaymentMethod(practiceId, payload.achId)
            dispatch(deletePaymentMethodSuccess(data))
            return dispatch(getPaymentMethods())
        } catch (err) {
            let error
            if (apiResponseContainErrorCode(err as any, 409)) {
                error = {
                    title: 'Unable to Delete',
                    message: 'Payment method is attached to a payment plan',
                }
            } else {
                error = {
                    title: 'Unable to Delete',
                    message: 'Error deleting payment method',
                }
            }
            return dispatch(deletePaymentMethodError(error))
        }
    }
}

const requestDeletePaymentMethod = () => {
    return {
        type: REQUEST_DELETE_PAYMENT_METHOD,
    }
}

const deletePaymentMethodSuccess = (payload: { message: string }) => {
    return {
        type: DELETE_PAYMENT_METHOD_SUCCESS,
    }
}

const deletePaymentMethodError = (payload: { title: string; message: string }) => {
    return {
        type: DELETE_PAYMENT_METHOD_ERROR,
        payload,
    }
}

const addNewPaymentMethodError = (payload: { title: string; message: string; messageJson: string }) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(addNewPaymentMethodSetupError(payload))
    }
}

const onPlaidLinkSuccess = (payload: {
    publicToken: string
    accountId: string
    plaidBankName: string
    accountLast4: string
}) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId, practiceId },
        } = getState()

        dispatch(showAddNewMethodDialog())
        dispatch(addNewMethodLoading())

        try {
            // eslint-disable-next-line
            const { data: createCustomerData } = await Api.createAchCustomerForPortal(
                payerId,
                practiceId,
                payload.publicToken,
                payload.accountId
            )

            dispatch(addNewPaymentMethodSuccess())
            return dispatch(getPaymentMethods())
        } catch (err) {
            dispatch(
                addNewPaymentMethodSetupError({
                    title: 'API Error',
                    message: `Unable to add bank account to customer ${payerId}`,
                    messageJson: err as any,
                })
            )
        }
    }
}

const onStripeAddCardSuccess = (payload: { paymentMethodCardId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            dispatch(addNewPaymentMethodSuccess())
            return dispatch(getPaymentMethods())
        } catch (err) {
            return dispatch(addNewPaymentMethodError(err as any))
        }
    }
}

export {
    REQUEST_PAYMENT_METHODS_SUCCESS,
    REQUEST_PAYMENT_METHODS_ERROR,
    NO_PAYMENT_METHODS_FOUND,
    START_DELETE_PAYMENT_METHOD,
    STOP_DELETE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD_SUCCESS,
    DELETE_PAYMENT_METHOD_ERROR,
    REQUEST_DELETE_PAYMENT_METHOD,
    START_DELETE_ACH_PAYMENT_METHOD,
    REQUEST_PAYMENT_METHODS,
    getPaymentMethods,
    startDeletePaymentMethod,
    stopDeletePaymentMethod,
    deletePaymentMethod,
    addNewPaymentMethodError,
    startDeleteAchPaymentMethod,
    onPlaidLinkSuccess,
    onStripeAddCardSuccess,
}
