import React from 'react'
import './PayDownPlanSuccess.scss'
import { SFSuccessDialogContent } from '@simplifeye/component-library'
import { formatCurrencyCents } from '../../../shared/utils'

interface Props {
    monthlyRemaining: number
    balanceRemaining: number
    isPaidOff: boolean
}

const PayDownPlanSuccess = ({ monthlyRemaining, balanceRemaining, isPaidOff }: Props) => {
    if (isPaidOff) {
        return (
            <div className="pay-down-plan-dialog-inner pay-down-plan-success">
                <div className="success-wrapper">
                    <SFSuccessDialogContent
                        title="Your payment plan has been successfully completed"
                        message="Your balance remaining is $0.00"
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div className="pay-down-plan-dialog-inner pay-down-plan-success">
                <div className="success-wrapper">
                    <SFSuccessDialogContent title="Success" message="Payment successfully added to payment plan" />
                </div>
                <div className="update-monthly-wrapper">
                    <div className="title">Here's how this affects your payment plan:</div>
                    <div className="plan-totals">
                        <div className="item">
                            <label htmlFor="monthlyPayment">NEW Monthly Payment</label>
                            <div id="monthlyPayment">{formatCurrencyCents(monthlyRemaining)}</div>
                        </div>
                        <div className="item">
                            <label htmlFor="balance">Balance Remaining</label>
                            <div id="balanceRemaining">{formatCurrencyCents(balanceRemaining)}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PayDownPlanSuccess
