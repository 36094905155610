import React from 'react'
import { Grid, Button } from '@material-ui/core'
import './UnpaidInvoiceNotice.scss'
import { formatCurrencyCents, formatDate } from '../../../shared/utils'
import { UnpaidInvoice } from '../../../types/UnpaidInvoice'
import WarningIcon from '@material-ui/icons/Warning'
import moment from 'moment'

interface Props {
    invoice: UnpaidInvoice
    onPay: (invoiceId: string) => void
    isLate?: boolean
    index: number
    isPending?: boolean
    isFailed?: boolean
}

const UnpaidInvoiceNotice = ({ invoice, onPay, isLate, index, isPending, isFailed }: Props) => {
    if (!invoice) {
        return null
    }

    const calcDaysPastDue = (dueDate: string): string => {
        const today = moment(new Date())
        const dueDateMoment = moment(dueDate)
        const daysDiff = today.diff(dueDateMoment, 'days') + 1

        if (daysDiff === 1) {
            return '1 day'
        } else {
            return daysDiff + ' days'
        }
    }

    return (
        <div className={`unpaid-invoice ${isLate || isFailed ? 'late-invoice' : ''}`}>
            <Grid container={true}>
                {isLate ? (
                    <Grid item={true} xs={12} className="late-header">
                        <WarningIcon className="icon" />
                        <div className="title">
                            This payment is <span>{calcDaysPastDue(invoice.dueDate)}</span> past due
                        </div>
                    </Grid>
                ) : null}
                {isFailed ? (
                    <Grid item={true} xs={12} className="late-header">
                        <WarningIcon className="icon" />
                        <div className="title">This payment failed</div>
                    </Grid>
                ) : null}
                <Grid item={true} xs={12} sm={12} lg={6}>
                    <div className={'field-label'}>
                        <span>Description</span>
                    </div>
                    <div className={'field-data invoice-id'}>
                        <span>{`${
                            invoice.procedureDescriptions.length > 0
                                ? invoice.procedureDescriptions[0].treatmentDescription
                                : ''
                        }`}</span>
                    </div>
                </Grid>
                <Grid item={true} xs={6} sm={4} lg={2}>
                    <div className={'field-label'}>
                        <span>Due By</span>
                    </div>
                    <div className={'field-data'}>
                        <span>{formatDate(invoice.dueDate)}</span>
                    </div>
                </Grid>
                <Grid item={true} xs={6} sm={4} lg={2}>
                    <div className={'field-label'}>
                        <span>Amount</span>
                    </div>
                    <div className={'field-data invoice-amount'}>
                        <span>{formatCurrencyCents(invoice.totalAmount)}</span>
                    </div>
                </Grid>
                <Grid item={true} xs={12} sm={4} lg={2}>
                    <Button
                        className={'payment-btn'}
                        variant="contained"
                        onClick={() => {
                            onPay(invoice.chargeEventId)
                        }}
                        id={`paymentButton${index}`}
                        disabled={isPending}
                    >
                        {isPending ? 'PENDING' : 'Make This Payment'}
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default UnpaidInvoiceNotice
