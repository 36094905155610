import { Dispatch } from 'react'
import Api from '../../api/Api'
import { apiResponseContainErrorCode } from '../../shared/utils'
import { AchPaymentMethod } from '../../types/AchPaymentMethod'
import { PaymentPlan } from '../../types/PaymentPlan'
import { PaymentPlanBasicInfo } from '../../types/PaymentPlanBasicInfo'
import { PayerPaymentMethods } from '../../types/Response/PayerPaymentMethods'
import { RootState } from '../app/RootState'
import { getPaymentMethods } from '../payment-methods/paymentMethods.action'
import { addNewPaymentMethodSetup, cancelAddNewMethod } from '../add-new-method/addNewMethod.actions'
import { CalculateFeesForSubtotalResponse } from '../../types/Response/CalculateFeesForSubtotalResponse'
import { OffCyclePaymentResponse } from '../../types/Response/OffCyclePaymentResponse'

const PAY_DOWN_PLAN_START_SUCCESS = '[PAY DOWN PLAN] PAY DOWN PLAN START SUCCESS'
const REQUEST_PAYMENT_PLANS = '[PAY DOWN PLAN] REQUEST PAYMENT PLANS'
const PAY_DOWN_PLAN_ERROR = '[PAY DOWN PLAN] PAY DOWN PLAN ERROR'
const ON_PAYMENT_PLAN_SELECT = '[PAY DOWN PLAN] ON PAYMENT PLAN SELECT'
const PAY_DOWN_PLAN_CANCEL = '[PAY DOWN PLAN] PAY DOWN PLAN CANCEL'
const PAY_DOWN_PLAN_LOADING = '[PAY DOWN PLAN] PAY DOWN PLAN LOADING'
const ON_GO_TO_SELECT_PAYMENT_METHOD_SUCCESS = '[PAY DOWN PLAN] ON GO TO SELECT PAYMENT METHOD SUCCESS'
const ON_USE_EXISTING_CREDIT_DEBIT = '[PAY DOWN PLAN] ON USE EXISTING CREDIT/DEBIT'
const ON_BACK_TO_SELECT_PAYMENT_PLAN = '[PAY DOWN PLAN] ON BACK TO SELECT PAYMENT PLAN'
const GET_BALANCE_REMAINING_SUCCESS = '[PAY DOWN PLAN] GET BALANCE REMAINING SUCCESS'
const ON_BACK_TO_SELECT_PAYMENT_METHOD = '[PAY DOWN PLAN] ON BACK TO SELECT PAYMENT METHOD'
const AMOUNT_TO_PAY_LESS_THAN_MAX = '[PAY DOWN PLAN] AMOUNT TO PAY LESS THAN MAX'
const AMOUNT_TO_PAY_MORE_THAN_MAX = '[PAY DOWN PLAN] AMOUNT TO PAY MORE THAN MAX'
const MAKE_OFF_CYCLE_PAYMENT_SUCCESS = '[PAY DOWN PLAN] MAKE OFF CYCLE PAYMENT SUCCESS'
const ON_USE_EXISTING_ACH = '[PAY DOWN PLAN] ON EXISTING ACH'
const ON_ADD_NEW_PAYMENT_METHOD_SELECTION = '[PAY DOWN PLAN] ON ADD NEW PAYMENT METHOD SELECTION'
const HIDE_PAY_DOWN_PLAN_DIALOG = '[PAY DOWN PLAN] HIDE PAY DOWN PLAN DIALOG'
const SHOW_PAY_DOWN_PLAN_DIALOG = '[PAY DOWN PLAN] SHOW PAY DOWN PLAN DIALOG'
const ADD_CARD_TOKEN_TO_USER_SUCCESS = '[PAY DOWN PLAN] ADD CARD TOKEN TO USER SUCCESS'
const CREATE_ACH_CUSTOMER_FOR_PORTAL_SUCCESS = '[PAY DOWN PLAN] CREATE CUSTOMER FOR PORTAL SUCCESS'
const ON_GO_TO_CONFIRMATION_SUCCESS = '[PAY DOWN PLAN] ON GO TO CONFIRMATION SUCCESS'
const ON_BACK_TO_CHOOSE_HOW_MUCH = '[PAY DOWN PLAN] ON BACK TO CHOOSE HOW MUCH'

export type Action =
    | { type: typeof PAY_DOWN_PLAN_START_SUCCESS; payload: { paymentPlans: PaymentPlanBasicInfo[] } }
    | { type: typeof REQUEST_PAYMENT_PLANS }
    | { type: typeof PAY_DOWN_PLAN_ERROR; payload: { title: string; message: string; messageJson: string } }
    | { type: typeof ON_PAYMENT_PLAN_SELECT; payload: { paymentPlanId: string } }
    | { type: typeof PAY_DOWN_PLAN_CANCEL }
    | { type: typeof PAY_DOWN_PLAN_LOADING }
    | { type: typeof ON_GO_TO_SELECT_PAYMENT_METHOD_SUCCESS }
    | { type: typeof ON_USE_EXISTING_CREDIT_DEBIT; payload: { savedCreditDebit: PayerPaymentMethods } }
    | { type: typeof ON_USE_EXISTING_ACH; payload: { achPaymentMethod: AchPaymentMethod } }
    | { type: typeof ON_BACK_TO_SELECT_PAYMENT_PLAN }
    | { type: typeof GET_BALANCE_REMAINING_SUCCESS; payload: { paymentPlan: PaymentPlan } }
    | { type: typeof ON_BACK_TO_SELECT_PAYMENT_METHOD }
    | { type: typeof AMOUNT_TO_PAY_LESS_THAN_MAX; payload: { amountToPayDown: number } }
    | { type: typeof AMOUNT_TO_PAY_MORE_THAN_MAX; payload: { amountToPayDown: number } }
    | { type: typeof MAKE_OFF_CYCLE_PAYMENT_SUCCESS; payload: { offCyclePaymentResponse: OffCyclePaymentResponse } }
    | { type: typeof ON_ADD_NEW_PAYMENT_METHOD_SELECTION }
    | { type: typeof HIDE_PAY_DOWN_PLAN_DIALOG }
    | { type: typeof SHOW_PAY_DOWN_PLAN_DIALOG }
    | {
          type: typeof ADD_CARD_TOKEN_TO_USER_SUCCESS
          payload: { tokenId: string; cardType: string; last4: string; cardFundingType: string }
      }
    | {
          type: typeof CREATE_ACH_CUSTOMER_FOR_PORTAL_SUCCESS
          payload: {
              plaidBankName: string
              accountLast4: string
              achId: string
              customerId: string
          }
      }
    | { type: typeof ON_GO_TO_CONFIRMATION_SUCCESS; payload: { fees: CalculateFeesForSubtotalResponse } }
    | { type: typeof ON_BACK_TO_CHOOSE_HOW_MUCH }

const payDownPlanStart = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId },
        } = getState()

        dispatch(requestPaymentPlans())

        try {
            const {
                data: { paymentPlans },
            } = await Api.getPaymentPlans(payerId)

            await dispatch(payDownPlanStartSuccess({ paymentPlans }))

            if (paymentPlans.length === 1) {
                await dispatch(onPaymentPlanSelect({ paymentPlanId: paymentPlans[0].paymentPlanId }))
                await dispatch(onGoToSelectPaymentMethod())
            }

            return
        } catch (err) {
            let error: { title: string; message: string; messageJson: string }
            if (apiResponseContainErrorCode(err as any, 409)) {
                error = {
                    title: 'Payment Plans',
                    message: `No payment plans for payer: ${payerId}`,
                    messageJson: JSON.stringify(err),
                }
            } else {
                error = {
                    title: 'Payment Plans',
                    message: `Error getting payment plans for payer: ${payerId}`,
                    messageJson: JSON.stringify(err),
                }
            }
            return dispatch(payDownPlanError(error))
        }
    }
}

const requestPaymentPlans = () => {
    return {
        type: REQUEST_PAYMENT_PLANS,
    }
}

const payDownPlanStartSuccess = (payload: { paymentPlans: PaymentPlanBasicInfo[] }) => {
    return {
        type: PAY_DOWN_PLAN_START_SUCCESS,
        payload,
    }
}

const payDownPlanCancel = () => {
    return {
        type: PAY_DOWN_PLAN_CANCEL,
    }
}

const payDownPlanError = (payload: { title: string; message: string; messageJson: string }) => {
    return {
        type: PAY_DOWN_PLAN_ERROR,
        payload,
    }
}

const onPaymentPlanSelect = (payload: { paymentPlanId: string }) => {
    return {
        type: ON_PAYMENT_PLAN_SELECT,
        payload,
    }
}

const onGoToSelectPaymentMethod = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(payDownDialogLoading())
        try {
            dispatch(getPaymentMethods())
            dispatch(onGoToSelectPaymentMethodSuccess())
        } catch (err) {
            return dispatch(
                payDownPlanError({
                    title: 'Payment Methods',
                    message: `Unable to get payment methods for your portalUserId`,
                    messageJson: JSON.stringify(err),
                })
            )
        }
    }
}

const onGoToSelectPaymentMethodSuccess = () => {
    return {
        type: ON_GO_TO_SELECT_PAYMENT_METHOD_SUCCESS,
    }
}

const onGoToHowMuch = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            payDownPlanDialog: { isAddingNewPaymentMethod },
        } = getState()

        if (isAddingNewPaymentMethod) {
            return dispatch(startAddNewPaymentMethod())
        } else {
            return dispatch(getBalanceRemaining())
        }
    }
}

const getBalanceRemaining = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            payDownPlanDialog: { paymentPlanSelected },
        } = getState()

        dispatch(payDownDialogLoading())

        try {
            const { data: paymentPlan } = await Api.getBalanceRemainingForPaymentPlan(paymentPlanSelected)
            return dispatch(getBalanceRemainingSuccess({ paymentPlan }))
        } catch (err) {
            return dispatch(
                payDownPlanError({
                    title: 'Payment Plan Totals',
                    message: `Unable to get payment plan totals for plan id: ${paymentPlanSelected}`,
                    messageJson: JSON.stringify(err),
                })
            )
        }
    }
}

const getBalanceRemainingSuccess = (payload: { paymentPlan: PaymentPlan }) => {
    return {
        type: GET_BALANCE_REMAINING_SUCCESS,
        payload,
    }
}

const payDownDialogLoading = () => {
    return {
        type: PAY_DOWN_PLAN_LOADING,
    }
}

const onUseExistingCreditDebitMethodSelection = (payload: { savedCreditDebit: PayerPaymentMethods }) => {
    return {
        type: ON_USE_EXISTING_CREDIT_DEBIT,
        payload,
    }
}

const onUseExistingAchSelection = (payload: { achPaymentMethod: AchPaymentMethod }) => {
    return {
        type: ON_USE_EXISTING_ACH,
        payload,
    }
}

const onBackToSelectPaymentPlan = () => {
    return {
        type: ON_BACK_TO_SELECT_PAYMENT_PLAN,
    }
}

const onBackToSelectPaymentMethod = () => {
    return {
        type: ON_BACK_TO_SELECT_PAYMENT_METHOD,
    }
}

const onAmountToPayDownChange = (payload: { amountToPayDown: number }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            payDownPlanDialog: { maxAllowedPayment },
        } = getState()

        if (payload.amountToPayDown > maxAllowedPayment) {
            return dispatch(amountToPayMoreThanMax({ amountToPayDown: payload.amountToPayDown }))
        } else {
            return dispatch(amountToPayLessThanMax({ amountToPayDown: payload.amountToPayDown }))
        }
    }
}

const amountToPayLessThanMax = (payload: { amountToPayDown: number }) => {
    return {
        type: AMOUNT_TO_PAY_LESS_THAN_MAX,
        payload,
    }
}

const amountToPayMoreThanMax = (payload: { amountToPayDown: number }) => {
    return {
        type: AMOUNT_TO_PAY_MORE_THAN_MAX,
        payload,
    }
}

const makeOffCyclePayment = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            payDownPlanDialog: { savedCreditDebit, paymentPlanDetails },
        } = getState()

        dispatch(payDownDialogLoading())

        const isTaxable = paymentPlanDetails.balanceRemaining.taxBalance > 0

        if (savedCreditDebit) {
            return dispatch(makeOffCyclePaymentCard({ isTaxable }))
        } else {
            return dispatch(makeOffCyclePaymentAch({ isTaxable }))
        }
    }
}

const makeOffCyclePaymentCard = (payload: { isTaxable: boolean }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            payDownPlanDialog: { paymentPlanSelected, amountToPayDown, savedCreditDebit },
        } = getState()

        try {
            const { data } = await Api.makeOffCyclePaymentUseCard(
                paymentPlanSelected,
                amountToPayDown,
                payload.isTaxable,
                savedCreditDebit.tokenId
            )
            return dispatch(makeOffCyclePaymentSuccess({ offCyclePaymentResponse: data }))
        } catch (err) {
            return dispatch(
                payDownPlanError({
                    title: 'Pay Down Error',
                    message: `Unable to make an off cycle payment using this bank account at this time`,
                    messageJson: JSON.stringify(err),
                })
            )
        }
    }
}

const makeOffCyclePaymentAch = (payload: { isTaxable: boolean }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            payDownPlanDialog: { paymentPlanSelected, amountToPayDown, savedAchPaymentMethod },
        } = getState()

        try {
            const { data } = await Api.makeOffCyclePaymentUseAch(
                paymentPlanSelected,
                amountToPayDown,
                payload.isTaxable,
                savedAchPaymentMethod.achId
            )
            return dispatch(makeOffCyclePaymentSuccess({ offCyclePaymentResponse: data }))
        } catch (err) {
            return dispatch(
                payDownPlanError({
                    title: 'Pay Down Error',
                    message: `Unable to make an off cycle payment using this bank account at this time`,
                    messageJson: JSON.stringify(err),
                })
            )
        }
    }
}

const makeOffCyclePaymentSuccess = (payload: { offCyclePaymentResponse: OffCyclePaymentResponse }) => {
    return {
        type: MAKE_OFF_CYCLE_PAYMENT_SUCCESS,
        payload,
    }
}

const onAddNewPaymentMethodSelection = () => {
    return {
        type: ON_ADD_NEW_PAYMENT_METHOD_SELECTION,
    }
}

const startAddNewPaymentMethod = () => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(hidePayDownDialog())
        return dispatch(addNewPaymentMethodSetup())
    }
}

const hidePayDownDialog = () => {
    return {
        type: HIDE_PAY_DOWN_PLAN_DIALOG,
    }
}

const showPayDownDialog = () => {
    return {
        type: SHOW_PAY_DOWN_PLAN_DIALOG,
    }
}

const onStripeAddCardSuccessPayDownFlow = (payload: { paymentMethodCardId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(payDownDialogLoading())
        dispatch(cancelAddNewMethod())
        dispatch(showPayDownDialog())

        const {
            session: { payerId },
        } = getState()

        try {
            const {
                data: { tokenId, cardType, last4, cardFundingType },
            } = await Api.getPaymentMethod(payload.paymentMethodCardId, payerId)
            dispatch(addCardTokenToUserSuccess({ tokenId, cardType, last4, cardFundingType }))
            return dispatch(getBalanceRemaining())
        } catch (err) {
            return dispatch(
                payDownPlanError({
                    title: 'Card Error',
                    message: 'Unable to add card token to user',
                    messageJson: JSON.stringify(err),
                })
            )
        }
    }
}

const addCardTokenToUserSuccess = (payload: {
    tokenId: string
    cardType: string
    last4: string
    cardFundingType: string
}) => {
    return {
        type: ADD_CARD_TOKEN_TO_USER_SUCCESS,
        payload,
    }
}

const onPlaidSuccessPayDownFlow = (payload: {
    publicToken: string
    accountId: string
    plaidBankName: string
    accountLast4: string
}) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId, practiceId },
        } = getState()

        dispatch(payDownDialogLoading())
        dispatch(cancelAddNewMethod())
        dispatch(showPayDownDialog())

        try {
            const { data } = await Api.createAchCustomerForPortal(
                payerId,
                practiceId,
                payload.publicToken,
                payload.accountId
            )
            dispatch(
                createAchCustomerForPortalSuccess({
                    plaidBankName: payload.plaidBankName,
                    accountLast4: payload.accountLast4,
                    achId: data.achId,
                    customerId: data.customerId,
                })
            )
            return dispatch(getBalanceRemaining())
        } catch (err) {
            return dispatch(
                payDownPlanError({
                    title: 'Bank Error',
                    message: 'Unable to add bank account to user',
                    messageJson: JSON.stringify(err),
                })
            )
        }
    }
}

const createAchCustomerForPortalSuccess = (payload: {
    plaidBankName: string
    accountLast4: string
    achId: string
    customerId: string
}) => {
    return {
        type: CREATE_ACH_CUSTOMER_FOR_PORTAL_SUCCESS,
        payload,
    }
}

const onGoToConfirmation = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { practiceId },
            payDownPlanDialog: { amountToPayDown, savedCreditDebit, paymentPlanSelectedInfo, paymentPlanDetails },
        } = getState()

        try {
            let paymentMethod
            if (savedCreditDebit) {
                paymentMethod = savedCreditDebit.cardFundingType
            } else {
                paymentMethod = 'ach'
            }

            const isTaxable = paymentPlanDetails.balanceRemaining.taxBalance > 0

            const { data } = await Api.calculateFeesForGivenSubtotal(
                practiceId,
                paymentPlanSelectedInfo.locationId,
                paymentMethod,
                [amountToPayDown],
                'invoice',
                isTaxable
            )

            return dispatch(onGoToConfirmationSuccess({ fees: data }))
        } catch (err) {
            return dispatch(
                payDownPlanError({
                    title: 'Fees Error',
                    message: 'Unable to calculate fees for the provide information',
                    messageJson: JSON.stringify(err),
                })
            )
        }
    }
}

const onGoToConfirmationSuccess = (payload: { fees: CalculateFeesForSubtotalResponse }) => {
    return {
        type: ON_GO_TO_CONFIRMATION_SUCCESS,
        payload,
    }
}

const onBackToChooseHowMuch = () => {
    return {
        type: ON_BACK_TO_CHOOSE_HOW_MUCH,
    }
}

export {
    PAY_DOWN_PLAN_START_SUCCESS,
    REQUEST_PAYMENT_PLANS,
    PAY_DOWN_PLAN_ERROR,
    ON_PAYMENT_PLAN_SELECT,
    PAY_DOWN_PLAN_CANCEL,
    PAY_DOWN_PLAN_LOADING,
    ON_GO_TO_SELECT_PAYMENT_METHOD_SUCCESS,
    ON_USE_EXISTING_CREDIT_DEBIT,
    ON_BACK_TO_SELECT_PAYMENT_PLAN,
    GET_BALANCE_REMAINING_SUCCESS,
    ON_BACK_TO_SELECT_PAYMENT_METHOD,
    AMOUNT_TO_PAY_LESS_THAN_MAX,
    AMOUNT_TO_PAY_MORE_THAN_MAX,
    MAKE_OFF_CYCLE_PAYMENT_SUCCESS,
    ON_USE_EXISTING_ACH,
    ON_ADD_NEW_PAYMENT_METHOD_SELECTION,
    HIDE_PAY_DOWN_PLAN_DIALOG,
    ADD_CARD_TOKEN_TO_USER_SUCCESS,
    SHOW_PAY_DOWN_PLAN_DIALOG,
    CREATE_ACH_CUSTOMER_FOR_PORTAL_SUCCESS,
    ON_GO_TO_CONFIRMATION_SUCCESS,
    ON_BACK_TO_CHOOSE_HOW_MUCH,
    payDownPlanStart,
    onPaymentPlanSelect,
    payDownPlanCancel,
    onGoToSelectPaymentMethod,
    onUseExistingCreditDebitMethodSelection,
    onBackToSelectPaymentPlan,
    onGoToHowMuch,
    onBackToSelectPaymentMethod,
    onAmountToPayDownChange,
    makeOffCyclePayment,
    onUseExistingAchSelection,
    onAddNewPaymentMethodSelection,
    onStripeAddCardSuccessPayDownFlow,
    onPlaidSuccessPayDownFlow,
    onGoToConfirmation,
    onBackToChooseHowMuch,
}
