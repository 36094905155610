import React, { useState, useEffect, ChangeEvent } from 'react'
import * as Constants from '../../../types/Constants'
import { PayerPaymentMethods } from '../../../types/Response/PayerPaymentMethods'
import { SFLoading, SFSelect } from '@simplifeye/component-library'
import { AchPaymentMethod } from '../../../types/AchPaymentMethod'
import { InvoicePaymentMethodsType } from '../../../types/Constants'
import PaymentMethodButton from './PaymentMethodButton/PaymentMethodButton'
import { IS_ACH_DISABLED } from '../../defaults'

import './SelectPaymentMethod.scss'

const NEW_PAYMENT = 'NEW'
const ACH_METHOD = 'ACH_METHOD'

interface Props {
    selectedPaymentMethod: InvoicePaymentMethodsType | null
    savedPaymentMethods: Array<PayerPaymentMethods>
    isAddingNewPaymentMethod: boolean
    achPaymentMethod: AchPaymentMethod
    onNewPaymentMethodSelect: (paymentMethodType: InvoicePaymentMethodsType) => void
    onPaymentMethodSelectChange: (
        paymentMethod: string,
        isExistingPayment: boolean,
        savedPaymentMethod?: PayerPaymentMethods,
        achPaymentMethod?: AchPaymentMethod
    ) => void
    isExternalInvoice: boolean
    // remove once otp pay is implemented for external users
    allowOTP?: boolean
    showDropdown?: boolean
    isLoading?: boolean
}

type PaymentSelectChangeEvent = ChangeEvent<HTMLSelectElement>

const SelectPaymentMethod = ({
    selectedPaymentMethod,
    savedPaymentMethods,
    isAddingNewPaymentMethod,
    achPaymentMethod,
    onNewPaymentMethodSelect,
    onPaymentMethodSelectChange,
    isExternalInvoice,
    allowOTP = true, // default true so other parts stay intact,
    showDropdown = true,
    isLoading = false,
}: Props) => {
    const [paymentOptions, setPaymentOptions] = useState<Array<{ value: string; text: string }>>([])
    const [selectedOption, setSelectedOption] = useState({ value: '', text: '' })
    const [existingPaymentMethod, setExistingPaymentMethod] = useState(null)
    const [isUsingExistingAchMethod, setIsUsingExistingAchMethod] = useState<boolean>(false)
    const [newPaymentMethodOptions, setNewPaymentMethodOptions] = useState<Array<{ value: string; text: string }>>([])
    const [newPaymentMethodSelectOption] = useState({ value: '', text: '' })

    useEffect(() => {
        const useExistingOptions = buildUseExistingPaymentMethodOptions(
            savedPaymentMethods,
            achPaymentMethod,
            isExternalInvoice
        )
        setPaymentOptions(useExistingOptions)

        setNewPaymentMethodOptions(buildNewPaymentMethodOptions())
    }, [selectedPaymentMethod, savedPaymentMethods, achPaymentMethod, isExternalInvoice])

    const buildUseExistingPaymentMethodOptions = (
        savedPaymentMethods: PayerPaymentMethods[],
        achPaymentMethod: AchPaymentMethod,
        isExternalInvoice: boolean
    ): Array<{ value: string; text: string }> => {
        let options: Array<{ value: string; text: string }> = []
        if (savedPaymentMethods && savedPaymentMethods.length > 0) {
            options = savedPaymentMethods.map(method => ({
                value: method.tokenId,
                text: `${method.cardType} ****${method.last4}`,
            }))
        }
        if (!IS_ACH_DISABLED && achPaymentMethod && !isExternalInvoice) {
            options.push({
                value: ACH_METHOD,
                text: `${achPaymentMethod.bankName} ****${achPaymentMethod.accountLast4}`,
            })
        }
        options.push({ value: NEW_PAYMENT, text: 'ADD NEW PAYMENT METHOD' })

        return options
    }

    useEffect(() => {
        const paymentOptionsWithoutNew = paymentOptions.filter(po => po.value !== NEW_PAYMENT)

        if (!isLoading && paymentOptionsWithoutNew.length === 0) {
            onPaymentSelectChange({
                target: {
                    value: NEW_PAYMENT,
                },
            } as PaymentSelectChangeEvent)
        }
    }, [isLoading])

    const buildNewPaymentMethodOptions = (): Array<{ value: string; text: string }> => [
        {
            value: Constants.InvoicePaymentMethods.CARD,
            text: 'Credit/Debit',
        },
        // {
        //     value: Constants.InvoicePaymentMethods.ACH,
        //     text: 'Bank Transfer',
        // },
        ...(allowOTP
            ? [
                  {
                      value: Constants.InvoicePaymentMethods.OTP,
                      text: 'Apple/Google Pay',
                  },
              ]
            : []),
    ]

    const onPaymentSelectChange = (event: PaymentSelectChangeEvent) => {
        const selectedTokenId = event.target.value
        setSelectedOption({ value: selectedTokenId, text: '' })
        if (selectedTokenId === NEW_PAYMENT) {
            setExistingPaymentMethod(null)
            setIsUsingExistingAchMethod(false)
            onPaymentMethodSelectChange('', false)
        } else if (selectedTokenId === ACH_METHOD) {
            setIsUsingExistingAchMethod(true)
            setExistingPaymentMethod(null)
            onPaymentMethodSelectChange('ach', true, null, achPaymentMethod)
        } else {
            const payerPaymentMethod = savedPaymentMethods.find(method => method.tokenId === selectedTokenId)
            const paymentMethod =
                ['credit', 'prepaid'].indexOf(payerPaymentMethod.cardFundingType) > -1
                    ? payerPaymentMethod.cardFundingType
                    : 'debit'
            setExistingPaymentMethod(payerPaymentMethod)
            setIsUsingExistingAchMethod(false)
            onPaymentMethodSelectChange(paymentMethod, true, payerPaymentMethod)
        }
    }

    const onNewPaymentMethodSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value as Constants.InvoicePaymentMethods
        onNewPaymentMethodSelect(value)
    }

    const paymentOptionsWithoutNew = paymentOptions.filter(po => po.value !== NEW_PAYMENT)

    if (isLoading) {
        return (
            <div className="select-payment-method select-payment-method--loading">
                <h3 className="section-header">Select Payment Method</h3>
                <div className="spm-loading-icon-wrapper">
                    <SFLoading width={60} />
                </div>
            </div>
        )
    }

    return (
        <div className="select-payment-method">
            <h3 className="section-header">Select Payment Method</h3>

            {showDropdown !== false && paymentOptionsWithoutNew.length > 0 && (
                <div className="choose-payment-method" id="paymentMethodSelect">
                    <label>Choose Payment Method</label>
                    <SFSelect
                        defaultText="Select Payment Method"
                        options={paymentOptions}
                        onChange={onPaymentSelectChange}
                        selectedOption={selectedOption}
                    />
                </div>
            )}

            {existingPaymentMethod ? (
                <div className="existing-payment-info">
                    <div className="info">
                        <label>Card Number</label>
                        <div className="value">{`****${existingPaymentMethod.last4}`}</div>
                    </div>
                    <div className="info">
                        <label>Expires</label>
                        <div className="value">{existingPaymentMethod.cardExpirationDate}</div>
                    </div>
                    <div className="info">
                        <label>Name on Card</label>
                        <div className="value">{existingPaymentMethod.nameOnCard}</div>
                    </div>
                </div>
            ) : null}

            {isUsingExistingAchMethod ? (
                <div className="existing-payment-info">
                    <div className="info">
                        <label>Bank</label>
                        <div className="value">{achPaymentMethod.bankName}</div>
                    </div>
                    <div className="info">
                        <label>Account</label>
                        <div className="value">{achPaymentMethod.accountLast4}</div>
                    </div>
                </div>
            ) : null}

            {isAddingNewPaymentMethod ? (
                <div>
                    <div className="new-payment-method-container">
                        <PaymentMethodButton
                            paymentMethodType={Constants.InvoicePaymentMethods.CARD}
                            isActive={selectedPaymentMethod === Constants.InvoicePaymentMethods.CARD}
                            onNewPaymentMethodSelect={onNewPaymentMethodSelect}
                        />
                        {false && !isExternalInvoice ? (
                            <PaymentMethodButton
                                paymentMethodType={Constants.InvoicePaymentMethods.ACH}
                                isActive={selectedPaymentMethod === Constants.InvoicePaymentMethods.ACH}
                                onNewPaymentMethodSelect={onNewPaymentMethodSelect}
                            />
                        ) : null}

                        {allowOTP && (
                            <PaymentMethodButton
                                paymentMethodType={Constants.InvoicePaymentMethods.OTP}
                                isActive={selectedPaymentMethod === Constants.InvoicePaymentMethods.OTP}
                                onNewPaymentMethodSelect={onNewPaymentMethodSelect}
                            />
                        )}
                    </div>
                    <div className="new-payment-method-container-select">
                        <div className="choose-payment-method">
                            <label>Select Payment Method Type</label>
                            <SFSelect
                                defaultText=""
                                options={newPaymentMethodOptions}
                                onChange={onNewPaymentMethodSelectChange}
                                selectedOption={newPaymentMethodSelectOption}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default SelectPaymentMethod
