import {
    Action,
    SETUP_STRIPE_MANUAL_DIALOG,
    STRIPE_MANUAL_CHECKOUT_SUCCESS,
    STRIPE_MANUAL_CHECKOUT_ERROR,
    RESET_STRIPE_MANUAL_DIALOG,
    ON_SUCCESS_REDIRECT,
} from './stripeManualCheckoutDialog.actions'
import { initialState } from './stripeManualCheckoutDialog.initial'
import { StripeManualCheckoutDialogState } from './stripeManualCheckoutDialog.state'

const stripeManualCheckoutReducer = (
    state: StripeManualCheckoutDialogState = initialState,
    action: Action
): StripeManualCheckoutDialogState => {
    switch (action.type) {
        case SETUP_STRIPE_MANUAL_DIALOG: {
            return {
                ...state,
                loading: false,
                show: true,
                clientSecret: action.payload.clientSecret,
                chargeEventId: action.payload.chargeEventId,
                paymentIntentId: action.payload.paymentIntentId,
                paymentMethod: action.payload.paymentMethod,
                billingTotals: action.payload.billingTotals,
            }
        }
        case STRIPE_MANUAL_CHECKOUT_SUCCESS: {
            return {
                ...state,
                success: true,
                error: {
                    showError: false,
                    title: '',
                    message: '',
                },
            }
        }
        case STRIPE_MANUAL_CHECKOUT_ERROR: {
            return {
                ...state,
                error: {
                    showError: true,
                    title: action.payload.title,
                    message: action.payload.message,
                },
            }
        }
        case RESET_STRIPE_MANUAL_DIALOG: {
            return {
                ...initialState,
            }
        }
        case ON_SUCCESS_REDIRECT: {
            return {
                ...state,
                redirect: true,
            }
        }
        default: {
            return { ...state }
        }
    }
}

export default stripeManualCheckoutReducer
