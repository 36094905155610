import Api from '../../api/Api'
import { InvoicePaymentMethodsType } from '../../types/Constants'
import { CalculateFeesForSubtotalResponse } from '../../types/Response/CalculateFeesForSubtotalResponse'

const SETUP_STRIPE_MANUAL_DIALOG = '[STRIPE MANUAL DIALOG] SETUP STRIPE MANUAL DIALOG'
const STRIPE_MANUAL_CHECKOUT_SUCCESS = '[STRIPE MANUAL DIALOG] STRIPE MANUAL CHECKOUT SUCCESS'
const STRIPE_MANUAL_CHECKOUT_ERROR = '[STRIPE MANUAL DIALOG] STRIPE MANUAL CHECKOUT ERROR'
const RESET_STRIPE_MANUAL_DIALOG = '[STRIPE MANUAL DIALOG] CLOSE STRIPE MANUAL DIALOG'
const ON_SUCCESS_REDIRECT = '[STRIPE MANUAL DIALOG] ON SUCCESS REDIRECT'

export type Action =
    | {
          type: typeof SETUP_STRIPE_MANUAL_DIALOG
          payload: {
              clientSecret: string
              chargeEventId: string
              paymentIntentId: string
              paymentMethod: InvoicePaymentMethodsType | null
              billingTotals: {
                  subtotal: number
                  tax: number
                  fee: number
                  total: number
                  allFees: CalculateFeesForSubtotalResponse['applicationFees']
                  allTotals: CalculateFeesForSubtotalResponse['total']
              }
          }
      }
    | { type: typeof STRIPE_MANUAL_CHECKOUT_SUCCESS }
    | { type: typeof STRIPE_MANUAL_CHECKOUT_ERROR; payload: { title: string; message: string } }
    | { type: typeof RESET_STRIPE_MANUAL_DIALOG }
    | { type: typeof ON_SUCCESS_REDIRECT }

const setupStripeDialog = (payload: {
    clientSecret: string
    chargeEventId: string
    paymentIntentId: string
    paymentMethod: InvoicePaymentMethodsType | null
    billingTotals: {
        subtotal: number
        tax: number
        fee: number
        total: number
        allFees: CalculateFeesForSubtotalResponse['applicationFees']
        allTotals: CalculateFeesForSubtotalResponse['total']
    }
}) => {
    return {
        type: SETUP_STRIPE_MANUAL_DIALOG,
        payload,
    }
}

const onStripeManualCheckoutSuccess = () => {
    return {
        type: STRIPE_MANUAL_CHECKOUT_SUCCESS,
    }
}

const onStripeManualCheckoutError = (payload: { title: string; message: string }) => {
    return {
        type: STRIPE_MANUAL_CHECKOUT_ERROR,
        payload,
    }
}

const changeChargeEventStatusToPending = (payload: { chargeEventId: string }) => {
    return async () => {
        try {
            await Api.changeChargeEventStatusToPending(payload.chargeEventId)
        } catch (err) {
            throw err
        }
    }
}

const resetStripeManualDialog = () => {
    return {
        type: RESET_STRIPE_MANUAL_DIALOG,
    }
}

const onSuccessRedirect = () => {
    return {
        type: ON_SUCCESS_REDIRECT,
    }
}

const updateMismatchCardPaymentAmount = (
    practiceId: string,
    paymentIntentId: string,
    amount: number,
    enableSavePaymentMethod: boolean,
    applicationFeeAmount?: number
) => {
    return async dispatch => {
        try {
            await Api.updateMismatchCardPaymentAmount({
                practiceId,
                paymentIntentId,
                amount,
                enableSavePaymentMethod,
                applicationFeeAmount,
            })
        } catch (err) {
            throw err
        }
    }
}

const updateEnableSavePaymentMethod = (
    practiceId: string,
    paymentIntentId: string,
    enableSavePaymentMethod: boolean
) => {
    return async dispatch => {
        try {
            await Api.updateMismatchCardPaymentAmount({
                practiceId,
                paymentIntentId,
                enableSavePaymentMethod,
            })
        } catch (err) {
            throw err
        }
    }
}

const updateExternalInvoiceMismatchCardPaymentAmount = (
    chargeEventId: string,
    paymentMethod: InvoicePaymentMethodsType
) => {
    return async dispatch => {
        try {
            await Api.updateExternalInvoiceMismatchCardPaymentAmount(chargeEventId, paymentMethod)
        } catch (err) {
            throw err
        }
    }
}

export {
    SETUP_STRIPE_MANUAL_DIALOG,
    STRIPE_MANUAL_CHECKOUT_SUCCESS,
    STRIPE_MANUAL_CHECKOUT_ERROR,
    RESET_STRIPE_MANUAL_DIALOG,
    ON_SUCCESS_REDIRECT,
    setupStripeDialog,
    onStripeManualCheckoutSuccess,
    onStripeManualCheckoutError,
    changeChargeEventStatusToPending,
    resetStripeManualDialog,
    onSuccessRedirect,
    updateMismatchCardPaymentAmount,
    updateEnableSavePaymentMethod,
    updateExternalInvoiceMismatchCardPaymentAmount,
}
