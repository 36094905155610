import { PaymentMethodState } from './paymentMethods.state'

export const initialState: PaymentMethodState = {
    loading: true,
    error: {
        showError: false,
        title: '',
        message: '',
    },
    paymentMethods: [],
    achPaymentMethod: null,
    deleteDialog: {
        show: false,
        loading: true,
        cardMethodToDelete: null,
        achMethodToDelete: null,
        error: {
            showError: false,
            title: '',
            message: '',
        },
    },
    paymentPlans: null,
}
