import { SFDialog } from '@simplifeye/component-library'
import React from 'react'
import { useDispatch } from 'react-redux'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useHistory } from 'react-router'

import { logUserOutFromTimeoutDialog } from '../../reducers/session/session.actions'
import './TimeoutDialog.scss'

interface Props {
    isUserTimeout: boolean
}

const TimeoutDialog = ({ isUserTimeout }: Props) => {
    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <SFDialog
            title="Session Timeout"
            open={isUserTimeout}
            onClose={() => dispatch(logUserOutFromTimeoutDialog())}
            content={
                <div className="timeout-dialog">
                    <div className="icon">
                        <ExitToAppIcon />
                    </div>
                    <div className="info-text">
                        Your current session has ended. Please log back in if you would like to continue.
                    </div>
                </div>
            }
            footer={{
                footerButtons: {
                    centerButton: {
                        text: 'Back to login',
                        action: () => {
                            dispatch(logUserOutFromTimeoutDialog())
                            history.push('/login')
                        },
                    },
                },
            }}
        />
    )
}

export default TimeoutDialog
