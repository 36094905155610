import { OverviewState } from './overview.state'
import { TransactionTableDefaults } from '../../types/Constants/TransactionTableDefaults'

const initialState: OverviewState = {
    loading: true,
    error: {
        showError: false,
        title: '',
        message: '',
    },
    payerTransactions: {
        transactions: [],
        pagination: {
            totalRecords: 0,
            totalPages: 0,
            pageNumber: 0,
            pageSize: TransactionTableDefaults.NUMBER_OF_RECORDS,
        },
    },
    unpaidInvoices: [],
    lateInvoices: [],
    nextScheduledPayments: [],
    pendingInvoices: [],
    failedPayments: [],
    failedInvoices: [],
    failedPaymentLoading: false,
    failedPaymentError: {
        showError: false,
        title: '',
        message: '',
    },
    failedPaymentSuccess: false,
}

export default initialState
