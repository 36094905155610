import { initialState } from './session.initial'
import { SessionState } from './session.state'
import {
    Action,
    LOG_USER_IN_FROM_SESSION,
    SELECT_PRACTICE_FROM_SESSION,
    SET_EXTERNAL_INVOICE_INFO_FROM_SESSION,
    ON_USER_TIMEOUT,
    SET_LOGO_URL,
    ADD_PRACTICE_AND_PAYER_TO_SESSION,
} from './session.actions'

const sessionReducer = (state: SessionState = initialState, action: Action): SessionState => {
    switch (action.type) {
        case LOG_USER_IN_FROM_SESSION: {
            return {
                ...state,
                portalUserId: action.payload.portalUserId,
            }
        }
        case SELECT_PRACTICE_FROM_SESSION: {
            return {
                ...state,
                practiceId: action.payload.selectedPractice.practiceId,
                payerId: action.payload.selectedPractice.payerId,
                practiceInfo: {
                    practiceName: action.payload.selectedPractice.practiceName,
                    address: {
                        addressStreet1: action.payload.selectedPractice.address.addressStreet1,
                        addressStreet2: action.payload.selectedPractice.address.addressStreet2,
                        unit: action.payload.selectedPractice.address.unit,
                        city: action.payload.selectedPractice.address.city,
                        state: action.payload.selectedPractice.address.state,
                        zip: action.payload.selectedPractice.address.zip,
                    },
                    phoneNumber: action.payload.selectedPractice.phoneNumber,
                    practiceLogoURL: action.payload.selectedPractice.practiceLogoURL,
                },
            }
        }
        case SET_EXTERNAL_INVOICE_INFO_FROM_SESSION: {
            return {
                ...state,
                practiceId: action.payload.practiceId,
                locationId: action.payload.locationId,
            }
        }
        case ON_USER_TIMEOUT: {
            return {
                ...state,
                isUserTimeout: true,
            }
        }
        case SET_LOGO_URL: {
            return {
                ...state,
                logoUrl: action.payload.logoUrl,
                logoBackgroundColor: action.payload.logoBackgroundColor
                    ? action.payload.logoBackgroundColor
                    : state.logoBackgroundColor,
            }
        }
        case ADD_PRACTICE_AND_PAYER_TO_SESSION: {
            return {
                ...state,
                payerId: action.payload.payerId,
                practiceId: action.payload.practiceId,
            }
        }
        default: {
            return { ...state }
        }
    }
}

export default sessionReducer
