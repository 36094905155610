import React, { useEffect } from 'react'
import '../LoginShared.scss'
import './Login.scss'
import { useDispatch, useSelector } from 'react-redux'
import { loginUseExternalInvoiceStart } from '../../reducers/login/login.action'
import { RootState } from '../../reducers/app/RootState'
import { useHistory, useLocation } from 'react-router'
import ForgotPassword from './forgot-password/ForgotPassword'
import DefaultLogin from './default-login/DefaultLogin'
import TextToPayLogin from './text-to-pay-login/TextToPayLogin'

const Login = () => {
    const {
        isUserLoggedIn,
        error,
        skipSelectPayerScreen,
        isOnForgotPassword,
        isLoading,
        isOnForgotPasswordSuccess,
        goToLoginFromAccountCreation,
        externalInvoice: { inExternalInvoice },
        logoUrl,
    } = useSelector((state: RootState) => state.login)

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        if (location.pathname.includes('/login/external-invoice')) {
            const queryObj = new URLSearchParams(location.search)
            const practiceId = queryObj.get('practiceId')
            const locationId = queryObj.get('locationId')
            dispatch(loginUseExternalInvoiceStart({ practiceId, locationId }))
            history.push(`/external-invoice?practiceId=${practiceId}&locationId=${locationId}`)
        }
    }, [location, dispatch])

    useEffect(() => {
        if (isUserLoggedIn) {
            if (inExternalInvoice) {
                history.push('/external-invoice')
            } else {
                if (skipSelectPayerScreen) {
                    history.push('/overview')
                } else {
                    history.push('/select-practice')
                }
            }
        }
    }, [isUserLoggedIn, error, skipSelectPayerScreen, isOnForgotPassword, history, inExternalInvoice])

    const validateEmail = (emailAddress: string): string => {
        const EMAIL_REGEX = /^.+@.+\..+$/

        if (emailAddress === '') {
            return 'Email is required'
        } else if (!EMAIL_REGEX.test(emailAddress)) {
            return 'Email address is in an invalid format'
        } else {
            return ''
        }
    }

    const validatedPassword = (password: string) => {
        if (password === '') {
            return 'Password is required'
        } else {
            return ''
        }
    }

    if (!isOnForgotPassword) {
        return (
            <DefaultLogin
                isLoading={isLoading}
                inExternalInvoice={inExternalInvoice}
                error={error}
                validateEmail={validateEmail}
                validatedPassword={validatedPassword}
                goToLoginFromAccountCreation={goToLoginFromAccountCreation}
                logoUrl={logoUrl}
            />
        )
    } else {
        return (
            <ForgotPassword
                error={error}
                isLoading={isLoading}
                isOnForgotPasswordSuccess={isOnForgotPasswordSuccess}
                validateEmail={validateEmail}
                logoUrl={logoUrl}
            />
        )
    }
}

export default Login
