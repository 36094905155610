import { InvoiceHistoryState } from './invoiceHistory.state'

const initialState: InvoiceHistoryState = {
    loading: true,
    error: {
        showError: false,
        title: '',
        message: '',
    },
    invoiceDetailsResponse: null,
}

export default initialState
