import Api from './interfaces/Api'
import SessionManager from '../../SessionManager'
import { ApiResponse } from '../types/Response/ApiResponse'
import { InvoiceDetailsResponse } from '../types/Response/InvoiceDetailsResponse'
import { PracticeFeeAndTaxResponse } from '../types/Response/PracticeFeeAndTaxResponse'
import { PracticePayerResponse } from '../types/Response/PracticePayerResponse'
import { LoginResponse } from '../types/Response/LoginResponse'
import { PaymentIntentResponse } from '../types/Response/PaymentIntentResponse'
import { GetFeesForInvoiceResponse } from '../types/Response/GetFeesForInvoiceResponse'
import { PracticeLogoResponse } from '../types/Response/PracticeLogoResponse'
import { PayerUnpaidInvoices } from '../types/Response/PayerUnpaidInvoicesResponse'
import { TransactionsPage } from '../types/TransactionsPage'
import { PayerPaymentMethodsResponse } from '../types/Response/PayerPaymentMethodsResponse'
import { PayInvoiceUseExistingResponse } from '../types/Response/PayInvoiceUseExistingResponse'
import { PaymentPlanResponse } from '../types/Response/PaymentPlanResponse'
import { NextSchedulePaymentResponse } from '../types/Response/NextScheduledPaymentResponse'
import { PayerInfoResponse } from '../types/Response/PayerInfoResponse'
import { PayerAddNewPaymentMethodResponse } from '../types/Response/PayerAddNewPaymentMethodResponse'
import { GetPaymentMethodResponse } from '../types/Response/GetPaymentMethodResponse'
import { UpdatePaymentPlanResponse } from '../types/Response/UpdatePaymentPlanResponse'
import { ChargeEventPendingUpdate } from '../types/Response/ChargeEventPendingUpdate'
import { PayerFailedPayment } from '../types/Response/PayerFailedPayment'
import { CreatePlaidLinkTokenResponse } from '../types/Response/CreatePlaidLinkTokenResponse'
import { PlaidAccountBalanaceResponse } from '../types/Response/PlaidAccountBalanceResponse'
import { CreateAchChargeForPortalResponse } from '../types/Response/CreateAchChargeForPortalResponse'
import { onUserTimeout } from '../reducers/session/session.actions'
import { RequestPasswordResetResponse } from '../types/Response/RequestPasswordResetResponse'
import { CalculateFeesForSubtotalResponse } from '../types/Response/CalculateFeesForSubtotalResponse'
import { ExternalInvoiceLoginResponse } from '../types/Response/ExternalInvoiceLoginResponse'
import { CreateVoluntaryInvoiceResponse } from '../types/Response/CreateVoluntaryInvoiceResponse'
import { PracticeLocationFeeResponse } from '../types/Response/PracticeLocationFeeResponse'
import { PaymentPlan } from '../types/PaymentPlan'
import { GetLocationLogoResponse } from '../types/Response/GetLocationLogoResponse'
import { LogoFromPatientIdResponse } from '../types/Response/LogoFromPatientIdResponse'
import { OffCyclePaymentResponse } from '../types/Response/OffCyclePaymentResponse'
import { PortalPayerResponse } from '../types/Response/PortalPayerResponse'
import { PracticeLocationResponse } from '../types/Response/PracticeLocationResponse'
import { GetGuestUserDetailsResponse } from '../types/Response/GetGuestUserDetailsResponse'
import { LocationFullInfoResponse } from '../types/Response/LocationFullInfoResponse'
import { GetExternalInvoiceExistingPatientResponse } from '../types/Response/ExternalInvoiceResponses'
import { InvoicePaymentMethodsType } from '../types/Constants'

const API_HOST = process.env.REACT_APP_API_HOST

class ApiManager {
    dispatcher: any
    token?: string

    constructor() {
        this.token = SessionManager.sessionToken || SessionManager.sessionGuestToken
    }

    encode = (query: any) => {
        return Object.keys(query)
            .map(key => `${key}=${encodeURIComponent(query[key])}`)
            .join('&')
    }

    get bearer() {
        return this.token ? `Bearer ${this.token}` : undefined
    }

    get headers() {
        return new Headers({ 'Content-Type': 'application/json' })
    }

    get dispatch() {
        return this.dispatcher
    }

    set dispatch(dispatcher: any) {
        this.dispatcher = dispatcher
    }

    set bearer(token: string) {
        this.token = token
    }

    fetch = async (endpoint: string, options: any) => {
        const response = await fetch(endpoint, options)
        if (response.status === 401) {
            this.dispatch(onUserTimeout())
            const error = new Error('redirecting')
            throw error
        }

        const body: ApiResponse<any> = await response.json().catch((err: any) => {
            throw Error(err)
        })

        if (response.ok && body.status !== 'error' && (body.data === null || (body.data && !body.data.error))) {
            return body
        } else {
            throw body
        }
    }

    fetchNoAuth = async (endpoint: string, args: any, options: any = {}) => {
        let url = options.host || API_HOST
        url += endpoint

        if (options.query) {
            url += `?${this.encode(options.query)}`
        }

        args.headers = args.headers || this.headers

        const result = await this.fetch(url, args)
        return result
    }

    fetchAuthed = async (endpoint: string, args: any, options: any = {}) => {
        let url = options.host || API_HOST
        url += endpoint
        if (options.query) {
            url += `?client=portal&${this.encode(options.query)}`
        } else {
            url += '?client=portal'
        }

        args.headers = args.headers || this.headers
        args.headers.set('authorization', this.bearer)

        const result = await this.fetch(url, args)
        return result
    }

    async login(username: string, password: string): Promise<ApiResponse<LoginResponse>> {
        const result: ApiResponse<LoginResponse> = await this.fetch(`${API_HOST}/api/v1/portalUser/login`, {
            method: 'post',
            mode: 'cors',
            headers: this.headers,
            body: JSON.stringify({ username, password }),
        })

        this.token = result.data.token

        return result
    }

    async loginExternalInvoice(
        username: string,
        password: string,
        practiceId: string
    ): Promise<ApiResponse<ExternalInvoiceLoginResponse>> {
        const result: ApiResponse<ExternalInvoiceLoginResponse> = await this.fetch(
            `${API_HOST}/api/v1/portalUser/login/payerless`,
            {
                method: 'post',
                mode: 'cors',
                headers: this.headers,
                body: JSON.stringify({ username, password, practiceId }),
            }
        )

        this.token = result.data.token

        return result
    }

    async logUserOut(): Promise<ApiResponse<{ success: boolean }>> {
        return this.fetchAuthed(`/api/v1/portalUser/logout`, {
            method: 'POST',
            mode: 'cors',
            body: '',
        })
    }

    async getPracticeLogo(practiceId: string): Promise<ApiResponse<PracticeLogoResponse>> {
        const result = await this.fetch(`${API_HOST}/api/v1/practiceLogo?practiceId=${practiceId}`, {
            method: 'get',
            mode: 'cors',
            headers: this.headers,
        })

        return result
    }

    async getLocationLogo(practiceId: string, locationId: string): Promise<ApiResponse<GetLocationLogoResponse>> {
        const result = await this.fetch(
            `${API_HOST}/api/v1/practiceLocation/practiceId/${practiceId}/locationId/${locationId}/logo`,
            {
                method: 'get',
                mode: 'cors',
                headers: this.headers,
            }
        )

        return result
    }

    async getLogoFromPatientId(practiceId: string, payerId: string): Promise<ApiResponse<LogoFromPatientIdResponse>> {
        return this.fetchAuthed(`/api/v1/practiceLocation/practiceId/${practiceId}/payerId/${payerId}/logo`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async requestPasswordReset(
        username: string,
        practiceId: string
    ): Promise<ApiResponse<RequestPasswordResetResponse>> {
        const practiceIdParam: any = {}
        if (practiceId) {
            practiceIdParam.practiceId = practiceId
        }
        const result = await this.fetch(`${API_HOST}/api/v1/portalUser/createPasswordReset`, {
            method: 'post',
            mode: 'cors',
            headers: this.headers,
            body: JSON.stringify({ username, ...practiceIdParam }),
        })

        return result
    }

    async getResetCode(username: string): Promise<ApiResponse<RequestPasswordResetResponse>> {
        return this.fetchAuthed(`/api/v1/portalUser/createPasswordReset`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ username, skipEmail: true }),
        })
    }

    async completePasswordReset(username: string, password: string, code: string): Promise<ApiResponse<any>> {
        const result: ApiResponse<Api.Account> = await this.fetch(
            `${API_HOST}/api/v1/portalUserAccount/portalUserAccountPasswordReset`,
            {
                method: 'post',
                mode: 'cors',
                headers: this.headers,
                body: JSON.stringify({
                    username,
                    newPassword: password,
                    code,
                }),
            }
        )

        return result
    }

    async putRegisterAccount(username: string, password: string, code: string): Promise<ApiResponse<Api.Account>> {
        const result: ApiResponse<Api.Account> = await this.fetch(
            `${API_HOST}/api/v1/portalUserAccount/portalUserAccountPasswordReset`,
            {
                method: 'post',
                mode: 'cors',
                headers: this.headers,
                body: JSON.stringify({
                    username,
                    newPassword: password,
                    code,
                }),
            }
        )

        return result
    }

    async createNewUserForExternalInvoice(
        practiceId: string,
        locationId: string,
        patient: {
            firstName: string
            lastName: string
            dob: string
            phoneNumber: string
            email: string
        }
    ): Promise<ApiResponse<any>> {
        const result: ApiResponse<Api.Account> = await this.fetch(
            `${API_HOST}/api/v1/practices/${practiceId}/location/${locationId}/createExternalUser`,
            {
                method: 'post',
                mode: 'cors',
                headers: this.headers,
                body: JSON.stringify({
                    patient,
                }),
            }
        )

        return result
    }

    async getPortalUserByEmail(email: string): Promise<ApiResponse<PortalPayerResponse>> {
        return this.fetchAuthed(`/api/v1/portalUser/username/${email}`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async getPatientTransactions(
        practiceId: string,
        payerId: string,
        pageNumber: number,
        numOfElements: number,
        status: string
    ): Promise<ApiResponse<TransactionsPage>> {
        return this.fetchAuthed(
            `/api/v1/payers/${payerId}/practices/${practiceId}/transactions`,
            {
                method: 'GET',
                mode: 'cors',
            },
            {
                query: {
                    page: pageNumber,
                    size: numOfElements,
                    status,
                },
            }
        )
    }

    async getPatientPaymentMethods(payerId: string): Promise<ApiResponse<PayerPaymentMethodsResponse>> {
        return this.fetchAuthed(
            `/api/v1/payers/${payerId}/paymentMethods`,
            {
                method: 'GET',
                mode: 'cors',
            },
            {
                query: {
                    activeFlag: true,
                },
            }
        )
    }

    async getInvoiceDetails(practiceId: string, chargeEventId: string): Promise<ApiResponse<InvoiceDetailsResponse>> {
        return this.fetchAuthed(`/api/v1/practices/${practiceId}/transactions/transactionId/${chargeEventId}`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async getInvoiceDetailsGuest(chargeEventId: string): Promise<ApiResponse<InvoiceDetailsResponse>> {
        return this.fetchAuthed(`/api/v1/practices/guest/transactions/${chargeEventId}/invoice`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async getPracticeFeeAndTaxRate(practiceId: string): Promise<ApiResponse<PracticeFeeAndTaxResponse>> {
        return this.fetchAuthed(`/api/v1/practices/${practiceId}/PracticeFee`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async getPayerInvoicesByStatus(
        payerId: string,
        chargeEventStatus: string
    ): Promise<ApiResponse<PayerUnpaidInvoices>> {
        return this.fetchAuthed(
            `/api/v1/chargeEvent/payerId/${payerId}/`,
            {
                method: 'GET',
                mode: 'cors',
            },
            {
                query: {
                    chargeEventType: 'invoice',
                    chargeEventStatus,
                },
            }
        )
    }

    async getAllPayers(portalUserId: string): Promise<ApiResponse<{ practices: Array<PracticePayerResponse> }>> {
        return this.fetchAuthed(`/api/v1/portalUsers/${portalUserId}/practices`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async getLocationInfo(practiceId: string, locationId: string): Promise<ApiResponse<LocationFullInfoResponse>> {
        return this.fetchAuthed(`/api/v1/practiceId/${practiceId}/locationId/${locationId}/fullInfo`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async createPaymentIntent(practiceId: string, chargeEventId: string): Promise<ApiResponse<PaymentIntentResponse>> {
        return this.fetchAuthed(`/api/v1/practices/${practiceId}/payments/${chargeEventId}`, {
            method: 'POST',
            mode: 'cors',
            body: '',
        })
    }

    async getFeesForInvoice(chargeEventId: string): Promise<ApiResponse<GetFeesForInvoiceResponse>> {
        return this.fetchAuthed(`/api/v1/payers/invoices/${chargeEventId}/calculateFees`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async updateChargeEventSelectedPaymentMethod(
        chargeEventId: string,
        selectedPayment: string
    ): Promise<ApiResponse<any>> {
        return this.fetchAuthed(`/api/v1/payments/invoice/transactions/${chargeEventId}`, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify({ paymentMethod: selectedPayment }),
        })
    }

    async payInvoiceUseExistingPaymentMethod(
        practiceId: string,
        chargeEventId: string,
        tokenId: string
    ): Promise<ApiResponse<PayInvoiceUseExistingResponse>> {
        return this.fetchAuthed(
            `/api/v1/practices/${practiceId}/payments/${chargeEventId}`,
            {
                method: 'POST',
                mode: 'cors',
                body: '',
            },
            {
                query: {
                    tokenId: tokenId,
                },
            }
        )
    }

    async getPaymentPlans(currentPayerId: string): Promise<ApiResponse<PaymentPlanResponse>> {
        return this.fetchAuthed(`/api/v1/payers/${currentPayerId}/paymentPlans`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async getNextScheduledPayments(currentPayerId: string): Promise<ApiResponse<NextSchedulePaymentResponse>> {
        return this.fetchAuthed(`/api/v1/payers/${currentPayerId}/payments`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async getPayerInfo(portalUserId: string): Promise<ApiResponse<PayerInfoResponse>> {
        return this.fetchAuthed(`/api/v1/portalUsers/portalUserId/${portalUserId}/payers`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async getPayerAddNewPaymentMethodStripeInfo(
        payerId: string,
        practiceId: string
    ): Promise<ApiResponse<PayerAddNewPaymentMethodResponse>> {
        return this.fetchAuthed(`/api/v1/payments/setup`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                payerId,
                practiceId,
                insertCard: false,
            }),
        })
    }

    async deleteCardPaymentMethod(currentPayerId: string, tokenId: string): Promise<ApiResponse<{ message: string }>> {
        return this.fetchAuthed(`/api/v1/payers/${currentPayerId}/paymentMethods/${tokenId}`, {
            method: 'DELETE',
            mode: 'cors',
            body: '',
        })
    }

    async deleteAchPaymentMethod(practiceId: string, achId: string): Promise<ApiResponse<{ message: string }>> {
        return this.fetchAuthed(`/api/v1/ach/${achId}`, {
            method: 'DELETE',
            mode: 'cors',
            body: JSON.stringify({
                practiceId,
            }),
        })
    }

    async getPaymentMethod(paymentMethodId: string, payerId: string): Promise<ApiResponse<GetPaymentMethodResponse>> {
        return this.fetchAuthed(`/api/v1/payments/payers/${payerId}/payment-methods/${paymentMethodId}`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async getBalanceRemainingForPaymentPlan(paymentPlanId: string): Promise<ApiResponse<PaymentPlan>> {
        return this.fetchAuthed(`/api/v1/paymentPlan/${paymentPlanId}/balance`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async updatePaymentPlanPaymentMethodUseCard(
        paymentPlanId: string,
        tokenId: string
    ): Promise<ApiResponse<UpdatePaymentPlanResponse>> {
        return this.fetchAuthed(`/api/v1/paymentPlan/${paymentPlanId}`, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify({ tokenId }),
        })
    }

    async updatePaymentPlanPaymentMethodUseAch(
        paymentPlanId: string,
        achId: string
    ): Promise<ApiResponse<UpdatePaymentPlanResponse>> {
        return this.fetchAuthed(`/api/v1/paymentPlan/${paymentPlanId}`, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify({ achId }),
        })
    }

    async changeChargeEventStatusToPending(chargeEventId: string): Promise<ApiResponse<ChargeEventPendingUpdate>> {
        return this.fetchAuthed(`/api/v1/chargeEvent/chargeEventId/${chargeEventId}`, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify({ chargeEventStatus: 'stripe_pending' }),
        })
    }

    async changeChargeEventStatusToDefault(chargeEventId: string): Promise<ApiResponse<ChargeEventPendingUpdate>> {
        return this.fetchAuthed(`/api/v1/chargeEvent/chargeEventId/${chargeEventId}`, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify({ chargeEventStatus: 'scheduled' }),
        })
    }

    async getPayerFailedPayments(payerId: string, practiceId: string): Promise<ApiResponse<Array<PayerFailedPayment>>> {
        return this.fetchAuthed(`/api/v1/payments/practiceId/${practiceId}/payerId/${payerId}/failed`, {})
    }

    async createPlaidLinkToken(
        practiceId: string,
        payerId: string
    ): Promise<ApiResponse<CreatePlaidLinkTokenResponse>> {
        return this.fetchAuthed(`/api/v1/ach/createLinkToken`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                payerId,
                practiceId,
            }),
        })
    }

    async createAchCustomerForPortal(
        payerId: string,
        practiceId: string,
        publicToken: string,
        plaidAccountId: string
    ): Promise<ApiResponse<{ achId: string; customerId: string }>> {
        return this.fetchAuthed(`/api/v1/ach/createCustomerForPortal`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                payerId,
                practiceId,
                publicToken,
                plaidAccountId,
            }),
        })
    }

    async createAchChargeForPortal(
        chargeEventId: string,
        customer: string,
        achId: string
    ): Promise<ApiResponse<CreateAchChargeForPortalResponse>> {
        return this.fetchAuthed(`/api/v1/ach/createAchChargeForPortal`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                chargeEventId,
                customer,
                achId,
                balanceCheck: true,
            }),
        })
    }

    async createAndChargeVoluntaryInvoice(
        practiceId: string,
        locationId: string,
        payerId: string,
        patientId: string,
        paymentInfo: {
            paymentMethod: string
            description: string
            subtotal: number
            tokenId: string
        },
        accountType: string
    ): Promise<ApiResponse<CreateVoluntaryInvoiceResponse>> {
        const finalPaymentMethod = paymentInfo.paymentMethod === 'otp' ? 'card' : paymentInfo.paymentMethod

        return this.fetchAuthed(`/api/v1/payments/practice/${practiceId}/createVoluntaryInvoice`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                locationId,
                payerId,
                patientId,
                isTaxable: false,
                paymentMethod: finalPaymentMethod,
                description: paymentInfo.description,
                subtotal: paymentInfo.subtotal,
                tokenId: paymentInfo.tokenId,
                accountType,
            }),
        })
    }

    async createVoluntaryInvoice(
        practiceId: string,
        locationId: string,
        // payerId: string,
        patientId: string,
        paymentInfo: {
            paymentMethod: string
            description: string
            subtotal: number
        },
        accountType: string
    ): Promise<ApiResponse<CreateVoluntaryInvoiceResponse>> {
        const finalPaymentMethod = paymentInfo.paymentMethod === 'otp' ? 'card' : paymentInfo.paymentMethod

        return this.fetchAuthed(`/api/v1/payments/practice/${practiceId}/createVoluntaryInvoice`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                locationId,
                // payerId,
                patientId,
                isTaxable: false,
                paymentMethod: finalPaymentMethod,
                description: paymentInfo.description,
                subtotal: paymentInfo.subtotal,
                accountType,
            }),
        })
    }

    async calculateFeesForGivenSubtotal(
        practiceId: string,
        locationId: string,
        paymentMethod: string,
        subtotal: number[],
        chargeType: string,
        isTaxable: boolean | boolean[]
    ): Promise<ApiResponse<CalculateFeesForSubtotalResponse>> {
        const finalPaymentMethod = paymentMethod === 'otp' ? 'card' : paymentMethod
        return this.fetchAuthed(`/api/v1/payments/calculateFees`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                practiceId,
                locationId,
                paymentMethod: finalPaymentMethod,
                subtotal,
                chargeType,
                isTaxableItem: Array.isArray(isTaxable) ? isTaxable : [isTaxable],
                terminalFlag: false,
            }),
        })
    }

    async updateMismatchCardPaymentAmount({
        practiceId,
        paymentIntentId,
        amount,
        enableSavePaymentMethod,
        applicationFeeAmount,
    }: {
        practiceId: string
        paymentIntentId: string
        amount?: number
        enableSavePaymentMethod: boolean
        applicationFeeAmount?: number
    }): Promise<ApiResponse<unknown>> {
        return this.fetchAuthed(`/api/v1/payments/paymentIntentId/${paymentIntentId}`, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify({
                practiceId,
                amount,
                enableSavePaymentMethod,
                applicationFeeAmount,
            }),
        })
    }

    async updateExternalInvoiceMismatchCardPaymentAmount(
        chargeEventId: string,
        paymentMethod: InvoicePaymentMethodsType
    ) {
        return this.fetchNoAuth(`/api/v1/payments/chargeEventId/${chargeEventId}/paymentMethod`, {
            method: 'PUT',
            mode: 'cors',
            body: JSON.stringify({
                paymentMethod,
            }),
        })
    }

    async getLocationNamesForPractice(practiceId: string): Promise<ApiResponse<Array<PracticeLocationResponse>>> {
        return this.fetch(`${API_HOST}/api/v1/practiceLocation/practiceId/${practiceId}/names`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async getPracticeLocationFee(
        practiceId: string,
        locationId: string
    ): Promise<ApiResponse<PracticeLocationFeeResponse>> {
        return this.fetchAuthed(`/api/v1/practices/${practiceId}/locations/${locationId}/practiceLocationFee`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async makeOffCyclePaymentUseCard(
        paymentPlanId: string,
        subtotal: number,
        taxable: boolean,
        tokenId: string
    ): Promise<ApiResponse<OffCyclePaymentResponse>> {
        return this.fetchAuthed(`/api/v1/paymentPlan/${paymentPlanId}/makeOffCyclePayment`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                subtotal,
                taxable,
                paymentMethod: {
                    tokenId,
                },
            }),
        })
    }

    async makeOffCyclePaymentUseAch(
        paymentPlanId: string,
        subtotal: number,
        taxable: boolean,
        achId: string
    ): Promise<ApiResponse<OffCyclePaymentResponse>> {
        return this.fetchAuthed(`/api/v1/paymentPlan/${paymentPlanId}/makeOffCyclePayment`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                subtotal,
                taxable,
                paymentMethod: {
                    achId,
                },
            }),
        })
    }

    async retryFailedPaymentCredit(
        practiceId: string,
        chargeEventId: string,
        tokenId: string
    ): Promise<ApiResponse<OffCyclePaymentResponse>> {
        return this.fetchAuthed(
            `/api/v1/payments/practiceId/${practiceId}/chargeEventId/${chargeEventId}/retry/failed`,
            {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    tokenId,
                }),
            }
        )
    }

    async retryFailedPaymentAch(practiceId: string, chargeEventId: string, achId: string): Promise<ApiResponse<any>> {
        return this.fetchAuthed(
            `/api/v1/payments/practiceId/${practiceId}/chargeEventId/${chargeEventId}/retry/failed`,
            {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    achId,
                }),
            }
        )
    }

    async fetchPracticeLogoBgColor(practiceId: string): Promise<ApiResponse<any>> {
        return this.fetchNoAuth(`/api/v1/practices/${practiceId}/practiceLogoBackgroundColorCode`, {
            method: 'GET',
            mode: 'cors',
        })
    }

    async getExistingPortalUser(
        email: string,
        chargeEventId: string,
        practiceId: string
    ): Promise<ApiResponse<GetGuestUserDetailsResponse>> {
        return this.fetch(`${API_HOST}/api/v1/portalUsers/exists`, {
            method: 'POST',
            mode: 'cors',
            headers: this.headers,
            body: JSON.stringify({
                email,
                chargeEventId,
                practiceId,
            }),
        })
    }

    getExternalInvoiceExistingPatient(
        practiceId: string,
        firstName: string,
        lastName: string,
        dateOfBirth: string
    ): Promise<ApiResponse<GetExternalInvoiceExistingPatientResponse>> {
        return this.fetchAuthed(`/api/v1/practiceId/${practiceId}/getExternalPatient`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                firstName,
                lastName,
                dateOfBirth,
            }),
        })
    }

    sendExternalInvoiceEmailReceipt(chargeEventId: string, email: string) {
        return this.fetchAuthed(`/api/v1/practices/locations/external-invoices/receipts`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                chargeEventId,
                email,
            }),
        })
    }
}

export default new ApiManager()
