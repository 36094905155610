import { RootState } from '../app/RootState'
import { Dispatch } from 'react'
import { apiResponseContainErrorCode } from '../../shared/utils'
import Api from '../../api/Api'
import { TransactionsPage } from '../../types/TransactionsPage'
import { UnpaidInvoice } from '../../types/UnpaidInvoice'
import { ChargeEventStatusForAPI } from '../../types/Constants/ChargeEventStatus'
import { ScheduledPayment } from '../../types/ScheduledPayment'
import { TransactionTableDefaults } from '../../types/Constants/TransactionTableDefaults'
import { PayerFailedPayment } from '../../types/Response/PayerFailedPayment'
import { getPaymentMethods } from '../payment-methods/paymentMethods.action'
import { addNewPaymentMethodSetupError } from '../add-new-method/addNewMethod.actions'
import {
    updateFailedPaymentDialogError,
    updateFailedPaymentDialogLoading,
    updateFailedPaymentDialogSuccess,
} from '../update-failed-payment-dialog/update-failed-payment-dialog.action'

const REQUEST_LOAD_OVERVIEW_DATA = '[OVERVIEW] REQUEST LOAD OVERVIEW DATA'
const LOAD_OVERVIEW_DATA_SUCCESS = '[OVERVIEW] LOAD OVERVIEW DATA SUCCESS'
const LOAD_OVERVIEW_DATA_ERROR = '[OVERVIEW] LOAD OVERVIEW DATA ERROR'
const REQUEST_PATIENT_TRANSACTIONS_SUCCESS = '[OVERVIEW] REQUEST PATIENT TRANSACTIONS SUCCESS'
const NO_PATIENT_TRANSACTIONS = '[OVERVIEW] NO PATIENT TRANSACTIONS'
const REQUEST_UNPAID_INVOICES_SUCCESS = '[OVERVIEW] UNPAID INVOICES SUCCESS'
const NO_UNPAID_INVOICES = '[OVERVIEW] NO UNPAID INVOICES'
const REQUEST_LATE_INVOICES_SUCCESS = '[OVERVIEW] REQUEST LATE INVOICES SUCCESS'
const NO_LATE_INVOICES = '[OVERVIEW] NO LATE INVOICES'
const REQUEST_NEXT_SCHEDULE_PAYMENT_SUCCESS = '[OVERVIEW] GET NEXT SCHEDULE PAYMENT SUCCESS'
const NO_SCHEDULED_PAYMENTS = '[OVERVIEW] NO SCHEDULED PAYMENTS'
const REQUEST_PENDING_INVOICES_SUCCESS = '[OVERVIEW] REQUEST PENDING INVOICES SUCCESS'
const NO_PENDING_INVOICES = '[OVERVIEW] NO PENDING INVOICES'
const FAILED_PAYMENTS_SUCCESS = '[OVERVIEW] FAILED PAYMENTS SUCCESS'
const NO_FAILED_PAYMENTS = '[OVERVIEW] NO FAILED PAYMENTS'
const REQUEST_FAILED_INVOICES_SUCCESS = '[OVERVIEW] REQUEST FAILED INVOICES SUCCESS'
const NO_FAILED_INVOICES = '[OVERVIEW] NO FAILED INVOICES'

export type Action =
    | { type: typeof REQUEST_LOAD_OVERVIEW_DATA }
    | { type: typeof LOAD_OVERVIEW_DATA_SUCCESS }
    | { type: typeof LOAD_OVERVIEW_DATA_ERROR; payload: { title: string; message: string } }
    | { type: typeof REQUEST_PATIENT_TRANSACTIONS_SUCCESS; payload: { payerTransactions: TransactionsPage } }
    | { type: typeof NO_PATIENT_TRANSACTIONS; payload: string }
    | { type: typeof REQUEST_UNPAID_INVOICES_SUCCESS; payload: { unpaidInvoices: Array<UnpaidInvoice> } }
    | { type: typeof NO_UNPAID_INVOICES; payload: string }
    | { type: typeof REQUEST_LATE_INVOICES_SUCCESS; payload: { lateInvoices: Array<UnpaidInvoice> } }
    | { type: typeof NO_LATE_INVOICES; payload: string }
    | {
          type: typeof REQUEST_NEXT_SCHEDULE_PAYMENT_SUCCESS
          payload: { nextScheduledPayments: Array<ScheduledPayment> }
      }
    | { type: typeof NO_SCHEDULED_PAYMENTS }
    | { type: typeof REQUEST_PENDING_INVOICES_SUCCESS; payload: { pendingInvoices: Array<UnpaidInvoice> } }
    | { type: typeof NO_PENDING_INVOICES }
    | { type: typeof FAILED_PAYMENTS_SUCCESS; payload: { failedPayments: Array<PayerFailedPayment> } }
    | { type: typeof NO_FAILED_PAYMENTS }
    | { type: typeof REQUEST_FAILED_INVOICES_SUCCESS; payload: { failedInvoices: Array<UnpaidInvoice> } }
    | { type: typeof NO_FAILED_INVOICES }

const loadOverviewData = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId },
        } = getState()

        dispatch(requestLoadOverviewData())

        Promise.all([
            dispatch(
                getPatientTransactions(
                    TransactionTableDefaults.START_PAGE,
                    TransactionTableDefaults.NUMBER_OF_RECORDS,
                    TransactionTableDefaults.STATUS
                )
            ),
            dispatch(getPayerUnpaidInvoices(payerId)),
            dispatch(getPayerLateInvoices(payerId)),
            dispatch(getNextScheduledPayment(payerId)),
            dispatch(getPayerPendingInvoices({ payerId: payerId })),
            dispatch(getPayerFailedInvoices({ payerId: payerId })),
            // dispatch(getPayerFailedPayments({ payerId, practiceId })),
        ])
            .then(() => {
                return dispatch(loadOverviewDataSuccess())
            })
            .catch((err: { title: string; message: string }) => {
                err.title = err.title ?? 'Loading Home Data'
                err.message = err.message ?? `Error loading home data for practiceId: and payerId: ${payerId}`
                return dispatch(loadOverviewDataError(err))
            })
    }
}

const requestLoadOverviewData = () => {
    return {
        type: REQUEST_LOAD_OVERVIEW_DATA,
    }
}

const loadOverviewDataSuccess = () => {
    return {
        type: LOAD_OVERVIEW_DATA_SUCCESS,
    }
}

const loadOverviewDataError = (payload: { title: string; message: string }) => {
    return {
        type: LOAD_OVERVIEW_DATA_ERROR,
        payload,
    }
}

const getPatientTransactions = (pageNumber: number, numOfElements: number, status: string) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId, practiceId },
        } = getState()

        try {
            const { data } = await Api.getPatientTransactions(
                practiceId,
                payerId,
                pageNumber + 1, // TODO: table paginator is zero based, api pagination starts at 1
                numOfElements,
                status
            )
            return dispatch(receivePatientTransactionsSuccess({ payerTransactions: data }))
        } catch (err) {
            if (apiResponseContainErrorCode(err as any, 409)) {
                return dispatch(noPatientTransactions())
            } else {
                const error = {
                    title: 'Patient Transactions',
                    message: `Error getting transactions for payerId: ${payerId} and practiceID: ${practiceId}`,
                }
                throw error
            }
        }
    }
}

const receivePatientTransactionsSuccess = (payload: { payerTransactions: TransactionsPage }) => {
    return {
        type: REQUEST_PATIENT_TRANSACTIONS_SUCCESS,
        payload,
    }
}

const noPatientTransactions = () => {
    return {
        type: NO_PATIENT_TRANSACTIONS,
        payload: {},
    }
}

const getPayerUnpaidInvoices = (payerId: string) => {
    return async (dispatch: any) => {
        try {
            const { data } = await Api.getPayerInvoicesByStatus(payerId, ChargeEventStatusForAPI.SCHEDULED)
            if (!data.chargeEvents || data.chargeEvents.length === 0) {
                return dispatch(noUnpaidInvoices())
            } else {
                return dispatch(getPayerUnpaidInvoicesSuccess({ unpaidInvoices: data.chargeEvents }))
            }
        } catch (err) {
            const error = {
                title: 'Unpaid Invoices',
                message: `Error getting invoices for payerId: ${payerId}`,
            }
            throw error
        }
    }
}

const getPayerUnpaidInvoicesSuccess = (payload: { unpaidInvoices: Array<UnpaidInvoice> }) => {
    return {
        type: REQUEST_UNPAID_INVOICES_SUCCESS,
        payload,
    }
}

const noUnpaidInvoices = () => {
    return {
        type: NO_UNPAID_INVOICES,
    }
}

const getPayerLateInvoices = (payerId: string) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await Api.getPayerInvoicesByStatus(payerId, ChargeEventStatusForAPI.UNPAID)
            if (!data.chargeEvents || data.chargeEvents.length === 0) {
                return dispatch(noLateInvoices())
            } else {
                return dispatch(getPayerLateInvoicesSuccess({ lateInvoices: data.chargeEvents }))
            }
        } catch (err) {
            const error = {
                title: 'Late Invoices',
                message: `Error getting late invoices for payerId: ${payerId}`,
            }
            throw error
        }
    }
}

const getPayerLateInvoicesSuccess = (payload: { lateInvoices: Array<UnpaidInvoice> }) => {
    return {
        type: REQUEST_LATE_INVOICES_SUCCESS,
        payload,
    }
}

const noLateInvoices = () => {
    return {
        type: NO_LATE_INVOICES,
    }
}

const getNextScheduledPayment = (payerId: string) => {
    return async (dispatch: any) => {
        try {
            const { data } = await Api.getNextScheduledPayments(payerId)
            if (!data.payments || data.payments.length === 0) {
                return dispatch(noScheduledPayments())
            } else {
                return dispatch(getScheduledPaymentSuccess({ nextScheduledPayments: data.payments }))
            }
        } catch (err) {
            const error = {
                title: 'Scheduled Payments',
                message: `Error getting scheduled payments for payerId: ${payerId}`,
            }
            throw error
        }
    }
}

const getScheduledPaymentSuccess = (payload: { nextScheduledPayments: Array<ScheduledPayment> }) => {
    return {
        type: REQUEST_NEXT_SCHEDULE_PAYMENT_SUCCESS,
        payload,
    }
}

const noScheduledPayments = () => {
    return {
        type: NO_SCHEDULED_PAYMENTS,
    }
}

const getPayerPendingInvoices = (payload: { payerId: string }) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await Api.getPayerInvoicesByStatus(payload.payerId, ChargeEventStatusForAPI.STRIPE_PENDING)
            if (!data.chargeEvents || data.chargeEvents.length === 0) {
                return dispatch(noPayerPendingInvoices())
            } else {
                return dispatch(getPayerPendingInvoicesSuccess({ pendingInvoices: data.chargeEvents }))
            }
        } catch (err) {
            const error = {
                title: 'Pending Invoices',
                message: `Error getting pending invoices for payerId: ${payload.payerId}`,
            }
            throw error
        }
    }
}

const getPayerPendingInvoicesSuccess = (payload: { pendingInvoices: Array<UnpaidInvoice> }) => {
    return {
        type: REQUEST_PENDING_INVOICES_SUCCESS,
        payload,
    }
}

const noPayerPendingInvoices = () => {
    return {
        type: NO_PENDING_INVOICES,
    }
}

const getPayerFailedPayments = () => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId, practiceId },
        } = getState()
        try {
            const { data } = await Api.getPayerFailedPayments(payerId, practiceId)

            // test data to get the failed payment section to show (payments will not go through, but card should update for plan with id 'X')
            // const data = [
            //     {
            //         payment: {
            //             paymentId: 'testpaymentId',
            //             chargeEventId: 'string',
            //             paidTs: '',
            //             updatedTs: '',
            //             paymentStatus: 'failed',
            //         },
            //         chargeEvent: {
            //             chargeEventId: 'testchargeEventId',
            //             totalAmount: 100,
            //             paymentPlanId: 'X',
            //             scheduledTs: '',
            //         },
            //         paymentPlan: {
            //             description: '',
            //         },
            //         card: {
            //             tokenId: 'abc',
            //             last4: '1234',
            //             cardType: 'debit',
            //         },
            //         ach: {
            //             achId: '',
            //             bankName: '',
            //             accountLast4: '',
            //             plaidAccountId: '',
            //         },
            //     },
            // ]

            dispatch(getPayerFailedPaymentsSuccess({ failedPayments: data }))
        } catch (err) {
            if (apiResponseContainErrorCode(err as any, 409)) {
                return dispatch(noFailedPayments())
            } else {
                const error = {
                    title: 'Failed Payments',
                    message: `Error getting failed payments for payerId: ${payerId} and practiceId: ${practiceId}`,
                }
                throw error
            }
        }
    }
}

const noFailedPayments = () => {
    return {
        type: NO_FAILED_PAYMENTS,
    }
}

const getPayerFailedPaymentsSuccess = (payload: { failedPayments: Array<PayerFailedPayment> }) => {
    return {
        type: FAILED_PAYMENTS_SUCCESS,
        payload,
    }
}

const getPayerFailedInvoices = (payload: { payerId: string }) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const { data } = await Api.getPayerInvoicesByStatus(payload.payerId, ChargeEventStatusForAPI.FAILED)
            if (!data.chargeEvents || data.chargeEvents.length === 0) {
                return dispatch(noPayerFailedInvoices())
            } else {
                return dispatch(getPayerFailedInvoicesSuccess({ failedInvoices: data.chargeEvents }))
            }
        } catch (err) {
            const error = {
                title: 'Pending Invoices',
                message: `Error getting pending invoices for payerId: ${payload.payerId}`,
            }
            throw error
        }
    }
}

const getPayerFailedInvoicesSuccess = (payload: { failedInvoices: Array<UnpaidInvoice> }) => {
    return {
        type: REQUEST_FAILED_INVOICES_SUCCESS,
        payload,
    }
}

const noPayerFailedInvoices = () => {
    return {
        type: NO_FAILED_INVOICES,
    }
}

const onFailedPaymentRetryExistingCard = (payload: { chargeEventId: string; tokenId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { practiceId },
        } = getState()

        dispatch(updateFailedPaymentDialogLoading())

        try {
            await Api.payInvoiceUseExistingPaymentMethod(practiceId, payload.chargeEventId, payload.tokenId)
            return dispatch(
                updateFailedPaymentDialogSuccess({
                    message: 'Your payment was processed successfully',
                })
            )
        } catch (err) {
            return dispatch(
                updateFailedPaymentDialogError({
                    title: 'Payment Error',
                    message: 'Unable to process your outstanding payment using the provided payment method',
                })
            )
        }
    }
}

const onFailedPaymentRetryExistingAch = (payload: { chargeEventId: string; achId: string }) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            session: { payerId },
        } = getState()

        dispatch(updateFailedPaymentDialogLoading())

        try {
            const {
                data: {
                    achPaymentMethod: { customerId },
                },
            } = await Api.getPatientPaymentMethods(payerId)
            await Api.createAchChargeForPortal(payload.chargeEventId, customerId, payload.achId)
            return dispatch(
                updateFailedPaymentDialogSuccess({
                    message: 'Your payment was processed successfully',
                })
            )
        } catch (err) {
            return dispatch(
                updateFailedPaymentDialogError({
                    title: 'Payment Error',
                    message: 'Unable to process your outstanding payment using the provided payment method',
                })
            )
        }
    }
}

const failedPaymentaddNewPaymentMethodError = (payload: { title: string; message: string; messageJson: string }) => {
    return async (dispatch: Dispatch<any>) => {
        dispatch(addNewPaymentMethodSetupError(payload))
    }
}

export {
    LOAD_OVERVIEW_DATA_SUCCESS,
    LOAD_OVERVIEW_DATA_ERROR,
    REQUEST_PATIENT_TRANSACTIONS_SUCCESS,
    NO_PATIENT_TRANSACTIONS,
    REQUEST_UNPAID_INVOICES_SUCCESS,
    NO_UNPAID_INVOICES,
    REQUEST_LATE_INVOICES_SUCCESS,
    NO_LATE_INVOICES,
    REQUEST_NEXT_SCHEDULE_PAYMENT_SUCCESS,
    NO_SCHEDULED_PAYMENTS,
    REQUEST_PENDING_INVOICES_SUCCESS,
    NO_PENDING_INVOICES,
    FAILED_PAYMENTS_SUCCESS,
    NO_FAILED_PAYMENTS,
    NO_FAILED_INVOICES,
    REQUEST_FAILED_INVOICES_SUCCESS,
    REQUEST_LOAD_OVERVIEW_DATA,
    loadOverviewData,
    getPatientTransactions,
    onFailedPaymentRetryExistingCard,
    failedPaymentaddNewPaymentMethodError,
    getPayerFailedPayments,
    onFailedPaymentRetryExistingAch,
}
