import React from 'react'
import './PaymentPlanSelection.scss'
import { Button, Grid } from '@material-ui/core'
import { PaymentPlan } from '../../../../types/PaymentPlan'

interface Props {
    paymentPlans: PaymentPlan[]
    selectedPaymentPlanIds: string[]
    onPaymentPlanSelected: (planId: string) => void
}

const PaymentPlanSelection = ({ paymentPlans, selectedPaymentPlanIds, onPaymentPlanSelected }: Props) => {
    const paymentPlanMethodDisplay = ({ paymentPlanPaymentMethod }: PaymentPlan) => {
        return paymentPlanPaymentMethod.bankName !== null
            ? `${paymentPlanPaymentMethod.bankName} ****${paymentPlanPaymentMethod.accountLast4}`
            : `${paymentPlanPaymentMethod.cardType} ****${paymentPlanPaymentMethod.last4}`
    }

    return (
        <div className="payment-plan-selection">
            <div className="title">Update Payment Plans to use new payment method?</div>
            <div>
                {paymentPlans.map((pp, key) => (
                    <Grid container={true} className="plan" key={key}>
                        <Grid item={true} xs={12} sm={12} md={5} className="item">
                            <label htmlFor="paymentPlanDescription">Description</label>
                            <div className="value">{pp.description}</div>
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={4} className="item">
                            <label htmlFor="paymentPlanId">Current Payment Method</label>
                            <div className="value">{paymentPlanMethodDisplay(pp)}</div>
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={3} className="item">
                            <Button
                                onClick={() => {
                                    onPaymentPlanSelected(pp.paymentPlanId)
                                }}
                                id={`${pp.paymentPlanId}_selectButton`}
                                className={
                                    selectedPaymentPlanIds.filter((p) => p === pp.paymentPlanId).length > 0
                                        ? 'plan-selected'
                                        : ''
                                }
                            >
                                {selectedPaymentPlanIds.filter((p) => p === pp.paymentPlanId).length > 0
                                    ? 'Selected'
                                    : 'Select'}
                            </Button>
                        </Grid>
                    </Grid>
                ))}
            </div>
        </div>
    )
}

export default PaymentPlanSelection
